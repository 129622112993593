<template>
  <section class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center" :autotest="getAutotest('close')">
      <button class="close-btn" @click="$emit('close-popup')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="title-div">
        <icon-base width="100" height="100">
          <d-icon-categories />
        </icon-base>
        <div>
          <div class="popup-title">
            <span v-if="showPopupType == 'create'">{{ $t("admin.createItem") }}</span>
            <span v-if="showPopupType == 'edit'">{{ $t("admin.editItem") }}</span>
          </div>
          <div>
            <v-form v-model="formValid" @submit.prevent autocomplete="off" :style="{ margin: '42px 0 72px 0' }">
              <v-text-field
                autofocus
                v-model="itemName"
                class="create-input"
                :label="$t('admin.itemName') + '*'"
                :rules="[rules.required, rules.noAllSpace, rules.itemNameAllowed]"
                autocomplete="chrome-off"
                @input="madeChanges = true"
                :autotest="getAutotest('name-input')"
              ></v-text-field>
            </v-form>
          </div>
          <v-card-actions class="btn-div">
            <v-spacer></v-spacer>
            <d-button
              v-if="showPopupType == 'create'"
              depressed
              round
              class="body-2 white--text text-capitalize btn-width"
              color="#4689F3"
              :disabled="!formValid"
              @click="createSubCategory()"
              light
              autotest="create-item-popup-create-btn"
              >{{ $t("all.create") }}</d-button
            >
            <d-button
              v-if="showPopupType == 'edit'"
              depressed
              round
              class="body-2 white--text text-capitalize btn-width"
              color="#4689F3"
              :disabled="!formValid || itemName == editItemData.name"
              @click="saveCategory()"
              light
              autotest="edit-item-popup-save-btn"
              >{{ $t("all.save") }}</d-button
            >
          </v-card-actions>
        </div>
      </div>
    </v-card>
    <!-- Category already in the system. -->
    <d-alert v-model="isExistingItem" type="alert" :message="$t('admin.alertItemAlreadyInSystem')"></d-alert>

    <div class="popup-close" @click="$emit('close-popup')"></div>
  </section>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconClose2 from "../icons/DIconClose2.vue";
import DIconCategories from "../icons/colored/DIconCategories.vue";
import DAlert from "../ui_components/DAlert.vue";
import { checkAdminCommonCharacters } from "@/js/character-validator/character-validator.js";

export default {
  name: "AdminSettingItemPopup",
  props: {
    showPopupType: String,
    itemsData: Array,
    editItemData: Object,
  },
  components: {
    IconBase,
    DIconClose2,
    DIconCategories,
    DAlert,
  },
  data() {
    return {
      itemName: "",
      formValid: false,
      madeChanges: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        itemNameAllowed: () => !this.itemNameNotAllowed || this.$t("rules.invalidName"),
      },
      isExistingItem: false,
    };
  },
  computed: {
    itemNameNotAllowed() {
      return checkAdminCommonCharacters(this.itemName);
    },
  },
  created() {
    if (this.showPopupType == "edit") {
      this.itemName = this.editItemData.name;
    }
  },
  mounted() {},
  methods: {
    getAutotest(tag) {
      if (this.showPopupType === "create") {
        switch (tag) {
          case "close":
            return "create-item-popup-close-btn";
          case "name-input":
            return "create-item-popup-name-input";
          default:
            return "";
        }
      }
      if (this.showPopupType === "edit") {
        switch (tag) {
          case "close":
            return "edit-item-popup-close-btn";
          case "name-input":
            return "edit-item-popup-name-input";
          default:
            return "";
        }
      }
    },
    createSubCategory() {
      if (this.checkExistingSubCategory()) {
        this.isExistingItem = true;
        return;
      }
      this.$emit("add-subcategory", { name: this.itemName });
      this.$emit("close-popup");
    },
    saveCategory() {
      if (this.checkExistingSubCategory()) {
        this.isExistingItem = true;
        return;
      }
      this.$emit("update-subcategory", {
        id: this.editItemData.id,
        name: this.itemName,
        saveToDb: { name: this.itemName },
      });
      this.$emit("close-popup");
    },
    checkExistingSubCategory() {
      const existingData = this.itemsData.find((item) => item.name.toLowerCase() === this.itemName.toLowerCase());
      return existingData != null && existingData.id != this.editItemData.id;
    },
  },
};
</script>
<style scoped>
.user-invite-create {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999 !important;
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 28px 20px 0 20px;
}
.popup-title {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-top: 12px;
}

.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
.btn-div {
  margin-top: 32px;
}
.btn-width {
  width: 120px;
}
</style>
