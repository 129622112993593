import axios from "axios";
import environment from "../../env";

const getBaseUrl = () => {
  const base =
    environment.backendType === "firebase"
      ? environment.service[environment.env].admin
      : environment.service[environment.env]["china"].admin;
  return `${base}sso`;
};
const ssoServer = axios.create({
  baseURL: getBaseUrl(),
});

const config = {
  token: "rG5kXk0CDbhgF4RBlNoV",
};

const checkIsExpectedError = (error) => {
  const responseStatus = error?.response?.status ?? null;
  const messageData = error?.response?.data ?? "";
  return responseStatus === 404 && messageData.startsWith("SSO not supported");
};

export async function getCheckIdpByEmail(email) {
  try {
    const requestBody = {
      token: config.token,
      email: email,
    };
    const { data } = await ssoServer.post("/check-idp", requestBody);
    return { ok: true, data, isExpectedErrorForNotSupportSSO: false };
  } catch (error) {
    const isExpectedErrorForNotSupportSSO = checkIsExpectedError(error);
    return { ok: false, data: {}, error, isExpectedErrorForNotSupportSSO };
  }
}

export async function getCheckIdpByHostname(hostname) {
  try {
    const requestBody = {
      token: config.token,
      hostname,
    };
    const { data } = await ssoServer.post("/check-idp", requestBody);
    return { ok: true, data, isExpectedErrorForNotSupportSSO: false };
  } catch (error) {
    const isExpectedErrorForNotSupportSSO = checkIsExpectedError(error);
    return { ok: false, data: {}, error, isExpectedErrorForNotSupportSSO };
  }
}
