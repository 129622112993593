import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import Vue from "vue";
import { getTranslationLanguageCode } from "@/js/workflow/checkLanguage.js";
import deephowApiServerWrapper from "@/server/api-server";

export const baseURL =
  environment.backendType === "firebase"
    ? environment.service[environment.env].language
    : environment.service[environment.env]["china"].language;

export async function getIdToken() {
  return authChoice().currentUser.getIdToken();
}
export async function getTranslationSentence(sentence, source, target) {
  try {
    const token = await getIdToken();
    if (!sentence) {
      throw "no sentence";
      return;
    }
    const sourceLang = getTranslationLanguageCode(source);
    const targetLang = getTranslationLanguageCode(target);
    const response = await axios({
      method: "post",
      url: baseURL + "v1/translations",
      headers: {
        "Content-Type": "application/json",
        "x-user-token": token,
      },
      data: {
        sentence: sentence,
        source: sourceLang,
        target: targetLang,
      },
    });
    return { ok: true, sentence: response.data.item.sentence };
  } catch (error) {
    console.log(error);
    return { ok: false, sentence: "", error };
  }
}

export const saveTranslationWorkflowTitle = (workflowId, titleTranslations) =>
  deephowApiServerWrapper("patch", `/v1/workflows/${workflowId}`, {
    data: {
      titleTranslations,
    },
  });

export const saveTranslationStepTitle = (stepId, titleTranslations) =>
  deephowApiServerWrapper("patch", `/v1/steps/${stepId}`, {
    data: {
      titleTranslations,
    },
  });
