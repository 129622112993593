<template>
  <div class="PlayerRustici">
    <!-- loading -->
    <player-loading v-if="loadingMessage">
      <div>
        <div class="PlayerRustici__loading" v-html="loadingMessage" />
        <div class="PlayerRustici__poweredBy">
          <span class="text-small">powered by</span> <span class="text-primary">deep</span>how
        </div>
      </div>
    </player-loading>

    <!-- unpublished video -->
    <player-unpublished-video
      v-else-if="errorMessage"
      isPublic
      :isUsingIframe="isUsingIframe"
      :isMobileDevice="isMobile"
    >
      {{ errorMessage }}
    </player-unpublished-video>

    <component
      v-else
      :is="isMobile ? 'player-processed-video-mobile' : 'player-processed-video'"
      :ref="isMobile ? 'PlayerProcessedVideoPhone' : 'PlayerProcessedVideo'"
      :workflow="workflowData"
      :steps="getters_steps"
      :attachmentsData="getter_attachments"
      :stepId="stepId"
      :startTimestamp="startTimestamp"
      :isFromRustici="isFromRustici"
      :isUsingIframe="isUsingIframe"
      :iframeFullscreen="iframeFullscreen"
      :isPlayerAttachmentShowUp.sync="isHidePlayerAnnotation"
      @workflow-ended="handleWorkflowEnded"
      @fetchVideoDataAndCurrentStepIndex="fetchVideoDataAndCurrentStepIndex"
    >
      <template slot="annotation">
        <PlayerAnnotation :isHideCanvas.sync="isHidePlayerAnnotation" :workflow="workflowData" :videoData="videoData" />
      </template>
      <template slot="append-video">
        <player-quiz-mask v-if="isShowPlayerQuizMask" @close-mask="isShowPlayerQuizMask = false" />
      </template>
    </component>
  </div>
</template>

<script>
import Analytics from "@/js/analytics/analytics";
import PlayerLoading from "@/components/DWorkflowPlayer/player/message/PlayerLoading.vue";
import PlayerProcessedVideo from "@/components/DWorkflowPlayer/player/PlayerProcessedVideo.vue";
import PlayerProcessedVideoMobile from "@/components/DWorkflowPlayer/player/PlayerProcessedVideoMobile.vue";
import PlayerUnpublishedVideo from "@/components/DPlayer/PlayerUnpublishedVideo.vue";
import PlayerAnnotation from "@/components/DPlayer/PlayerAnnotation";
import { mapActions, mapState, mapGetters } from "vuex";
import { IS_MOBILE } from "@/constants/device-version";
import { validateRusticiLogin } from "@/server/admin-server";
import {
  IS_LOCAL_DEV_MODE,
  IS_FROM_RUSTICI,
  countDuration,
  getBookmark,
  getLessonStatus,
  getRusticiToken,
  getStudentInfo,
  getSuspendData,
  setBookmark,
  setReachedEnd,
  setSuspendData,
  setRusticiInfo,
} from "@/js/video-player/rustici";
import MixinSkills from "@/components/MixinSkills";
import MixinUser from "@/components/MixinUser";
import { loggingError } from "@/server/error-log-server.js";

const CHECK_STATUS = {
  TODO: "TODO",
  FAIL: "FAIL",
  SUCCESS: "SUCCESS",
  SUCCESS_NEW_USER: "SUCCESS_NEW_USER",
};
export default {
  name: "PlayerRustici",
  mixins: [MixinSkills, MixinUser],
  components: {
    PlayerQuizMask: () => import("@/components/DWorkflowPlayer/player/message/PlayerQuizMask.vue"),
    PlayerLoading,
    PlayerProcessedVideo,
    PlayerProcessedVideoMobile,
    PlayerUnpublishedVideo,
    PlayerAnnotation,
  },
  data() {
    const videoData = {
      clientWidth: 0,
      clientHeight: 0,
      currentStepIndex: 0,
      currentVideoTime: 0,
    };
    return {
      countApi: 0,
      videoData,
      workflowId: "",
      stepId: "",
      lastestWatchedTotalTime: 0,
      startTimestamp: 0,
      isCompleted: false,
      isMobile: IS_MOBILE,
      isFromRustici: IS_FROM_RUSTICI,
      isUsingIframe: true,
      iframeFullscreen: false,
      isWorkflowLoading: true,
      isBookmarkLoading: IS_LOCAL_DEV_MODE ? false : true,
      isHidePlayerAnnotation: false,
      isShowPlayerQuizMask: false,
      isShowWelcomeMessage: false,
      checkUserStatus: CHECK_STATUS.TODO,
      recordToRXDTimer: null,
    };
  },
  computed: {
    ...mapState("auth", ["isLogin", "userProfile"]),
    ...mapState("workflowPlayer", ["workflowData", "showErrorMsg"]),
    ...mapGetters("workflowPlayer", ["getters_steps", "getter_attachments"]),
    ...mapGetters("workflowPlayer", ["getters_steps", "getter_attachments", "getter_is_wait_to_quiz"]),
    ...mapGetters("permission", ["getter_quiz_enabled"]),
    errorMessage() {
      if (!this.isFromRustici || this.checkUserStatus === CHECK_STATUS.FAIL) return this.$t("admin.noPermission");
      if (this.showErrorMsg) return this.$t("player.thisWorkflowIsNotAvailable");
      return "";
    },
    loadingMessage() {
      if (this.checkUserStatus === CHECK_STATUS.FAIL) return "";
      if (!this.isLogin) return this.$t("player.loadingUserInfo");
      if (this.isShowWelcomeMessage) return this.$t("auth.welcomeToDeepHow").replace("DeepHow", "<b>DeepHow</b>");
      if (this.isBookmarkLoading || this.isWorkflowLoading) return this.$t("player.loadingVideo");
      return "";
    },
    totalDuration() {
      return countDuration(this.getters_steps, "duration");
    },
  },
  watch: {
    isLogin: {
      handler(isLogin) {
        if (isLogin && [CHECK_STATUS.SUCCESS, CHECK_STATUS.SUCCESS_NEW_USER].includes(this.checkUserStatus)) {
          this.initWorkflowWhenAuthReady();
        }
      },
    },
    checkUserStatus: {
      handler(checkUserStatus) {
        if (this.isLogin && [CHECK_STATUS.SUCCESS, CHECK_STATUS.SUCCESS_NEW_USER].includes(checkUserStatus)) {
          this.initWorkflowWhenAuthReady();
        }
      },
    },
  },
  async created() {
    this.$store.dispatch("auth/logout");
    if (!this.isFromRustici) {
      this.closeLoading();
      return;
    }
    this.validateRusticiLogin();
    window.addEventListener("fullscreenchange", this.fullscreenChangeHandler);
  },
  beforeDestroy() {
    this.$store.dispatch("auth/logout");
    Analytics.removeEventProperties("Workflow ID");
    Analytics.removeEventProperties("Workspace ID");
    clearInterval(this.recordToRXDTimer);
    window.removeEventListener("fullscreenchange", this.fullscreenChangeHandler);
    this.clearAllWorkflowPlayerData();
  },
  methods: {
    ...mapActions("workflowPlayer", [
      "clearWorkflowData",
      "clearAllWorkflowPlayerData",
      "fetchWorkflowData",
      "showErrorMessage",
    ]),
    async initWorkflowWhenAuthReady() {
      if (this.checkUserStatus === CHECK_STATUS.SUCCESS_NEW_USER) {
        this.isShowWelcomeMessage = true;
        setTimeout(() => {
          this.isShowWelcomeMessage = false;
        }, 2000);
      }

      this.workflowId = this.$route.params.workflowId;
      this.stepId = this.$route.params.stepId;
      if (!this.stepId) {
        this.setLastestWatchedStepAndTime();
      }
      this.getTrackingDocumentAndSetAnalyticsTrack(); // (optional) without async to prevent blocked flow
      const { isSuccess, workflow } = await this.fetchWorkflowData({ workflowId: this.workflowId });
      if (!isSuccess || !workflow.published) {
        this.showErrorMessage();
        this.clearWorkflowData();
        this.closeLoading();
        return;
      }
      this.setRecordTimer();
      this.closeLoading();
    },
    async validateRusticiLogin() {
      try {
        const studentInfo = getStudentInfo();
        const rusticiToken = getRusticiToken();
        if (!studentInfo || !rusticiToken) {
          throw "IS NOT FROM RUSTICI";
        }
        const { ok, data, errorMessage } = await validateRusticiLogin(rusticiToken, studentInfo);
        if (!ok) {
          throw `VALIDATE FAILED - ${errorMessage}`;
        }
        await this.auth().signInWithCustomToken(data.item.customToken);

        // Set Rustici Info for tracking.
        setRusticiInfo({ studentId: studentInfo.studentId, type: data.item.type });

        this.checkUserStatus = data.item.isNewUser ? CHECK_STATUS.SUCCESS_NEW_USER : CHECK_STATUS.SUCCESS;
      } catch (e) {
        console.log(e, "==");
        this.checkUserStatus = CHECK_STATUS.FAIL;
        this.closeLoading();
      }
    },
    async getTrackingDocumentAndSetAnalyticsTrack() {
      try {
        const data = await this.getUserWorkflowTracking({ uid: this.userProfile.id }, { id: this.workflowId });
        Analytics.setTrack({
          category: "PlayerMain",
          action: "Watch Workflow",
          name: "Watch Workflow",
          params: {
            dimension3: this.workflowId,
            dimension4: data[0].id,
            dimension5: this.workflowData.group,
          },
        });
      } catch (e) {
        if (e.code === "permission-denied" && this.countApi < 3) {
          setTimeout(() => {
            this.getTrackingDocumentAndSetAnalyticsTrack();
          }, 3000);
        }
        loggingError(e);
      } finally {
        this.countApi++;
      }
    },
    closeLoading() {
      this.isWorkflowLoading = false;
      this.isBookmarkLoading = false;
    },
    recordToRXD() {
      const refsPlayer = this.isMobile ? this.$refs.PlayerProcessedVideoPhone : this.$refs.PlayerProcessedVideo;
      if (!refsPlayer) return;

      const stepId = this.getters_steps?.[refsPlayer?.currentStep]?.id;
      if (stepId) {
        const currentVideoTime = refsPlayer?.currentVideoTime ?? 0;
        setBookmark(`stepId=${stepId}&videoTime=${currentVideoTime}`);
      }

      const status = getLessonStatus();
      const steps = refsPlayer.workflowTracker ? Object.values(refsPlayer.workflowTracker.steps) : [];
      const watchedTime = countDuration(steps, "playedDuration");
      const totalWatchedTime = this.lastestWatchedTotalTime + watchedTime;
      this.isCompleted = totalWatchedTime >= this.totalDuration * 0.9;
      console.log("==totalWatchedTime==", this.isCompleted, totalWatchedTime);
      if (this.isCompleted && !["completed", "passed", "failed"].includes(status)) {
        setReachedEnd();
      }
      if (isNaN(totalWatchedTime)) {
        return;
      }
      setSuspendData(`watchedTime=${totalWatchedTime}`);
    },
    setRecordTimer() {
      this.setLastestWatchedTotalTime();
      this.recordToRXDTimer = setInterval(() => {
        this.recordToRXD();
      }, 3000);
    },
    setLastestWatchedTotalTime() {
      const suspendData = getSuspendData();
      const matchedResult = suspendData.match(/^watchedTime=(\S*)$/);
      if (matchedResult) {
        this.lastestWatchedTotalTime = Number(matchedResult[1]);
      }
    },
    setLastestWatchedStepAndTime() {
      const bookmarkContent = getBookmark();
      const matchedResult = bookmarkContent.match(/^stepId=(\S*)&videoTime=(\S*)$/);
      if (matchedResult) {
        const [, stepId, startTimestamp] = matchedResult;
        this.stepId = stepId;
        this.startTimestamp = Number(startTimestamp);
      }
      this.isBookmarkLoading = false;
    },
    fullscreenChangeHandler() {
      this.iframeFullscreen = !this.iframeFullscreen;
      if (this.iframeFullscreen) {
        this.$refs.PlayerProcessedVideo.playing = true;
      } else {
        this.$refs.PlayerProcessedVideo.closeAllComponents();
        this.$refs.PlayerProcessedVideo.playing = false;
      }
    },
    fetchVideoDataAndCurrentStepIndex(videoData) {
      for (const key in videoData) {
        if (Object.prototype.hasOwnProperty.call(videoData, key)) {
          this.videoData[key] = videoData[key];
        }
      }
    },
    handleWorkflowEnded() {
      if (this.getter_is_wait_to_quiz && this.getter_quiz_enabled) {
        this.isShowPlayerQuizMask = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.PlayerRustici {
  position: fixed;
  inset: 0;
  background: var(--dGrey1-color);
  align-items: center;
  justify-content: center;
  display: flex;
  &__poweredBy,
  &__loading {
    font-size: 16px;
  }
  .text-small {
    font-size: 12px;
  }
  &__poweredBy {
    z-index: 2;
    position: fixed;
    right: 16px;
    bottom: 16px;
  }
}
</style>
