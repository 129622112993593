<template>
  <div v-show="getter_mobile_divided_screen" class="PlayerAttachmentsMobile">
    <div class="PlayerAttachmentsMobile__topBar">
      <div class="PlayerAttachmentsMobile__topBar--title">
        {{ $t("all.attachments") }}
      </div>
      <button class="PlayerAttachmentsMobile__topBar--closeBtn" @click="closeAttachments">
        <icon-base width="18" height="18" iconColor="#9397a6">
          <d-icon-close-2 />
        </icon-base>
      </button>
    </div>
    <div class="PlayerAttachmentsMobile__buttonsRow">
      <attachment-btn-group isMobileDevice large :value="showPart" @input="updateShowPart($event)" />
    </div>
    <div class="PlayerAttachmentsMobile__content">
      <div ref="attachmentsWrapper" class="PlayerAttachmentsMobile__list">
        <div v-for="attachments in getter_current_step_attachments" :key="attachments.type">
          <div ref="diagram">
            <div
              v-for="(item, idx) in attachments.diagrams"
              :key="attachments.type + idx"
              class="mb-3"
              @click.stop="onclickDiagram(idx)"
            >
              <attachment-diagram-card :imgURL="item" />
            </div>
          </div>
          <div ref="links">
            <div v-for="(item, idx) in attachments.links" :key="attachments.type + idx">
              <attachment-link-card :title="item.label" :link="item.link" />
            </div>
          </div>
          <div ref="pdf">
            <div v-for="(item, idx) in attachments.pdfs" :key="attachments.type + idx">
              <attachment-pdf-card :item="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDiagramPopup" class="PlayerAttachmentsMobile__diagram">
      <div class="PlayerAttachmentsMobile__diagram--carousel">
        <d-carousel
          pagingDots
          :value="currentDiagramIdx"
          :items="getter_current_step_attachments_diagrams"
          @input="updateCurrentIndex($event)"
        />
      </div>
      <div class="PlayerAttachmentsMobile__diagram--number">
        <button class="PlayerAttachmentsMobile__diagram--closeBtn" @click="onclickCloseDiagram">
          <icon-base width="18" height="18" iconColor="#9397a6">
            <d-icon-close-2 />
          </icon-base>
        </button>
        <span class="PlayerAttachmentsMobile__diagram--numText" v-html="diagramsNum"></span>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import AttachmentBtnGroup from "@/components/DWorkflowPlayer/attachments/AttachmentBtnGroup.vue";
import AttachmentDiagramCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentDiagramCard.vue";
import AttachmentLinkCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentLinkCard.vue";
import AttachmentPdfCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentPdfCard.vue";
import DCarousel from "@/components/ui_components/DCarousel.vue";
import { getPagingNumber } from "@/js/common/formatText.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PlayerAttachmentsMobile",
  props: {
    currentStepName: {
      type: String,
    },
    currentStep: {
      type: Number,
    },
  },
  components: {
    IconBase,
    DIconClose2,
    AttachmentBtnGroup,
    AttachmentDiagramCard,
    AttachmentLinkCard,
    AttachmentPdfCard,
    DCarousel,
  },
  watch: {
    getter_current_step_attachments_diagrams() {
      this.onclickCloseDiagram();
    },
  },
  data() {
    return {
      showPart: "diagram",
      currentDiagramIdx: 0,
      showDiagramPopup: false,
    };
  },
  methods: {
    ...mapActions("workflowPlayer", [
      "setSelectedAttachmentStep",
      "closeAttachmentsPopup",
      "enterPictureInPictureMode",
      "exitPictureInPictureMode",
    ]),
    updateShowPart(event) {
      this.showPart = event;
      const part = event;
      if (this.$refs[part] && this.$refs[part][0]) {
        const offsetTop = this.$refs[part][0].offsetTop;
        const container = this.$refs.attachmentsWrapper;
        container.scrollTo({
          top: offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    closeAttachments() {
      this.closeAttachmentsPopup();
      this.setSelectedAttachmentStep({ step: {} });
    },
    updateCurrentIndex(event) {
      this.currentDiagramIdx = event;
    },
    onclickDiagram(idx) {
      this.currentDiagramIdx = idx;
      this.showDiagramPopup = true;
      this.enterPictureInPictureMode();
    },
    onclickCloseDiagram() {
      this.showDiagramPopup = false;
      this.exitPictureInPictureMode();
    },
  },
  computed: {
    ...mapGetters("workflowPlayer", [
      "getter_current_step_attachments",
      "getter_mobile_divided_screen",
      "getter_current_step_attachments_diagrams",
    ]),
    diagramsNum() {
      return getPagingNumber({
        current: this.currentDiagramIdx + 1,
        total: this.getter_current_step_attachments_diagrams.length,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.PlayerAttachmentsMobile {
  z-index: 9;
  position: absolute;
  height: 100%;
  width: 30%;
  top: 0;
  right: 0;
  background: #121314;
  &__topBar {
    position: relative;
    height: 60px;
    width: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--closeBtn {
      margin-bottom: -6px;
    }
    &--title {
      font-size: 16px;
      line-height: 19px;
      color: #9397a6;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
  &__buttonsRow {
    position: relative;
    height: 60px;
    width: 100%;
    padding: 0 30px 24px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__content {
    position: relative;
    height: calc(100% - 120px);
    width: 100%;
  }
  &__list {
    position: absolute;
    width: 100%;
    height: calc(100% - 24px);
    top: 0;
    left: 0;
    padding: 0 24px 0 30px;
    overflow: auto;
    border-right: 1px solid #33353a;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: #585858;
    }
  }
  &__diagram {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #121314;
    display: flex;
    &--carousel {
      position: relative;
      width: 70%;
      height: 100%;
      padding: 24px 0 24px 48px;
    }
    &--number {
      position: relative;
      width: 30%;
      height: 100%;
      padding: 24px;
      text-align: left;
    }
    &--closeBtn {
      position: absolute;
      right: 30px;
      top: 21px;
    }
    &--numText {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      & ::v-deep span {
        font-weight: 400;
        color: #9397a6;
      }
    }
  }
}
</style>
