function getMixinIdleRef(binding) {
  if (binding.modifiers.mixinIdleRef) {
    return binding.value;
  }
}
function getMixinIdleId(binding) {
  return binding.arg;
}
function stopIdleTimer(binding) {
  const mixinIdleRef = getMixinIdleRef(binding);
  const mixinIdleID = getMixinIdleId(binding);
  if (mixinIdleRef && typeof mixinIdleRef.onDirectiveIdleTimeout === "function") {
    mixinIdleRef.onDirectiveIdleTimeout(true, mixinIdleID);
  }
}

function startIdleTimer(binding) {
  const mixinIdleRef = getMixinIdleRef(binding);
  const mixinIdleID = getMixinIdleId(binding);
  if (mixinIdleRef && typeof mixinIdleRef.onDirectiveIdleTimeout === "function") {
    mixinIdleRef.onDirectiveIdleTimeout(false, mixinIdleID);
  }
}

export default {
  bind(el, binding, vnode) {
    stopIdleTimer(binding);
  },
  unbind(el, binding, vnode) {
    startIdleTimer(binding);
  },
};
