import store from "@/store/index";
import { i18n } from "@/main";
const copyByExecCommand = (text) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
};

export const copyText = async (copyContent, successMessage) => {
  if (!copyContent) return;
  await copy(copyContent);
  const message = successMessage ?? i18n.t("editor.transcriptionCopySuccess");
  store.dispatch("global/openAlert", { message, type: "success" });
};

export const copy = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (e) {
    // prevent iframe not support navigator.clipboard api
    console.log("copy failed reason ==>", e);
    copyByExecCommand(text);
  }
};
export const getSentenceText = (stepSentences) => stepSentences.map(({ sentence }) => sentence).join("\n");
