<template lang="html">
  <section class="mixin-db">
    <!-- <h1>mixin-db Component</h1> -->
  </section>
</template>

<script lang="js">
import db from '../../db.js';
import { loggingError } from '@/server/error-log-server.js';
  export default  {
    name: 'mixin-db',
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
    deleteDocument(collection, documentId){
      return db.deleteDocument.call(this, collection, documentId);
      // return new Promise(function(resolve, reject){
      //   this.$db.collection(collection).doc(documentId).delete()
      //   .then(function() {
      //     console.log("Document successfully deleted!");
      //     resolve();
      //   }.bind(this))
      //   .catch(function(error) {
      //     console.error("Error removing document: ", error);
      //     reject(error);
      //   });
      // }.bind(this));
    },
    duplicateDocument(collection, documentId){
        return db.duplicateDocument.call(this, collection, documentId);
        // return new Promise(function(resolve, reject){
        //   this.$db.collection(collection).doc(documentId).get()
        //   .then(function(data) {
        //       const newDocumentId = this.$db.collection(collection).doc().id;
        //       let newData = data.data();
        //       newData["id"] = newDocumentId;
        //       this.addDocument(collection, newDocumentId, newData).then(function(){
        //         resolve(newDocumentId);
        //       }
        //       )
        //   }.bind(this))
        //   .catch(function(error) {
        //       reject(error);
        //   });
        // }.bind(this));
      },
    duplicateDocumentToOrg(collection, documentId, organization){
      return db.duplicateDocumentToOrg.call(this, collection, documentId, organization);
        // return new Promise(function(resolve, reject){
        //   this.$db.collection(collection).doc(documentId).get()
        //   .then(function(data) {
        //       const newDocumentId = this.$db.collection(collection).doc().id;
        //       let newData = data.data();
        //       newData["id"] = newDocumentId;
        //       newData["organization"] = organization
        //       this.addDocument(collection, newDocumentId, newData).then(function(){
        //         resolve();
        //       }
        //       )
        //   }.bind(this))
        //   .catch(function(error) {
        //       reject(error);
        //   });
        // }.bind(this));
      },
      //
      // getDocumentByWhere(collection, whereArray, limit){
      //   return db.getDocumentByWhere.call(this, collection, whereArray, limit);
        // const promiseTemplate1 = `return new Promise(function(resolve, reject){`
        // const collectionTemplate = `this.$db.collection("${collection}")`;
        // let whereTemplate ="";
        // let hasOrganization = false;
        // whereArray.forEach(where => {
        //   whereTemplate += `.where("${where.field}","${where.comparison}","${where.value}")`;
        //   if(where.field == "organization"){
        //     hasOrganization = true;
        //   }
        // });
        // if(!hasOrganization){
        //   whereTemplate += `.where("organization", "==", "${this.$organization}")`;
        // }
        // if(limit){
        //   whereTemplate += `.limit(${limit}`;
        // }
        // const thenTemplate = `.get().then(function(querySnapshot) {
        //         let docuemnts = [];
        //         querySnapshot.forEach(function(doc) {
        //             docuemnts.push(doc.data());
        //         });
        //         resolve(docuemnts);
        //     }).catch(function(error) {
        //         reject(error);
        //     });`
        // const promiseTemplate2 = `}.bind(this));`;
        // const query = promiseTemplate1 + collectionTemplate + whereTemplate + thenTemplate + promiseTemplate2;
        // return Function(query).bind(this)();
      // },
      getDocumentByQuery(collection, comparisons, methods){
        return db.getDocumentByQuery.call(this, collection, comparisons, methods);
        // const promiseTemplate1 = `return new Promise(function(resolve, reject){`
        // const collectionTemplate = `this.$db.collection("${collection}")`;
        // let whereTemplate ="";
        // let hasOrganization = false;
        // comparisons.forEach(where => {
        //   whereTemplate += `.where("${where.field}","${where.comparison}","${where.value}")`;
        //   if(where.field == "organization"){
        //     hasOrganization = true;
        //   }
        // });
        // if(!hasOrganization){
        //   whereTemplate += `.where("organization", "==", "${this.$organization}")`;
        // }
        // if(methods){
        //   methods.forEach(method=>{
        //     whereTemplate += `.${method.type}(${method.value})`
        //   })
        // }
        // const thenTemplate = `.get().then(function(querySnapshot) {
        //         let docuemnts = [];
        //         querySnapshot.forEach(function(doc) {
        //             docuemnts.push(doc.data());
        //         });
        //         resolve(docuemnts);
        //     }).catch(function(error) {
        //         reject(error);
        //     });`
        // const promiseTemplate2 = `}.bind(this));`;
        // const query = promiseTemplate1 + collectionTemplate + whereTemplate + thenTemplate + promiseTemplate2;
        // return Function(query).bind(this)();
      },
      getDocumentByQuery2(collection, comparisons, methods, callback){
        return db.getDocumentByQuery2.call(this, collection, comparisons, methods, callback);
      },
      getDocumentListener(collection, documentId, callback){
          return db.getDocumentListener.call(this, collection, documentId, callback);
          // const listener = this.$db.collection(collection).doc(documentId)
          // .onSnapshot(function(doc) {
          //   callback(doc);
          // });
          // return listener;
      },
      getDocumentQueryListener(collection, comparisons, methods, callback){
        return db.getDocumentQueryListener.call(this, collection, comparisons, methods, callback);
      },
      getDocumentByFieldValue(collection, field, value){
        return db.getDocumentByFieldValue.call(this, collection, field, value);
        // return new Promise(function(resolve, reject){
        //   if(field == "organization"){
        //     this.$db.collection(collection)
        //     .where(field, "==", value)
        //     // .where("organization", "==", this.$organization)
        //     .get()
        //     .then(function(querySnapshot) {
        //         debugger
        //         if(querySnapshot.metadata.fromCache){
        //           throw "Connection to database failed."
        //         }
        //         let docuemnts = [];
        //         querySnapshot.forEach(function(doc) {
        //             docuemnts.push(doc.data());
        //         });
        //         resolve(docuemnts);
        //     }).catch(function(error) {
        //         debugger
        //         reject(error);
        //     });
        //   }else{
        //     this.$db.collection(collection)
        //     .where(field, "==", value)
        //     .where("organization", "==", this.$organization)
        //     .get()
        //     .then(function(querySnapshot) {
        //         debugger
        //         if(querySnapshot.metadata.fromCache){
        //           throw "Connection to database failed."
        //         }
        //         let docuemnts = [];
        //         querySnapshot.forEach(function(doc) {
        //             docuemnts.push(doc.data());
        //         });
        //         resolve(docuemnts);
        //     }).catch(function(error) {
        //         debugger
        //         reject(error);
        //     });
        //   }

        // }.bind(this));
      },
      getDocumentByArrayElement(collection, field, element){
        return db.getDocumentByArrayElement.call(this, collection, field, element);
        // return new Promise(function(resolve, reject){
        //   this.$db.collection(collection)
        //   .where(field, "array-contains", element).get()
        //   .then(function(querySnapshot) {
        //       let docuemnts = [];
        //       querySnapshot.forEach(function(doc) {
        //           docuemnts.push(doc.data());
        //       });
        //       resolve(docuemnts);
        //   }).catch(function(error) {
        //       reject(error);
        //   });
        // }.bind(this));
      },
      getAllDocument(collection){
        return db.getAllDocument.call(this, collection);
        // return new Promise(function(resolve, reject){
        //   this.$db.collection(collection).get()
        //   .then(function(querySnapshot) {
        //       let docuemnts = [];
        //       querySnapshot.forEach(function(doc) {
        //           docuemnts.push(doc.data());
        //       });
        //       resolve(docuemnts);
        //   }).catch(function(error) {
        //       reject(error);
        //   });
        // }.bind(this));
      },
      getDocument(collection, documentId){
        return db.getDocument.call(this, collection, documentId)
        // return new Promise(function(resolve, reject){
        //   this.$db.collection(collection).doc(documentId).get()
        //   .then(function(data) {
        //       resolve(data.data());
        //   })
        //   .catch(function(error) {
        //       reject(error);
        //   });
        // }.bind(this));
      },
      addDocument(collection, documentId, data){
        return db.addDocument.call(this, collection, documentId, data)
        // return new Promise(function(resolve, reject){
        //   this.$db.collection(collection).doc(documentId).set(data, { merge: true })
        //   .then(function() {
        //       resolve();
        //   })
        //   .catch(function(error) {
        //       reject(error);
        //   });
        // }.bind(this));
      },
      async updateDocumentAndVersion(collection, documentId, data){
        //IMPORTANT: THE $collection_versions collection must be created with proper firestore security rules
        //before attempting to use this function; otherwise the whole batch transaction will fail
        const doc = await this.getDocument(collection, documentId).catch(err=>{
          console.log("failed to check totalVersions for versioning.")
          loggingError(err);
        })
        //stop versioning if max is reached
        if(doc && (!doc.totalVersions || doc.totalVersions < this.$collectionVersioning.maxVersionsAllowed)){
          const versionDocId = this.generateDocId(collection + "_versions");
          const versionDoc = doc;
          versionDoc.id = versionDocId;
          versionDoc.oid = documentId; //original id of the document
          versionDoc.vid = versionDocId; //version id to avoid duplicated copies if the version is later restore and modify
          versionDoc.vTimestamp = await this.getServerTimestamp();
          data.totalVersions = (data.totalVersions) ? parseInt(data.totalVersions)+1 : 1;

          const version = {
            type: "set",
            collection: collection + "_versions",
            id: versionDocId,
            data: versionDoc
          }
          const update = {
              type: "update",
              collection: collection,
              id: documentId,
              data: data
          }
          return db.batchWrites.call(this, [version, update]);
        }else{
          return db.updateDocument.call(this, collection, documentId, data);
        }
      },
      updateDocument(collection, documentId, data){
        if(this.$collectionVersioning.collections.includes(collection)){
          return this.updateDocumentAndVersion(collection, documentId, data);
        }else{
          return db.updateDocument.call(this, collection, documentId, data);
        }
        // return new Promise(function(resolve, reject){
        //   this.$db.collection(collection).doc(documentId).update(data)
        //   .then(function() {
        //       resolve();
        //   })
        //   .catch(function(error) {
        //       reject(error);
        //   });
        // }.bind(this));
      },
      generateDocId(collection) {
        return db.generateDocId.call(this, collection);
        // return this.$db.collection(collection).doc().id;
      },
      updateNestedObject(collection, documentId, data){
        return db.updateNestedObject.call(this, collection, documentId, data);
      },
      updateMap(collection, documentId, data) {
        return db.updateMap.call(this, collection, documentId, data);
        // return new Promise(function(resolve, reject){
        //   this.$db.collection(collection).doc(documentId).update({
        //     ["transcriptions" + "." + data.language]: data.id
        //   })
        //   .then(function() {
        //       resolve();
        //   })
        //   .catch(function(error) {
        //       reject(error);
        //   });
        // }.bind(this));
      },
      batchWrites(writes){
        return db.batchWrites.call(this, writes);
      },
      getServerTimestamp(collection) {
        return db.getServerTimestamp.call(this);
      },
      updateDocumentArrayField(collection, documentId, data){
        return db.updateDocumentArrayField.call(this, collection, documentId, data);
      },
      updateCounter(collection, documentId, data){
        return db.updateCounter.call(this, collection, documentId, data);
      },
      updateDocumentArrayUnion(collection, documentId, data){
        return db.updateDocumentArrayUnion.call(this, collection, documentId, data);
      },
      updateDocumentArrayRemove(collection, documentId, data){
        return db.updateDocumentArrayRemove.call(this, collection, documentId, data);
      }
    },
    computed: {

    }
}
</script>

<style scoped>
.mixin-db {
}
</style>
