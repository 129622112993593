<template>
  <div
    class="DUploadImage"
    :class="{ DUploadImage__dragging: isDraggingFile }"
    @click="selectFile()"
    @dragenter="onDragToUpload($event)"
    @dragover="onDragToUpload($event)"
    @drop="dropToUpload($event)"
    @dragleave="onDragToUploadLeave($event)"
  >
    <div v-if="state === 'already-uploaded'" class="DUploadImage__container">
      <d-icon-cloud-upload />
      <div class="DUploadImage__title">{{ $t("admin.logoAlreadyUploaded") }}</div>
      <div
        class="DUploadImage__subtitle"
        :class="{ 'DUploadImage__subtitle--dragging': isDraggingFile }"
        v-html="$t('admin.uploadLogoReplaceMsg')"
      ></div>
      <div class="DUploadImage__imageName">
        <icon-base width="18" height="18" class="DUploadImage__imageName--icon">
          <d-icon-image />
        </icon-base>
        {{ imageName }}
      </div>
    </div>

    <div v-else-if="state === 'no-image'" class="DUploadImage__container">
      <d-icon-cloud-upload />
      <div class="DUploadImage__title">{{ $t("admin.uploadYourLogo") }}</div>
      <div
        class="DUploadImage__subtitle"
        :class="{ 'DUploadImage__subtitle--dragging': isDraggingFile }"
        v-html="$t('admin.uploadLogoMsg')"
      ></div>
    </div>

    <div v-else-if="state === 'uploading'" class="DUploadImage__container">
      <div class="DUploadImage__icon">
        <icon-base width="70" height="70" viewBox="0 0 70 70" color="#9397A6">
          <d-icon-cloud />
        </icon-base>
        <v-progress-circular
          :size="22"
          :width="1.6"
          indeterminate
          color="#4A8CF1"
          class="DUploadImage__icon--circular"
        ></v-progress-circular>
      </div>
      <div class="DUploadImage__title" v-html="$t('admin.uploadingYourLogo')"></div>
    </div>

    <div v-else-if="state === 'uploaded-success'" class="DUploadImage__container">
      <d-icon-cloud-success />
      <div class="DUploadImage__title" v-html="$t('admin.logoSuccessfullyUploaded')"></div>
      <div
        class="DUploadImage__subtitle DUploadImage__subtitle--success"
        :class="{ 'DUploadImage__subtitle--dragging': isDraggingFile }"
        v-html="$t('admin.uploadLogoReplaceMsg')"
      ></div>
      <div class="DUploadImage__imageName">
        <icon-base width="18" height="18" class="DUploadImage__imageName--icon">
          <d-icon-image />
        </icon-base>
        {{ imageName }}
      </div>
    </div>

    <div v-else-if="state === 'uploaded-error'" class="DUploadImage__container">
      <d-icon-cloud-error />
      <div class="DUploadImage__title" v-html="$t('admin.errorInUpload')"></div>
      <div class="DUploadImage__subtitle" v-html="$t('admin.uploadLogoMsg')"></div>
      <div class="DUploadImage__subtitle--redText" v-html="$t('admin.errorInUploadMsg')"></div>
    </div>

    <input hidden type="file" accept="image/png" ref="fileUploader" @change="onChangedInput()" />
  </div>
</template>
<script>
import IconBase from "@/components/IconBase.vue";
import DIconCloud from "@/components/icons/admin/DIconCloud";
import DIconCloudSuccess from "@/components/icons/admin/DIconCloudSuccess";
import DIconCloudUpload from "@/components/icons/admin/DIconCloudUpload";
import DIconCloudError from "@/components/icons/admin/DIconCloudError";
import DIconImage from "@/components/icons/DIconImage.vue";
import { getLimitFileName } from "@/js/common/formatText.js";

export default {
  name: "DUploadImage",
  props: {},
  components: {
    IconBase,
    DIconCloud,
    DIconCloudSuccess,
    DIconCloudUpload,
    DIconCloudError,
    DIconImage,
  },
  mounted() {
    this.checkEventListener();
  },
  beforeDestroy() {
    if (this.removeBtn) {
      this.removeBtn.removeEventListener("click", this.onClickRemoveBtn);
    }
  },
  watch: {
    state: function () {
      this.checkEventListener();
    },
  },
  data() {
    return {
      state: "no-image",
      imageName: "",
      isDraggingFile: false,
      supportedFileTypes: ["png"],
      alert: {
        show: false,
        type: false,
        message: "",
      },
      localFile: null,
      removeBtn: null,
    };
  },
  methods: {
    selectFile() {
      if (this.state === "uploading") {
        return;
      }
      this.$refs.fileUploader.click();
    },
    onDragToUpload(event) {
      event.stopPropagation();
      event.preventDefault();
      this.isDraggingFile = true;
    },
    onDragToUploadLeave(event) {
      event.preventDefault();
      this.isDraggingFile = false;
    },
    dropToUpload(event) {
      this.isDraggingFile = false;
      event.stopPropagation();
      event.preventDefault();
      if (this.state === "uploading") {
        return;
      }
      if (event.dataTransfer && event.dataTransfer.files.length != 0) {
        this.$refs.fileUploader.files = event.dataTransfer.files;
        this.preparingToUpload();
      }
    },
    onChangedInput() {
      const file = this.$refs.fileUploader ? this.$refs.fileUploader.value : "";
      if (file.length > 0) {
        this.preparingToUpload();
      }
    },
    preparingToUpload() {
      if (this.state === "uploading") {
        return;
      }
      this.localFile = this.$refs.fileUploader.files[0];
      const name = this.localFile.name;
      // upload the logo and watermark files (whole body maxmium: 20MB)
      // 3 MB = 3145728 Bytes, image change to base64 will be enlarged
      const isTooLarge = this.localFile.size > 3145728;
      const fileType = name.substr(name.lastIndexOf(".") + 1);
      const isSupported = this.supportedFileTypes.includes(fileType.toLowerCase());
      if (isTooLarge || !isSupported) {
        this.state = "uploaded-error";
        this.localFile = null;
      } else {
        this.imageName = getLimitFileName({ nameLength: 40, fileName: name });
        this.$emit("set-logo-src", this.localFile);
      }
    },
    checkEventListener() {
      if (this.state === "uploaded-success" || this.state === "already-uploaded") {
        this.$nextTick(() => {
          this.removeBtn = document.getElementById("DUploadImage_removeLogo");
          if (this.removeBtn) {
            this.removeBtn.addEventListener("click", this.onClickRemoveBtn);
          }
        });
      }
    },
    onClickRemoveBtn(event) {
      event.stopPropagation();
      this.$emit("delete-logo");
    },
  },
};
</script>

<style scoped lang="scss">
$height: 250px;
.DUploadImage {
  width: 100%;
  height: $height;
  position: relative;
  background: #ffffff;
  border: 1px dashed #9397a6;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  &__container {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 44px;
  }
  &__icon {
    position: relative;
    height: 70px;
    width: 70px;
    margin: 0 auto;
    &--circular {
      position: absolute;
      top: 29px;
      left: 24px;
    }
  }
  &__title {
    margin-bottom: 4px;
    margin-top: -4px;
    font-size: 21px;
    line-height: 24px;
    color: #1d1e21;
  }
  &__subtitle {
    font-size: 16px;
    line-height: 22px;
    color: #9397a6;
    &--dragging {
      color: #3d3f45;
    }
    & ::v-deep span {
      color: #4689f3;
      text-decoration: underline;
      cursor: pointer;
    }
    &--redText {
      margin-top: 8px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #fc585b;
    }
    &--success ::v-deep span {
      color: #3abf6f;
      cursor: pointer;
    }
  }
  &__imageName {
    margin-top: 8px;
    display: inline-block;
    padding: 4px 10px;
    border: 1px solid #9397a6;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #9397a6;
    &--icon {
      margin-bottom: -4px;
      margin-right: 3px;
      color: #9397a6;
    }
  }
  &__dragging {
    border: 1px dashed #3d3f45;
  }
  &:hover {
    border: 1px dashed #3d3f45;
  }
  &:hover .DUploadImage__subtitle {
    color: #3d3f45 !important;
  }
}
</style>
