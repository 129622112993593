<template>
  <div class="kahuna">
    <div class="kahunaWrapper">
      <d-layout class="integrationTitle align-center">
        <d-flex>
          <p class="integrationTitle__text mb-0">
            Kahuna - {{ $tc("admin.workspacesIntegrated", this.kahunaWorkspaces.length) }}
          </p>
        </d-flex>
        <d-flex>
          <d-button
            small
            round
            depressed
            class="integrationTitle__addButton body-2 px-4 ml-auto d-flex"
            dark
            @click="showAddWorkspacePopup = true"
            >{{ $t("admin.addWorkspace") }}</d-button
          >
        </d-flex>
      </d-layout>

      <div class="integratedWorkspacesTable">
        <div class="integratedWorkspacesTable__searchBox d-flex align-center">
          <div class="integratedWorkspaceTable__searchIcon">
            <icon-base color="#52545D" width="24" height="24">
              <d-icon-search />
            </icon-base>
          </div>
          <form autocomplete="off" style="width: 100%">
            <v-text-field
              flat
              solo
              v-model="searchInput"
              :placeholder="$t('admin.searchForWorkspaces')"
              single-line
              hide-details
              color="#919191"
              background-color="transparent"
              class="integratedWorkspacesTable__searchInput"
              autotest="kahuna-search-for-workspace"
            ></v-text-field>
          </form>
        </div>
        <div class="integratedWorkspacesTable__dataTableWrapper">
          <v-data-table
            v-model="selectedWorkspaces"
            disable-initial-sort
            select-all
            class="integratedWorkspacesTable__dataTable"
            :class="{ 'integratedWorkspacesTable__dataTable--noData': kahunaWorkspaces.length === 0 }"
            :headers="tableHeader"
            :items="kahunaWorkspaces"
            :loading="isLoading"
            :search="searchInput"
            item-key="id"
            :rows-per-page-items="[10, 25, 50, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]"
            :rows-per-page-text="$t('admin.workspacesPerPage')"
            :pagination.sync="pagination"
          >
            <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>

            <template slot="items" slot-scope="props">
              <td class="text-xs-center">
                <v-checkbox
                  @click="props.selected = !props.selected"
                  v-model="props.selected"
                  class="integratedWorkspacesTable__checkbox"
                ></v-checkbox>
              </td>
              <td class="text-xs-left text-capitalize" autotest="kahuna-workspace-name-display">
                <span class="text-no-wrap">{{ props.item.workspaceName }}</span>
              </td>
              <td class="text-xs-left" autotest="kahuna-workspace-group-name-display">
                {{ props.item.workspaceGroupName }}
              </td>
              <td class="text-xs-left" autotest="kahuna-workspace-workflow-counts-display">
                {{ props.item.workflowCounts }}
              </td>
              <td class="text-xs-left" autotest="kahuna-workspace-user-counts-display">
                {{ props.item.userCounts }}
              </td>
              <td class="text-xs-left" autotest="kahuna-workspace-last-updated-display">
                {{ props.item.syncMode }}
              </td>
              <td class="text-xs-left" autotest="kahuna-workspace-last-added-display">
                {{ props.item.addedAt }}
              </td>
              <td class="text-xs-left" autotest="kahuna-workspace-last-sync-display">
                {{ props.item.latestSyncAt }}
              </td>
              <td class="text-xs-center text-no-wrap">
                <div class="integratedWorkspacesTable__buttonsWrapper">
                  <button @click="setRemoveTargetWorkspace(props.item)" autotest="kahuna-workspace-delete">
                    <icon-base color="#1E1F22" height="22" width="22">
                      <d-icon-trash />
                    </icon-base>
                  </button>
                </div>
              </td>
            </template>

            <template slot="no-data">
              <tr v-if="isLoading">
                <td colspan="9">
                  <span>{{ $t("admin.loadingIntegratedWorkspaces") }}</span>
                </td>
              </tr>

              <tr v-else>
                <td colspan="9">
                  <span>{{ $t("admin.noKahunaWorkspaces") }}</span>
                </td>
              </tr>
            </template>

            <template slot="no-results">
              <tr class="noSearchResult">
                <td colspan="9">
                  <span>{{ $t("admin.noResultsFound") }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <!-- selected workspaces expand -->
    <v-expand-transition>
      <div v-if="selectedWorkspaces.length > 0" class="fullscreenBottom">
        <div class="fullscreenBottom__contentWrapper">
          <p class="fullscreenBottom__content">
            <span class="dBlue--text">{{ selectedWorkspaces.length }}</span>
            {{ $tc("admin.workspacesSelected", selectedWorkspaces.length) }}
            <d-button
              outline
              round
              color="#4689f4"
              class="body-2 text-capitalize ml-4 fullscreenBottom__button"
              light
              @click="clearSelectedWorkspaces"
              >{{ $t("skills.clearAll") }}</d-button
            >
            <d-button
              @click="showRemoveWorkspaceConfirmation = true"
              round
              color="#4689f4"
              class="elevation-0 body-2 white--text text-capitalize fullscreenBottom__button"
              light
              >{{ $t("admin.removeFromIntegration") }}</d-button
            >
          </p>
        </div>
      </div>
    </v-expand-transition>

    <admin-setting-kahuna-integrate-workspace-popup
      v-if="showAddWorkspacePopup"
      @setIntegratedWorkspaces="setIntegratedWorkspaces"
      @closePopup="showAddWorkspacePopup = false"
      :excludedWorkspaces="kahunaWorkspaces"
    />

    <admin-kahuna-sync-preferences
      @integrateWorkspaces="integrateWorkspaces"
      v-model="showSyncPreferencesPopup"
    ></admin-kahuna-sync-preferences>

    <admin-kahuna-remove-workspace-confirmation
      @removeWorkspace="removeKahunaWorkspaces"
      v-model="showRemoveWorkspaceConfirmation"
    ></admin-kahuna-remove-workspace-confirmation>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconTrash from "@/components/icons/DIconTrash.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinAnalytics from "@/components/MixinAnalytics.vue";
import {
  getKahunaWorkspaces,
  createKahunaWorkspaces,
  deleteKahunaWorkspaces,
} from "@/server/kahuna-integration-server.js";
import { loggingError } from "@/server/error-log-server.js";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "AdminSettingKahuna",
  components: {
    IconBase,
    DIconSearch,
    DIconTrash,
    AdminSettingKahunaIntegrateWorkspacePopup: () =>
      import("@/components/DAdmin/Settings/integrations/AdminSettingKahunaIntegrateWorkspacePopup.vue"),
    AdminKahunaSyncPreferences: () =>
      import("@/components/DAdmin/Settings/integrations/AdminKahunaSyncPreferences.vue"),
    AdminKahunaRemoveWorkspaceConfirmation: () =>
      import("@/components/DAdmin/Settings/integrations/AdminKahunaRemoveWorkspaceConfirmation.vue"),
  },
  mixins: [MixinUser, MixinDB, MixinAnalytics],
  data() {
    return {
      kahunaWorkspaces: [],
      integratedWorkspaceIds: [],
      removeTargetWorkspace: [],
      selectedWorkspaces: [],
      showAddWorkspacePopup: false,
      showSyncPreferencesPopup: false,
      showRemoveWorkspaceConfirmation: false,
      selectAll: false,
      searchInput: "",
      pagination: {},
      isLoading: true,
      tableHeader: [
        {
          text: this.$t("admin.workspaceName"),
          align: "left",
          sortable: true,
          value: "workspaceName",
          autotest: "kahuna-workspace-name-sort",
        },
        {
          text: this.$t("all.workspaceGroup"),
          align: "left",
          sortable: true,
          value: "workspaceGroupName",
          autotest: "kahuna-workspace-workspace-group-name-sort",
        },
        {
          text: this.$t("all.workflows"),
          align: "left",
          sortable: true,
          value: "workflowCounts",
          autotest: "kahuna-workspace-workflow-counts-sort",
        },
        {
          text: this.$t("all.users"),
          align: "left",
          sortable: true,
          value: "userCounts",
          autotest: "kahuna-workspace-user-counts-sort",
        },
        {
          text: this.$t("admin.syncMode"),
          align: "left",
          sortable: true,
          value: "syncMode",
          autotest: "kahuna-workspace-sync-mode-sort",
        },
        {
          text: this.$t("admin.dateAdded"),
          align: "left",
          sortable: true,
          value: "addedAt",
          autotest: "kahuna-workspace-added-at-sort",
        },
        {
          text: this.$t("admin.latestSync"),
          align: "left",
          sortable: true,
          value: "latestSyncAt",
          autotest: "kahuna-workspace-latest-sync-at-sort",
        },
        {
          text: "",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("workspaceStates", ["workflowCountMap"]),
    ...mapGetters("workspace", ["getter_workspace_with_workspace_group_map"]),
    displayedWorkspaces: {
      get() {
        const { rowsPerPage, page } = this.pagination;
        const start = rowsPerPage * (page - 1);
        return this.kahunaWorkspaces.slice(start, start + rowsPerPage);
      },
      set(newValue) {
        this.selectAll = this.displayedWorkspaces.length > 0 && newValue.length === this.displayedWorkspaces.length;
      },
    },
  },
  mounted() {
    this.initializeKahunaWorkspaces();
  },
  watch: {
    pagination: {
      handler() {
        this.clearSelectedWorkspaces();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("global", ["openAlert"]),
    async initializeKahunaWorkspaces() {
      try {
        this.isLoading = true;
        const { ok, data } = await getKahunaWorkspaces({ organizationId: this.$organization });
        if (ok) {
          await this.setKahunaWorkspaces(data.items);
        }
      } catch (error) {
        console.log(error);
        loggingError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async setKahunaWorkspaces(workspaces) {
      const workspacesWithActiveUserCounts = await this.setUserCounts(workspaces);
      const kahunaWorkspaces = workspacesWithActiveUserCounts.map(
        ({ id, createdAt, lastSyncViewsAt, activeUserCounts, syncMode: syncedType }) => {
          const addedAt =
            Object.keys(createdAt).length === 0 ? new Date().toLocaleDateString() : this.formatTime(createdAt);
          const latestSyncAt = this.formatTime(lastSyncViewsAt);
          const syncMode =
            syncedType === "all" ? this.$t("admin.syncAll") : syncedType === "new" ? this.$t("admin.syncNew") : "-";
          const { name: workspaceName, workspaceGroupName } = this.getter_workspace_with_workspace_group_map[id];
          return {
            id,
            workspaceGroupName,
            workspaceName,
            workflowCounts: this.workflowCountMap[id]?.published || 0,
            userCounts: activeUserCounts,
            syncMode,
            addedAt,
            latestSyncAt,
          };
        }
      );
      this.kahunaWorkspaces = [...kahunaWorkspaces, ...this.kahunaWorkspaces];
    },
    async setUserCounts(workspaces) {
      return await Promise.all(
        workspaces.map(async (workspace) => {
          const activeUserCounts = await this.getActiveUserCounts(workspace.id);
          return { ...workspace, activeUserCounts };
        })
      );
    },
    async getActiveUserCounts(workspaceId) {
      try {
        const { active } = await this.getGroupUserStats(workspaceId);
        return active;
      } catch (error) {
        console.log(error);
        loggingError(error);
        return "-";
      }
    },
    formatTime(time) {
      const seconds = time?._seconds;
      const nanoseconds = time?._nanoseconds;
      if (typeof seconds !== "number" || typeof nanoseconds !== "number") return "-";
      return new Date(seconds * 1000 + nanoseconds / 1e6).toLocaleDateString();
    },
    setIntegratedWorkspaces(workspaces) {
      this.integratedWorkspaceIds = workspaces.map((workspace) => workspace.id);
      this.showAddWorkspacePopup = false;
      this.showSyncPreferencesPopup = true;
    },
    async integrateWorkspaces(syncMode) {
      try {
        const { ok, data } = await createKahunaWorkspaces({
          organizationId: this.$organization,
          workspaceIds: this.integratedWorkspaceIds,
          syncMode,
        });
        if (!ok) {
          this.openAlert({ message: this.$t("admin.alertIntegrateWorkspacesFailed"), type: "error" });
          return;
        }
        const kahunaWorkspaces = data.item.map((item) => item.data);
        await this.setKahunaWorkspaces(kahunaWorkspaces);
        this.openAlert({ message: this.$t("admin.alertIntegrateWorkspacesSuccessfully"), type: "success" });
      } catch (error) {
        console.log(error);
        loggingError(error);
      } finally {
        this.clearSelectedWorkspaces();
      }
    },
    // Click delete workspace icon to set the target workspace to remove.
    setRemoveTargetWorkspace(workspace) {
      this.removeTargetWorkspace = workspace;
      this.showRemoveWorkspaceConfirmation = true;
    },
    // Delete the selected workspaces expand.
    async removeKahunaWorkspaces() {
      /**
       * When multiple workspaces are selected, clicking the delete icon will only remove the clicked workspace.
       * The selection status of other workspaces remains unchanged.
       */
      try {
        const removeTarget = !!this.removeTargetWorkspace.id ? [this.removeTargetWorkspace] : this.selectedWorkspaces;
        const workspaceIds = removeTarget.map((workspace) => workspace.id);
        const { ok } = await deleteKahunaWorkspaces({ organizationId: this.$organization, workspaceIds });
        if (!ok) {
          this.openAlert({ message: this.$t("admin.alertRemoveIntegratedWorkspacesFailed"), type: "error" });
        }
        this.kahunaWorkspaces = this.kahunaWorkspaces.filter((workspace) => !workspaceIds.includes(workspace.id));
        this.openAlert({ message: this.$t("admin.alertRemoveIntegratedWorkspacesSuccessfully"), type: "success" });
      } catch (error) {
        console.log(error);
        loggingError(error);
      } finally {
        this.removeTargetWorkspace = {};
        this.showRemoveWorkspaceConfirmation = false;
        this.selectAll = false;
      }
    },
    clearSelectedWorkspaces() {
      this.selectAll = false;
      this.selectedWorkspaces = [];
    },
    toggleSelectAll() {
      const { rowsPerPage, page } = this.pagination;
      const start = rowsPerPage * (page - 1);
      const end = rowsPerPage < 0 ? this.kahunaWorkspaces.length : start + rowsPerPage;
      this.selectedWorkspaces = this.selectAll ? this.kahunaWorkspaces.slice(start, end) : [];
    },
  },
};
</script>

<style scoped lang="scss">
.kahuna {
  position: relative;
  width: 100%;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;

  .kahunaWrapper {
    height: 100%;
  }

  .integrationTitle {
    border: 1px solid #e7e7e7;
    border-bottom: none;
    background-color: #ffffff;
    padding: 24px;

    &__text {
      color: #3f3f3f;
      font-family: Roboto;
      font-size: 20px;
      letter-spacing: 0;
    }

    &__addButton {
      margin: 0;
      text-transform: none !important;

      &--disabled {
        pointer-events: none !important;
        opacity: 0.6;
      }
    }
  }

  .integratedWorkspacesTable {
    position: relative;
    border: 1px solid #e7e7e7;
    background-color: #ffffff;
    box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);

    &__searchBox {
      position: relative;
      width: 100%;
      height: 72px;
      border: 1px solid #e7e7e7;
      background-color: #ffffff;
      box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
      padding: 0 22px;
    }

    &__searchIcon {
      height: 32px;
      width: 32px;
      padding: 4px;
    }

    &__searchInput {
      margin: 0;
      padding: 0;

      ::v-deep .v-text-field__slot input {
        font-family: Roboto;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;

        &::placeholder {
          opacity: 0.5;
          color: #919191;
          font-family: Roboto;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
    }

    &__dataTableWrapper {
      width: 100%;
      overflow: auto;
      border-top: 1px solid #e2e7f0;

      &:hover::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
      }

      ::v-deep table.v-table thead tr {
        border-bottom: 1px solid #e2e7f0;
        background-color: #eff2f7;
        height: 32px;
        color: #475267;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
      }

      ::v-deep table.v-table .v-icon {
        color: #cdcdcd;
      }

      ::v-deep table.v-table thead tr th:first-child,
      tbody td:first-child {
        padding: 0 15px;
      }

      .v-table__overflow:hover::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
      }

      ::v-deep table.v-table tbody td {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        text-align: left;
        color: #475267;
      }

      .integratedWorkspacesTable__dataTable ::v-deep .v-table__overflow:hover::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
      }

      .integratedWorkspacesTable__dataTable ::v-deep .v-input--selection-controls__ripple,
      .integratedWorkspacesTable__dataTable ::v-deep table.v-table th .v-input--is-label-active .v-icon {
        color: #4a8cf1;
      }

      .integratedWorkspacesTable__dataTable .noSearchResult ::v-deep td,
      .integratedWorkspacesTable__dataTable--noData ::v-deep table.v-table tbody td {
        height: 150px;
        text-align: center;
      }
    }

    &__uncheckedCircle {
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      border: 1px solid #d4d4d4;
      border-radius: 9px;
    }

    &__headerText {
      text-transform: uppercase;
      color: #4e4e4e;
      font-family: Roboto;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
    }

    &__fieldText {
      color: rgba(82, 84, 93, 0.5);
    }

    &__buttonsWrapper {
      display: flex;
      padding-top: 4px;
    }

    &__checkbox ::v-deep {
      .v-input__slot {
        margin: 0;
      }

      .accent--text {
        color: #4a8cf1 !important;
      }

      .v-messages {
        display: none;
      }

      .v-icon {
        color: #cdcdcd;
      }
    }
  }

  .fullscreenBottom {
    position: fixed;
    height: 70px;
    width: 100%;
    background-color: #323338;
    bottom: 0;
    left: 0;

    &__contentWrapper {
      position: relative;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &__content {
      color: #ffffff;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
    }

    &__button {
      min-width: 120px;
    }
  }
}
</style>
