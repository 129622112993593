<template>
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.40625 4.125C7.9375 2.5625 10.4688 2.5625 12.0312 4.125C13.5312 5.59375 13.5938 7.96875 12.25 9.5625L12.0625 9.75C11.875 9.96875 11.5625 10 11.375 9.8125C11.1562 9.625 11.125 9.3125 11.3125 9.09375L11.4688 8.90625C12.5 7.71875 12.4375 5.9375 11.3438 4.84375C10.1562 3.65625 8.28125 3.65625 7.09375 4.84375L3.21875 8.71875C2.03125 9.90625 2.03125 11.7812 3.21875 12.9688C4.375 14.125 6.28125 14.125 7.4375 12.9688L8.15625 12.25C8.34375 12.0625 8.65625 12.0625 8.875 12.25C9.0625 12.4375 9.0625 12.7812 8.875 12.9688L8.15625 13.6562C6.59375 15.2188 4.0625 15.2188 2.5 13.6562C0.9375 12.0938 0.9375 9.5625 2.5 8L6.40625 4.125ZM15.5625 11.9062C14.0312 13.4688 11.5 13.4688 9.9375 11.9062C8.4375 10.4375 8.375 8.0625 9.71875 6.46875L9.90625 6.28125C10.0938 6.0625 10.4062 6.03125 10.5938 6.21875C10.8125 6.40625 10.8438 6.71875 10.6562 6.9375L10.5 7.125C9.46875 8.3125 9.53125 10.0938 10.625 11.1875C11.8125 12.375 13.6875 12.375 14.875 11.1875L18.75 7.3125C19.9375 6.125 19.9375 4.25 18.75 3.0625C17.5938 1.90625 15.6875 1.90625 14.5312 3.0625L13.8125 3.78125C13.625 3.96875 13.3125 3.96875 13.0938 3.78125C12.9062 3.5625 12.9062 3.25 13.0938 3.0625L13.8125 2.34375C15.375 0.78125 17.9062 0.78125 19.4688 2.34375C21.0312 3.90625 21.0312 6.4375 19.4688 8L15.5625 11.9062Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "DIconLink",
};
</script>

<style scoped></style>
