import environment from "@/../env.js";

export const DEFAULT_LOCALE = environment.backendType == "ali" ? "zh" : "en";
export const UI_LOCALE_NAME_MAP = {
  en: "English",
  zh: "中文",
  "es-mx": "Español (México)",
  "ja-jp": "日本語",
  "de-de": "Deutsch",
  "pt-br": "Português",
  "fr-fr": "Français",
  "fr-ca": "Français (Canadien)",
  "th-th": "ไทย",
  "ru-ru": "Pусский",
  "ko-kr": "한국어",
};

// { [OLD_UI_LOCALE]: "NEW_UI_LOCALE" }
export const MIGRATE_TO_CURRENT_UI_LOCALE_MAP = {
  "es-es": "es-mx",
};

// { [UI_LOCALE]: "DICTIONARY_LOCALE" }
export const MIGRATE_TO_CURRENT_DICTIONARY_LOCALE_MAP = {
  en: "en-us",
};

export const UI_LOCALES = Object.keys(UI_LOCALE_NAME_MAP);

const getLocale = () => {
  return localStorage.getItem("locale") || DEFAULT_LOCALE;
};

export const getUILocaleName = (locale = "") => {
  const currentLocale = MIGRATE_TO_CURRENT_UI_LOCALE_MAP[locale] || locale;
  return UI_LOCALE_NAME_MAP[currentLocale] || "";
};

export const i18nConfig = {
  locale: getLocale(),
  fallbackLocale: "en",
  escapeParameterHtml: true,
  messages: UI_LOCALES.reduce((result, locale) => ({ ...result, [locale]: require(`../lang/${locale}.json`) }), {}),
};
