<!--
  A Simple simulation of the Teleport component provided by Vue3
  ref: https://vuejs.org/guide/built-ins/teleport

  This component can be replaced if upgraded to Vue3
-->
<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DTeleport",
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const toElement = document.querySelector(this.to);
    if (toElement) {
      toElement.appendChild(this.$el);
    }
  },
  destroyed() {
    const toElement = document.querySelector(this.to);
    if (toElement) {
      toElement.removeChild(this.$el);
    }
  },
};
</script>
