import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  // NOTICE: this API is not support china version, so the value is null
  return environment.backendType === "firebase"
    ? environment.service[environment.env].chatServer
    : environment.service[environment.env]["china"].chatServer;
}

const chatServer = axios.create({
  baseURL: getBaseUrl(),
});

chatServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);
const apiWrapper = apiWrapperGenerator(chatServer);

export const postChatQuery = (
  payload = {
    query: "",
    oid: "",
    token: "",
    previousMessages: [],
    group: "",
    topkRetriever: 5,
    userId: "",
    sessionId: "",
    previousAnswerId: "",
  }
) => apiWrapper("post", `/chat/query`, payload);

export const postChatLike = (
  payload = {
    answerId: "",
    oid: "",
    token: "",
    userId: "",
    like: false,
  }
) => apiWrapper("post", `/chat/like`, payload);

export const postChatReferences = (
  payload = {
    oid: "",
    token: "",
    answer: "",
    references: [],
  }
) => apiWrapper("post", `/chat/references`, payload);

//TODO: use apiWrapper
export const createChatMessage = (payload) => apiWrapper("post", `/v2/chats`, payload);

export const getChatList = () => apiWrapper("get", `/v2/chats`, {});

export const getChatById = (chatId) => apiWrapper("get", `/v2/chats/${chatId}`);

export const sendQuestion = (chatId, payload) => apiWrapper("post", `/v2/chats/${chatId}/messages`, payload);

export const clearChat = () => apiWrapper("post", "/v2/chats/clear");

export const likeMsg = (chatId, msgId, payload) =>
  apiWrapper("post", `/v2/chats/${chatId}/messages/${msgId}/like`, payload);

export const getChatPresetQuestions = () => apiWrapper("get", `/v2/chats/preset-questions`, {});

export const getWorfklowRecommendQuestions = (workflowId) =>
  apiWrapper("get", `/v2/chats/workflow/${workflowId}/preset-questions`, {});

export const generateSop = (workflowId) => apiWrapper("post", "/v2/chats/multimodal-sop", { workflowId });

export const getChatByWorkflowId = (workflowId) => apiWrapper("get", `/v2/chats/workflow/${workflowId}`, {});

export const clearChatByWorkflowId = (workflowId) => apiWrapper("post", `/v2/chats/workflow/${workflowId}/clear`, {});
