<template>
  <d-category-selector
    v-model="searchInput"
    :dark="dark"
    :block="block"
    :placeholder="$t('builder.searchForCategoryName', { name: categoryName })"
    :filteredMenuItems="filteredItems"
    :selectedItems="selectedItems"
    @on-click-category-item="$emit('add-category-item', $event)"
    @remove-category-item="$emit('remove-category-item', $event)"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";
import DCategorySelector from "./DCategorySelector.vue";
export default {
  name: "DCategorySearchableSelector",
  props: {
    open: Boolean,
    categoryName: String,
    categoryId: String,
    selectedItems: Array,
    dark: Boolean,
    block: Boolean,
  },
  components: { DCategorySelector },
  data: function () {
    return {
      searchInput: "",
    };
  },
  methods: {
    ...mapActions("category", ["fetchSubCategoriesByCategoryId"]),
  },
  watch: {
    open: {
      immediate: true,
      handler(open) {
        if (open) {
          this.fetchSubCategoriesByCategoryId(this.categoryId);
          return;
        }
        this.searchInput = "";
      },
    },
  },
  computed: {
    ...mapState("category", ["subCategoriesByCategoryId"]),
    filteredItems() {
      const items = (this.subCategoriesByCategoryId && this.subCategoriesByCategoryId[this.categoryId]) || [];
      return items.filter((item) => !!item.name.toLowerCase().includes(this.searchInput.toLowerCase()));
    },
  },
};
</script>

<style scoped></style>
