<template>
  <div
    :class="{
      DLayout: true,
      DLayout__column: column,
      DLayout__row: row,
      DLayout__wrap: wrap,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "DLayout",
  props: {
    column: Boolean,
    row: Boolean,
    wrap: Boolean,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.DLayout {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  min-width: 0;
  &__column {
    flex-direction: column;
  }
  &__row {
    flex-direction: row;
  }
  &__wrap {
    flex-wrap: wrap;
  }
}
</style>
