<template>
  <div class="panel" :class="{ active: isSelected }">
    <div class="panel__title" @click="$emit('handleClickCategory', isSelected ? '' : category.id)">
      <div class="panel__title--text text-truncate text-sm-left">
        {{ category.name }}
        <span>({{ category.subcategories.length }})</span>
      </div>
      <div
        v-if="isSelected"
        class="panel__title--select-all-btn"
        @click.stop="$emit('handleSelectAll', category.subcategories)"
      >
        {{ $t("admin.selectAll") }}
      </div>
      <d-icon-arrow-down class="icon" :class="{ active: isSelected }" />
    </div>

    <div
      class="panel__scroll"
      v-if="category.subcategories && category.subcategories.length"
      :style="{ height: isSelected ? `${category.subcategories.length * 54}px` : 0 }"
    >
      <div
        v-for="subCategory in sortedTargetSubCategories"
        :key="subCategory.id"
        class="panel__lists"
        @click="$emit('handleClickSubCategory', subCategory.id)"
      >
        <span class="text-truncate text-sm-left">{{ subCategory.name }}</span>
        <icon-base color="#4689F3" v-if="selectedSubCategoryIds.includes(subCategory.id)">
          <d-icon-circle-checked />
        </icon-base>
        <span v-else class="panel__lists--icon-circle"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconBase from "@/components/IconBase.vue";
import DIconCircleChecked from "@/components/icons/DIconCircleChecked.vue";
import DIconArrowDown from "@/components/icons/category/DIconArrowDown.vue";
import { sortAlphabeticallyByColumnKey } from "@/js/common/listLib";

export default {
  name: "CategoryExpansionPanel",
  components: {
    IconBase,
    DIconArrowDown,
    DIconCircleChecked,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    selectedId: {
      type: String,
      required: true,
    },
    selectedSubCategoryIds: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("category", ["getters_get_sub_category_by_id"]),
    isSelected() {
      return this.selectedId === this.category.id;
    },
    sortedTargetSubCategories() {
      if (!this.isSelected) return [];
      if (!this.category.subcategories) return [];
      return this.category.subcategories
        .map((id) => this.getters_get_sub_category_by_id(id))
        .sort(sortAlphabeticallyByColumnKey("name"));
    },
  },
};
</script>

<style scoped lang="scss">
.panel {
  --panel-padding: 16px 8px;
  --sub-category-height: 54px;
  --text-color: #9397a6;
  --border-bottom: 1px solid #28292e;
  --bg-color: #1d1e21;

  border-bottom: var(--border-bottom);
  color: #fff;
  transition: all 0.2s ease-in;

  &.active {
    background-color: var(--bg-color);
    border-radius: 4px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--panel-padding);
    border-bottom: var(--border-bottom);
    cursor: pointer;

    &--text {
      font-size: 16px;

      span {
        color: var(--text-color);
      }
    }

    &--select-all-btn {
      margin-left: auto;
      margin-right: 8px;
      font-size: 12px;
      cursor: pointer;
      color: var(--text-color);
    }

    .icon {
      transition: all 0.2s ease-in;

      &.active {
        transform: rotate(180deg);
        stroke: #3d3f45;

        & ::v-deep path {
          fill: #fff;
        }

        & ::v-deep rect {
          fill: #121314;
        }
      }
    }
  }

  &__scroll {
    overflow: hidden;
    transition: all 0.3s ease-in;
  }

  &__lists {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--panel-padding);
    height: var(--sub-category-height);
    border-bottom: var(--border-bottom);
    cursor: pointer;
    transition: background 0.2s ease-in;

    &:hover {
      background: #28292e;
      border: 1px solid #4689f3;

      .panel__lists--icon-circle {
        border: 1px solid #9397a6;
      }
    }

    &--icon-circle {
      width: 24px;
      height: 24px;
      border: 1px solid #28292e;
      border-radius: 50%;
      transition: all 0.2s ease-in;
    }
  }

  & ::v-deep .text-truncate {
    width: 450px;
  }
}
</style>
