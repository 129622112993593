<template>
  <d-image
    :height="height"
    :width="width"
    :contain="contain"
    :aspect-ratio="aspectRatio"
    :src="visibleImgSrc"
    :emitOnUrlLoaded="emitOnUrlLoaded"
    @click="$emit('click')"
    v-on="$listeners"
    v-bind="$attrs"
  />
</template>

<script>
import DImage from "@/components/ui_components/DImage.vue";
export default {
  name: "d-lazy-image",
  props: {
    src: String,
    aspectRatio: Number,
    contain: Boolean,
    height: String,
    width: String,
    emitOnUrlLoaded: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    DImage,
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          this.setIsVisible();
          this.$emit("setImageVisible", this.src);
          this.observer.unobserve(this.$el);
        }
      });
    });
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  data() {
    return {
      observer: null,
      visibleImgSrc: "",
    };
  },
  methods: {
    setIsVisible() {
      this.visibleImgSrc = this.src;
    },
  },
};
</script>
