function insertScript() {
  window.userGuidingLayer = window.userGuidingLayer || [];
  var f = document.getElementsByTagName("script")[0];
  var k = document.createElement("script");
  k.async = true;
  k.src = "https://static.userguiding.com/media/user-guiding-646879875ID-embedded.js";
  f.parentNode.insertBefore(k, f);
  if (window.userGuiding) return;
  var ug = (window.userGuiding = { q: [] });
  ug.c = function (n) {
    return function () {
      ug.q.push([n, arguments]);
    };
  };
  var m = ["previewGuide", "finishPreview", "track", "triggerNps", "hideChecklist", "launchChecklist"];
  for (var j = 0; j < m.length; j += 1) {
    ug[m[j]] = ug.c(m[j]);
  }
}

export default {
  initialize() {
    if (process.env.VUE_APP_USERGUIDING_ON !== "true") {
      return;
    }
    insertScript();
  },
  updateUserInfo(uid, payload) {
    try {
      if (process.env.VUE_APP_USERGUIDING_ON !== "true") {
        return;
      }
      window.userGuiding.identify(uid, payload);
    } catch (e) {
      console.log(e.message);
    }
  },
};
