<template>
  <svg width="15" height="2" viewBox="0 0 15 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 0V2H0V0H15Z" fill="currentColor" />
  </svg>
</template>
<script>
export default {
  name: "DIconMinus",
};
</script>
