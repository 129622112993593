import store from "../store/index";
import deephowApiServerWrapper, { fetchGroups } from "@/server/api-server";

function getSearchResult(keyword, data) {
  return data.filter((group) => {
    if (group.name.toLowerCase().includes(keyword.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  });
}

export const fetchNavigatorWorkspaces = async (params = { only_likes: false, keyword: "", type: "view" }) => {
  try {
    const { ok, data } = await fetchGroups(params);
    if (ok) {
      if (!("keyword" in params)) {
        await store.dispatch("navigator/storeOnlyLikeWorkspaces", data.items);
      } else if (!("only_likes" in params)) {
        const searchResult = getSearchResult(params.keyword, data.items);
        await store.dispatch("navigator/storeOnlyKeywordsWorkspaces", searchResult);
      } else {
        await store.dispatch("navigator/storeWorkspaceData", data.items);
      }
      return data;
    } else {
      console.error(data.message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const fetchSkills = () => deephowApiServerWrapper("get", "/v1/navigator/skills");

export const fetchSkillDetail = (skillId) => deephowApiServerWrapper("get", `/v1/navigator/skills/${skillId}`);
