// docs: https://axios-http.com/docs/api_intro
// axios.get(url[, config])
// axios.delete(url[, config])
// axios.head(url[, config])
// axios.options(url[, config])
// axios.post(url[, data[, config]])
// axios.put(url[, data[, config]])
// axios.patch(url[, data[, config]])
import store from "@/store/index";
import { checkCodeIsNotAllowIp } from "@/js/login/checkRestrictedIp";
import { chatErrorHandler } from "@/js/maven/maven2";
import { loggingError } from "@/server/error-log-server.js";

export const apiWrapperGenerator =
  (apiServer) =>
  async (method, url, ...args) => {
    try {
      const { data, data: { success, message, code } = {} } = await apiServer[method](url, ...args);
      if (checkCodeIsNotAllowIp(code)) {
        store.dispatch("auth/setIsCheckIpNotAllowed");
      }
      if (success) {
        return { code, ok: success, data };
      } else {
        return { code, ok: success, errorMessage: message, data };
      }
    } catch (error) {
      const data = error?.response?.data ?? {};
      const code = data?.code;
      if (checkCodeIsNotAllowIp(code)) {
        store.dispatch("auth/setIsCheckIpNotAllowed");
      }
      chatErrorHandler(url, error);
      loggingError(error);
      return { code, ok: false, errorMessage: error.message, data };
    }
  };

export const otherApisWrapperGenerator =
  (apiServer) =>
  async (method, url, ...args) => {
    try {
      const { data } = await apiServer[method](url, ...args);
      return { ok: true, data };
    } catch (error) {
      const errorMessage = error.response ? error.response.data.messages.join("/") : error.message;
      loggingError(error);
      return { ok: false, errorMessage: errorMessage };
    }
  };
