<template>
  <div class="DShareScormDownloadPopup">
    <div class="DShareScormDownloadPopup__fullscreen">
      <p class="DShareScormDownloadPopup__description" v-html="description">
        <!-- Do not close this page! <br />In a few minutes your file will be ready for download. -->
      </p>
      <p class="DShareScormDownloadPopup__title">{{ title }}</p>
      <p class="DShareScormDownloadPopup__subtitle pb-2">
        <icon-base color="#8D909F" height="16" width="16" class="DShareScormDownloadPopup__icon">
          <d-icon-video-file />
        </icon-base>
        {{ `${videoInfo.title}${fileFormat}` }}
      </p>
      <d-icon-processing-bar-flex height="8px" width="370px" class="DShareScormDownloadPopup__placeholderBar" />
    </div>
    <div class="DShareScormDownloadPopup__processing">
      <p class="DShareScormDownloadPopup__processingDescription">{{ processingDescription }}</p>
      <d-button
        round
        depressed
        class="caption text-capitalize white--text DShareScormDownloadPopup__processingBtn"
        color="#4689f4"
        light
        @click="$emit('click-processing-btn')"
        >{{ processingBtnText }}</d-button
      >
    </div>
  </div>
</template>

<script>
import IconBase from "@/components//IconBase.vue";
import DIconVideoFile from "@/components/icons/DIconVideoFile.vue";
import DIconProcessingBarFlex from "@/components/icons/animated/DIconProcessingBarFlex.vue";

export default {
  components: {
    IconBase,
    DIconVideoFile,
    DIconProcessingBarFlex,
  },
  props: {
    videoInfo: {
      type: Object,
      default: () => ({ title: "" }),
    },
    fileFormat: {
      type: String,
      require: true,
      validator: (val) => [".mp4", ".zip"].indexOf(val) !== -1,
    },
    description: {
      type: String,
    },
    title: {
      type: String,
    },
    processingDescription: {
      type: String,
    },
    processingBtnText: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.DShareScormDownloadPopup {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #1e1f22;
  &__fullscreen {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  &__description {
    color: #ffffff;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    margin-bottom: 48px;
  }
  &__title {
    color: #ffffff;
    font-family: Roboto;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__icon {
    margin-bottom: -2px;
    margin-right: 6px;
  }
  &__subtitle {
    color: #8d909f;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    padding: 0 48px;
  }
  &__placeholderBar {
    position: relative;
    margin: 0px auto 24px auto;
  }
  &__processing {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 114px;
  }
  &__processingDescription {
    height: 16px;
    color: #8d909f;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 4px;
  }
  &__processingBtn {
    width: 374px;
  }
}
</style>
