const NavigatorRoute = [
  {
    path: "/navigator",
    name: "NavigatorMain",
    props: true,
    component: () => import("@/components/DNavigator/NavigatorMain"),
    redirect: { name: "NavigatorHome" },
    meta: { auth: true },
    children: [
      {
        path: "/navigator/home/:workspaceGroupId?",
        name: "NavigatorHome",
        props: true,
        component: () => import("@/components/DNavigator/Home/NavigatorHome"),
        children: [
          {
            path: "/navigator/home/WorkspaceExplorer/:workspaceId",
            alias: "/navigator/home/MyWorkspaces/WorkspaceExplorer/:workspaceId",
            name: "WorkspaceExplorer",
            props: true,
            component: () => import("@/components/DNavigator/Workspaces/WorkspaceExplorer"),
            children: [],
          },
        ],
      },
      {
        path: "/navigator/workspaceGroup/:workspaceGroupId",
        name: "NavigatorWorkspaceGroup",
        component: () => import("@/components/DNavigator/WorkspaceGroup/NavigatorWorkspaceGroup"),
        meta: { auth: true },
      },
      {
        path: "/navigator/skills/:part?",
        name: "NavigatorSkills",
        props: true,
        component: () => import("@/components/DNavigator/Skills/NavigatorSkillsExplorer"),
        children: [],
      },
      {
        path: "/navigator/skillplayer/:skillId",
        name: "NavigatorSkillPlayer",
        props: true,
        component: () => import("@/components/DNavigator/Skills/SkillPlayer"),
      },
      {
        path: "/navigator/favorites",
        name: "NavigatorFavorites",
        props: true,
        component: () => import("@/components/DNavigator/Favorites/NavigatorFavorites"),
        redirect: { name: "FavoritesWorkflows" },
        children: [
          {
            path: "/navigator/favorites/workflows",
            name: "FavoritesWorkflows",
            props: true,
            component: () => import("@/components/DNavigator/Favorites/FavoritesWorkflows"),
          },
          {
            path: "/navigator/favorites/workspaces",
            name: "FavoritesWorkspaces",
            props: true,
            component: () => import("@/components/DNavigator/Favorites/FavoritesWorkspaces"),
          },
          {
            path: "/navigator/favorites/WorkspaceExplorer/:workspaceId",
            name: "FavoritesWorkspaceExplorer",
            props: true,
            component: () => import("@/components/DNavigator/Workspaces/WorkspaceExplorer"),
          },
        ],
      },
      {
        path: "/navigator/search",
        name: "NavigatorSearch",
        component: () => import("@/components/PlayerExplorerSearch"),
        meta: { auth: true },
        props: true,
      },
    ],
  },
];
export default NavigatorRoute;
