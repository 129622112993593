<template>
  <div class="DEditorPopup">
    <div class="DEditorPopup__container">
      <div class="DEditorPopup__container--icon">
        <slot name="icon">
          <d-icon-exclamation-mark />
        </slot>
      </div>
      <div class="DEditorPopup__container--body">
        <div class="DEditorPopup__container--body-title">
          <slot name="title">{{ $t("alert.warning") }}</slot>
        </div>
        <div class="DEditorPopup__container--body-content">
          <slot name="content">{{ $t("editor.startATTSWillConvertAllTranscription") }}</slot>
        </div>
      </div>
      <div class="DEditorPopup__container--action">
        <slot name="action">
          <d-button outline round color="#4689f4" class="button" @click="$emit('close')"
            >{{ $t("new.cancel") }}
          </d-button>
          <d-button dark round color="#4689F3" class="button" @click="$emit('allowTTS')"
            >{{ $t("editor.startCreatingTTSFromScratch") }}
          </d-button>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DEditorConfirmPopup",
  components: {
    DIconExclamationMark: () => import("@/components/icons/editor/DIconExclamationMark.vue"),
  },
};
</script>

<style scoped lang="scss">
.DEditorPopup {
  position: fixed;
  inset: 0;
  background-color: rgba(black, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 560px;
    min-height: 400px;
    background-color: var(--dDarkBg-color);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    &--icon {
      flex: 0 0 42%;
      display: flex;
      align-items: center;
    }

    &--body {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 380px;
      &-title {
        color: white;
        font-size: 24px;
        font-weight: 500;
      }
      &-content {
        font-size: 18px;
        color: #9397a6;
      }
    }

    &--action {
      display: flex;
      width: 100%;
      padding: 30px 1rem 0;
      .button {
        flex: 1 0 0;
        font-size: 14px;
        font-weight: 500;
        text-transform: initial;
      }
    }
  }
}
</style>
