const callbackMap = new WeakMap();
const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    if (callbackMap.has(entry.target)) {
      const fn = callbackMap.get(entry.target);
      const computedStyle = window.getComputedStyle(entry.target);
      const isElementInvisible = computedStyle.display === "none" || computedStyle.visibility === "hidden";
      if (isElementInvisible) {
        return;
      }
      fn({
        clientWidth: entry.borderBoxSize[0].inlineSize,
        clientHeight: entry.borderBoxSize[0].blockSize,
      });
    }
  }
});

export default {
  bind(el, binding) {
    callbackMap.set(el, binding.value);
    resizeObserver.observe(el);
  },
  unbind(el) {
    resizeObserver.unobserve(el);
  },
};
