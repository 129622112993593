/**
 * API doc
 * https://docs.google.com/document/d/1oCVXJcqsrNoSVjjIkQchRHeX2S97SbPehbkndomm554/edit#
 */
import deephowApiServerWrapper from "@/server/api-server";

export const fetchAttachmentData = (stepId) => deephowApiServerWrapper("get", `/v1/steps/${stepId}/addons`);

export const putUpdateAttachmentData = async ({ stepId, addonId, requestBody }) => {
  try {
    if (stepId == null || addonId == null) {
      return {
        ok: false,
        errorMessage: `Missing params: ${stepId == null && "stepId: " + stepId + ","} ${
          addonId == null && "addonId: " + addonId
        }`,
      };
    }
    const result = await deephowApiServerWrapper("put", `/v1/steps/${stepId}/addons/${addonId}`, requestBody);
    return result;
  } catch (error) {
    console.log(error);
    return { ok: false, error, errorMessage: error.message };
  }
};

export const postCreateAttachmentData = ({ stepId, requestBody }) =>
  deephowApiServerWrapper("post", `/v1/steps/${stepId}/addons`, requestBody);
