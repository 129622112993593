import { createSummary } from "@/server/ai-summary-server";
import { getWorkflowSummary } from "@/server/workflow-server";

export const namespaced = true;

export const state = {
  isAISummaryPublished: false,
  summaryStatus: "completed", // ‘pending’ | ‘processing’ | ‘completed’ | ‘failure’
  summary: [],
  summaryId: "",
  isUserChangeLocalData: false,
};

export const getters = {
  getter_is_ai_processing_summary(state) {
    return ["processing", "pending"].includes(state.summaryStatus);
  },
};

export const actions = {
  async generateSummary({ commit }, { workflowId }) {
    try {
      const { ok, data, errorMessage } = await createSummary({ workflowId });
      if (!ok) {
        console.error(errorMessage);
        return;
      }
      commit("MUTATE_SUMMARY_STATUS", data.item.status);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchSummaryFromDb({ commit }, workflowId) {
    try {
      const { data, ok } = await getWorkflowSummary(workflowId);
      if (ok && data.item) {
        commit("MUTATE_SUMMARY", data.item.content);
        commit("MUTATE_SUMMARY_ID", data.item.id);
        commit("MUTATE_SUMMARY_STATUS", data.item.status);
        commit("MUTATE_IS_SUMMARY_PUBLISHED", data.item.activate);
      } else {
        commit("MUTATE_SUMMARY", []);
        commit("MUTATE_SUMMARY_ID", "");
        commit("MUTATE_SUMMARY_STATUS", "completed");
        commit("MUTATE_IS_SUMMARY_PUBLISHED", false);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async updateItem({ state, commit }, { summary }) {
    commit("MUTATE_SUMMARY", summary);
    commit("MUTATE_IS_USER_CHANGE_LOCAL_DATA", true);
  },
  setSummaryId({ commit }, id) {
    commit("MUTATE_SUMMARY_ID", id);
  },
};

export const mutations = {
  MUTATE_SUMMARY_STATUS(state, status) {
    state.summaryStatus = status;
  },
  MUTATE_SUMMARY(state, summary) {
    state.summary = summary;
  },
  MUTATE_SUMMARY_ID(state, id) {
    state.summaryId = id;
  },
  MUTATE_IS_USER_CHANGE_LOCAL_DATA(state, isChange) {
    state.isUserChangeLocalData = isChange;
  },
  MUTATE_IS_SUMMARY_PUBLISHED(state, isPublished) {
    state.isAISummaryPublished = isPublished;
  },
};
