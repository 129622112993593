<template>
  <section class="mixin-avatar" />
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import { getImageBlob, getSignedImageUrl } from "@/js/common/imageLib.js";

var subscribeList = {};

export default {
  name: "mixin-avatar",
  props: [],
  mixins: [MixinDB],
  mounted() {},
  beforeDestroy() {},
  data() {
    return {};
  },
  methods: {
    getUserAvatar(userId, callback) {
      //console.log("getUserAvatar", userId);
      const self = this;
      return new Promise(async function (resolve, reject) {
        let avatar = {
          url: "",
          userId: userId,
        };

        try {
          if (self.hasCacheOrLoading(userId, avatar, callback)) {
            resolve();
            return;
          }

          //console.log("getDocument", userId, userDoc);
          let userDoc = await self.getDocument("users", userId);

          if (self.hasCacheOrLoading(userId, avatar, callback)) {
            resolve();
            return;
          }

          if (userDoc && userDoc.avatar) {
            self.$cachedAvatars[userId] = avatar;
            const blobUrl = await self.handleGetUserAvatarBlob(userDoc.avatar);
            self.$cachedAvatars[userId].url = avatar.url = blobUrl;
            callback(avatar);
            self.publish(userId, avatar);

            resolve();
          } else {
            callback(avatar);
            resolve();
          }
        } catch (e) {
          //console.warn(e);
          callback(avatar);
          resolve();
        }
      });
    },
    hasCacheOrLoading(userId, avatar, callback) {
      const self = this;
      if (self.$cachedAvatars[userId] && self.$cachedAvatars[userId].url) {
        //console.log("cache", userId, self.$cachedAvatars[userId].url);
        avatar.url = self.$cachedAvatars[userId].url;
        callback(avatar);
        return true;
      }

      if (self.$cachedAvatars[userId] && self.$cachedAvatars[userId].userId == userId) {
        //console.log("loading", userId);
        self.subscribe(userId, callback);
        return true;
      }

      return false;
    },
    subscribe(userId, callback) {
      try {
        const id = this.uuidv4();
        const subscribe = {
          id: id,
          callback: callback,
          unsubscribe: () => {
            //console.log("unsubscribe", id, subscribeList[userId][id]);
            delete subscribeList[userId][id];
          },
        };

        if (!subscribeList[userId]) {
          subscribeList[userId] = {};
        }
        //console.log("subscribeList subscribe", userId, subscribe);
        subscribeList[userId][id] = subscribe;
        //console.log("subscribeList subscribe finished", userId, subscribeList);

        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    },
    publish(userId, avatar) {
      try {
        if (!subscribeList[userId]) {
          return false;
        }

        //console.log("subscribeList publish", userId, avatar);
        Object.keys(subscribeList[userId]).forEach((id) => {
          //console.log("subscribe");
          subscribeList[userId][id].callback(avatar);
        });

        // unsubscribe after published
        Object.keys(subscribeList[userId]).forEach((id) => {
          subscribeList[userId][id].unsubscribe();
        });
        delete subscribeList[userId];
        //console.log("subscribeList publish finished", userId, subscribeList);

        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    async handleGetUserAvatarBlob(src) {
      const signedUrl = await getSignedImageUrl(src);
      const { ok, url: blobUrl } = await getImageBlob(signedUrl);
      return ok ? blobUrl : signedUrl;
    },
    async updateUserAvatar(userId, url, callback) {
      let blobUrl = await this.handleGetUserAvatarBlob(url);
      let avatar = {
        url: blobUrl,
        userId: userId,
      };
      this.$cachedAvatars[userId] = avatar;
      callback(avatar);
      this.publish(userId, avatar);
    },
  },
  computed: {},
};
</script>
