<template>
  <div ref="desktopControls" class="ControlsDesktop">
    <!-- Play Or Pause video when click the main area of the player-->
    <div
      class="ControlsDesktop__main"
      :class="{ 'ControlsDesktop__main--small': isSmallScreen, 'ControlsDesktop__main--hideControls': hideControls }"
      @click="onclickPlayOrPause"
    >
      <safety-info-watermark v-if="showSafetyInfoWaterMark" :watermarkId="watermarkId" />
    </div>
    <!-- top -->
    <div
      v-if="showTopBar"
      class="ControlsDesktop__topBar"
      :class="{ 'ControlsDesktop__topBar--hidden': hideControls }"
      @click.stop
    >
      <div class="ControlsDesktop__title" :class="{ 'ControlsDesktop__title--small': isSmallScreen }">
        <button
          class="ControlsDesktop__menuBtn"
          :class="{ 'ControlsDesktop__menuBtn--small': isSmallScreen }"
          @click="$emit('click-menu-btn', $event)"
        >
          <icon-base color="white">
            <d-icon-menu-fullscreen />
          </icon-base>
        </button>
        <div class="ControlsDesktop__title--text">
          <p
            class="ControlsDesktop__title--workflow text-no-wrap text-truncate"
            :class="{ 'ControlsDesktop__title--workflowSm': isSmallScreen }"
          >
            {{ workflowTitle }}
          </p>
          <p
            class="ControlsDesktop__title--step text-no-wrap text-truncate"
            :class="{ 'ControlsDesktop__title--stepSm': isSmallScreen }"
          >
            {{ stepNum }}. {{ stepTitle }}
          </p>
        </div>
      </div>
      <button
        v-if="showCloseBtn"
        class="ControlsDesktop__closeBtn"
        :class="{ 'ControlsDesktop__closeBtn--small': isSmallScreen }"
        @click="onclickClose"
      >
        <icon-base color="white">
          <d-icon-close-2 />
        </icon-base>
      </button>
    </div>

    <!-- center buttons (only for small screen) -->
    <div class="ControlsDesktop__center" :class="{ 'ControlsDesktop__center--hidden': hideControls }">
      <div class="ControlsDesktop__centerBtns" @click.stop>
        <button class="ControlsDesktop__btns--btnSm" @click="$emit('play-back')">
          <icon-base class="ControlsDesktop__btns--btnRotate">
            <d-icon-player-fast-forward />
          </icon-base>
        </button>
        <button class="ControlsDesktop__btns--btnSm" @click="$emit('play-previous-step')">
          <icon-base class="ControlsDesktop__btns--btnRotate" viewBox="0 0 10 14">
            <d-icon-player-next />
          </icon-base>
        </button>
        <button class="ControlsDesktop__btns--playBtnSm" @click="onclickPlayOrPause">
          <icon-base color="white">
            <component :is="playing ? 'd-icon-player-pause-2' : 'd-icon-player-play-2'" />
          </icon-base>
        </button>
        <button class="ControlsDesktop__btns--btnSm" @click="$emit('play-next-step')">
          <icon-base viewBox="0 0 10 14">
            <d-icon-player-next />
          </icon-base>
        </button>
        <button class="ControlsDesktop__btns--btnSm" @click="$emit('play-forward')">
          <icon-base>
            <d-icon-player-fast-forward />
          </icon-base>
        </button>
      </div>
    </div>

    <!-- bottom -->
    <div class="ControlsDesktop__bottomBar" @click.stop>
      <!-- time progress bar -->
      <div class="ControlsDesktop__bottomTime">
        <span
          class="ControlsDesktop__bottomTime--time"
          :class="{
            'ControlsDesktop__bottomTime--timeSm': isSmallScreen,
            'ControlsDesktop__bottomTime--hideTime': hideControls,
          }"
          v-html="videoTime"
        ></span>
        <div
          class="ControlsDesktop__bottomTime--timeWrapper"
          :class="{ 'ControlsDesktop__bottomTime--timeWrapperSm': isSmallScreen }"
        >
          <slot></slot>
        </div>
      </div>

      <!-- Bottom buttons -->
      <div
        class="ControlsDesktop__bottomControls"
        :class="{
          'ControlsDesktop__bottomControls--small': isSmallScreen,
          'ControlsDesktop__bottomControls--hidden': hideControls,
        }"
        @click.stop
      >
        <div class="ControlsDesktop__btns">
          <button
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            @click="$emit('play-back')"
          >
            <icon-base class="ControlsDesktop__btns--btnRotate">
              <d-icon-player-fast-forward />
            </icon-base>
          </button>
          <desktop-step-name-tooltip
            isPrevious
            :stepNumber="previousStepNum"
            :stepTitle="previousStepTitle"
            :hidden="currentStep === 0"
          >
            <button
              class="ControlsDesktop__btns--btnLg ControlsDesktop__btns--btnNextLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="$emit('play-previous-step')"
            >
              <icon-base class="ControlsDesktop__btns--btnRotate" viewBox="0 0 10 14">
                <d-icon-player-next />
              </icon-base>
            </button>
          </desktop-step-name-tooltip>
          <button
            class="ControlsDesktop__btns--playBtnLg"
            :class="{ 'ControlsDesktop__btns--playBtnSm': isSmallScreen }"
            @click="onclickPlayOrPause"
          >
            <icon-base color="white">
              <component :is="playing ? 'd-icon-player-pause-2' : 'd-icon-player-play-2'" />
            </icon-base>
          </button>
          <desktop-step-name-tooltip
            :stepNumber="nextStepNum"
            :stepTitle="nextStepTitle"
            :hidden="currentStep === getters_steps.length - 1"
          >
            <button
              class="ControlsDesktop__btns--btnLg ControlsDesktop__btns--btnNextLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="$emit('play-next-step')"
            >
              <icon-base viewBox="0 0 10 14">
                <d-icon-player-next />
              </icon-base>
            </button>
          </desktop-step-name-tooltip>
          <button
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            @click="$emit('play-forward')"
          >
            <icon-base>
              <d-icon-player-fast-forward />
            </icon-base>
          </button>
        </div>
        <div class="ControlsDesktop__btnsLeft">
          <button
            v-if="!getter_is_gen2"
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            @click="$emit('click-search')"
          >
            <icon-base>
              <d-icon-search />
            </icon-base>
          </button>
        </div>
        <div class="ControlsDesktop__btnsRight">
          <controls-repeat-button
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            :size="isSmallScreen ? 'small' : 'large'"
            :disableTooltip="IS_TABLET"
          />
          <volume-slider :volume="volume" :isMuted="isMuted" @update-volume="$emit('update-volume', $event)">
            <button
              class="ControlsDesktop__btns--btnLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="$emit('click-mute')"
            >
              <icon-base>
                <component :is="isMuted ? 'd-icon-player-mute' : 'd-icon-player-volume'" />
              </icon-base>
            </button>
          </volume-slider>
          <button
            class="ControlsDesktop__btns--btnLg"
            :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
            @click="$emit('click-setting')"
            @mouseenter="mouseenterSettingsButton"
          >
            <icon-base>
              <d-icon-player-settings />
            </icon-base>
          </button>
          <!-- theater mode button -->
          <v-tooltip v-if="enableTheaterButton" top color="#2C2D32" z-index="101">
            <button
              slot="activator"
              class="ControlsDesktop__btns--btnLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="changeTheaterMode"
            >
              <icon-base>
                <component :is="isTheaterMode ? 'd-icon-theater-mode-exit' : 'd-icon-theater-mode'" />
              </icon-base>
            </button>
            <span> {{ isTheaterMode ? $t("player.exitTheaterMode") : $t("player.theaterMode") }} (T)</span>
          </v-tooltip>
          <!-- fullscreen mode button -->
          <v-tooltip v-if="!IS_TABLET" top color="#2C2D32" z-index="101">
            <button
              slot="activator"
              class="ControlsDesktop__btns--btnLg"
              :class="{ 'ControlsDesktop__btns--btnSm': isSmallScreen }"
              @click="$emit('change-fullscreen')"
            >
              <icon-base>
                <component :is="isDesktopFullscreen ? 'd-icon-player-exit-fullscreen' : 'd-icon-player-fullscreen'" />
              </icon-base>
            </button>
            <span> {{ isDesktopFullscreen ? $t("player.exitFullscreen") : $t("player.fullscreen") }} (F)</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconMenuFullscreen from "@/components/icons/player/DIconMenuFullscreen.vue";
import DIconPlayerPlay2 from "@/components/icons/player/DIconPlayerPlay2.vue";
import DIconPlayerPause2 from "@/components/icons/player/DIconPlayerPause2.vue";
import DIconPlayerNext from "@/components/icons/player/DIconPlayerNext.vue";
import DIconPlayerFastForward from "@/components/icons/player/DIconPlayerFastForward.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconPlayerFullscreen from "@/components/icons/player/DIconPlayerFullscreen.vue";
import DIconPlayerExitFullscreen from "@/components/icons/player/DIconPlayerExitFullscreen.vue";
import DIconPlayerSettings from "@/components/icons/player/DIconPlayerSettings.vue";
import DIconPlayerVolume from "@/components/icons/player/DIconPlayerVolume.vue";
import DIconPlayerMute from "@/components/icons/player/DIconPlayerMute.vue";
import DIconTheaterModeExit from "@/components/icons/player/DIconTheaterModeExit.vue";
import DIconTheaterMode from "@/components/icons/player/DIconTheaterMode.vue";
import DesktopStepNameTooltip from "@/components/DWorkflowPlayer/controls/DesktopStepNameTooltip.vue";
import VolumeSlider from "@/components/DWorkflowPlayer/controls/VolumeSlider.vue";
import ControlsRepeatButton from "@/components/DWorkflowPlayer/controls/ControlsRepeatButton.vue";
import { IS_TABLET } from "@/constants/device-version";
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { getFormattedVideoDuration, getNumberWithZero } from "@/js/common/formatText.js";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "ControlsDesktop",
  components: {
    IconBase,
    DIconMenuFullscreen,
    DIconPlayerPlay2,
    DIconPlayerPause2,
    DIconPlayerNext,
    DIconPlayerFastForward,
    DIconClose2,
    DIconSearch,
    DIconPlayerFullscreen,
    DIconPlayerExitFullscreen,
    DIconPlayerSettings,
    DIconPlayerVolume,
    DIconPlayerMute,
    DIconTheaterModeExit,
    DIconTheaterMode,
    DesktopStepNameTooltip,
    VolumeSlider,
    ControlsRepeatButton,
    SafetyInfoWatermark: () => import("./SafetyInfoWatermark.vue"),
  },
  props: {
    hideControls: Boolean,
    playing: Boolean,
    isMuted: Boolean,
    currentStep: Number,
    currentVideoTime: Number,
    showCloseBtn: Boolean,
    subtitlesChoice: String,
    iframeFullscreen: Boolean,
    isUsingIframe: Boolean,
    isEmbedDeephow: Boolean,
    enableTheaterButton: Boolean,
    volume: Number,
    showTopBarForcible: {
      type: Boolean,
      default: false,
    },
    watermarkId: {
      type: String,
    },
  },
  watch: {
    isDesktopFullscreen: function () {
      this.checkPlayerWidth();
    },
    isTheaterMode: function () {
      this.checkPlayerWidth();
    },
  },
  mounted() {
    this.checkPlayerWidth();
    window.addEventListener("resize", this.checkPlayerWidth);
    document.addEventListener("fullscreenchange", this.checkPlayerWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkPlayerWidth);
    window.removeEventListener("fullscreenchange", this.checkPlayerWidth);
  },
  data() {
    return {
      IS_TABLET,
      smallPlayerMaxWidth: 1263,
      isSmallScreen: true,
    };
  },
  methods: {
    ...mapActions("workflowPlayer", ["changeTheaterMode"]),
    onclickClose() {
      if (this.iframeFullscreen) {
        this.$emit("change-fullscreen");
        return;
      }
      this.$emit("close-player");
    },
    onclickPlayOrPause() {
      if (this.playing) {
        this.$emit("pause-video");
        return;
      }
      this.$emit("play-video");
    },
    checkPlayerWidth() {
      if (this.$refs.desktopControls) {
        const playerWidth = this.$refs.desktopControls.clientWidth;
        this.isSmallScreen = playerWidth <= this.smallPlayerMaxWidth;
      }
    },
    getStepDisplayTitle(stepIdx) {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      const step = this.getters_steps[stepIdx] || {};
      return getDisplayTitle(step, displayLang);
    },
    mouseenterSettingsButton() {
      if (IS_TABLET) return;
      this.$emit("click-setting");
    },
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
    ...mapGetters("workflowPlayer", ["getters_steps", "getter_is_window_mode"]),
    ...mapGetters("permission", ["getter_safety_watermark_enabled"]),
    ...mapState("workflowPlayer", ["workflowData", "isTheaterMode", "isDesktopFullscreen"]),
    showSafetyInfoWaterMark() {
      return this.workflowData.enableSafetyWatermark;
    },
    videoTime() {
      const current = getFormattedVideoDuration({ videoDuration: this.currentVideoTime });
      const total = getFormattedVideoDuration({ videoDuration: this.getters_steps[this.currentStep].duration });
      return `${current} / ${total}`;
    },
    workflowTitle() {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      return getDisplayTitle(this.workflowData, displayLang);
    },
    stepNum() {
      return getNumberWithZero({ num: this.currentStep + 1 });
    },
    stepTitle() {
      return this.getStepDisplayTitle(this.currentStep);
    },
    previousStepTitle() {
      return this.getStepDisplayTitle(this.currentStep - 1);
    },
    previousStepNum() {
      return getNumberWithZero({ num: this.currentStep });
    },
    nextStepTitle() {
      return this.getStepDisplayTitle(this.currentStep + 1);
    },
    nextStepNum() {
      return getNumberWithZero({ num: this.currentStep + 2 });
    },
    showTopBar() {
      return this.showTopBarForcible || !this.getter_is_window_mode || this.isUsingIframe || this.isEmbedDeephow;
    },
  },
};
</script>

<style lang="scss" scoped>
$bottomHeight: 94px;
$bottomHeightSm: 60px;
.ControlsDesktop {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  &__main {
    position: absolute;
    width: 100%;
    height: calc(100% - #{$bottomHeight});
    left: 0;
    top: 0;
    pointer-events: auto;
    &--small {
      height: calc(100% - #{$bottomHeightSm});
    }
    &--hideControls {
      height: calc(100% - 20px);
    }
  }
  &__topBar {
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: auto;
    background: linear-gradient(180deg, rgba(29, 30, 33, 0.8) 0%, rgba(29, 30, 33, 0) 100%);
    opacity: 1;
    transition: 0.3s;
    &--hidden {
      pointer-events: none;
      opacity: 0;
      transition: 0.3s;
    }
  }
  &__title {
    position: relative;
    max-width: calc(100% - 100px);
    padding: 16px 0 16px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--small {
      padding: 8px 16px;
    }
    &--text {
      position: relative;
      min-width: calc(100% - 60px);
      text-align: left;
    }
    &--workflow {
      color: #8d909f;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      margin: 0;
    }
    &--workflowSm {
      font-size: 12px;
      line-height: 16px;
    }
    &--step {
      color: #ffffff;
      font-size: 24px;
      line-height: 26px;
      font-weight: 400;
      margin: 4px 0 0 0;
    }
    &--stepSm {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__menuBtn {
    height: 32px;
    width: 32px;
    margin-right: 24px;
    svg {
      height: 32px;
      width: 32px;
    }
    &--small {
      height: 24px;
      width: 24px;
      margin-right: 12px;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
  &__closeBtn {
    height: 32px;
    width: 32px;
    padding-top: 4px;
    margin-right: 26px;
    svg {
      height: 24px;
      width: 24px;
    }
    &--small {
      height: 24px;
      width: 24px;
      padding-top: 3px;
      margin-right: 14px;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
  &__center {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 0.3s;
    display: flex;
    display: none;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(29, 30, 33, 0.8) 0%, rgba(29, 30, 33, 0) 60%);
    @media (max-width: 599px) {
      display: flex;
      opacity: 1;
      transition: 0.3s;
    }
    &--hidden {
      opacity: 0;
      transition: 0.3s;
    }
  }
  &__centerBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
  }
  &__bottomBar {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: auto;
  }
  &__bottomTime {
    position: relative;
    width: 100%;
    height: 4px;
    padding: 0 24px;
    &--time {
      position: absolute;
      left: 24px;
      top: -24px;
      color: #ffffff;
      font-size: 12px;
      opacity: 1;
      transition: 0.3s;
    }
    &--timeSm {
      left: 16px;
      top: -18px;
    }
    &--hideTime {
      pointer-events: none;
      opacity: 0;
      transition: 0.3s;
    }
    &--timeWrapper {
      position: absolute;
      width: calc(100% - 48px);
      left: 24px;
    }
    &--timeWrapperSm {
      width: 100%;
      left: 0px;
    }
  }
  &__bottomControls {
    position: relative;
    height: $bottomHeight;
    width: 100%;
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 1;
    background: linear-gradient(180deg, rgba(29, 30, 33, 0) 0%, rgba(29, 30, 33, 0.8) 100%);
    &--small {
      height: $bottomHeightSm;
      transition: 0.3s;
    }
    &--hidden {
      padding-top: 0px;
      height: 8px;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s;
    }
  }
  &__btns {
    $btnMarginLg: 16px;
    $btnMarginSm: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;
    @media (max-width: 599px) {
      display: none;
    }
    &--btnRotate {
      transform: rotateY(180deg);
    }
    &--btnLg {
      color: #ffffff;
      margin: 0 $btnMarginLg;
      svg {
        height: 30px;
        width: 30px;
      }
    }
    &--btnNextLg {
      padding: 3px;
      svg {
        height: 24px;
        width: 24px;
      }
    }
    &--btnSm {
      color: #ffffff;
      margin: 0 $btnMarginSm;
      svg {
        height: 18px;
        width: 18px;
      }
    }
    &--playBtnLg {
      margin: 0 $btnMarginLg;
      svg {
        height: 52px;
        width: 52px;
      }
    }
    &--playBtnSm {
      margin: 0 $btnMarginSm;
      svg {
        height: 26px;
        width: 26px;
      }
    }
  }
  &__btnsLeft {
    position: absolute;
    left: 0;
    padding-left: 8px;
  }
  &__btnsRight {
    position: absolute;
    right: 0;
    padding-right: 8px;
  }
}
</style>
