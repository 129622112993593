import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

const baseURL =
  environment.backendType === "firebase"
    ? environment.service[environment.env].admin
    : environment.service[environment.env]["china"].admin;

const organizationMappingServer = axios.create({
  baseURL,
});

organizationMappingServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

const config = {
  serverToken: "rG5kXk0CDbhgF4RBlNoV",
  organizationSettingsBaseUrl: "/v1/organization-settings/my",
  settingsBaseUrl: `${baseURL}settings`,
};

const apiWrapper = apiWrapperGenerator(organizationMappingServer);

export async function fetchOrganizationMappings(type) {
  try {
    const url = `${config.organizationSettingsBaseUrl}/mappings?type=${type}`;
    return await apiWrapper("get", url);
  } catch (error) {
    console.log(error);
  }
}

export async function putOrganizationMappings({ type, attribute, mappings }) {
  try {
    const settings = {
      type,
      attribute,
      mappings,
    };
    const url = `${config.organizationSettingsBaseUrl}/mappings`;
    return await apiWrapper("put", url, settings);
  } catch (error) {
    console.log(error);
  }
}

export async function fetchMappingAttributeFields() {
  try {
    const url = `${config.settingsBaseUrl}/get-fields`;
    const tokens = {
      token: config.serverToken,
      data: {
        idToken: await authChoice().currentUser.getIdToken(),
      },
    };
    const { data } = await axios.post(url, tokens);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function fetchMappingAttributeValues({ field }) {
  try {
    const url = `${config.settingsBaseUrl}/get-values`;
    const payload = {
      token: config.serverToken,
      field,
      data: {
        idToken: await authChoice().currentUser.getIdToken(),
      },
    };
    const { data } = await axios.post(url, payload);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export default organizationMappingServer;
