<template>
  <div class="d-skills-asign-users">
    <div class="scroll-div">
      <div class="share-div content-center">
        <button class="close-btn" @click="$emit('close-popup')">
          <icon-base color="#8D909F" width="16" height="16">
            <d-icon-close2 />
          </icon-base>
        </button>
        <!-- v-if="!isConfirmation" -->
        <div>
          <div class="title-div">
            <d-icon-mapping class="mb-2" />
            <div v-if="popupType == 'add'" class="popup-title">{{ $t("admin.selectValues") }}</div>
            <div v-else class="popup-title">{{ $t("admin.removeValues") }}</div>
          </div>
          <div class="serach-div">
            <v-text-field
              solo
              flat
              background-color="transparent"
              single-line
              hide-details
              v-model="searchInput"
              class="search-text"
              :placeholder="$t('admin.searchForValues')"
              autocomplete="chrome-off"
            >
              <!-- @input="handleFindValues()" -->
              <template slot="prepend-inner">
                <icon-base color="#52545D" width="24" height="24" class="mr-3">
                  <d-icon-search />
                </icon-base>
              </template>
            </v-text-field>
          </div>
          <button
            v-if="overflowNum > 0"
            class="more-btn"
            :class="{ 'mr-2 pl-1': !showOneLine }"
            @click="showOrHideSelectedItems()"
          >
            <span v-if="showOneLine">{{ $tc("admin.numMore", overflowNum) }}</span>
            <v-icon v-else color="#4a90e2">expand_less</v-icon>
          </button>
          <div
            ref="selectedUserDiv"
            class="selected-users"
            :class="{ 'show-one-line': selectedItems.length > 0, 'show-all-lines': !showOneLine }"
          >
            <div
              v-for="item in selectedItems"
              :key="item"
              ref="singleUser"
              class="selected-single-user text-capitalize"
            >
              {{ item }}
              <button @click="clickItem(item)" class="delete-user-btn">
                <icon-base color="#5E5E5E" width="10" height="10" class="delete-user-icon">
                  <d-icon-close2 />
                </icon-base>
              </button>
            </div>
          </div>
          <div ref="userList" class="user-list-div">
            <v-list light dense two-line class="dropdown-list-scroll">
              <!-- <div v-if="isSearchingUsers && searchInput.length>0" class="text-xs-center">
                <v-progress-circular
                  size="22"
                  width="2"
                  color="dBlue"
                  indeterminate
                  class="mr-2 ml-1 mb-1"
                ></v-progress-circular>
                <span class="user-list-email">{{$t('admin.searchingforUsers')}}</span>
              </div>-->
              <div v-if="showValueList.length === 0 && searchInput.length > 0" class="text-xs-center">
                <span class="user-list-email">- {{ $t("admin.noResultsFound") }} -</span>
              </div>
              <div>
                <div v-for="item in showValueList" :key="item">
                  <v-list-tile
                    avatar
                    class="dropdown-list-item app-no-select"
                    :class="{
                      'added-users': isInAddedArray(addedAttributes, item),
                    }"
                  >
                    <v-list-tile-avatar size="40">
                      <div class="first-letter">
                        <span class="text-letter">{{ getNameInitials(item) }}</span>
                      </div>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <span class="text-capitalize user-list-name">{{ item }}</span>
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <button @click="clickItem(item)">
                        <icon-base v-if="isInArray(selectedItems, item)" width="32" height="32">
                          <d-icon-circle-checked-green />
                        </icon-base>
                        <icon-base v-else width="32" height="32" class="add-icon">
                          <d-icon-circle-add v-if="popupType == 'add'" />
                          <d-icon-circle-rmove v-if="popupType == 'remove'" />
                        </icon-base>
                      </button>
                    </v-list-tile-action>
                  </v-list-tile>
                </div>
              </div>
            </v-list>
          </div>

          <div v-if="popupType == 'add'" ref="messageDiv" class="message-div">
            <d-button
              depressed
              round
              block
              class="body-2 white--text no-text-transform"
              color="#4689F3"
              :disabled="selectedItems.length == 0"
              light
              @click="addAttributes"
              >{{ $t("admin.assign") }}</d-button
            >
          </div>

          <div v-if="popupType == 'remove'" ref="messageDiv" class="message-div">
            <d-button
              depressed
              round
              block
              class="body-2 white--text no-text-transform"
              color="#4689F3"
              :disabled="selectedItems.length == 0"
              light
              @click="removeAttributes()"
              >{{ $t("admin.remove") }}</d-button
            >
          </div>
        </div>

        <!-- add confirmation -->
        <!-- <div v-if="isConfirmation && popupType=='add'">
          <div class="comfirmation-div content-center">
            <d-icon-mapping class="mb-2" />
            <div class="popup-title mb-2">{{$t('admin.assignValues')}}</div>
            <div class="comfirmation-text">
              All new users and
              <b>{{totalAffected}}</b>
              existing users will be assigned to {{editWorkspaceData.name}}, are you sure?
            </div>
            <v-card-actions class="btn-div">
              <d-layout class="mt-4">
                <d-flex class="xs6 mr-2">
                  <v-btn
                    outline
                    depressed
                    round
                    block
                    class="body-2 no-text-transform"
                    color="#4689f4"
                    @click="$emit('close-popup')"
                  >{{$t('all.no')}}</v-btn>
                </d-flex>
                <d-flex class="xs6 ml-2">
                  <v-btn
                    depressed
                    round
                    block
                    class="body-2 white--text no-text-transform"
                    color="#4689F3"
                    @click="$emit('add-attributes', selectedItems), $emit('close-popup')"
                  >{{$t('admin.addUsersBtn')}}</v-btn>
                </d-flex>
              </d-layout>
            </v-card-actions>
          </div>
        </div>-->

        <!-- remove confirmation -->
        <!-- <div v-if="isConfirmation && popupType=='remove'">
          <div class="comfirmation-div content-center">
            <d-icon-mapping class="mb-2" />
            <div class="popup-title mb-2">{{$t('admin.removeValues')}}</div>
            <div class="comfirmation-text">
              You are going to remove
              <b>150 users</b> from the Workspace
              <b>{{editWorkspaceData.name}}</b>, are you sure?
            </div>
            <v-card-actions class="btn-div">
              <d-layout class="mt-4">
                <d-flex class="xs6 mr-2">
                  <v-btn
                    outline
                    depressed
                    round
                    block
                    class="body-2 no-text-transform"
                    color="#4689f4"
                    @click="$emit('close-popup')"
                  >{{$t('all.no')}}</v-btn>
                </d-flex>
                <d-flex class="xs6 ml-2">
                  <v-btn
                    depressed
                    round
                    block
                    class="body-2 white--text no-text-transform"
                    color="#4689F3"
                    @click="$emit('remove-attributes', selectedItems), $emit('close-popup')"
                  >{{$t('admin.YesChangeBtn')}}</v-btn>
                </d-flex>
              </d-layout>
            </v-card-actions>
          </div>
        </div>-->
      </div>
      <div class="close-bg" @click="$emit('close-popup')"></div>

      <!-- <div class="test-test">
        <p class="white--text">showOneLine:{{showOneLine}} | overflowNum:{{overflowNum}}</p>
        <p class="red--text">{{addedAttributes}}</p>

        <p class="blue--text">editWorkspaceData: {{editWorkspaceData}}</p>
        <p class="blue--text">{{editedIndex}}
            <br>
            {{selectedItems}}
        </p>
        {{attributeValueList}}
      </div>-->
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconMapping from "@/components/icons/colored/DIconMapping.vue";
import DIconUser from "@/components/icons/colored/DIconUser.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconCircleAdd from "@/components/icons/DIconCircleAdd.vue";
import DIconCircleCheckedGreen from "@/components/icons/colored/DIconCircleCheckedGreen.vue";
import DIconArrowUp from "@/components/icons/DIconArrowUp.vue";
import DIconCircleRmove from "@/components/icons/DIconCircleRmove.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import MixinDB from "@/components/MixinDB.vue";
import axios from "axios";

export default {
  name: "DAdminEditValuesPopup",
  props: {
    popupType: String,
    editWorkspace: Object,
    attributeValues: Array,
    selectedAttribute: Object,
  },
  watch: {},
  mounted() {
    this.addedAttributes = [...this.editWorkspace.attributeValues];
    this.attributeValueList = [...this.attributeValues];
    this.$nextTick(() => this.getListHeight());
  },
  created() {
    this.$store.dispatch("getUsers", { organization: this.$organization });
  },
  mixins: [MixinAdmin, MixinDB],
  data() {
    return {
      // isConfirmation: false,
      attributeValueList: [],
      searchInput: "",
      foundValues: [],
      selectedItems: [],
      autoGrowHack: false,
      scrollTop: 0,
      maxScrollTop: 0,
      isSearchingUsers: false,
      showTooltip: false,
      assignHistory: [],
      showOneLine: true,
      overflowNum: 0,
      showMoreUsers: false,
      addedAttributes: [],
      totalAffected: 0,
    };
  },
  components: {
    IconBase,
    DIconMapping,
    DIconUser,
    DIconSearch,
    DIconClose2,
    DIconCircleAdd,
    DIconCircleCheckedGreen,
    DIconArrowUp,
    DIconCircleRmove,
  },
  methods: {
    async getAffectedUserCount() {
      const self = this;
      const data = {
        token: "rG5kXk0CDbhgF4RBlNoV",
        data: {
          idToken: await self.getIdToken(),
        },
        field: self.selectedAttribute.key,
        values: self.selectedItems,
      };
      return new Promise(function (resolve, reject) {
        axios
          .post(self.$service.admin + "settings/get-affected-users", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNameInitials(name) {
      if (name.length > 0) {
        let initials = "";
        const nameArray = name.split(" ");
        initials = nameArray[0].substring(0, 1).toUpperCase();
        if (nameArray.length > 1) {
          initials += nameArray[nameArray.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      }
    },
    clickItem(item) {
      const selectedItems = this.selectedItems;
      const inArrayIndex = selectedItems.findIndex((value) => value === item);
      inArrayIndex === -1 ? selectedItems.unshift(item) : selectedItems.splice(inArrayIndex, 1);
      this.$nextTick(function () {
        this.checkOverflown();
        this.getListHeight();
      });
    },
    checkOverflown() {
      let firstLine = 0;
      const els = this.$refs.singleUser;
      if (els.length > 0) {
        for (let i = 0; i < els.length; i++) {
          let el = els[i];
          if (el.offsetTop == 0) {
            firstLine++;
          } else {
            break;
          }
        }
      }
      this.overflowNum = els.length - firstLine;
    },
    showOrHideSelectedItems() {
      this.showOneLine = !this.showOneLine;
      this.$nextTick(() => this.getListHeight());
    },
    getListHeight() {
      if (this.$refs.messageDiv && this.$refs.selectedUserDiv) {
        const messageHeight = this.$refs.messageDiv.offsetHeight;
        // defult top: 332px;
        let newTop = 332;
        let selectedUserHeight = 0;
        if (this.selectedItems.length > 0) {
          selectedUserHeight = this.$refs.selectedUserDiv.offsetHeight + 10;
          newTop = newTop + selectedUserHeight;
        }
        this.$nextTick(function () {
          this.$refs.userList.style.top = newTop + "px";
          this.$refs.userList.style.height = 440 - messageHeight - selectedUserHeight + "px";
        });
      }
    },
    async addAttributes() {
      this.$emit("add-attributes", this.selectedItems);
      this.$emit("close-popup");
    },
    removeAttributes() {
      this.$emit("remove-attributes", this.selectedItems);
      this.$emit("close-popup");
    },
    isInAddedArray(arr, item) {
      if (this.popupType == "add") {
        const pos = arr.indexOf(item);
        return pos != -1;
      } else {
        return false;
      }
    },
    isInArray(arr, item) {
      const pos = arr.indexOf(item);
      return pos != -1;
    },
  },
  computed: {
    showValueList() {
      if (this.popupType == "add") {
        return this.attributeValueList.filter((value) => {
          if (value.toLowerCase().includes(this.searchInput.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        return this.addedAttributes.filter((value) => {
          if (value.toLowerCase().includes(this.searchInput.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.d-skills-asign-users {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
}
.d-skills-asign-users:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.scroll-div {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 800px;
}
.share-div {
  height: 780px;
  width: 480px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding: 36px;
  z-index: 1;
}
.comfirmation-div {
  width: 420px;
}
.content-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
}
.title-div {
  position: absolute;
  width: 100%;
  height: 240px;
  top: 0;
  left: 0;
  padding-top: 48px;
  text-align: center;
}
.popup-title {
  color: #52545d;
  font-family: Roboto;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}
.serach-div {
  position: absolute;
  width: 100%;
  height: 60px;
  top: 258px;
  left: 0;
  padding: 5px 24px 0 24px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.search-text ::v-deep .v-text-field__slot input {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  line-height: 26px;
}
.search-text ::v-deep .v-text-field__slot input::placeholder {
  opacity: 0.5;
  color: #919191;
  font-family: Roboto;
  font-size: 20px;
  line-height: 26px;
}
.selected-users {
  position: absolute;
  top: 336px;
  left: 22px;
  text-align: left;
  overflow: hidden;
  padding: 0 44px 0 0;
  width: calc(100% - 30px);
  opacity: 0;
  height: 32px;
  transition: 0.3s;
  pointer-events: none;
}
.show-one-line {
  opacity: 1;
  height: 32px;
  transition: 0.3s;
  pointer-events: auto;
}
.show-all-lines {
  height: auto;
  max-height: 114px;
  overflow: auto;
  transition: 0.3s;
  pointer-events: auto;
}
.show-all-lines::-webkit-scrollbar {
  width: 3px;
}
.show-all-lines::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #4a4a4a15;
}
.show-all-lines::-webkit-scrollbar-thumb {
  background-color: #9b9b9b75;
  border-radius: 2px;
}
.selected-single-user {
  position: relative;
  background-color: #1314151a;
  margin-right: 4px;
  color: #52545d;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  border-radius: 14px;
  height: 28px;
  display: inline-block;
  margin-bottom: 6px;
  padding-left: 12px;
  padding-right: 4px;
}
.delete-user-btn {
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 12px;
}
.delete-user-icon {
  margin-bottom: -1px;
}
.more-btn {
  position: absolute;
  /* background-color: rgba(255, 99, 71, 0.178); */
  height: 24px;
  right: 14px;
  top: 340px;
  color: #4a90e2;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
  cursor: pointer;
  z-index: 1;
}
.user-list-div {
  position: absolute;
  width: calc(100% - 16px);
  top: 332px;
  left: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
}
.user-list-div::-webkit-scrollbar {
  width: 3px;
}
.user-list-div::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #4a4a4a15 !important;
}
.user-list-div::-webkit-scrollbar-thumb {
  background-color: #9b9b9b75;
  border-radius: 2px;
}
.user-list-name {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.user-list-email {
  color: #8d909f;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}
.message-div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-top: 1px solid #f0f0f0;
  padding: 24px 24px 0 24px;
  transition: 0.3s;
}

.no-text-transform {
  text-transform: none !important;
}

.comfirmation-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.user-list-div ::v-deep .theme--light.v-list {
  background-color: transparent !important;
}

.dropdown-list-item:hover {
  background-color: #9b9b9b1c !important;
}
.added-users {
  opacity: 0.4;
  pointer-events: none !important;
}
.add-icon {
  color: #52545d;
  opacity: 0.5;
}
.add-icon:hover {
  opacity: 1;
}
.close-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.test-test {
  position: absolute;
  width: 200px;
  height: 100%;
  z-index: 9999;
  text-align: left;
  overflow: auto;
}
.first-letter {
  height: 40px;
  width: 40px;
  border: 1px solid #dadada;
  background-color: #ececec;
  border-radius: 20px;
  text-align: center;
  padding-top: 7px;
}
.text-letter {
  color: #8d909f;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
</style>
