<template>
  <v-dialog
    :content-class="`versionHistoryPopup ${isBuilder && 'my-0 ml-auto mr-0 max-height-full'}`"
    :value="isOpenVersionHistory"
    @input="$emit('update:isOpenVersionHistory', false)"
    :max-width="maxWidth"
    persistent
  >
    <v-card class="card" :class="[type]">
      <v-card-title class="card__title">
        <ul>
          <li>
            <d-icon-version-history-title />
            <p class="ml-2 mr-auto mb-0 d-inline-block">{{ $t("editor.versionHistory") }}</p>
          </li>
        </ul>
        <span class="close-btn" @click="$emit('update:isOpenVersionHistory', false)"></span>
      </v-card-title>
      <v-card-text class="card__text">
        <loading-view v-if="isOpenLoading" :type="type" />
        <empty-view v-if="isVersionHistoryDataEmpty" :type="type" />
        <history-action
          v-else
          v-for="history in versionHistoryData"
          :key="history.version"
          :history-data="history"
          :type="type"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchVersionHistoryByWorkflowId } from "@/server/version-history";
import { loggingError } from "@/server/error-log-server.js";

export default {
  name: "VersionHistoryPopup",
  components: {
    IconBase: () => import("@/components/IconBase.vue"),
    DIconVersionHistoryTitle: () => import("@/components/icons/editor/DIconVersionHistoryTitle.vue"),
    HistoryAction: () => import("@/components/DEditorBuilder/VersionHistory/Fragments/HistoryAction.vue"),
    LoadingView: () => import("@/components/DEditorBuilder/VersionHistory/LoadingView.vue"),
    EmptyView: () => import("@/components/DEditorBuilder/VersionHistory/EmptyView.vue"),
  },
  props: {
    isOpenVersionHistory: Boolean,
    workflowId: String,
    type: {
      type: String,
      validator(value) {
        return ["editor", "builder"].includes(value);
      },
      default: () => "builder",
    },
  },
  data() {
    return {
      maxWidth: 850,
      versionHistoryData: [],
      isOpenLoading: false,
    };
  },
  computed: {
    isBuilder() {
      return this.type === "builder";
    },
    isVersionHistoryDataEmpty() {
      return !this.isOpenLoading && this.versionHistoryData.length === 0;
    },
  },
  methods: {
    async refreshVersionHistoryData(workflowId) {
      try {
        this.isOpenLoading = true;
        if (!workflowId) {
          return;
        }
        const { ok, data, errorMessage } = await fetchVersionHistoryByWorkflowId(workflowId);
        if (!ok) {
          console.error(errorMessage);
          return;
        }
        this.versionHistoryData = data.items.sort((a, b) => b.version - a.version);
      } catch (error) {
        console.error(error);
        await loggingError(error);
      } finally {
        this.isOpenLoading = false;
      }
    },
  },
  watch: {
    workflowId: {
      immediate: true,
      handler(workflowId) {
        if (!this.isOpenVersionHistory) {
          return;
        }
        this.refreshVersionHistoryData(workflowId);
      },
    },
    isOpenVersionHistory(isOpenVersionHistory) {
      if (!isOpenVersionHistory || !this.workflowId) {
        this.versionHistoryData = [];
        return;
      }
      this.refreshVersionHistoryData(this.workflowId);
    },
    type: {
      immediate: true,
      handler(type) {
        switch (type) {
          case "editor":
            this.maxWidth = 850;
            break;
          case "builder":
            this.maxWidth = 420;
            break;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.versionHistoryPopup {
  &::v-deep &.builder {
    color: green;
  }
  &.builder {
    margin-left: auto;
  }
  .card {
    background-color: #28292e;
    color: #fff;

    &.builder {
      height: 100vh;
      .card__text {
        height: 100%;
        max-height: calc(100% - 80px);
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #33353a;
      padding: 32px 32px 22px 32px;
      font-size: 16px;
      font-weight: 700;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #4689f3;
            left: 0;
            top: calc(100% + 22px);
          }
        }
      }

      .close-btn {
        --roate-angle: 45deg;
        width: 22px;
        height: 22px;
        position: relative;
        cursor: pointer;
        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #9397a6;
          top: 50%;
          left: 50%;
          transform-origin: center;
          transform: translate(-50%, -50%) rotate(var(--roate-angle));
        }

        &:after {
          --roate-angle: -45deg;
        }
      }
    }

    &__text {
      position: relative;
      overflow-y: auto;
      max-height: 700px;
      padding: 32px 32px 16px 32px;

      @media (max-height: 900px) {
        max-height: 500px;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #1d1e21;
      }
      &:hover::-webkit-scrollbar-thumb {
        background-color: #454545;
      }
    }
  }
}
</style>
