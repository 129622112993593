<template>
  <v-dialog dark v-model="showPopup" max-width="374px" :persistent="persistent">
    <v-card color="#1E1F22" class="card-div">
      <v-card-text class="pa-0 mb-4">
        <h3 class="content-text pb-3" v-html="content"></h3>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <button v-if="cancelBtnName" class="button-cancel" @click="$emit('input', false)" :autotest="autotest.cancel">
          {{ cancelBtnName }}
        </button>
        <button
          class="button-action"
          @click="$emit('input', false), $emit('clickActionBtn')"
          :autotest="autotest.action"
        >
          {{ actionBtnName }}
        </button>
      </v-card-actions>
    </v-card>
    <div v-if="!persistent" class="click-to-close" @click="$emit('input', false)"></div>
  </v-dialog>
</template>

<script>
export default {
  name: "DConfirmationPopupSm",
  props: {
    value: Boolean, //for v-model value
    content: String,
    cancelBtnName: String,
    actionBtnName: String,
    autotest: {
      type: Object,
      default: () => ({
        action: "popup-yes-btn",
        cancel: "popup-cancel-btn",
      }),
    },
    persistent: Boolean,
  },
  watch: {
    value: function () {
      this.showPopup = this.value;
    },
  },
  mounted() {},
  data() {
    return {
      showPopup: false,
    };
  },
};
</script>

<style scoped>
.card-div {
  border: 1px solid rgba(145, 145, 145, 0.2) !important;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: left;
  padding: 24px 32px;
}
.content-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
button {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.button-cancel {
  color: #9b9b9b;
  margin-right: 32px;
}
.button-action {
  color: #4689f3;
}
.click-to-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
</style>
