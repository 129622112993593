import { fetchWorkflowSteps } from "@/server/api-server";
import environment from "../../../env";
import firebase from "firebase";
import db from "../../../db";
import { cloneDeep } from "lodash-es";
import { add } from "mathjs";
import { isNumber } from "@/js/common/formatText";

const getGoogleFirestore = function () {
  return environment.backendType !== "ali" ? firebase.firestore() : null;
};
export const namespaced = true;
export const state = {
  steps: [],
  transcriptionMap: {}, // {[key: transcriptionId]: transcription}
  dbTranscriptionMap: {}, // {[key: transcriptionId]: transcription}
  languageCode: "",
  isLocalDataDifferentFromDB: false,
  isReadyOnly: false,
};

export const mutations = {
  MUTATION_STEPS(state, steps) {
    state.steps = steps;
  },
  MUTATION_TRANSCRIPTION_MAP(state, transcription) {
    const { id } = transcription;
    state.transcriptionMap = { ...state.transcriptionMap, [id]: transcription };
    state.dbTranscriptionMap[id] = cloneDeep(transcription);
  },
  MUTATION_LANGUAGE_CODE(state, languageCode) {
    state.languageCode = languageCode;
  },
  MUTATION_SENTENCE_BY_STEP_ID_AND_INDEX(state, { stepId, sentenceIndex, sentence }) {
    const step = state.steps.find(({ id }) => id === stepId);
    if (!step) {
      console.error(`step data is invalid`);
      return;
    }
    const transcriptionId = step.transcriptions[state.languageCode];
    if (!transcriptionId) {
      console.error(`transcriptionId is invalid`);
      return;
    }
    const transcription = state.transcriptionMap[transcriptionId];
    const sentences = JSON.parse(transcription.sentences);
    sentences[sentenceIndex].sentence = sentence;
    transcription.sentences = JSON.stringify(sentences);
    state.transcriptionMap[transcriptionId] = transcription;
  },
  RESET_IS_LOCAL_DATA_DIFFERENT_FROM_DB(state) {
    state.isLocalDataDifferentFromDB = false;
  },
  MUTATION_READY_ONLY(state, isReadyOnly) {
    state.isReadyOnly = isReadyOnly;
  },
  RESET_DEFAULT(state) {
    state.steps = [];
    state.transcriptionMap = {};
    state.dbTranscriptionMap = {};
    state.languageCode = "";
    state.isLocalDataDifferentFromDB = false;
  },
};
export const getters = {};

export const actions = {
  async fetchStepsByWorkflowId({ commit, dispatch }, workflowId) {
    const { ok, data } = await fetchWorkflowSteps(workflowId);
    if (!ok) {
      console.error(`Fetching step data occurs an error`);
      return;
    }
    commit("MUTATION_STEPS", data.items);
  },
  async fetchTranscriptionById({ commit }, transcriptionId) {
    this.$db = getGoogleFirestore();
    const transcription = await db.getDocument.call(this, "transcriptions", transcriptionId);
    commit("MUTATION_TRANSCRIPTION_MAP", transcription);
    return transcription;
  },
  storeLanguageCode({ commit }, languageCode) {
    commit("MUTATION_LANGUAGE_CODE", languageCode);
  },
  changeSentenceByStepIdAndSentenceIndex({ commit, dispatch }, { sentenceIndex, stepId, sentence }) {
    commit("MUTATION_SENTENCE_BY_STEP_ID_AND_INDEX", { sentenceIndex, stepId, sentence });
    dispatch("compareDBTranscriptionAndLocalTranscription");
  },
  compareDBTranscriptionAndLocalTranscription({ state }) {
    state.isLocalDataDifferentFromDB =
      Object.values(state.transcriptionMap).length !== Object.values(state.dbTranscriptionMap).length;
    if (state.isLocalDataDifferentFromDB) {
      return;
    }
    for (let i = 0; i < state.steps.length; i++) {
      const transcriptionId = state.steps[i].transcriptions[state.languageCode];
      const localTranscription = state.transcriptionMap[transcriptionId];
      const dbTranscription = state.dbTranscriptionMap[transcriptionId];
      if (!localTranscription || !dbTranscription || localTranscription.sentences !== dbTranscription.sentences) {
        state.isLocalDataDifferentFromDB = true;
        break;
      }
    }
  },
  generateTranscriptionBatch({ state }) {
    return state.steps.map((step) => {
      const transcriptionId = step.transcriptions[state.languageCode];
      return {
        type: "update",
        collection: "transcriptions",
        id: transcriptionId,
        data: state.transcriptionMap[transcriptionId],
      };
    });
  },
  resetStepSentenceState({ commit }) {
    commit("RESET_DEFAULT");
  },
  resetIsLocalDataDifferentFromDB({ commit }) {
    commit("RESET_IS_LOCAL_DATA_DIFFERENT_FROM_DB");
  },
  storeSentenceReadyOnly({ commit }, isReadyOnly) {
    commit("MUTATION_READY_ONLY", isReadyOnly);
  },
  checkEmptySentence() {
    let isEmpty = false;
    for (const transcriptionId in state.transcriptionMap) {
      if (isEmpty) {
        break;
      }
      const transcription = state.transcriptionMap[transcriptionId];
      for (const sentences of JSON.parse(transcription.sentences)) {
        if (sentences.sentence === "") {
          isEmpty = true;
          break;
        }
      }
    }
    return isEmpty;
  },
  getSentenceIndexByCurrentTime({ state }, { currentTime, stepIndex }) {
    let targetIndex = -1;
    try {
      if (!state.steps[stepIndex] || !state.languageCode) {
        return targetIndex;
      }
      const transcription = state.transcriptionMap[state.steps[stepIndex].transcriptions[state.languageCode]];
      if (!transcription) {
        return targetIndex;
      }
      const sentences = JSON.parse(transcription.sentences);
      for (let index = 0; index < sentences.length; index++) {
        const sentence = sentences[index];
        if (currentTime >= sentence.startTime && currentTime <= sentence.endTime) {
          targetIndex = index;
        }
      }
      return targetIndex;
    } catch (error) {
      console.error(error);
    }
  },
  getVideoCurrentTimeByStepIndexAndSentenceIndex({ state }, { stepIndex, sentenceIndex }) {
    try {
      const stepTime = state.steps
        .slice(0, stepIndex)
        .reduce((accumulateTime, step) => accumulateTime + step.duration, 0);
      const transcription = state.transcriptionMap[state.steps[stepIndex]?.transcriptions[state.languageCode]];
      if (!transcription) {
        console.error("transcription is invalid");
        return;
      }
      const sentencesTime = JSON.parse(transcription.sentences)[sentenceIndex]?.startTime;
      if (!isNumber(sentencesTime)) {
        console.error("sentencesTime is invalid");
        return;
      }
      return add(stepTime, sentencesTime);
    } catch (error) {
      console.error(error);
    }
  },
};
