<template>
  <div class="ControlsMobileFullscreen">
    <div class="ControlsMobileFullscreen__main">
      <safety-info-watermark v-if="showSafetyInfoWatermark" :watermarkId="watermarkId" />
    </div>

    <v-toolbar
      dark
      color="transparent"
      height="64"
      class="elevation-0 ControlsMobileFullscreen__toolbar"
      :class="{ ControlsMobileFullscreen__hide: hideControls }"
    >
      <button class="mt-2 ml-1" @click="$emit('click-menu-btn', $event)">
        <icon-base color="white" height="24" width="24">
          <d-icon-menu-fullscreen />
        </icon-base>
      </button>
      <v-toolbar-title class="text-xs-left mt-1 mr-3" @click="$emit('click-menu-btn', $event)">
        <p class="ControlsMobileFullscreen__title--workflow text-no-wrap text-truncate">{{ workflowDisplayTitle }}</p>
        <p class="ControlsMobileFullscreen__title--step">{{ stepNum }}. {{ stepTitle }}</p>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <button v-if="!isSearchPlayer" @click="exitMobileFullscreen" class="mt-2 mr-1">
        <icon-base color="white" height="18" width="18">
          <d-icon-close-2 />
        </icon-base>
      </button>
    </v-toolbar>

    <div
      class="ControlsMobileFullscreen__center"
      :class="{ ControlsMobileFullscreen__hide: hideControls }"
      @click.self="$emit('show-or-hide-icons')"
    >
      <div class="ControlsMobileFullscreen__btns">
        <button class="ControlsMobileFullscreen__btns--btn" @click="$emit('play-back')">
          <icon-base color="white" height="32" width="32" class="ControlsMobileFullscreen__btns--btnRotate">
            <d-icon-player-fast-forward />
          </icon-base>
        </button>
        <button class="ControlsMobileFullscreen__btns--btn" @click="$emit('play-previous-step')">
          <icon-base
            color="white"
            height="28"
            width="28"
            viewBox="0 0 10 14"
            class="ControlsMobileFullscreen__btns--btnRotate"
          >
            <d-icon-player-next />
          </icon-base>
        </button>
        <button class="ControlsMobileFullscreen__btns--btn" @click="$emit('play-pause-video')">
          <icon-base height="56" width="56" color="white">
            <component :is="playing ? 'd-icon-player-pause-2' : 'd-icon-player-play-2'" />
          </icon-base>
        </button>
        <button class="ControlsMobileFullscreen__btns--btn" @click="$emit('play-next-step')">
          <icon-base color="white" height="28" width="28" viewBox="0 0 10 14">
            <d-icon-player-next />
          </icon-base>
        </button>
        <button class="ControlsMobileFullscreen__btns--btn" @click="$emit('play-forward')">
          <icon-base color="white" height="32" width="32">
            <d-icon-player-fast-forward />
          </icon-base>
        </button>
      </div>
      <div class="ControlsMobileFullscreen__shadow--up"></div>
      <div class="ControlsMobileFullscreen__shadow--down"></div>
    </div>

    <div class="ControlsMobileFullscreen__bottom">
      <span class="ControlsMobileFullscreen__bottom--time" :class="{ ControlsMobileFullscreen__hide: hideControls }"
        >{{ formatedCurrentTime }} / {{ formatedDuration }}</span
      >
      <div
        class="ControlsMobileFullscreen__bottom--timeWrapper"
        :class="{ 'ControlsMobileFullscreen__bottom--timeWrapperLower': hideControls }"
      >
        <slot />
      </div>

      <div class="ControlsMobileFullscreen__bottom--btns" :class="{ ControlsMobileFullscreen__hide: hideControls }">
        <button class="ControlsMobileFullscreen__bottom--btn" @click="$emit('click-serach')" v-if="!getter_is_gen2">
          <icon-base color="white" height="18" width="18">
            <d-icon-search />
          </icon-base>
        </button>
        <controls-repeat-button class="ControlsMobileFullscreen__bottom--btn" size="medium" disableTooltip />
        <button class="ControlsMobileFullscreen__bottom--btn" @click="$emit('click-setting')">
          <icon-base color="white" height="20" width="20">
            <d-icon-player-settings />
          </icon-base>
        </button>
        <button
          class="ControlsMobileFullscreen__bottom--btn ControlsMobileFullscreen__bottom--btnRight"
          @click="exitMobileFullscreen"
        >
          <icon-base color="white" height="20" width="20">
            <d-icon-player-exit-fullscreen />
          </icon-base>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconMenuFullscreen from "@/components/icons/player/DIconMenuFullscreen.vue";
import DIconPlayerClose from "@/components/icons/player/DIconPlayerClose.vue";
import DIconPlayerPlay2 from "@/components/icons/player/DIconPlayerPlay2.vue";
import DIconPlayerPause2 from "@/components/icons/player/DIconPlayerPause2.vue";
import DIconPlayerNext from "@/components/icons/player/DIconPlayerNext.vue";
import DIconPlayerSettings from "@/components/icons/player/DIconPlayerSettings.vue";
import DIconPlayerFastForward from "@/components/icons/player/DIconPlayerFastForward.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconLoading from "@/components/icons/animated/DIconLoading.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconPlayerExitFullscreen from "@/components/icons/player/DIconPlayerExitFullscreen.vue";
import ControlsRepeatButton from "@/components/DWorkflowPlayer/controls/ControlsRepeatButton.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ControlsMobileFullscreen",
  components: {
    IconBase,
    DIconMenuFullscreen,
    DIconPlayerClose,
    DIconPlayerPlay2,
    DIconPlayerPause2,
    DIconPlayerNext,
    DIconPlayerSettings,
    DIconPlayerFastForward,
    DIconClose2,
    DIconLoading,
    DIconSearch,
    DIconPlayerExitFullscreen,
    ControlsRepeatButton,
    SafetyInfoWatermark: () => import("./SafetyInfoWatermark.vue"),
  },
  props: {
    workflowId: String,
    hideControls: Boolean,
    isPublic: Boolean,
    isSearchPlayer: Boolean,
    workflowDisplayTitle: String,
    stepNum: String,
    stepTitle: String,
    playing: Boolean,
    formatedCurrentTime: String,
    formatedDuration: String,
    watermarkId: {
      type: String,
    },
  },
  methods: {
    ...mapActions("workflowPlayer", ["exitMobileFullscreen"]),
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
    ...mapState("workflowPlayer", ["workflowData"]),
    showSafetyInfoWatermark() {
      return this.workflowData.enableSafetyWatermark;
    },
  },
};
</script>

<style lang="scss" scoped>
$bottomHeight: 76px;
.ControlsMobileFullscreen {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s;
  &__hide {
    z-index: -2;
    opacity: 0;
    transition-duration: 0.3s;
  }
  &__main {
    position: absolute;
    width: 100%;
    height: calc(100% - #{$bottomHeight});
    left: 0;
    top: 0;
  }
  &__toolbar {
    z-index: 1;
  }
  &__title {
    &--workflow {
      color: #8d909f;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      margin: 0;
    }
    &--step {
      font-size: 20px;
      color: #ffffff;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      margin: 4px 0 0 0;
    }
  }
  &__center {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btns {
    padding-top: 6px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &--btn {
      margin: 0 26px;
      @media screen and (orientation: portrait) {
        margin: 0 3vw;
      }
      @media screen and (orientation: landscape) {
        margin: 0 26px;
      }
    }
    &--btnRotate {
      transform: rotateY(180deg);
    }
  }
  &__shadow {
    &--up {
      z-index: -2;
      position: absolute;
      top: 0;
      left: 0;
      height: 60%;
      width: 100%;
      background: linear-gradient(180deg, rgba(12, 12, 14, 1) 0%, rgba(12, 12, 14, 0) 100%);
      pointer-events: none;
    }
    &--down {
      z-index: -2;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60%;
      width: 100%;
      background: linear-gradient(0deg, rgba(12, 12, 14, 1) 0%, rgba(12, 12, 14, 0) 100%);
      pointer-events: none;
    }
  }
  &__bottom {
    z-index: 1;
    position: absolute;
    height: $bottomHeight;
    width: 100%;
    bottom: 0;
    left: 0;
    &--time {
      position: absolute;
      left: 24px;
      bottom: 40px;
      color: #ffffff;
      font-size: 12px;
    }
    &--timeWrapper {
      z-index: 2;
      position: absolute;
      width: calc(100% - 48px);
      left: 24px;
      bottom: 16px;
    }
    &--timeWrapperLower {
      width: 100%;
      left: 0;
      bottom: -10px;
    }
    &--btns {
      position: absolute;
      height: 32px;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
    }
    &--btn {
      height: 32px;
      width: 32px;
      padding-top: 6px;
      margin-right: 20px;
    }
    &--btnRight {
      padding-top: 5px;
      margin-right: 16px;
    }
  }
}
</style>
