import Vue from "vue";
export const namespaced = true;
export const state = {
  commentsData: [],
  selectedComment: {
    edit: "",
    delete: "",
    updated: "",
    new: "",
    deleted: "",
  },
};
export const mutations = {
  STORE_MORE_COMMENTS_DATA(state, { comments }) {
    state.commentsData = state.commentsData.concat(comments);
  },
  STORE_COMMENTS_DATA(state, { comments }) {
    state.commentsData = comments;
  },
  CLEAR_COMMENTS_DATA(state) {
    state.commentsData = [];
  },
  DELETE_COMMENT_BY_INDEX(state, { index }) {
    state.commentsData.splice(index, 1);
  },
  UPDATE_COMMENT_BY_INDEX(state, payload = { index, newComment: {} }) {
    const newData = { ...state.commentsData[payload.index], ...payload.newComment };
    Vue.set(state.commentsData, payload.index, newData);
  },
  CLEAR_SELECTED_COMMENT(state) {
    state.selectedComment.edit = "";
    state.selectedComment.delete = "";
    state.selectedComment.updated = "";
    state.selectedComment.new = "";
    state.selectedComment.deleted = "";
  },
  STORE_EDIT_COMMENT(state, { commentId }) {
    state.selectedComment.edit = commentId;
    state.selectedComment.delete = "";
  },
  STORE_DELETE_COMMENT(state, { commentId }) {
    state.selectedComment.edit = "";
    state.selectedComment.delete = commentId;
  },
  STORE_UPDATED_COMMENT(state, { commentId }) {
    state.selectedComment.updated = commentId;
  },
  STORE_ADDED_NEW_COMMENT(state, { commentId }) {
    state.selectedComment.new = commentId;
  },
  STORE_DELETED_COMMENT(state, { commentId }) {
    state.selectedComment.deleted = commentId;
  },
};

export const actions = {
  storeDeleteComment({ commit }, { commentId }) {
    commit("STORE_DELETE_COMMENT", { commentId });
  },
  storeEditComment({ commit }, { commentId }) {
    commit("STORE_EDIT_COMMENT", { commentId });
  },
  clearSelectedComment({ commit }) {
    commit("CLEAR_SELECTED_COMMENT");
  },
  storeMoreCommentsData({ commit }, { comments }) {
    commit("STORE_MORE_COMMENTS_DATA", { comments });
  },
  storeCommentsData({ commit }, { comments }) {
    commit("STORE_COMMENTS_DATA", { comments });
  },
  clearCommentsData({ commit }) {
    commit("CLEAR_COMMENTS_DATA");
  },
  deleteCommentInCommentsData({ state, commit, dispatch }, { deletedCommentId }) {
    const index = state.commentsData.findIndex((comment) => comment.id === deletedCommentId);
    if (index === -1) {
      console.warn("Not found deleted comment");
      return;
    }
    dispatch("storeDeletedComment", { commentId: deletedCommentId });
    setTimeout(() => {
      commit("DELETE_COMMENT_BY_INDEX", { index });
      commit("CLEAR_SELECTED_COMMENT");
    }, 300);
  },
  updateCommentInCommentsData({ state, commit, dispatch }, { updatedCommentId, newComment }) {
    const index = state.commentsData.findIndex((comment) => comment.id === updatedCommentId);
    if (index === -1) {
      console.warn("Not found updated comment");
      return;
    }
    commit("UPDATE_COMMENT_BY_INDEX", { index, newComment });
    commit("CLEAR_SELECTED_COMMENT");
    dispatch("storeUpdatedComment", { commentId: updatedCommentId });
  },
  deleteReactionInCommentsData({ state, commit }, { updatedCommentId, reactionId, emojiCode }) {
    const index = state.commentsData.findIndex((comment) => comment.id === updatedCommentId);
    if (index === -1) {
      console.warn("Not found updated comment");
      return;
    }
    let newComment = state.commentsData[index];
    const reactionIdx = newComment.reactions[emojiCode].findIndex((reaction) => reaction.id === reactionId);
    if (reactionIdx === -1) {
      console.warn("Not found updated reaction");
      return;
    }
    newComment.reactions[emojiCode].splice(reactionIdx, 1);
    if (newComment.reactions[emojiCode].length === 0) {
      delete newComment.reactions[emojiCode];
    }
    commit("UPDATE_COMMENT_BY_INDEX", { index, newComment });
  },
  updateReactionsInCommentsData({ state, commit }, { updatedCommentId, newReactions }) {
    const index = state.commentsData.findIndex((comment) => comment.id === updatedCommentId);
    if (index === -1) {
      console.warn("Not found updated comment");
      return;
    }
    let newComment = state.commentsData[index];
    newComment.reactions = newReactions;
    commit("UPDATE_COMMENT_BY_INDEX", { index, newComment });
  },
  storeUpdatedComment({ commit }, { commentId }) {
    commit("STORE_UPDATED_COMMENT", { commentId });
    setTimeout(() => {
      commit("STORE_UPDATED_COMMENT", { commentId: "" });
    }, 1500);
  },
  storeAddedNewComment({ commit }, { commentId }) {
    commit("STORE_ADDED_NEW_COMMENT", { commentId });
    setTimeout(() => {
      commit("STORE_ADDED_NEW_COMMENT", { commentId: "" });
    }, 1500);
  },
  storeDeletedComment({ commit }, { commentId }) {
    commit("STORE_DELETED_COMMENT", { commentId });
    setTimeout(() => {
      commit("STORE_DELETED_COMMENT", { commentId: "" });
    }, 500);
  },
};
