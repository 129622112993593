<template>
  <section class="mixin-skills"></section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MixinDB from "./MixinDB.vue";
export default {
  name: "mixin-skills",
  mixins: [MixinDB],
  data() {
    return {};
  },
  computed: {
    ...mapState("auth", ["userProfile"]),
    ...mapGetters("auth", ["getter_user_organization"]),
  },
  methods: {
    async createUserCertificate(playlistId, selectedUser) {
      try {
        const signatureId = this.generateDocId("userSkillCertificates");

        const signature = {
          displayName: this.userProfile.displayName,
          email: this.userProfile.email,
          uid: this.userProfile.id,
          signDate: await this.getServerTimestamp(),
        };

        const data = {
          id: signatureId,
          playlistId: playlistId,
          displayName: selectedUser.displayName,
          email: selectedUser.email,
          uid: selectedUser.userId,
          signature: signature,
          certified: true,
          organization: this.getter_user_organization,
        };
        await this.addDocument("userSkillCertificates", signatureId, data);
        return { ok: true };
      } catch (e) {
        return { ok: false };
      }
    },
    getUserWorkflowTracking(user, workflow) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByQuery("tracking", [
            {
              field: "uid",
              comparison: "==",
              value: user.uid,
            },
            {
              field: "workflowId",
              comparison: "==",
              value: workflow.id,
            },
            {
              field: "organization",
              comparison: "==",
              value: self.$organization,
            },
          ])
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
</script>
