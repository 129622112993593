export const settingsMapping = {
  QUIZ: {
    action: "updateQuizSettings",
    apiParam: "quiz",
    enableSuccessfullyMessage: "admin.alertEnableAiPoweredQuizzingSuccess",
    disableSuccessfullyMessage: "admin.alertDisableAiPoweredQuizzingSuccess",
    enableFailedMessage: "admin.alertEnableAiPoweredQuizzingFailed",
    disableFailedMessage: "admin.alertDisableAiPoweredQuizzingFailed",
  },
  MULTI_MODAL_SOP: {
    action: "updateMultiModalSopSettings",
    apiParam: "multiModalSOP",
    enableSuccessfullyMessage: "admin.alertEnableAiGenerateMultiModalSopSuccess",
    disableSuccessfullyMessage: "admin.alertDisableAiGenerateMultiModalSopSuccess",
    enableFailedMessage: "admin.alertEnableAiGenerateMultiModalSopFailed",
    disableFailedMessage: "admin.alertDisableAiGenerateMultiModalSopFailed",
  },
  AIQA: {
    action: "updateAiQaSettings",
    apiParam: "AIQA",
    enableSuccessfullyMessage: "admin.alertEnableWorkflowQaWithAiSuccess",
    disableSuccessfullyMessage: "admin.alertDisableWorkflowQaWithAiSuccess",
    enableFailedMessage: "admin.alertEnableWorkflowQaWithAiFailed",
    disableFailedMessage: "admin.alertDisableWorkflowQaWithAiFailed",
  },
  SUMMARY: {
    action: "updateAISummarySettings",
    apiParam: "aiSummary",
    enableSuccessfullyMessage: "admin.alertEnableAISummarySuccess",
    enableFailedMessage: "admin.alertEnableAISummaryFailed",
    disableSuccessfullyMessage: "admin.alertDisableAISummarySuccess",
    disableFailedMessage: "admin.alertDisableAISummaryFailed",
  },
};
