<template>
  <div class="d-admin-setting">
    <div class="tab-title-row">
      <div class="tab-title text-no-wrap" autotest="workspace-group-count">
        <p>
          <b>{{ getter_admin_workspace_groups.length }}</b>
          {{ $tc("admin.workspaceGroupsNum", getter_admin_workspace_groups.length) }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <d-button
        round
        color="#4689f4"
        class="white--text elevation-0 body-2 text-capitalize btn-width mt-1"
        @click="showPopupType = 'create'"
        light
        autotest="create-workspace-group-btn"
        >{{ $t("admin.createWorkspaceGroup") }}</d-button
      >
    </div>
    <div class="table-wrap">
      <d-data-table
        add-search
        local-search
        :headers="tableHeader"
        :items="getter_admin_workspace_groups"
        :loading="isLoading"
        :placeholder="$t('admin.searchWorkspaceGroups')"
        searchAutotest="workspace-group-search-bar"
      >
        <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
        <template slot="headerCell" slot-scope="props">
          <span>{{ props.header.text }}</span>
        </template>

        <template slot="items" slot-scope="props">
          <td class="text-xs-left" :style="{ width: '90%' }">
            <span
              @click="enterWorkspaceGroup(props.item.id)"
              class="workspace-group-name-hover app-all-white-spaces"
              autotest="workspace-group-name-display"
              >{{ props.item.name }}</span
            >
          </td>

          <td class="text-xs-left text-no-wrap">
            <button
              class="mr-3 table-btn"
              @click="openEditWorkspaceGroupPopup(props.item)"
              autotest="edit-workspace-group-btn"
            >
              <icon-base height="22" width="22">
                <d-icon-pen />
              </icon-base>
            </button>

            <button
              v-if="props.item.id !== $defaultWorkspaceGroupId"
              class="table-btn"
              @click="openDeleteWorkspaceGroupPopup(props.item)"
              autotest="delete-workspace-group-btn"
            >
              <icon-base height="22" width="22">
                <d-icon-trash />
              </icon-base>
            </button>
          </td>
        </template>

        <template slot="no-data">
          <span>{{ $t("admin.noWorkspaceGroupsYet") }}</span>
        </template>
        <template slot="no-results">
          <span>{{ $t("analytics.msgNoSearchResults") }}</span>
        </template>
      </d-data-table>
    </div>

    <admin-workspace-group-popup
      v-if="showPopupType === 'create' || showPopupType === 'edit'"
      :showPopupType="showPopupType"
      :editedWorkspaceGroup="editedWorkspaceGroup"
      :isProcessingAction="isProcessingAction"
      @addWorkspaceGroup="createWorkspaceGroup($event)"
      @updateWorkspaceGroup="updateWorkspaceGroup($event)"
      @closePopup="closePopup"
    ></admin-workspace-group-popup>

    <d-confirmation-popup
      v-model="showDeleteWorkspaceGroup"
      light
      redActionBtn
      :title="$t('admin.deleteWorkspaceGroup')"
      :cancelBtnName="$t('all.cancel')"
      :actionBtnName="disabledDeleteWorkspaceGroup ? '' : $t('skills.delete')"
      :content="deleteMessage"
      :autotest="{
        close: 'delete-workspace-group-popup-close-btn',
        action: 'delete-workspace-group-popup-yes-btn',
        cancel: 'delete-workspace-group-popup-cancel-btn',
      }"
      @clickActionBtn="deleteWorkspaceGroup"
    ></d-confirmation-popup>
  </div>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import IconBase from "@/components/IconBase.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconTrash from "@/components/icons/DIconTrash.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import AdminWorkspaceGroupPopup from "@/components/DAdmin/workspaceGroup/AdminWorkspaceGroupPopup.vue";
import { getAllTypesWorkspaceGroups } from "@/js/workspace-group/workspace-group.js";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "AdminWorkspaceGroups",
  components: {
    IconBase,
    DIconPen,
    DIconTrash,
    DConfirmationPopup,
    DDataTable,
    AdminWorkspaceGroupPopup,
  },
  mixins: [MixinDB, MixinUser],
  props: {},
  data() {
    return {
      showPopupType: "",
      editedWorkspaceGroup: {},
      showDeleteWorkspaceGroup: false,
      disabledDeleteWorkspaceGroup: false,
      deleteMessage: "",
      isProcessingAction: false,
      tableHeader: [
        {
          text: this.$t("all.name"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "workspace-group-name-sort",
        },
        {
          text: "",
          sortable: false,
        },
      ],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("workspaceGroups", ["getter_admin_workspace_groups"]),
  },
  mounted() {
    this.initializeWorkspaceGroups();
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    ...mapActions("workspaceGroups", [
      "getWorkspaceGroupList",
      "addWorkspaceGroup",
      "editWorkspaceGroup",
      "removeWorkspaceGroup",
    ]),
    async initializeWorkspaceGroups() {
      this.isLoading = true;
      await this.getWorkspaceGroupList({ workspacesType: "admin" });
      this.isLoading = false;
    },
    async createWorkspaceGroup(payload) {
      this.isProcessingAction = true;

      const { name, locale } = payload;
      const { ok } = await this.addWorkspaceGroup({ name, locale });

      if (ok) {
        await getAllTypesWorkspaceGroups();
        this.closePopup();

        const message = this.$t("admin.alertCreateWorkspaceGroupSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } else {
        const message = this.$t("admin.alertWorkspaceGroupNameAlreadyExists");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }

      this.isProcessingAction = false;
    },
    openEditWorkspaceGroupPopup(workspaceGroup) {
      this.editedWorkspaceGroup = workspaceGroup;
      this.showPopupType = "edit";
    },
    async updateWorkspaceGroup(payload) {
      this.isProcessingAction = true;

      const { id, name, locale } = payload;
      const { ok } = await this.editWorkspaceGroup({ id, name, locale });
      if (ok) {
        await getAllTypesWorkspaceGroups();
        this.closePopup();

        const message = this.$t("admin.alertEditWorkspaceGroupSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } else {
        const message = this.$t("admin.alertWorkspaceGroupNameAlreadyExists");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }

      this.isProcessingAction = false;
    },
    openDeleteWorkspaceGroupPopup(workspaceGroup) {
      this.editedWorkspaceGroup = workspaceGroup;
      const { name } = this.editedWorkspaceGroup;

      // TODO: check for workspaces inside workspace group with `deleteWorkspaceGroupDisabled`.
      // TODO: update messages with i18n.
      const hasWorkspaces = this.editedWorkspaceGroup.workspaces.length > 0;
      this.disabledDeleteWorkspaceGroup = hasWorkspaces;
      this.deleteMessage = hasWorkspaces
        ? this.$t("admin.alertWorkspaceAssignedDeleteGroupFailed")
        : this.$t("admin.deleteWorkspaceGroupMessage", { name });
      this.showDeleteWorkspaceGroup = true;
    },
    async deleteWorkspaceGroup() {
      const { id, name } = this.editedWorkspaceGroup;
      const { ok } = await this.removeWorkspaceGroup(id);
      if (ok) {
        await getAllTypesWorkspaceGroups();

        const message = this.$t("admin.alertDeleteWorkspaceGroupSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } else {
        const message = this.$t("admin.alertDeleteWorkspaceGroupFailed");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
    enterWorkspaceGroup(workspaceGroupId) {
      this.$router.push({ name: "AdminWorkspaceGroupWorkspaces", params: { workspaceGroupId, tab: "workspaces" } });
    },
    closePopup() {
      this.showPopupType = "";
      this.editedWorkspaceGroup = {};
    },
  },
};
</script>

<style scoped>
.d-admin-setting {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
}
.tab-title-row {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding: 0 28px;
}
.tab-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 44px;
  text-align: left;
  width: 50%;
  position: relative;
  display: flex;
}
.btn-width {
  min-width: 140px;
  text-transform: none !important;
}
/* table */
.table-wrap {
  height: 100%;
  width: 100%;
  padding: 0 28px;
  overflow: scroll;
}
.table-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}

.workspace-group-name-hover:hover {
  color: #2d2e32;
  cursor: pointer;
  font-weight: 500;
}
.table-btn:hover {
  color: #2d2e32;
}
@media screen and (max-width: 959px) {
  .tab-title-row {
    padding: 0 20px;
  }
  .table-wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
}
</style>
