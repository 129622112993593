export const namespaced = true;
export const state = {
  isIdleReady: false,
};

export const mutations = {
  SET_IS_IDLE_READY(state, payload = false) {
    state.isIdleReady = payload;
  },
};

export const getters = {
  is_idle_ready: (state) => state.isIdleReady,
};
