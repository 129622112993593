import { render, staticRenderFns } from "./AttachmentsBarList.vue?vue&type=template&id=41672c2b&scoped=true&"
import script from "./AttachmentsBarList.vue?vue&type=script&lang=js&"
export * from "./AttachmentsBarList.vue?vue&type=script&lang=js&"
import style0 from "./AttachmentsBarList.vue?vue&type=style&index=0&id=41672c2b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41672c2b",
  null
  
)

export default component.exports