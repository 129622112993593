<template>
  <div class="d-tooltip-show-all" :class="hostClass" :style="{ '--max-width': maxWidth }">
    <div class="tooltip-div">
      <div ref="textContainer" class="link-text" v-on="$listeners" :style="{ color: color }">
        <slot>{{ text }}</slot>
      </div>
      <div class="tooltip">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DTooltipShowAll",
  props: {
    text: String,
    maxWidth: {
      type: String,
      default: "300",
    },
    color: {
      type: String,
      default: "#4e4e4e",
    },
  },
  mixins: [],
  data() {
    const cssConfig = {
      hostClass: {
        clickable: false,
        textOverflow: false,
      },
    };
    return {
      ...cssConfig,
    };
  },
  components: {},
  methods: {
    hasClickListeners() {
      return !!this.$listeners && !!this.$listeners.click;
    },
    updateClickableStatus() {
      this.hostClass.clickable = this.hasClickListeners();
    },
    updateTextOverflowStatus() {
      this.hostClass.textOverflow = this.$refs.textContainer.clientWidth >= this.maxWidth;
    },
  },
  mounted() {
    this.updateClickableStatus();
    this.updateTextOverflowStatus();
  },
  computed: {},
};
</script>

<style scoped>
.d-tooltip-show-all {
  position: relative;
  max-width: calc(var(--max-width) * 1px);
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}

.link-text {
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  color: #4e4e4e;
  font-family: Roboto;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.clickable .link-text:hover {
  cursor: pointer;
  font-weight: 500;
}
.tooltip {
  color: #4e4e4e;
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  visibility: hidden;
  background-color: #fff;
  z-index: 1;
  top: 20px;
  padding: 4px 8px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.5s ease;
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;
}
.textOverflow .link-text:hover + .tooltip {
  visibility: visible;
  transition: opacity 0.5s ease;
  opacity: 1;
  transition-delay: 1s;
}
</style>
