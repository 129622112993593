import { downgradeWithOrgAdminRole as _downgradeWithOrgAdminRole } from "@/server/user-role-server.js";
import { updateTolatestUserdataFromDb } from "@/js/user/user.js";
import { getBaseDataForUserDataTable, USER_DATA_TABLE_TYPE } from "@/js/user-data-table/user-data-table.js";

export const RoleType = {
  OrgAdmin: "admin-organization",
  WorkspaceAdmin: "admin-group",
};

function canChangeUserRole({ vueInstanceRef, userData }) {
  const result = {
    allow: true,
    disallow: false,
  };

  const loginUserDoesNotHavePermission = !vueInstanceRef.$access.isOrgAdmin;
  if (loginUserDoesNotHavePermission) {
    return result.disallow;
  }

  const nonAcitvedUserToBeUpgrade = userData.status !== "active";
  if (nonAcitvedUserToBeUpgrade) {
    return result.disallow;
  }

  const passwordlessUserToBeUpgrade = !!userData.passwordless;
  if (passwordlessUserToBeUpgrade) {
    return result.disallow;
  }

  return result.allow;
}

export function canUpgradeToOrgAdmin({ userData, vueInstanceRef }) {
  const upgrade = {
    allow: true,
    disallow: false,
  };

  const cantChangeUserRole = !canChangeUserRole({
    vueInstanceRef,
    userData,
  });

  if (cantChangeUserRole) {
    return upgrade.disallow;
  }

  const hasBeenUpgradeToOrgAdmin = userData.role === RoleType.OrgAdmin;
  if (hasBeenUpgradeToOrgAdmin) {
    return upgrade.disallow;
  }

  return upgrade.allow;
}

export function canDowngradeOrgAdmin({ userData, vueInstanceRef }) {
  const downgrade = {
    allow: true,
    disallow: false,
  };

  const cantChangeUserRole = !canChangeUserRole({
    vueInstanceRef,
    userData,
  });

  if (cantChangeUserRole) {
    return downgrade.disallow;
  }

  const isNotOrgAdmin = userData.role !== RoleType.OrgAdmin;
  if (isNotOrgAdmin) {
    return downgrade.disallow;
  }

  return downgrade.allow;
}

export function showMoreBtnAdminUserList({ userData, vueInstanceRef }) {
  const moreBtn = {
    show: true,
    hide: false,
  };

  const cantChangeUserRole = !canChangeUserRole({
    vueInstanceRef,
    userData,
  });

  if (cantChangeUserRole) {
    return moreBtn.hide;
  }

  return moreBtn.show;
}

export function upgradeToOrgAdmin({ mixinUserRef, userData, mixinDbRef, vueInstanceRef }) {
  const lowerCaseEmail = userData.email.toLowerCase();
  const role = "admin-organization";

  return mixinUserRef
    .addOrgAdminUser(lowerCaseEmail, role, userData.fullName, vueInstanceRef.$i18n.locale)
    .then((res) => {
      const msg = res.data;
      if (msg.includes("Successfully changed user role.")) {
        return checkUserAlreadyInOrg({
          email: lowerCaseEmail,
          mixinDbRef,
        }).then((user) => {
          return {
            isSuccessfullyChangeRole: !!user,
          };
        });
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export async function downgradeWithOrgAdminRole({ vueInstanceRef, userData }) {
  return _downgradeWithOrgAdminRole({
    vueInstanceRef,
    userData: {
      organization: vueInstanceRef.$organization,
      ...userData,
    },
  });
}
function checkUserAlreadyInOrg({ email, mixinDbRef }) {
  return new Promise(function (resolve, reject) {
    mixinDbRef
      .getDocumentByFieldValue("users", "email", email)
      .then((data) => {
        if (data.length > 0) {
          resolve(data[0]);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

////// addSharedAccount ////////////

export async function addSharedAccountUser({
  mixinUserRef,
  mixinDbRef,
  formData,
  groupLocale,
  vueInstanceRef,
  whenInviteSucessFn,
  showAlertFn,
}) {
  const user = await getSharedAccountUser({
    employeeId: formData.employeeId,
    mixinUserRef,
    mixinDbRef,
  });
  if (
    user &&
    checkUserAlreadyInWorkspace({
      user,
      groupId: formData.groupId,
    })
  ) {
    showAlertFn({
      type: "alert",
      msg: vueInstanceRef.$t("admin.alertUserIsAlreadyInWorkspace", { info: user.employeeId }),
    });
    return;
  }
  const handleGetSharedAccountUser = async (msg) => {
    try {
      const user = await getSharedAccountUser({
        employeeId: formData.employeeId,
        mixinUserRef,
        mixinDbRef,
      });
      vueInstanceRef.$store.dispatch("updateUser", {
        user,
        action: "add",
        level: "group",
      });
      if (msg.includes("already exists")) {
        showAlertFn({
          type: "success",
          msg: vueInstanceRef.$t("admin.alertSuccessfullyAddExistingShared"),
        });
      } else {
        showAlertFn({
          type: "success",
          msg: vueInstanceRef.$t("admin.alertSuccessfullyCreatedShared"),
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };
  try {
    const res = await mixinUserRef.addSharedUser(
      formData.employeeId,
      formData.role,
      formData.fullName,
      [formData.groupId],
      groupLocale ? groupLocale : vueInstanceRef.$i18n.locale,
      formData.password
    );
    const msg = res.data;
    await handleGetSharedAccountUser(msg);
  } catch (err) {
    console.warn(err);
    showAlertFn({
      type: "error",
      msg: vueInstanceRef.$t("admin.alertFailedAddShared"),
    });
  }
}
/**
 * Use in AdminAddUserPopup.vue
 * Available roles
 *  - Workspace Admin
 *  - Publisher
 *  - Viewer
 * @return object: status for success callback after invited in each component
 */
export async function addUserToWorkspace({
  tableLevel, // org: 'org', workspace: 'group'
  mixinUserRef,
  vueInstanceRef,
  mixinDbRef,
  groupLocale,
  newUserData,
  alertFn,
}) {
  const handleException = (msg) => {
    if (msg.includes("Organization admin cannot be assigned to a workspace")) {
      alertFn({ type: "error", msg: "admin.alertOrganizationAdminAssignedFailed" });
    } else if (msg.includes("admin.alertCheckUserInSystemFailed")) {
      alertFn({ type: "error", msg: "admin.alertCheckUserInSystemFailed" });
    } else if (msg.includes("user already exists in another organization.")) {
      alertFn({ type: "alert", msg: "admin.alertUserInOtherOrg" });
    } else if (msg.includes("admin.alertUserIsAlreadyInWorkspace")) {
      alertFn({ type: "alert", msg, params: { info: newUserData.email } });
    } else if (msg.includes("admin.alertPendingInvitation")) {
      alertFn({ type: "alert", msg: "admin.alertPendingInvitation" });
    } else {
      console.warn(msg);
    }
  };

  /** Check if User is already in org and workspace */
  const findUserInOrg = async function (email) {
    try {
      const response = await checkUserAlreadyInOrg({ email: email.toLowerCase(), mixinDbRef });
      return response;
    } catch (e) {
      handleException("admin.alertCheckUserInSystemFailed");
    }
  };
  const userInOrg = await findUserInOrg(newUserData.email);
  if (userInOrg && checkUserAlreadyInWorkspace({ user: userInOrg, groupId: newUserData.groupId })) {
    handleException("admin.alertUserIsAlreadyInWorkspace");
    return;
  }

  /** Add User in workspace */
  const latestGroupData = await mixinDbRef.getDocumentByFieldValue("groups", "id", newUserData.groupId);
  const defaultInterfaceLanguage =
    Array.isArray(latestGroupData) && latestGroupData.length > 0 ? latestGroupData[0].locale : null;
  const languageCode = defaultInterfaceLanguage || groupLocale || vueInstanceRef.$i18n.locale;
  const handleAddGroupUser = async (payload = { email, role, displayName, groupId, languageCode }) => {
    try {
      const response = await mixinUserRef.addGroupUser(payload);
      return response;
    } catch (err) {
      const msg = err.response.data;
      handleException(msg);
    }
  };
  const { data: ResMsgOfAddGroup } = await handleAddGroupUser({ ...newUserData, languageCode });
  const addNewUserInWorkspaceTable = (user) => {
    /** Its only change frontend data, there is no request to API */
    formatUserDataToDisplayOnDataTableWhenInviteSuccess({
      userRef: user,
      groupId: newUserData.groupId,
      newRole: newUserData.role,
    });
    updateVuexUserDataAndAddNewUserToTable({
      vueInstanceRef,
      user,
      level: tableLevel,
    });
  };

  let successStatus = null;
  if (userInOrg) {
    /** Add user has already registered */
    await updateTolatestUserdataFromDb({
      mixinDbRef,
      userDataRef: userInOrg,
    });

    addNewUserInWorkspaceTable(userInOrg);
    successStatus = "added-existing";
  } else {
    /** Add and Invite no-registered user */
    const newUserAddedInOrg = await findUserInOrg(newUserData.email);
    if (ResMsgOfAddGroup.includes("Invitation created for")) {
      addNewUserInWorkspaceTable(newUserAddedInOrg);
      successStatus = "has-email";
    } else if (
      ResMsgOfAddGroup.includes("Existing invitation updated for") ||
      ResMsgOfAddGroup.includes("An invitation has already been sent to the user. Please wait for the user to accept.")
    ) {
      handleException("admin.alertPendingInvitation");
    } else {
      console.warn(msg);
    }
  }
  /**Even if the invites disabled states is false, need to set isUserDisabled to true*/
  const newUserAddedResult = {
    isUserDisabled: !!successStatus,
    successStatus,
  };
  return newUserAddedResult;
}

export function checkUserAlreadyInWorkspace({ user, groupId }) {
  if (user.groups.includes(groupId)) {
    return true;
  }
  return false;
}

export async function inviteNoEmailUser({
  mixinUserRef,
  mixinDbRef,
  formData,
  groupLocale,
  vueInstanceRef,
  whenInviteSucessFn,
  showAlertFn,
}) {
  const user = await getNoEmailUser({
    employeeId: formData.employeeId,
    mixinUserRef,
    mixinDbRef,
  });
  if (
    user &&
    checkUserAlreadyInWorkspace({
      user,
      groupId: formData.groupId,
    })
  ) {
    showAlertFn({
      type: "alert",
      msg: vueInstanceRef.$t("admin.alertUserIsAlreadyInWorkspace", { info: user.employeeId }),
    });
    return;
  }

  mixinUserRef
    .addPasswordlessUser(
      formData.employeeId,
      formData.role,
      formData.fullName,
      [formData.groupId],
      groupLocale ? groupLocale : vueInstanceRef.$i18n.locale
    )
    .then((res) => {
      const msg = res.data;
      //get userId to save in store
      getNoEmailUser({
        employeeId: formData.employeeId,
        mixinUserRef,
        mixinDbRef,
      })
        .then((user) => {
          vueInstanceRef.$store.dispatch("updateUser", {
            user: user,
            action: "add",
            level: "group",
          });
          if (msg.includes("User already exists")) {
            whenInviteSucessFn({
              type: "added-existing",
              user,
              ...formData,
            });
          } else {
            whenInviteSucessFn({
              type: "no-email",
              user,
              ...formData,
            });
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    })
    .catch((err) => {
      console.warn(err);
    });
}

export const getSharedAccountUser = async ({ employeeId, mixinUserRef, mixinDbRef }) => {
  const email = await mixinUserRef.getEmailOfSharedAccountUser(employeeId);
  try {
    const data = await mixinDbRef.getDocumentByFieldValue("users", "email", email);
    return data.length > 0 ? data[0] : false;
  } catch (e) {
    console.warn(e);
  }
};
export function getNoEmailUser({ employeeId, mixinUserRef, mixinDbRef }) {
  return new Promise(function (resolve, reject) {
    //use email to find user
    const email = mixinUserRef.getEmailOfPasswordlessUser(employeeId);
    mixinDbRef
      .getDocumentByFieldValue("users", "email", email)
      .then((data) => {
        if (data.length > 0) {
          resolve(data[0]);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function formatUserDataToDisplayOnDataTableWhenInviteSuccess({ userRef, groupId, newRole }) {
  setupUserRoleWhenInviteSuccess({
    userRef,
    groupId,
    newRole,
  });

  const userBaseDataOfDataTable = getBaseDataForUserDataTable({
    user: userRef,
    dataTableType: userRef.disabled ? USER_DATA_TABLE_TYPE.INACTIVE : USER_DATA_TABLE_TYPE.ACTIVE,
  });

  Object.assign(userRef, userBaseDataOfDataTable);
}

export function formatUserDataToDisplayOnDataTableWhenRemoveSuccess({ vueInstanceRef, userRef, groupId }) {
  // remove this group from user roles
  vueInstanceRef.$delete(userRef.roles, groupId);

  const userBaseDataOfDataTable = getBaseDataForUserDataTable({
    user: userRef,
    dataTableType: userRef.disabled ? USER_DATA_TABLE_TYPE.INACTIVE : USER_DATA_TABLE_TYPE.ACTIVE,
  });

  Object.assign(userRef, userBaseDataOfDataTable);
}

function setupUserRoleWhenInviteSuccess({ userRef, groupId, newRole }) {
  const isNewRoleAnOrgAdmin = userRef.roles[groupId] === "admin-organization";
  if (isNewRoleAnOrgAdmin) {
    return;
  }
  userRef.roles = {
    ...userRef.roles,
    [groupId]: newRole,
  };
}

export function updateVuexUserDataAndAddNewUserToTable({ vueInstanceRef, user, level }) {
  vueInstanceRef.$store.dispatch("updateUser", {
    user: user,
    action: "add",
    level,
  });

  updateUserRolesOfVuex({
    vueInstanceRef,
    user,
  });

  vueInstanceRef.$store.commit("clearGroupUserCacheByUserID", {
    userId: user.id,
  });
}

export function updateVuexUserDataWhenRemovedUserToWorkspace({ vueInstanceRef, user }) {
  vueInstanceRef.$store.dispatch("updateUser", {
    update: {},
    user: user,
    action: "remove",
  });

  updateUserRolesOfVuex({
    vueInstanceRef,
    user,
  });

  vueInstanceRef.$store.commit("clearGroupUserCacheByUserID", {
    userId: user.id,
  });
}

function updateUserRolesOfVuex({ vueInstanceRef, user }) {
  vueInstanceRef.$store.commit("updateUserWithoutGroup", {
    user,
    update: { roles: user.roles },
    action: "update",
  });
}
/** Reviewer functions */

/** Check if user is reviewer or not
 * @params roles(object): record all user's role in different workspace(group)
 *  group ID as key, and role as value
 *  roles: {
 *    {groupA_ID}: "viewer"
 *    {groupB_ID}: "publisher"
 *  }
 * @return Boolean
 */
export function isReviewer({ enableWorkflowReview = false, groupId = "", reviewGroups = [], roles = {} }) {
  if (!enableWorkflowReview) return false;
  return enableWorkflowReview && reviewGroups.indexOf(groupId) !== -1 && roles[groupId] !== "viewer";
}
/** Check if user has permission to be reviewer or not
 * @params roles(object): record all user's role in different workspace(group)
 *  group ID as key, and role as value
 *  roles: {
 *    {groupA_ID}: "viewer"
 *    {groupB_ID}: "publisher"
 *  }
 * @return Boolean
 */
export function canBeReviewer({ enableWorkflowReview = false, roles = {}, groupId = "" }) {
  const canRoleBeReviewer = roles[groupId] !== "viewer";
  return enableWorkflowReview && canRoleBeReviewer;
}
/** Check user can be added as reviewer by groupId
 * @return Boolean
 */
export function canAddAsReviewer({ groupId = [], userData = {} }) {
  const { reviewGroups = [] } = userData;
  if (reviewGroups.length === 0) return true;
  return reviewGroups.indexOf(groupId) === -1;
}
