<template>
  <div :class="['App', { 'App__header-alert--open': isOpenGlobalRestrictedIpAlert }]">
    <v-app>
      <template v-if="canShowRouterView">
        <DRestrictedIpOrgAdminAlert v-if="isOpenGlobalRestrictedIpAlert" />
        <d-auth-header v-if="!isLogin" />
        <div class="App__body">
          <d-left-side-menu v-if="isLogin" :supportLeftSide="supportLeftSide && !isMaven2" />
          <d-maven-chat-side-menu v-if="isLogin && isMaven2" />
          <div class="App__content">
            <d-header
              v-if="isLogin"
              :supportLeftSide="supportLeftSide"
              :isLoggedIn="isLogin"
              :access="access"
              @open-user-profile="showUserProfile = !showUserProfile"
            />
            <keep-alive>
              <router-view
                v-if="$route.meta.keepAlive"
                :isMobileDevice="isMobileDevice"
                :isTabletDevice="isTabletDevice"
                :isIframe="isIframe"
              ></router-view>
            </keep-alive>
            <router-view
              v-if="!$route.meta.keepAlive"
              :isMobileDevice="isMobileDevice"
              :isTabletDevice="isTabletDevice"
              :isIframe="isIframe"
            ></router-view>
          </div>

          <template v-if="isLogin">
            <!-- uploader -->
            <uploader
              v-if="showUploader && uploadType !== 'ImportFromZoom'"
              :showUploader="showUploader"
              :uploadType="uploadType"
              :isRecordingScreen="isRecordingScreen"
              :userGroupList="groups"
              :class="{ 'hide-uploader': isRecordingScreen }"
              @close-uploader="goToUploadedWorkspace"
              @startRecordScreen="updateIsRecordingScreen(true)"
              @endRecordScreen="updateIsRecordingScreen(false)"
              @update-current-group="updateCurrentGroupAfterUpload($event)"
              @go-to-editor="goToEditor()"
            ></uploader>
            <!-- zoom uploader -->
            <zoom-uploader
              v-if="showUploader && uploadType === 'ImportFromZoom'"
              :userGroupList="groups"
              @close-uploader="goToUploadedWorkspace"
            />
            <user-profile
              v-if="showUserProfile"
              :isMobileDevice="false"
              :isTabletDevice="isTabletDevice"
              @close-user-profile="showUserProfile = false"
            />
            <user-profile-main
              v-if="userProfileConfig.isOpen"
              :isMobileDevice="isMobileDevice"
              :isTabletDevice="isTabletDevice"
            />
            <d-idle
              v-if="customizedPermissions != null"
              :allowAutoLogout="customizedPermissions.allowAutoLogout"
              :autoLogoutTimeout="customizedPermissions.autoLogoutTimeout"
              @update-allowAutoLogout="customizedPermissions.allowAutoLogout = $event"
              @logout="handleLogout"
            />
            <d-quiz-screen />
          </template>
        </div>
      </template>
      <d-check-restricted-ip v-else />
      <new-version-snackbar :isMobileDevice="isMobileDevice" />
      <d-global-alert />
      <d-troubleshooting-popup v-if="isTroublshootingOpen" />
      <d-use-app-popup v-if="showUseAppPopup" @close="showUseAppPopup = false" />
      <d-cookie-accept-popup
        v-if="showAcceptCookiePopup"
        @close="closeUserConsentsPopup"
        :consents="consents"
        :type="consentsPopupType"
      />
      <d-gen2-forbidden-popup v-model="showGen2ForbiddenAlert" @close="forbiddenPopupHandler" />
    </v-app>
  </div>
</template>

<script>
import Vue from "vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import eventbus from "./main";
import MixinAnalytics from "@/components/MixinAnalytics.vue";
import Analytics from "./js/analytics/analytics";
import DHeader from "@/components/DHeader/DHeader.vue";
import DAuthHeader from "@/components/DHeader/DAuthHeader.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import {
  updateBrowerTabLocalStorage,
  getLogoutImmediatelyLocalStorage,
  removeLogoutImmediatelyLocalStorage,
} from "@/js/idle/idle.js";
import { IS_TABLET, IS_MOBILE } from "@/constants/device-version";
import { isIpadOrIphoneDevice } from "@/js/mobile.js";
import { mavenAllowedListInMobile, MAVEN_ROUTE_NAME } from "@/router/routes/MavenRoute.js";
import { IS_MAVEN_WHITELIST_DOMAIN } from "@/constants/envVersion";
import { RoleType } from "@/js/user-role/user-role.js";
import { playerRoutes } from "@/constants/routers";
import { setLogoutSharedAccountLocalStorage } from "@/js/login/login.js";
import {
  getEmployeeIdAccountFromLocalStorage,
  setEmployeeIdAccountInLocalStorage,
  isEmployeeIdAccountInLocalStorage,
} from "@/js/user/employee-id.js";
import {
  getSharedAccountFromLocalStorage,
  setSharedAccountInLocalStorage,
  removeSharedAccountFromLocalStorage,
  isSharedAccountInLocalStorage,
} from "@/js/user/share-account.js";
import { refreshEmployeeIdLogin, refreshSharedAccountLogin } from "@/server/basic-authentication-server.js";
import auth from "../auth.js";
import { setIsIpPassedLocalStorage } from "@/js/login/checkRestrictedIp";
import userGuide from "./js/userGuide";
import { getUserConsents } from "@/server/api-server";
import { loggingError } from "@/server/error-log-server.js";
import { analyticsRoutes } from "@/constants/routers";
import { IS_FROM_RUSTICI } from "@/js/video-player/rustici";
import { MIGRATE_TO_CURRENT_UI_LOCALE_MAP } from "@/lang/i18n";

export default {
  name: "App",
  components: {
    DHeader,
    DAuthHeader,
    DQuizScreen: () => import("@/components/DQuiz/DQuizScreen"),
    UserProfile: () => import("@/components/DUser/UserProfile"),
    Uploader: () => import("@/components/DUploader/Uploader.vue"),
    ZoomUploader: () => import("@/components/DUploader/ZoomUploader.vue"),
    DGlobalAlert: () => import("@/components/ui_components/DGlobalAlert"),
    UserProfileMain: () => import("@/components/DUserProfile/UserProfileMain"),
    NewVersionSnackbar: () => import("@/components/DPlayer/NewVersionSnackbar"),
    DIdle: () => import("@/components/ui_components/DIdle.vue"),
    DUseAppPopup: () => import("@/components/DPopup/DUseAppPopup.vue"),
    DCookieAcceptPopup: () => import("@/components/DPopup/DCookieAcceptPopup.vue"),
    DGen2ForbiddenPopup: () => import("@/components/DPopup/DGen2ForbiddenPopup.vue"),
    DTroubleshootingPopup: () => import("@/components/DPopup/DTroubleshootingPopup.vue"),
    DLeftSideMenu: () => import("@/components/DLeftSideMenu/DLeftSideMenu.vue"),
    DMavenChatSideMenu: () => import("@/components/DLeftSideMenu/DMavenChatSideMenu.vue"),
    DCheckRestrictedIp: () => import("@/components/DLayout/DCheckRestrictedIp.vue"),
    DRestrictedIpOrgAdminAlert: () => import("@/components/DLayout/DRestrictedIpOrgAdminAlert.vue"),
  },
  mixins: [MixinDB, MixinUser, MixinAnalytics],
  mounted() {
    window.addEventListener("offline", this.onLeavePage);
    window.addEventListener(this.terminationEvent, this.onLeavePage);
  },
  data() {
    return {
      auth,
      terminationEvent: "onpagehide" in self ? "pagehide" : "unload",
      access: {
        editor: false,
        player: true,
        admin: false,
        skills: false,
      },
      // TODO: replace "isTabletDevice" and "isMobileDevice"
      // when building new header and side menu by Content Structure project
      isMobileDevice: IS_MOBILE,
      isTabletDevice: IS_TABLET,
      isIframe: window.self != window.top,
      showUploader: false,
      uploadType: "",
      showUserProfile: false,
      customizedPermissions: null,
      playerRoutes,
      showUseAppPopup: false,
      showGen2ForbiddenAlert: false,
      consents: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === "SharedMain") {
        /** auto logout: to initialize browserTabList */
        updateBrowerTabLocalStorage([]);
      }
      this.redirectRouter();
      this.handleCheckIp();
    },
    userProfile() {
      this.getUserLocale();
    },
    isLogin(val) {
      this.handleCheckIp();
      if (val) {
        this.getWorkflowWithQuiz();
      }
    },
    getter_user_ip_not_allowed(getter_user_ip_not_allowed) {
      if (getter_user_ip_not_allowed) {
        this.redirectRouter();
      }
    },
  },
  created() {
    /** direct link to iOS APP from iPhone or iPad */
    const isUsingIpadOrIphoneDevice = isIpadOrIphoneDevice();
    const regexPublicLink = new RegExp(/\/p\//);
    const isPublicLink = regexPublicLink.test(location.pathname);
    if (isUsingIpadOrIphoneDevice && !isPublicLink) this.showUseAppPopup = true;

    this.initAccess();
    const self = this;
    self.auth().onAuthStateChanged(async (user) => {
      if (user && user.email) {
        /**
         * In the China version, unlike the global version, either Shared Account or Employee ID users stay logged in even we use 'disposablePwd' for login.
         * Due to this behavior, when the user is a Shared Account, we need to verify if the Shared Devices permission is active to maintain the status.
         */
        if (
          !isEmployeeIdAccountInLocalStorage() &&
          isSharedAccountInLocalStorage() &&
          !(await this.checkAndHandleSharedDevicesPermission(true))
        )
          return;

        const credentialString = localStorage.getItem("ssoCredential");
        if (credentialString) {
          const credential = JSON.parse(credentialString);
          const response = await self.validateOAuth(credential);
          if (response === "error") {
            self.signOutWithError();
            return;
          }

          if (response.data.useCustomToken) {
            self
              .auth()
              .signInWithCustomToken(response.data.userToken)
              .catch(function (error) {
                self.isLoggingInSso = false;
                loggingError(error);
                alert("Login failed");
                return;
              });
          }
        }

        /** To confirm user need to logout and force to trigger */
        if (getLogoutImmediatelyLocalStorage() === "true") {
          self.handleLogout();
          removeLogoutImmediatelyLocalStorage();
          return;
        }

        Analytics.setUserUid(user.uid);

        self.initializeUser(user).then(async () => {
          const onAccessReady = async function () {
            self.fetchEditableGroups();
            await self.getOrgPermissionsSettings({ organization: self.$organization });
          };
          self.setupWorkspaceColorsConfig({
            mixinDbRef: self,
          });
          self.getConsents();
          if (self.$route.query.redirect) {
            onAccessReady();
            self.$router.push(self.$route.query.redirect);
          } else {
            await onAccessReady();
            self.redirectRouter();
          }
          localStorage.removeItem("ssoCredential");
        });
      } else {
        /**
         * Case 1: Employee ID user or Shared Account user back to DeepHow.
         * Employee ID user possible to login by itself or Shared Account,
         * so we should check Employee ID in localStorage first,
         * then decide to login or continue to check Shared Account in localStorage.
         * Also, if Shared Devices option is disabled, we should logout Shared Account and clear localStorage.
         *
         * Case 2: Employee ID user logout.
         * If a Employee ID user logged in by Shared Account then logout,
         * the Shared Account user will be logged in automatically by checking the localStorage.
         */

        // Employee ID User Re-login.
        if (isEmployeeIdAccountInLocalStorage()) return this.employeeIdAccountReLogin();

        // Shared Account User Re-login.
        if (!isSharedAccountInLocalStorage() || !(await this.checkAndHandleSharedDevicesPermission())) return;
        this.sharedAccountReLogin();
      }
    });
    eventbus.$on("show-uploader", function ({ uploadType } = {}) {
      self.showUploader = true;
      self.uploadType = uploadType;
    });
  },
  computed: {
    ...mapState("editor", ["groups"]),
    ...mapState("global", ["userProfileConfig", "isTroublshootingOpen"]),
    ...mapState("auth", ["isLogin", "userProfile"]),
    ...mapState("permission", ["mavenEnable"]),
    ...mapState("userConsents", ["consentsPopupType", "showAcceptCookiePopup"]),
    ...mapGetters(["getter_is_gen2", "getter_is_allowed_using_maven_features"]),
    ...mapGetters("permission", ["getter_shared_account_enabled"]),
    ...mapGetters("auth", ["getter_user_role", "getter_user_ip_passed", "getter_user_ip_not_allowed"]),
    userProfile() {
      //get user profile from vuex
      return this.$store.state.userProfile;
    },
    isAdminPage() {
      return /^Admin/.test(this.$route.name);
    },
    isNeedAuthPage() {
      return this.$route.meta?.auth || this.$route.matched.some((route) => route.meta.auth);
    },
    isOpenGlobalRestrictedIpAlert() {
      if (["404", "403"].includes(this.$route.name)) return false;
      return this.isNeedAuthPage && this.getter_user_ip_not_allowed;
    },
    isRecordingScreen() {
      return this.$store.state.isRecordingScreen;
    },
    canShowRouterView() {
      if (!this.$route.name) return false;
      if (!this.isNeedAuthPage) return true;
      if (!this.isLogin) return false;
      if (this.getter_user_ip_passed) return true;
      if (this.isAdminPage && this.getter_user_role === RoleType.OrgAdmin) return true;
      return false;
    },
    supportLeftSide() {
      const routeName = this.$route.name;
      if (this.isMobileDevice && routeName === "NavigatorHome") return true;
      if (!this.isNeedAuthPage) return false;
      const hideLeftSideAuthRoutes = [
        ...analyticsRoutes,
        "NavigatorSkills",
        "NavigatorSkillPlayer",
        "FavoritesWorkflows",
        "FavoritesWorkspaces",
        "FavoritesWorkspaceExplorer",
        "PlayerMainFullscreen",
        "PlayerRustici",
        "PlayerEmbed",
        "PlayerPreview",
        "SkillsBuilder",
        "SkillsBuilderUsers",
        "SkillsBuilderQuizAnalytics",
        "SkillsBuilderSettings",
        "MediaPicker",
        "SharedMain",
        "UserProfileMain",
        MAVEN_ROUTE_NAME.MAVEN_CHAT,
        MAVEN_ROUTE_NAME.MAVEN_MAP,
      ];
      const isHideMenuBtnRoute = hideLeftSideAuthRoutes.includes(routeName);
      return this.isLogin && !this.isMobileDevice && !this.$shared && !isHideMenuBtnRoute;
    },
    isMaven2() {
      return this.$route.name === MAVEN_ROUTE_NAME.MAVEN;
    },
  },
  beforeDestroy() {
    window.removeEventListener("offline", this.onLeavePage);
    window.removeEventListener(this.terminationEvent, this.onLeavePage);
  },
  methods: {
    ...mapActions("editor", ["fetchEditableGroups"]),
    ...mapActions("workspaceColor", ["setupWorkspaceColorsConfig"]),
    ...mapActions("permission", ["getOrgPermissionsSettings", "setIsRedirectedByIpRestriction"]),
    ...mapActions("userConsents", ["openUserConsentsPopup", "closeUserConsentsPopup"]),
    ...mapActions("workflowQuiz", ["getWorkflowWithQuiz"]),
    handleCheckIp() {
      if (!this.isLogin) return;
      if (!this.isNeedAuthPage) return;
      this.$store.dispatch("auth/checkIp");
    },
    onLeavePage() {
      setIsIpPassedLocalStorage(false);
    },
    async redirectRouter() {
      if (!this.isLogin) {
        return;
      }
      try {
        if (this.getter_is_gen2 && !IS_MAVEN_WHITELIST_DOMAIN) {
          if (this.showGen2ForbiddenAlert) return;
          this.showGen2ForbiddenAlert = true;
          return;
        }

        if (!this.isAdminPage && this.getter_user_ip_not_allowed && this.getter_user_role === RoleType.OrgAdmin) {
          this.setIsRedirectedByIpRestriction(true);
          this.$router.push("/admin/settings");
          return;
        }

        // for Shared Account
        if (this.$isSharedAccount) {
          await this.getOrgPermissionsSettings({ organization: this.$organization });
          if (this.getter_shared_account_enabled) {
            this.$router.push("/shared");
            return;
          }
        }
        if (this.$route.query.redirect) {
          this.$router.push(self.$route.query.redirect);
          return;
        }
        const defaultHomePage = this.getter_is_gen2 ? "/MavenChat" : "/navigator/home";
        if (this.$route.path === "/") {
          this.$router.push(defaultHomePage);
          return;
        }
        const gen2ForbiddenPathRegex = /\/(skills|navigator|analytics)/;
        if (this.getter_is_gen2 && gen2ForbiddenPathRegex.test(this.$route.path)) {
          this.$router.push(defaultHomePage);
          return;
        }
        const currentRoute = this.$route.name;
        const canAccessByMobileAndTablet = [
          ...this.playerRoutes,
          "PlayerMain",
          "PlayerPublic",
          "PlayerFullscreenPublic",
          ...mavenAllowedListInMobile,
        ].includes(currentRoute);
        const isPlayer = this.playerRoutes.includes(currentRoute);

        // Maven route rules
        // if gen1: can not access MavenChat and MavenMap, otherwise direct to Navigator
        // if gen2: can access MavenChat and MavenMap, otherwise redirect to MavenChat
        // if gen2plus: can access MavenChat and MavenMap
        const isMavenChat = MAVEN_ROUTE_NAME.MAVEN_CHAT === currentRoute;
        const isMavenMap = MAVEN_ROUTE_NAME.MAVEN_MAP === currentRoute;

        if (
          ((isMavenChat || isMavenMap) && !this.getter_is_gen2 && !this.getter_is_allowed_using_maven_features) ||
          (currentRoute === MAVEN_ROUTE_NAME.MAVEN && !this.mavenEnable)
        ) {
          this.$router.push(defaultHomePage);
        }
        // hide the non-player part of the website if it's using mobile
        if (this.isMobileDevice && !isPlayer && !canAccessByMobileAndTablet) {
          this.$router.push(defaultHomePage);
          return;
        }
        // hide the non-player part of the website if it's using Tablet
        if (this.isTabletDevice && !isPlayer && !canAccessByMobileAndTablet) {
          this.$router.push(defaultHomePage);
        }
      } catch (e) {
        console.log("redirectRouter error", e.message);
        loggingError(e);
      }
    },
    initializeUser(user) {
      if (!user.email) return;
      const self = this;
      return new Promise(function (resolve, reject) {
        self.$store.dispatch("auth/getUserProfile");
        self
          .getUserRole(user)
          .then(() => {
            self.setUserAccess();
            self.access = self.$access;
            if (self.$backendType == "ali") {
              self.setUserStorageAccess();
              if (!self.setUserStorageAccessInterval) {
                //TODO make this better , ex: all event trigger based
                self.setUserStorageAccessInterval = setInterval(self.setUserStorageAccess, 1 * 60 * 60 * 1000); // per hour to prevent sts token expired
              }
            }
            self.customizedPermissions = self.getCustomizedPermissions();
            // TODO_REMOVE_WAIT_REFACTOR
            // should set is_login after set data to Vue.prototype,
            // because it too many code use this.$xxx
            self.$store.commit("auth/SET_IS_LOGIN", true);
            resolve();
          })
          .catch((err) => {
            self.handleLogout();
            reject();
          });
      });
    },
    getUserLocale() {
      const userLocale = this.userProfile[0].locale;
      if (userLocale) {
        const currentLocale = MIGRATE_TO_CURRENT_UI_LOCALE_MAP[userLocale] || userLocale;
        this.$i18n.locale = currentLocale;
        localStorage.setItem("locale", currentLocale);
      } else {
        this.updateUserLocale(this.$i18n.locale);
      }
    },
    updateUserLocale(lang) {
      //save to database
      const self = this;
      const id = this.userProfile[0].id;
      if (lang) {
        self
          .updateDocument("users", id, {
            locale: lang,
          })
          .then(() => {
            self.userProfile[0].locale = lang;
            localStorage.setItem("locale", lang);
          })
          .catch((err) => loggingError(err));
      }
    },
    updateCurrentGroupAfterUpload(group) {
      Vue.prototype.$editorGroup = group.id;
    },
    updateIsRecordingScreen(booType) {
      this.$store.commit("updateIsRecording", booType);
    },
    handleLogout() {
      this.setPrototypeUser(null);
      this.setPrototypeOrganization(null);
      this.customizedPermissions = null;
      this.logout();
      this.$router.push("/");
    },
    forbiddenPopupHandler() {
      this.showGen2ForbiddenAlert = false;
      this.handleLogout();
    },
    goToUploadedWorkspace(workspaceId) {
      this.uploadType = "";
      this.showUploader = false;
      if (!workspaceId) return;
      this.$router.push(`/editor/workspace/${workspaceId}`);
    },
    async sharedAccountReLogin() {
      try {
        const sharedAccountData = JSON.parse(getSharedAccountFromLocalStorage());
        const { ok, data } = await refreshSharedAccountLogin(sharedAccountData.refreshToken);
        if (!ok) {
          await this.logoutSharedAccountUser(); // From MixinUser.
          return;
        }
        const refreshedSharedAccountData = { ...sharedAccountData, refreshToken: data.item.refreshToken };
        setSharedAccountInLocalStorage(JSON.stringify(refreshedSharedAccountData));
        const { user } = await auth().signInWithEmailAndPassword(sharedAccountData.email, data.item.disposablePwd);
        if (user) this.$router.push("/shared");
      } catch (error) {
        await this.logoutSharedAccountUser(); // From MixinUser.
      }
    },
    async employeeIdAccountReLogin() {
      try {
        const employeeIdAccountData = JSON.parse(getEmployeeIdAccountFromLocalStorage());
        const { ok, data } = await refreshEmployeeIdLogin(employeeIdAccountData.refreshToken);
        if (!ok) {
          this.logoutEmployeeIdAccountUser(); // From MixinUser.
          return;
        }
        const refreshedEmployeeIdAccountData = { ...employeeIdAccountData, refreshToken: data.item.refreshToken };
        setEmployeeIdAccountInLocalStorage(JSON.stringify(refreshedEmployeeIdAccountData));
        const { user } = await auth().signInWithEmailAndPassword(employeeIdAccountData.email, data.item.disposablePwd);
        if (user) this.$router.push("/navigator/home");
      } catch (error) {
        this.logoutEmployeeIdAccountUser(); // From MixinUser.
      }
    },
    async checkAndHandleSharedDevicesPermission(hasUser) {
      const sharedAccountData = JSON.parse(getSharedAccountFromLocalStorage());
      const clientKey = this.generateClientKeyForEmployeeId({ employeeId: sharedAccountData.account });
      await this.getOrgPermissionsSettings({ clientKey });
      if (this.getter_shared_account_enabled) return true;
      await this.logoutSharedAccountUser(hasUser); // From MixinUser.
    },
    async getConsents() {
      if (IS_FROM_RUSTICI) return;
      const res = await getUserConsents();
      const { item: consents } = res.data;
      consents.forEach((consent) => {
        if (!consent.signed) {
          consent.signedInfo = { isAgree: false };
        }
      });
      this.consents = consents;
      const everyConsentIsSign = consents.every((consent) => consent.signed);
      const allMandatoryConsnetIsAgree = !consents.some(
        (consent) => consent.signed && consent.mandatory && !consent.signedInfo.isAgree
      );
      if (everyConsentIsSign && allMandatoryConsnetIsAgree) return;
      this.openUserConsentsPopup({ type: "banner" });
    },
  },
};
</script>

<style lang="scss">
html {
  overflow-y: hidden !important;
}
body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #0c0c0e;
  font-family: "Roboto", Helvetica, Arial, Sans-serif;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8d909f !important;
}

#app {
  background-color: #242527;
}
.App {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  font-family: "Roboto", Helvetica, Arial, Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #242527;
  /* Removing the White Bars in Safari on iPhone iOS 11 */
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  --total-header-height: calc(var(--header-height) + var(--header-alert-height));
  --header-alert-height: 0px;
  &__header-alert--open {
    --header-alert-height: 40px;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    background: #121314;
  }
  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
  }
}

button:focus {
  outline: none;
}

/* make v-dialog background color darker */
.v-overlay--active:before {
  opacity: 0.8 !important;
}
.v-overlay:before {
  background-color: #000000 !important;
}

.dropdown-list-item:hover {
  background-color: #52545d !important;
}

.uploader-menu .v-list {
  background: #2c2d32 !important;
  color: white !important;
}
.app-no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.v-list-app {
  background: #2c2d32 !important;
  color: white;
}
.hide-uploader {
  opacity: 0;
  pointer-events: none !important;
  z-index: -1;
}
.app-all-white-spaces {
  /* show all the white spaces for names */
  white-space: pre-wrap !important;
}

#app .max-height-full {
  max-height: 100%;
}
</style>
