export const LOGIN_ACCOUNT_TYPE = {
  EMAIL: 1,
  EMPLOYEEID: 2,
  SHARED: 3,
};

export const LOGIN_FORM_TYPE = {
  EMAIL_OR_ID: "EMAIL_OR_ID",
  PASSWORD: "PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
};

export const LOGIN_METHOD_TYPE = {
  SSO_ONLY: "SSO_ONLY",
  EMAIL_ONLY: "EMAIL_ONLY",
  EMAIL_AND_SSO: "EMAIL_AND_SSO",
  EMAIL_GENERIC_SSO: "EMAIL_GENERIC_SSO",
};

/** use localstorage for china version login and logout all tabs */
export const HAS_ALREADY_SIGN_IN_KEY = "hasAlreadySignInKey";
export const HAS_ALREADY_LOGOUT_KEY = "hasAlreadyLogoutKey";
export const SIGN_IN_SHARED_ACCOUNT_KEY = "signInSharedAccount";
export const LOGOUT_SHARED_ACCOUNT_KEY = "logoutSharedAccount";
export const setSignInLocalStorage = () => {
  localStorage.setItem(HAS_ALREADY_SIGN_IN_KEY, true);
};
export const removeSignInLocalStorage = () => {
  localStorage.removeItem(HAS_ALREADY_SIGN_IN_KEY);
};
export const setLogoutLocalStorage = () => {
  localStorage.setItem(HAS_ALREADY_LOGOUT_KEY, true);
};
export const removeLogoutLocalStorage = () => {
  localStorage.removeItem(HAS_ALREADY_LOGOUT_KEY);
};
export const setSignInSharedAccountLocalStorage = () => {
  localStorage.setItem(SIGN_IN_SHARED_ACCOUNT_KEY, true);
};
export const removeSignInSharedAccountLocalStorage = () => {
  localStorage.removeItem(SIGN_IN_SHARED_ACCOUNT_KEY);
};
export const setLogoutSharedAccountLocalStorage = () => {
  localStorage.setItem(LOGOUT_SHARED_ACCOUNT_KEY, true);
};
export const removeLogoutSharedAccountLocalStorage = () => {
  localStorage.removeItem(LOGOUT_SHARED_ACCOUNT_KEY);
};
