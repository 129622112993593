export const checkUploadDiagramType = (types = []) => {
  if (Array.isArray(types)) {
    return !types.find((type) => !/\bimage.*$/g.test(type) || /(tiff|svg|xbitmap)/.test(type));
  } else if (typeof types === "string") {
    return /\bimage.*$/g.test(type) && /(tiff|svg|xbitmap)/.test(type);
  }
};

export const checkUploadPDFmType = (types = []) => {
  if (Array.isArray(types)) {
    return !types.find((type) => !/\bpdf/i.test(type));
  } else if (typeof types === "string") {
    return /\bpdf/i.test(type);
  }
};
