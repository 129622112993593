<template>
  <section class="CompanyLogo">
    <div class="CompanyLogo__title">{{ $t("admin.qrCodeAndWatermark") }}</div>
    <section class="CompanyLogo__wrapper">
      <div class="CompanyLogo__content">
        <div class="CompanyLogo__subtitle">
          <span class="CompanyLogo__content--text">{{ $t("admin.insertYourBrand") }}</span>
        </div>
        <div class="CompanyLogo__content--box">
          <d-upload-image ref="DUploadImage" @delete-logo="deleteLogo()" @set-logo-src="setLogoSrc($event)" />
          <div>
            <p class="CompanyLogo__content--text mb-1 mt-4 pt-1">{{ $t("admin.simulation") }}</p>
            <p class="CompanyLogo__content--msg">{{ $t("admin.simulationMsg") }}</p>
          </div>
          <d-layout row wrap class="mt-4 pt-1">
            <d-flex>
              <div class="CompanyLogo__qr-code">
                <d-q-r-code-template
                  :isPrivate="true"
                  :logo="logoSrc || dbLogoSrc"
                  :redirectLink="'www.deephow.com'"
                  :title="$t('admin.workflowTitle')"
                />
              </div>
            </d-flex>
            <d-flex>
              <div class="CompanyLogo__video">
                <img width="100%" src="@/img/video_screenshot.png" alt="video screenshot" />
                <div v-if="dbWatermarkSrc && !logoSrc" class="CompanyLogo__watermark">
                  <d-image :src="dbWatermarkSrc" class="CompanyLogo__watermark--image"></d-image>
                </div>
                <div v-else class="CompanyLogo__watermark" ref="watermarkCapture">
                  <d-video-watermark-template :logoSrc="logoSrc" :fillHeightLogo="fillHeightLogo" />
                </div>
              </div>
            </d-flex>
          </d-layout>
        </div>
      </div>

      <d-alert v-model="alert.show" :type="alert.type" :message="alert.message" />
    </section>
  </section>
</template>

<script>
import DUploadImage from "@/components/ui_components/DUploadImage.vue";
import DQRCodeTemplate from "@/components/ui_components/DQRCodeTemplate.vue";
import DVideoWatermarkTemplate from "@/components/ui_components/DVideoWatermarkTemplate.vue";
import DImage from "@/components/ui_components/DImage.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import html2canvas from "html2canvas";
import { fetchCompanyLogo, updateCompanyLogo, deleteCompanyLogo } from "@/server/company-logo-server.js";
import { getLimitFileName } from "@/js/common/formatText.js";

export default {
  name: "AdminCompanyLogo",
  props: {},
  components: {
    DUploadImage,
    DQRCodeTemplate,
    DVideoWatermarkTemplate,
    DImage,
    DAlert,
  },
  watch: {},
  created() {
    this.checkCompanyLogo();
  },
  mounted() {},
  data() {
    return {
      alert: {
        show: false,
        type: "",
        message: "",
      },
      fillHeightLogo: false,
      logoSrc: "",
      dbLogoSrc: "",
      dbWatermarkSrc: "",
      logoFileName: "",
    };
  },
  methods: {
    setLogoSrc(localFile) {
      let img = new Image();
      img.src = window.URL.createObjectURL(localFile);
      img.onload = () => {
        // $logoMaxHeight:74px; $logoMaxWidth:180px;
        const ratio = 180 / 74;
        this.fillHeightLogo = img.width / img.height < ratio;
        this.logoSrc = img.src;
        this.saveLogo();
      };
    },
    getLogoBase64(url) {
      let promise = new Promise(function (resolve, reject) {
        let img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
          let canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          let dataURL = canvas.toDataURL("image/png");
          resolve(dataURL.replace(/^data:image\/(png|jpg|jpeg|pdf);base64,/, ""));
        };
        img.src = url;
      });
      return promise;
    },
    async saveLogo() {
      if (!this.logoSrc) {
        return;
      }
      this.$refs.DUploadImage.state = "uploading";
      const logoBase64 = await this.getLogoBase64(this.logoSrc);
      const watermarkCanvas = await html2canvas(this.$refs.watermarkCapture, {
        backgroundColor: null,
        useCORS: true,
        scale: 4,
      });
      const watermarkBase64 = watermarkCanvas.toDataURL("image/png").split(";base64,")[1];
      const filename = this.$refs.DUploadImage.localFile.name || "logo_image.png";
      const logo = {
        filename: filename,
        base64Image: logoBase64,
      };
      const watermark = {
        filename: "watermark" + filename,
        base64Image: watermarkBase64,
      };
      const response = await updateCompanyLogo({
        organization: this.$organization,
        logo,
        watermark,
      });
      if (response.ok) {
        this.$refs.DUploadImage.state = "uploaded-success";
        this.dbLogoSrc = response.item.logo.url;
        this.dbWatermarkSrc = response.item.watermark.url;
        this.logoSrc = "";
      } else {
        this.$refs.DUploadImage.state = "uploaded-error";
        this.logoSrc = "";
      }
    },
    async checkCompanyLogo() {
      const response = await fetchCompanyLogo({
        organization: this.$organization,
      });
      if (response.ok) {
        this.dbLogoSrc = response.item.logo.url;
        this.logoFileName = getLimitFileName({ nameLength: 40, fileName: response.item.logo.filename });
        this.dbWatermarkSrc = response.item.watermark.url;
        this.$refs.DUploadImage.state = "already-uploaded";
        this.$refs.DUploadImage.imageName = this.logoFileName;
      }
    },
    async deleteLogo() {
      const response = await deleteCompanyLogo({
        organization: this.$organization,
      });
      if (response.ok) {
        this.removeCurrentLogo();
        this.alert.message = this.$t("admin.alertLogoSuccessfullyRemoved");
        this.alert.type = "success";
        this.alert.show = true;
      } else {
        this.alert.message = this.$t("admin.alertLogoFailedRemoved");
        this.alert.type = "error";
        this.alert.show = true;
      }
    },
    removeCurrentLogo() {
      this.$refs.DUploadImage.state = "no-image";
      this.$refs.DUploadImage.localFile = null;
      this.logoFileName = "";
      this.logoSrc = "";
      this.dbLogoSrc = "";
      this.dbWatermarkSrc = "";
      this.fillHeightLogo = false;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.CompanyLogo {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  &__title {
    width: 100%;
    padding: 32px 32px 20px 32px;
    color: #3f3f3f;
    font-size: 32px;
    line-height: 32px;
  }
  &__wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - 232px);
    overflow: auto;
    padding: 0 32px 32px 32px;
  }
  &__wrapper:hover::-webkit-scrollbar-thumb {
    background-color: #b6b6b6;
  }
  &__content {
    position: relative;
    width: 100%;
    min-width: 760px;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.15);
    border-radius: 4px 4px 0px 0px;
    &--box {
      position: relative;
      width: 100%;
      padding: 50px;
      max-width: 1060px;
    }
    &--btn {
      position: absolute;
      right: 0;
      top: 0;
      min-width: 120px;
    }
    &--text {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #4e4e4e;
    }
    &--msg {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #646464;
      opacity: 0.7;
    }
  }
  &__subtitle {
    padding: 24px 22px;
    border-bottom: 1px solid #e7e7e7;
  }
  &__qr-code {
    width: 264px;
    border-radius: 4px;
    margin: 0 30px 30px 0;
    overflow: hidden;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
  }
  &__video {
    display: inline-block;
    position: relative;
    width: 660px;
    height: 412px;
    background-color: #b6b6b6;
    border-radius: 4px;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    &--image {
      width: 100%;
    }
  }
  &__watermark {
    z-index: 1;
    position: absolute;
    top: -22px;
    right: -42px;
    display: inline-block;
    transform: scale(0.5);
    &--image {
      width: 200px;
    }
  }
}
</style>
