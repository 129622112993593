import axios from "axios";
import authChoice from "../../auth";
import Vue from "vue";
import environment from "../../env";

const ApiType = {
  MainCategory: "category",
  SubCategory: "subcategory",
};

async function getIdToken() {
  return authChoice().currentUser.getIdToken();
}

export function deleteMainCategory({ id }) {
  return deleteCategory({
    apiType: ApiType.MainCategory,
    id,
  });
}

export function deleteSubCategory({ id }) {
  return deleteCategory({
    apiType: ApiType.SubCategory,
    id,
  });
}

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].admin
    : environment.service[environment.env]["china"].admin;
}

function deleteCategory({ apiType, id }) {
  return new Promise(async function (resolve, reject) {
    const userIdToken = await getIdToken();
    if (!userIdToken) {
      reject("faield to get token");
      return;
    }
    const data = {
      token: "rG5kXk0CDbhgF4RBlNoV",
      type: apiType,
      id,
      userIdToken,
    };
    const baseUrl = getBaseUrl();
    axios
      .post(`${baseUrl}categories/deletecat`, data)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject();
        console.log(error);
      });
  });
}
