<template>
  <section class="AttachmentBtnGroup" :class="{ 'AttachmentBtnGroup--mobile': isMobileDevice }">
    <attachment-btn-base
      v-for="button in buttons"
      :key="button.part"
      :isActive="value === button.part"
      :isDisabled="isDisabled(button.part)"
      :large="large"
      :isMobileDevice="isMobileDevice"
      @click="$emit('input', button.part)"
    >
      <template slot="icon">
        <icon-base v-if="button.part === 'diagram'" width="12" height="12">
          <d-icon-diagram />
        </icon-base>
        <icon-base v-else-if="button.part === 'links'" width="14" height="14">
          <d-icon-link />
        </icon-base>
        <icon-base v-else-if="button.part === 'pdf'" width="14" height="14">
          <d-icon-pdf />
        </icon-base>
      </template>
      <template slot="text">
        {{ button.text }}
      </template>
    </attachment-btn-base>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase";
import DIconDiagram from "@/components/icons/player/DIconDiagram.vue";
import DIconLink from "@/components/icons/player/DIconLink";
import DIconPdf from "@/components/icons/DIconPdf.vue";
import AttachmentBtnBase from "@/components/DWorkflowPlayer/attachments/AttachmentBtnBase.vue";
import { mapGetters } from "vuex";

export default {
  name: "AttachmentBtnGroup",
  props: {
    value: String,
    large: Boolean,
    isMobileDevice: Boolean,
  },
  created() {
    this.setActiveBtn();
  },
  watch: {
    getter_current_step_attachments() {
      this.setActiveBtn();
    },
  },
  data() {
    return {
      showPart: "",
      buttons: [
        {
          text: this.$t("player.diagrams"),
          part: "diagram",
        },
        {
          text: this.$t("builder.links"),
          part: "links",
        },
        {
          text: this.$t("player.pdfFiles"),
          part: "pdf",
        },
      ],
    };
  },
  components: { IconBase, DIconDiagram, DIconLink, DIconPdf, AttachmentBtnBase },
  methods: {
    setActiveBtn() {
      let currentBtn = "";
      const typeButtonMapping = {
        diagram: "diagrams",
        links: "links",
        pdf: "pdfs",
      };
      for (const element of this.getter_current_step_attachments) {
        if (element[typeButtonMapping[element.type]]?.length > 0) {
          currentBtn = element.type;
          break;
        }
      }
      this.$emit("input", currentBtn);
    },
    isDisabled(part) {
      let foundElement = this.getter_current_step_attachments.find((element) => {
        return element.type === part;
      });
      return foundElement === undefined;
    },
  },
  computed: {
    ...mapGetters("workflowPlayer", ["getter_current_step_attachments"]),
  },
};
</script>

<style lang="scss" scoped>
.AttachmentBtnGroup {
  display: flex;
  &--mobile {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
