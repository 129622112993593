<template>
  <section v-if="hasAccess" class="skills-main">
    <skills-organization-v-2 />
  </section>
</template>

<script>
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";

export default {
  name: "SkillsMain",
  props: {},
  created() {
    this.auth().onAuthStateChanged((user) => {
      if (user) {
        if (this.$access && this.$access.skills) {
          this.hasAccess = true;
        } else {
          this.getUserRole(user).then(() => {
            this.setUserAccess().then(() => {
              if (this.$access && this.$access.skills) {
                this.hasAccess = true;
              } else {
                //no skills access
                this.$router.replace("/navigator/home");
              }
            });
          });
        }
      }
    });
  },
  mounted() {},
  data() {
    return {
      hasAccess: false,
    };
  },
  components: {
    SkillsOrganizationV2: () => import("@/components/DSkills/SkillsOrganizationV2"),
  },
  mixins: [MixinDB, MixinUser],
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.skills-main {
  height: 100%;
  width: 100%;
}
</style>
