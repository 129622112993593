<template>
  <d-layout row class="DPagination" :class="[theme, variant === 'card' && 'DPagination--card']">
    <div v-if="canSelectPageSize" class="DPagination__pageSize">
      <span>{{ pageSizeText || $t("$vuetify.dataIterator.rowsPerPageText") }}</span>
      <div class="DPagination__pageSizeSelect">
        <select :value="pageSize" @change="onPageSizeChanged">
          <option v-for="pageSizeOption in pageSizeOptions" :key="pageSizeOption" :value="pageSizeOption">
            {{ pageSizeOption }}
          </option>
        </select>
      </div>
    </div>

    <div class="DPagination__prevAndNext">
      <span>{{ $t("$vuetify.dataIterator.pageText", pageTextParams) }}</span>
      <button
        class="DPagination__arrowButton"
        @click="onCurrentPageChanged(currentPage - 1)"
        :disabled="currentPage === 1"
      >
        <font-awesome-icon :icon="['far', 'chevron-left']" />
      </button>
      <button
        class="DPagination__arrowButton"
        @click="onCurrentPageChanged(currentPage + 1)"
        :disabled="totalCount <= 0 || currentPage === totalPages"
      >
        <font-awesome-icon :icon="['far', 'chevron-right']" />
      </button>
    </div>
  </d-layout>
</template>

<script>
export default {
  name: "DPagination",
  props: {
    theme: {
      type: String,
      default: "dark", // 'dark' | 'light',
    },
    variant: {
      type: String,
      default: "default", // 'default' | 'card'
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    pageSizeText: {
      type: String,
      default: "",
    },
    pageSizeOptions: {
      type: Array, // number[]
      default: () => [5, 10, 20, 25],
    },
    canSelectPageSize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    pageTextParams() {
      const from = 1 + (this.currentPage - 1) * this.pageSize;
      const to = this.currentPage * this.pageSize;
      const totalCount = this.totalCount;
      return {
        0: this.formatPageNumber(from),
        1: to <= totalCount ? this.formatPageNumber(to) : this.formatPageNumber(totalCount),
        2: this.formatPageNumber(totalCount),
      };
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.pageSize);
    },
  },
  methods: {
    formatPageNumber(number) {
      return number < 10 ? `0${number}` : `${number}`;
    },
    onPageSizeChanged(event) {
      this.$emit("onPageSizeChanged", +event.target.value);
    },
    onCurrentPageChanged(newPage) {
      this.$emit("onPageChanged", newPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.DPagination {
  align-items: center;
  justify-content: flex-end;
  column-gap: var(--spacing-07);
  font-size: 12px;
  line-height: 100%;
  &--card {
    padding: var(--spacing-04) var(--spacing-05);
    border-radius: var(--spacing-03);
  }
  &__pageSize {
    flex: 0 0 auto;
  }
  &__prevAndNext {
    flex: 0 0 auto;
  }
}
.DPagination__pageSize {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-05);
  padding: 0 12px;
  &Select {
    min-width: 64px;
    border-radius: var(--spacing-03);
    padding: 0px var(--spacing-03);
    cursor: pointer;
    > select {
      padding: var(--spacing-03) 0px;
      width: 100%;
      appearance: auto;
      outline: none;
    }
  }
}
.DPagination__prevAndNext {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-05);
  .DPagination__arrowButton {
    width: 32px;
    height: 32px;
    border-radius: var(--spacing-03);
    padding: var(--spacing-03);
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
}

// dark theme (new ui style)
.DPagination.dark {
  color: var(--dGrey6-color);
  &.DPagination--card {
    background: var(--dGrey2-color);
    border: 1px solid var(--dGrey3-color);
    padding: var(--spacing-04) var(--spacing-05);
    border-radius: var(--spacing-03);
  }
  .DPagination__pageSize {
    &Select {
      color: var(--dWhite-color);
      background: var(--dGrey4-color);
      border: 1px solid var(--dGrey5-color);
    }
  }
  .DPagination__prevAndNext {
    .DPagination__arrowButton {
      color: var(--dWhite-color);
      border: 1px solid var(--dGrey5-color);
      background: var(--dGrey4-color);
      &:disabled {
        color: var(--dGrey6-color);
        background: rbga(51, 53, 58, 0.32); // var(--dGrey4-color) with opacity 0.32;
      }
    }
  }
}

// light theme (old ui style)
.DPagination.light {
  color: var(--dLigthGrey600-color);
  &.DPagination--card {
    border: 1px solid var(--dLigthGrey-color);
    padding: var(--spacing-04) var(--spacing-05);
    border-radius: var(--spacing-03);
  }
  .DPagination__pageSize {
    &Select {
      border: 1px solid var(--dLigthGrey-color);
    }
  }
  .DPagination__prevAndNext {
    .DPagination__arrowButton {
      border: 1px solid var(--dLigthGrey-color);
      &:disabled {
        color: var(--dLigthGrey-color);
      }
    }
  }
}
</style>
