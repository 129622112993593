import environment from "@/../env.js";

export async function appendMissingUserDataFromDB({ mixinDbRef, userListRef }) {
  const userIdList = {
    inviting: [],
    invited: [],
  };
  userListRef.forEach((user) => {
    if (user.invitation) {
      userIdList.inviting.push(user.id);
    } else {
      userIdList.invited.push(user.id);
    }
  });

  const fetchUsersPromiseList = Promise.all([
    fetchInvitedUsers({
      mixinDbRef,
      userIds: userIdList.inviting,
    }),
    fetchUsers({
      mixinDbRef,
      userIds: userIdList.invited,
    }),
  ]);

  const dbUsers = (await fetchUsersPromiseList).flat();

  const isNoDbUsers = !Array.isArray(dbUsers);
  if (isNoDbUsers) {
    return;
  }

  let dbUser = dbUsers.pop();
  while (dbUser) {
    const userRef = userListRef.find((_userRef) => _userRef.id === dbUser.id);
    if (userRef) {
      Object.assign(userRef, dbUser);
    }

    dbUser = dbUsers.pop();
  }
}

async function fetchInvitedUsers({ mixinDbRef, userIds }) {
  return batchFetchUsers({
    mixinDbRef,
    userIds,
    createFetchUserPromiseFn: ({ userId }) =>
      mixinDbRef.getDocumentByQuery("invitations", [
        {
          field: "id",
          comparison: "in",
          value: userId,
        },
      ]),
  });
}

export async function fetchUsers({ mixinDbRef, userIds }) {
  return batchFetchUsers({
    mixinDbRef,
    userIds,
    createFetchUserPromiseFn: ({ userId }) =>
      mixinDbRef.getDocumentByQuery("users", [
        {
          field: "id",
          comparison: "in",
          value: userId,
        },
      ]),
  });
}

async function batchFetchUsers({ mixinDbRef, userIds, createFetchUserPromiseFn }) {
  const noUserIds = !Array.isArray(userIds);
  if (noUserIds) {
    return;
  }

  const limitation = {
    inFilterSupportMaxOfElement: environment.backendType === "firebase" ? 10 : 999999999,
  };

  const fetchUserPromises = [];
  for (let i = 0; i < userIds.length; i += limitation.inFilterSupportMaxOfElement) {
    fetchUserPromises.push(
      createFetchUserPromiseFn({
        userId: userIds.slice(i, i + limitation.inFilterSupportMaxOfElement),
      })
    );
  }

  const users = (await Promise.all(fetchUserPromises)).flat();
  return users;
}

export async function updateTolatestUserdataFromDb({ mixinDbRef, userDataRef }) {
  const userDataOfDB = await mixinDbRef.getDocumentByFieldValue("users", "id", userDataRef.id);

  const hasDbData = Array.isArray(userDataOfDB) && userDataOfDB.length > 0;
  if (hasDbData) {
    Object.assign(userDataRef, userDataOfDB[0]);
  }
}
