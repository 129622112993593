import Vue from "vue";
import Router from "vue-router";
import PlayerMain from "@/components/DWorkflowPlayer/player/PlayerMain.vue";
import PlayerRustici from "@/components/DWorkflowPlayer/player/PlayerRustici.vue";
import BuilderMain from "@/components/BuilderMain";
import Auth from "@/components/Auth";
import UserInviteCreate from "@/components/UserInviteCreate";
import UserNewPassword from "@/components/UserNewPassword";
const UserEmailExpiredResetPassowrd = () => import("@/components/DUser/UserEmailExpiredResetPassowrd");
const DZoomLanding = () => import("@/components/DUser/DZoomLanding");
import PlayerFullscreenPublic from "@/components/DWorkflowPlayer/player/PlayerFullscreenPublic";
import SkillsMain from "@/components/DSkills/SkillsMain";
import SkillsBuilder from "@/components/DSkills/SkillsBuilder";
const SkillsExplorerV2 = () => import("@/components/DSkills/SkillsExplorerV2");
const SkillsMatrixV2 = () => import("@/components/DSkills/SkillsMatrixV2");
const SkillsBuilderUsers = () => import("@/components/DSkills/SkillsBuilderUsers");
const SkillsBuilderQuizAnalytics = () => import("@/components/DSkills/SkillsBuilderQuizAnalytics");
const SkillsBuilderSettings = () => import("@/components/DSkills/SkillsBuilderSettings");
const MediaPicker = () => import("@/components/MediaPicker");
const SharedMain = () => import("@/components/DLogin/Shared/SharedMain");
const WorkflowPlayerMain = () => import("@/components/DWorkflowPlayer/WorkflowPlayerMain");
const UserProfileMain = () => import("@/components/DUserProfile/UserProfileMain");
import auth from "../../auth.js";
Vue.use(Router);
import NavigatorRoute from "./routes/NavigatorRoute";
import EditorRoute from "./routes/EditorRoute";
import AdminRoute from "./routes/AdminRoute";
import AnalyticsRoute from "./routes/AnalyticsRoute";
import MavenRoute from "./routes/MavenRoute";
import eventbus from "@/main";
import { playerRoutes, editSkillsRoutes, editorRoutes, adminRoutes } from "@/constants/routers";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new Router({
  mode: "history",
  routes: [
    ...EditorRoute,
    ...NavigatorRoute,
    ...AnalyticsRoute,
    ...AdminRoute,
    ...MavenRoute,
    {
      path: "/playermainfullscreen/:workflowId/:stepId?",
      name: "PlayerMainFullscreen",
      component: PlayerMain,
      meta: { auth: true },
      props: true,
    },
    {
      path: "/rustici/:workflowId/:stepId?",
      name: "PlayerRustici",
      component: PlayerRustici,
      props: true,
    },
    {
      path: "/playermain/:workflowId/:stepId?",
      name: "PlayerMain",
      component: WorkflowPlayerMain,
      meta: { auth: true },
      props: true,
    },
    {
      path: "/playerembed/:workflowId/:stepId?",
      name: "PlayerEmbed",
      component: PlayerMain,
      meta: { auth: true },
      props: true,
    },
    {
      path: "/PlayerPreview/:workflowId/:stepId?",
      meta: { auth: true },
      props: true,
      redirect: (to) => {
        const { workflowId, stepId } = to.params;
        let target = { name: "404" };
        if (window.top.location.href.includes("buildermain")) {
          target = { name: "PlayerEmbed", params: { workflowId, stepId, isPreviewVideoMode: true } };
        }
        return target;
      },
    },
    {
      path: "/skills/main/",
      name: "SkillsMain",
      component: SkillsMain,
      meta: { auth: true },
      children: [
        {
          path: "/skills/explorer/:workspaceId?",
          name: "SkillsExplorer",
          component: SkillsExplorerV2,
          meta: { auth: true },
          props: true,
        },
        {
          path: "/skills/matrix/:workspaceId?",
          name: "SkillsMatrix",
          component: SkillsMatrixV2,
          meta: { auth: true },
          props: true,
        },
        {
          path: "/skillsbuilder/:playlistId",
          name: "SkillsBuilder",
          component: SkillsBuilder,
          meta: { auth: true },
          props: true,
          children: [
            {
              path: "/skillsbuilder/:playlistId/users/",
              name: "SkillsBuilderUsers",
              component: SkillsBuilderUsers,
              props: true,
            },
            {
              path: "/skillsbuilder/:playlistId/settings/",
              name: "SkillsBuilderSettings",
              component: SkillsBuilderSettings,
              props: true,
            },
            {
              path: "/skillsbuilder/:playlistId/quiz-analytics/",
              name: "SkillsBuilderQuizAnalytics",
              component: SkillsBuilderQuizAnalytics,
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "/buildermain/:id",
      name: "BuilderMain",
      component: BuilderMain,
      meta: { auth: true },
      props: true,
    },
    {
      path: "/mediapicker",
      name: "MediaPicker",
      component: MediaPicker,
      meta: { auth: true },
      props: false,
    },
    {
      path: "/shared",
      name: "SharedMain",
      component: SharedMain,
      meta: { auth: true },
      props: false,
    },
    {
      path: "/UserProfile/:uid",
      name: "UserProfileMain",
      component: UserProfileMain,
      meta: { auth: true },
      props: false,
    },
    {
      path: "*",
      redirect: "/",
    },
    //// Public Routes ////
    {
      path: "/p/:workflowId/:stepId?",
      name: "PlayerPublic",
      component: WorkflowPlayerMain,
      meta: { auth: false },
      props: true,
    },
    {
      path: "/playerfullscreenpublic/:workflowId/:stepId?",
      name: "PlayerFullscreenPublic",
      component: PlayerFullscreenPublic,
      meta: { auth: false },
      props: true,
    },
    {
      path: "/userinvitecreate/:token/:name?/:lang?",
      name: "UserInviteCreateLang",
      component: UserInviteCreate,
      meta: { auth: false },
    },
    {
      path: "/userinvitecreate/:token/:name?",
      name: "UserInviteCreate",
      component: UserInviteCreate,
      meta: { auth: false },
    },
    {
      path: "/usernewpassword/:token/:lang?",
      name: "UserNewPasswordLang",
      component: UserNewPassword,
      meta: { auth: false },
    },
    {
      path: "/usernewpassword/:token",
      name: "UserNewPassword",
      component: UserNewPassword,
      meta: { auth: false },
    },
    {
      path: "/emailexpired/:type?/:lang?/:email?",
      name: "UserEmailExpiredResetPassowrd",
      component: UserEmailExpiredResetPassowrd,
      meta: { auth: false },
    },
    {
      path: "/zoomlanding/:status/:lang?/:subdomain?",
      name: "DZoomLanding",
      component: DZoomLanding,
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/components/DNotFound/DNotFound"),
    },
    {
      path: "/403",
      name: "403",
      component: () => import("@/components/DNotFound/DNoPermission"),
    },
    {
      path: "/",
      name: "Auth",
      component: Auth,
      meta: { auth: false },
    },
  ],
});
const findMatchedProductByRouteName = ({ name }) => {
  if (!name) return null;

  let product;
  if (playerRoutes.includes(name)) product = "player"; // mean navigator
  else if (editSkillsRoutes.includes(name)) product = "skill";
  else if (editorRoutes.includes(name)) product = "editor";
  else if (adminRoutes.includes(name)) product = "admin";
  else product = "others";
  return product;
};
router.beforeEach((to, from, next) => {
  // when change page, close search on the DLeftSideMenu.vue
  const isFromSameProduct = findMatchedProductByRouteName(to) === findMatchedProductByRouteName(from);
  if (!isFromSameProduct) eventbus.$emit("closeSearchOnSideBar");

  const authRequired = to.matched.some((route) => route.meta.auth);
  const currentUser = auth().currentUser;
  if (!authRequired || (currentUser && currentUser.uid)) {
    next();
    return;
  }
  auth().onAuthStateChanged((user) => {
    if (user) {
      next();
    } else {
      next({ name: "Auth", query: { redirect: to.path } });
    }
  });
});

export default router;
