const isTablet = () => {
  const userAgent = window.navigator.userAgent;
  const hasTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;
  const tabletKeywords = [/iPad/, /Android(?!.*Mobile)/, /Windows.*(Touch|Tablet\sPC)/i, /Silk/, /Kindle/, /Tablet/];
  return hasTouch && tabletKeywords.some((keyword) => userAgent.match(keyword));
};

export const IS_TABLET = isTablet();

const isMobile = () => {
  const userAgent = window.navigator.userAgent;
  const mobileKeywords = [/iPhone|Android|iPod|BlackBerry|Windows\sPhone/];
  return mobileKeywords.some((keyword) => userAgent.match(keyword));
};

export const IS_MOBILE = isMobile();

const isSafari = () => {
  const userAgent = window.navigator.userAgent;
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
  return isSafari && !window.chrome;
};

export const IS_SAFARI = isSafari();

export const IS_USE_IFRAME = window.self != window.top;
