<template>
  <div class="DQRCodeTemplate">
    <div class="DQRCodeTemplate__container">
      <vue-qrcode :text="redirectLink" :size="225" />
      <div class="DQRCodeTemplate__title-row">
        <div v-if="isPrivate" class="DQRCodeTemplate__private-icon">
          <d-icon-lock />
        </div>
        <div class="DQRCodeTemplate__title">{{ title }}</div>
      </div>
      <div v-if="logo" class="DQRCodeTemplate__logo">
        <img :src="logo" alt="logo" />
      </div>
    </div>
    <div class="DQRCodeTemplate__powered-by">
      <img width="145px" src="@/img/poweredBy.png" />
    </div>
  </div>
</template>
<script>
import VueQrcode from "vue-qrcode-component";
import DIconLock from "@/components/icons/builder/DIconLock";
export default {
  name: "DQRCodeTemplate",
  props: {
    isPrivate: Boolean,
    title: String,
    logo: String,
    redirectLink: String,
  },
  components: {
    DIconLock,
    VueQrcode,
  },
};
</script>

<style scoped lang="scss">
$width: 265px;
.DQRCodeTemplate {
  background: white;
  min-height: auto;
  width: $width;
  padding: 10px;
  position: relative;

  &__container {
    height: 100%;
    width: 100%;
    // 13 px. prevent be covered by poweredby logo.
    padding: 10px 10px 13px;
    border: 1px solid #4689f3;
  }

  &__title-row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  &__private-icon {
    width: 20px;
    margin-right: 5px;
  }

  &__title {
    color: #3d3f45;
    font-size: 18px;
    word-break: break-word;
    text-align: left;
    line-height: 21px;
    margin-top: 1px;
  }

  &__logo {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      height: auto;
      max-height: 150px;
      max-width: 100%;
    }
  }

  &__powered-by {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24px;
    text-align: center;
  }
}
</style>
