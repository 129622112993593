import { AUDIO_CONTENT_STATUS } from "@/constants/editorStatus";

const getLanguageByVoice = (voice) => {
  const matched = voice.match(/[a-z]{2}-[A-Z]{2}/);
  return matched ? matched[0] : "";
};

export const checkShouldGenerate = (audioSourceMaps, blocks, isRequestingMaps, voice) => {
  const shouldGenerate = blocks.find((blockItem) => {
    const blockStatus = getBlockStatus(
      audioSourceMaps[blockItem.text2SpeechId],
      blockItem,
      isRequestingMaps[blockItem.key],
      voice
    );
    return [AUDIO_CONTENT_STATUS.TODO, AUDIO_CONTENT_STATUS.FAILURE].includes(blockStatus);
  });
  return !!shouldGenerate;
};

export const getBlockStatus = (audioSource = null, blockItem, isRequesting, stateVoice) => {
  if (isRequesting) {
    return AUDIO_CONTENT_STATUS.PENDING;
  }

  if (audioSource) {
    const { status, text, voice, rate } = audioSource;
    const apiResponseStatus = AUDIO_CONTENT_STATUS[status.toUpperCase()];
    if (status !== AUDIO_CONTENT_STATUS.COMPLETED) {
      return apiResponseStatus;
    }
    const shouldReGenerated =
      text !== blockItem.text || (text !== "" && rate && voice && (rate !== blockItem.speed || voice !== stateVoice));
    return shouldReGenerated ? AUDIO_CONTENT_STATUS.TODO : apiResponseStatus;
  }

  return AUDIO_CONTENT_STATUS.TODO;
};

export const getTextToSpeechPayload = (blockItem, voice) => {
  return blockItem.text === ""
    ? {
        text: "",
        duration: blockItem.time,
      }
    : {
        text: blockItem.text,
        voice,
        rate: blockItem.speed,
        language: getLanguageByVoice(voice),
      };
};
