import adminServer from "./admin-server";
import authChoice from "../../auth";

const fixedToken = "rG5kXk0CDbhgF4RBlNoV";

export async function deleteWorkspace({ groupData }) {
  let ok = true;
  let data = {};
  let errorMessage;
  const idToken = await authChoice().currentUser.getIdToken();
  const requestBody = {
    token: fixedToken,
    type: "group",
    action: "delete",
    data: {
      idToken,
      organization: groupData.organization,
      groupId: groupData.groupId,
    },
  };

  try {
    const response = await adminServer.post("admin", requestBody);
    data = response.data;
  } catch (e) {
    ok = false;
    console.error(e);
    errorMessage = e.response.data;
  } finally {
    return { ok, data, errorMessage };
  }
}
