import adminServer, { apiWrapper } from "./admin-server";

const config = {
  token: "rG5kXk0CDbhgF4RBlNoV",
};

export async function recordLoginActivityOfEmailLogin({ user, deviceInfo }) {
  const requestBody = {
    token: config.token,
    type: "usr/pwd",
    userId: user.uid,
    email: user.email,
    device: deviceInfo,
    product: "web",
  };

  try {
    const { data } = await adminServer.post("login-activity", requestBody);
    return data;
  } catch (e) {
    console.error(e);
  }
}

export async function registerUserLastInteraction() {
  try {
    return await apiWrapper("patch", "/v1/admin/user/last-interaction");
  } catch (error) {
    console.log(error);
  }
}
