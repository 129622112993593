import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].admin
    : environment.service[environment.env]["china"].admin;
}

const workspaceServer = axios.create({
  baseURL: getBaseUrl(),
});

// TODO: Confirm `x-user-token`.
workspaceServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

const serverToken = "rG5kXk0CDbhgF4RBlNoV";

// TODO: Confirm `serverToken` or `configToken` is needed.
export const postWorkspace = async ({ data }) =>
  await workspaceServer.post("/admin", { action: "add", type: "new-group", token: serverToken, data });

export const putWorkspace = async ({ data }) =>
  workspaceServer.post("/admin", { action: "change", type: "group-change", token: serverToken, data });

export default workspaceServer;
