import deephowApiServerWrapper from "@/server/api-server";

export const createTeam = ({ workspace, name }) => deephowApiServerWrapper("post", `/v1/teams/`, { workspace, name });

export const updateTeam = ({ teamId, name }) => deephowApiServerWrapper("patch", `/v1/teams/${teamId}`, { name });

export const deleteTeam = ({ teamId }) => deephowApiServerWrapper("delete", `/v1/teams/${teamId}`);

export const addTeamUsers = ({ teamId, users }) =>
  deephowApiServerWrapper("post", `/v1/teams/${teamId}/addUsers`, { users });
