import { postWorkspace, putWorkspace } from "@/server/workspace-server";

export const namespaced = true;

export const state = {};

export const getters = {
  getter_workspaces_with_workspace_groups_name: (state, getters, rootState, rootGetters) =>
    rootGetters["workspaceGroups/getter_admin_workspace_groups"].flatMap((workspaceGroup) =>
      workspaceGroup.workspaces.map((workspace) => ({ workspaceGroupName: workspaceGroup.name, ...workspace }))
    ),
  getter_workspace_with_workspace_group_map: (state, getters) =>
    getters.getter_workspaces_with_workspace_groups_name.reduce(
      (result, workspace) => ({ ...result, [workspace.id]: workspace }),
      {}
    ),
};

export const mutations = {};

export const actions = {
  createWorkspace: async ({ commit }, { data }) => await postWorkspace({ data }),
  patchWorkspace: async ({ commit }, { data }) => await putWorkspace({ data }),
};
