import { render, staticRenderFns } from "./AdminWorkspaceGroupPopup.vue?vue&type=template&id=13f899e6&scoped=true&"
import script from "./AdminWorkspaceGroupPopup.vue?vue&type=script&lang=js&"
export * from "./AdminWorkspaceGroupPopup.vue?vue&type=script&lang=js&"
import style0 from "./AdminWorkspaceGroupPopup.vue?vue&type=style&index=0&id=13f899e6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f899e6",
  null
  
)

export default component.exports