import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].ulApiServer
    : environment.service[environment.env]["china"].ulApiServer;
}

const ulIntegrationServer = axios.create({
  baseURL: getBaseUrl(),
});

ulIntegrationServer.interceptors.request.use(
  async (config) => {
    config.headers["Authorization"] = `bearer ${await authChoice().currentUser.getIdToken()}`;
    return config;
  },
  (error) => Promise.resolve(error)
);

export const getUlOrganizations = () => ulIntegrationServer.get(`/api/ul-integration/organizations`);

export const exportComplianceWire = (payload) => ulIntegrationServer.post("/api/ul-integration/training-item", payload);

export default ulIntegrationServer;
