<template>
  <section>
    <admin-setting-permission-item
      :allow="getter_shared_account_enabled"
      :title="$t('admin.sharedDevices')"
      :subtitle="$t('admin.allowsSharedAccountMsg')"
      autotest="shared-account-toggle"
      @click-switch="showConfirmation.sharedAccount = true"
    >
      <div v-if="getter_shared_account_enabled" class="SharedAccount--checkbox">
        <v-checkbox v-model="disableDHLogin" color="#4689F3" readonly @click="showConfirmation.dhLogin = true">
          <div
            slot="label"
            class="SharedAccount--checkbox__label"
            v-html="$t('admin.limitIDLoginToSharedAccount')"
          ></div>
        </v-checkbox>
        <v-checkbox
          v-model="enableEmployeeCreation"
          class="ma-0 pa-0"
          color="#4689F3"
          readonly
          @click="showConfirmation.employeeCreation = true"
        >
          <div
            slot="label"
            class="SharedAccount--checkbox__label"
            v-html="$t('admin.allowEmployeesCreateIDAccount')"
          ></div>
        </v-checkbox>
      </div>
    </admin-setting-permission-item>

    <d-change-permission-confirmation
      v-if="showConfirmation.sharedAccount"
      :title="$t('admin.sharedDevices')"
      :content="
        getter_shared_account_enabled ? $t('admin.disableSharedAccountMsg') : $t('admin.enableSharedAccountMsg')
      "
      @close-popup="showConfirmation.sharedAccount = false"
      @change-permission="changeSharedAccountLogin"
    ></d-change-permission-confirmation>

    <d-change-permission-confirmation
      v-if="showConfirmation.dhLogin"
      :title="$t('admin.sharedDevices')"
      :content="
        (disableDHLogin ? $t('admin.enableDHLoginMsg') : $t('admin.disableDHLoginMsg')) +
        '<br>' +
        $t('admin.changesMadeImmediately')
      "
      @close-popup="showConfirmation.dhLogin = false"
      @change-permission="changeSharedDeviceSetting('DH_LOGIN')"
    ></d-change-permission-confirmation>

    <d-change-permission-confirmation
      v-if="showConfirmation.employeeCreation"
      :title="$t('admin.sharedDevices')"
      :content="
        (enableEmployeeCreation ? $t('admin.disableEmployeeCreationMsg') : $t('admin.enableEmployeeCreationMsg')) +
        '<br>' +
        $t('admin.changesMadeImmediately')
      "
      @close-popup="showConfirmation.employeeCreation = false"
      @change-permission="changeSharedDeviceSetting('EMPLOYEE_CREATION')"
    ></d-change-permission-confirmation>
  </section>
</template>

<script>
import AdminSettingPermissionItem from "@/components/DAdmin/Settings/AdminSettingPermissionItem.vue";
import DChangePermissionConfirmation from "@/components/DAdmin/confirmations/DChangePermissionConfirmation.vue";
import { loggingError } from "@/server/error-log-server.js";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "AdminSettingSharedAccount",
  props: {},
  components: {
    AdminSettingPermissionItem,
    DChangePermissionConfirmation,
  },
  watch: {},
  mounted() {
    this.initSharedDeviceSettings();
  },
  data() {
    return {
      disableDHLogin: false,
      enableEmployeeCreation: false,
      changedSharedDeviceType: "",
      showConfirmation: {
        sharedAccount: false,
        employeeCreation: false,
        dhLogin: false,
      },
    };
  },
  computed: {
    ...mapGetters("permission", [
      "getter_shared_account_enabled",
      "getter_dh_login_enabled",
      "getter_employee_creation_enabled",
    ]),
    updateData() {
      const sharedDeviceTypeDictionary = {
        DH_LOGIN: {
          disableDHLogin: this.getter_dh_login_enabled,
          enableEmployeeCreation: this.getter_employee_creation_enabled,
        },
        EMPLOYEE_CREATION: {
          disableDHLogin: !this.getter_dh_login_enabled,
          enableEmployeeCreation: !this.getter_employee_creation_enabled,
        },
      };
      return { sharedDevice: sharedDeviceTypeDictionary[this.changedSharedDeviceType] };
    },
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    ...mapActions("permission", ["updateSharedAccountSettings", "updateSharedDeviceSettings"]),
    async initSharedDeviceSettings() {
      this.disableDHLogin = !this.getter_dh_login_enabled;
      this.enableEmployeeCreation = this.getter_employee_creation_enabled;
    },
    async changeSharedAccountLogin() {
      this.showConfirmation.sharedAccount = false;
      const toEnable = !this.getter_shared_account_enabled;
      const updateData = { sharedAccountLogin: toEnable };
      try {
        await this.updateSharedAccountSettings({
          organization: this.$organization,
          updateData,
        });
        const message = toEnable
          ? this.$t("admin.alertEnableSharedAccountSuccess")
          : this.$t("admin.alertDisableSharedAccountSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } catch (error) {
        const message = toEnable
          ? this.$t("admin.alertEnableSharedAccountFailed")
          : this.$t("admin.alertDisableSharedAccountFailed");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
        loggingError(error);
      }
    },
    async changeSharedDeviceSetting(type) {
      this.showConfirmation = {
        sharedAccount: false,
        dhLogin: false,
        employeeCreation: false,
      };
      this.changedSharedDeviceType = type;
      try {
        await this.updateSharedDeviceSettings({
          organization: this.$organization,
          updateData: this.updateData,
          sharedDeviceType: type,
        });
        this.disableDHLogin = !this.getter_dh_login_enabled;
        this.enableEmployeeCreation = this.getter_employee_creation_enabled;
        const message = this.$t("admin.alertSharedDeviceSuccessfullyChanged");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } catch (error) {
        const message = this.$t("admin.alertSharedDeviceFailedChanged");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
        loggingError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.SharedAccount {
  &--checkbox {
    padding-top: 20px;
    &__label {
      color: #919191;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
