const AnalyticsMain = () => import("@/components/AnalyticsMain");
const AnalyticsMainOrganization = () => import("@/components/DAnalytics/AnalyticsMainOrganization");
const AnalyticsSingleWorkflow = () => import("@/components/AnalyticsSingleWorkflow");
const AnalyticsDataUsers = () => import("@/components/DAnalytics/AnalyticsDataUsers");
const AnalyticsSingleUser = () => import("@/components/AnalyticsSingleUser");
const AnalyticsQuizSingleWorkflow = () => import("@/components/DAnalytics/AnalyticsQuizSingleWorkflow");
const AnalyticsQuizSingleUser = () => import("@/components/DAnalytics/AnalyticsQuizSingleUser");
const AnalyticsQuizWorkflows = () => import("@/components/DAnalytics/AnalyticsQuizWorkflows");

const AnalyticsRoute = [
  {
    path: "/analytics/main",
    name: "AnalyticsMainOrganization",
    component: AnalyticsMainOrganization,
    meta: { auth: true },
  },
  {
    path: "/analytics/quiz",
    name: "AnalyticsQuizWorkflows",
    component: AnalyticsQuizWorkflows,
    meta: { auth: true },
  },
  {
    path: "/analytics/quiz/workflow/:workflowId",
    name: "AnalyticsQuizSingleWorkflow",
    component: AnalyticsQuizSingleWorkflow,
    meta: { auth: true },
  },
  {
    path: "/analytics/quiz/:quizId/workflow/:workflowId/user/:userId",
    name: "AnalyticsQuizSingleUser",
    component: AnalyticsQuizSingleUser,
    meta: { auth: true },
  },
  {
    path: "/analytics/users",
    name: "AnalyticsDataUsers",
    component: AnalyticsDataUsers,
    meta: { auth: true, keepAlive: true },
  },
  {
    path: "/analytics/user/:userId",
    name: "AnalyticsSingleUser",
    component: AnalyticsSingleUser,
    meta: { auth: true },
    props: true,
  },
  {
    path: "/analytics/:tab",
    name: "AnalyticsMain",
    component: AnalyticsMain,
    meta: { auth: true },
    children: [
      {
        path: "/analytics/workflow/:workflowId",
        name: "AnalyticsSingleWorkflow",
        component: AnalyticsSingleWorkflow,
        meta: { auth: true },
        props: true,
      },
    ],
  },
];

export default AnalyticsRoute;
