<template lang="html">
  <section class="player-voice-assistant">
    <audio id="beep" ref="beep" hidden>
      <source src="../assets/beep.mp3" type="audio/mpeg" />
    </audio>
    <!-- <audio ref="test" :src="audioBlob" hidden/> -->
  </section>
</template>

<script lang="js">
import RecorderService from '@/js/RecorderService'
import utils from '@/js/Utils'
import { loggingError } from '@/server/error-log-server.js';
  export default  {
    name: 'player-voice-assistant',
    props: [],
    created(){
      this.recorderSrvc = new RecorderService();
      this.recorderSrvc.em.addEventListener('recording', (evt) => this.onNewRecording(evt));
    },
    mounted() {

    },
    data() {
      return {
        mediaStream: null,
        recordingInProgress: false,
        recording: null,
        audioBlob: null,
        recordingTimeout: 3000, // recording timeout value; 1000 = 1 second
      }
    },
    methods: {
      playVideo(){
        this.$emit("playVideoEvent");
      },
      pauseVideo(){
        this.$emit("pauseVideoEvent");
      },
      playNextStep(){
        this.$emit("playNextStepEvent");
      },
      repeat(){
        this.$emit("repeatEvent");
      },
      playBeep(){
        this.$refs.beep.play();
      },
      openMenu(){
        this.$emit("openMenuEvent");
      },
      mute(){
        this.$emit("muteEvent");
      },
      unmute(){
        this.$emit("unmuteEvent");
      },
      showMeHowTo(doSomething){
        this.$emit("showMeHowToEvent", doSomething);
      },
      showMe(tag){
        this.$emit("showMeEvent", tag);
      },
      goBack(){
        this.$emit("goBackEvent");
      },
      speakIn(language){
        this.$emit("speakInEvent", language);
      },
      startWebSpeechAPI(){
        const self = this;
        let speechResult = "";
        const handleSpeechResult = this.handleSpeechResult;
        const recognition = new SpeechRecognition();
        console.log("new speech recognition started");
        recognition.start();
        self.voiceStarted = true;
        recognition.onspeechend = function() {
          console.log("speech ended");
        };
        recognition.onend = function() {
          self.voiceStarted = false;
          self.unmute();
          console.log("Speech recognition service disconnected");
        };
        recognition.onresult = function(event) {
          //when result is available
          speechResult = event.results[0][0].transcript.toLowerCase();
          console.log(
            "speech result = " + event.results[0][0].transcript.toLowerCase()
          );
          handleSpeechResult(speechResult);
        }.bind(this);
        recognition.onnomatch = function(event) {
          //when no match, restart
          console.log("speech no match");
        };
        recognition.onerror = function(event) {
          //when error, restart
          console.log("Error occurred in recognition: " + event.error);
        };
      },
      startVoiceRecognition(continuous = false) {
        const self = this;
        if (this.voiceStarted) return;
        if (!continuous) this.playBeep();
        this.mute(); //mute video to reduce noise
        window.SpeechRecognition =
          window.webkitSpeechRecognition || window.SpeechRecognition;
        //safari doesn't support SpeechRecognition
        if(window.SpeechRecognition){
          this.startWebSpeechAPI();
        }else{
          alert("SpeechRecognition not supported for this browser.")
          // self.startRecording();
          // setTimeout(() => {
          //   self.stopRecording();
          // }, self.recordingTimeout);
        }
      },
      startRecording () {
        this.recorderSrvc.config.stopTracksAndCloseCtxWhenFinished = true;
        this.recorderSrvc.config.manualEncoderId === 'ogg'; //use ogg encoder;not sure if this actually produces ogg format; it seems to be wav file
        this.recorderSrvc.startRecording()
          .then(() => {
            this.recordingInProgress = true;
          })
          .catch((error) => {
            console.error('Exception while start recording: ' + error)
            loggingError(error);
            alert('Exception while start recording: ' + error.message)
          })
      },
      stopRecording () {
        this.recorderSrvc.stopRecording();
        this.recordingInProgress = false;
      },
      //called when the recording is ready
      onNewRecording (evt) {
        const self = this;
        // this.audioBlob = evt.detail.recording.blobUrl;
        //fetch the blob file and then convert to base64
        fetch(evt.detail.recording.blobUrl).then(res => res.blob())
          .then(function (blob) {
              // let blob = new Blob([wav], {type: "audio/wav"});
              //use FileReader to convert blob into base64
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function() {
                  const base64Data = reader.result;
                  //speech to text using base64 audio
                  self.getTextFromSpeech(base64Data.slice(base64Data.indexOf(",")+1))
                  .then((speechResult)=>{
                    self.handleSpeechResult(speechResult);
                  });
              }
          });
      },
      getTextFromSpeech(base64Data){
        return new Promise(function(resolve, reject){
          const config = {
            // encoding: "OGG_OPUS",
            // sampleRateHertz: 16000,
            languageCode: "en-US",
          };
          const audio = {
            content: base64Data,
          };
          const request = {
            config: config,
            audio: audio,
          };
          let successCallback = function(data) {
            const transcription = data.results[0].alternatives[0].transcript;
            resolve(transcription);
          };
        //disabled using speech api from the frontend
        // [CODE_HIDDEN_BEFORE] jquery is deprecated library. if you want to reopen this code, please change $.ajax to axios.
          // $.ajax({
          //   type: "POST",
          //   contentType: "application/json",
          //   url:
          //     "https://speech.googleapis.com/v1/speech:recognize?key=AIzaSyC9KXuphDmhp4327yW_li4w8xFieH5899Q",
          //   data: JSON.stringify(request),
          //   success: successCallback
          // });
        })
      },
      handleSpeechResult(speechResult){
        //must make references here; inside speechRecogition cannot access vue component methods
        const commands = {
          play: this.playVideo,
          pause: this.pauseVideo,
          next: this.playNextStep,
          repeat: this.repeat,
          stephanie: this.playBeep,
          menu: this.openMenu,
          mute: this.mute
        };
        const showMeHowTo = this.showMeHowTo;
        const showMe = this.showMe;
        const goBack = this.goBack;
        const speakIn = this.speakIn;
        const unmute = this.unmute;
        if (commands.hasOwnProperty(speechResult)) {
          //execute commands if it's found
          let command = commands[speechResult];
          command();
          if (speechResult != "stephanie") unmute();
        } else {
        //detect show me command
        let arr = speechResult.split(" ");
        if (arr[0] === "show" && arr[1] === "me") {
          if (arr[2] === "how" && arr[3] === "to") {
            showMeHowTo(arr.slice(4).join(" "));
            unmute();
          } else {
             // let tag = arr[2];
              let tag = arr.slice(2).join(" ");
              showMe(tag);
              unmute();
            }
          } else if (arr[0] === "go" && arr[1] === "back") {
            goBack();
            unmute();
          } else if (arr[0] === "speak") {
            let language = arr[1];
            speakIn(language);
            unmute();
          }
        }
      }
    },
    computed: {

    }
}
</script>

<style scoped>
.player-voice-assistant {
}
</style>
