import store from "@/store";
import Vue from "vue";

const getAllTypesWorkspaceGroups = async () => {
  store.dispatch("workspaceGroups/getWorkspaceGroupList", { workspacesType: "admin" });
  store.dispatch("workspaceGroups/getWorkspaceGroupList", { workspacesType: "edit" });
  store.dispatch("workspaceGroups/getWorkspaceGroupList", { workspacesType: "view" });
};

const sortWorkspaceGroups = ({ items, isView }) => {
  sortWorkspaceGroupsAndWorkspaces(items);
  if (isView) {
    sortViewWorkspaces(items);
  }
  moveDefaultWorkspaceGroupToTop(items);
};

const moveDefaultWorkspaceGroupToTop = (items) => {
  const defaultWorkspaceGroupId = Vue.prototype.$defaultWorkspaceGroupId;
  const index = items.findIndex((item) => item.id === defaultWorkspaceGroupId);
  if (index !== -1) {
    const defaultItem = items.splice(index, 1)[0];
    items.unshift(defaultItem);
  }
};

const sortWorkspaceGroupsAndWorkspaces = (items) => {
  const sortRule = (first, second) => {
    const getType = (char) => {
      if (/[^a-zA-Z0-9]/.test(char)) {
        return "symbol";
      } else if (/[0-9]/.test(char)) {
        return "digit";
      } else {
        return "letter";
      }
    };

    const firstName = first.name.trim().toLowerCase();
    const secondName = second.name.trim().toLowerCase();

    const firstType = getType(firstName.charAt(0));
    const secondType = getType(secondName.charAt(0));

    if (firstType === secondType) {
      return firstName.localeCompare(secondName);
    }
    // symbols > digits > letters
    if (firstType === "symbol") {
      return -1;
    }
    if (secondType === "symbol") {
      return 1;
    }
    if (firstType === "digit") {
      return -1;
    }
    if (secondType === "digit") {
      return 1;
    }
    return firstName.localeCompare(secondName);
  };

  items.sort(sortRule);
  items.forEach((item) => {
    if (item.workspaces && item.workspaces.length > 0) {
      item.workspaces.sort(sortRule);
    }
  });
};

const sortViewWorkspaces = (items) => {
  // isInThisGroup > isLike > others
  const sortRule = (first, second) => {
    if (first.isInThisGroup && !second.isInThisGroup) {
      return -1;
    } else if (!first.isInThisGroup && second.isInThisGroup) {
      return 1;
    }
    if (first.isLike && !second.isLike) {
      return -1;
    } else if (!first.isLike && second.isLike) {
      return 1;
    }
  };
  items.map((item) => {
    if (item.workspaces && item.workspaces.length > 0) {
      item.workspaces.sort(sortRule);
    }
  });
};

export { getAllTypesWorkspaceGroups, sortWorkspaceGroups };
