<template>
  <div
    class="DPlayerSettingItem"
    :class="{ 'DPlayerSettingItem--canClick': canClick }"
    @click="canClick ? $emit('trigger-setting', value) : null"
  >
    <section class="DPlayerSettingItem__labelContainer">
      <icon-base color="#ffffff" height="20" width="20">
        <slot name="icon">icon</slot>
      </icon-base>
      <span class="DPlayerSettingItem__label">{{ label }}</span>
    </section>
    <section class="DPlayerSettingItem__featureContainer">
      <slot name="feature">
        <span class="DPlayerSettingItem__currentText">{{ currentStatesText }}</span>
        <icon-base class="DPlayerSettingItem__forwardBtn" color="#ffffff" height="14" width="14">
          <d-icon-arrow-forward />
        </icon-base>
      </slot>
    </section>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconArrowForward from "@/components/icons/DIconArrowForward.vue";
export default {
  name: "DPlayerSettingItem",
  components: {
    IconBase,
    DIconArrowForward,
  },
  props: {
    canClick: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    currentStatesText: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.DPlayerSettingItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  padding: 10px 10px;
  &:hover {
    background: #28292e;
  }
  &--canClick {
    cursor: pointer;
  }
  &__labelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #ffffff;
  }
  &__label {
    margin-left: 17px;
    font-weight: 500;
  }
  &__featureContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__currentText {
    margin-right: 17px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #9397a6;
  }
}
</style>
