import adminServer from "./admin-server";
import authChoice from "../../auth";

const config = {
  token: "rG5kXk0CDbhgF4RBlNoV",
};

export async function downgradeWithOrgAdminRole({ userData }) {
  const idToken = await authChoice().currentUser.getIdToken();
  const requestBody = {
    token: config.token,
    action: "change",
    type: "org-admin-downgrade",
    data: {
      idToken,
      organization: userData.organization,
      email: userData.email,
      role: userData.role,
    },
  };
  try {
    const { data } = await adminServer.post("admin", requestBody);
    return data;
  } catch (e) {
    console.error(e);
  }
}
