import { render, staticRenderFns } from "./BuilderAnnotationTimeline.vue?vue&type=template&id=159ebccb&scoped=true&"
import script from "./BuilderAnnotationTimeline.vue?vue&type=script&lang=js&"
export * from "./BuilderAnnotationTimeline.vue?vue&type=script&lang=js&"
import style0 from "./BuilderAnnotationTimeline.vue?vue&type=style&index=0&id=159ebccb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159ebccb",
  null
  
)

export default component.exports