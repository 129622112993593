import { HTTP_CODE } from "@/constants/http-code";
export const IS_IP_PASSED = "isIpPassed";
export const setIsIpPassedLocalStorage = (isPass = false) => {
  localStorage.setItem(IS_IP_PASSED, isPass);
};

export const getIsIpPassedLocalStorage = () => {
  return localStorage.getItem(IS_IP_PASSED) === "true";
};

export const checkCodeIsNotAllowIp = (code) => code === HTTP_CODE.NOT_ALLOWED_IP;
