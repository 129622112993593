<template>
  <div
    class="builderViewer fullscreen-center"
    :style="{ width: videoWidthViewer + 'px', height: videoHeightViewer + 'px' }"
  >
    <video
      ref="videoPlayer"
      class="video-player"
      crossorigin="anonymous"
      :poster="displayPoster"
      :src="videoSource"
      @ended="handlePauseVideo"
      @waiting="showSpinner"
      @canplay="hideSpinner"
      @loadeddata="handlePlayerLoadedData"
      @timeupdate="handlePlayerTimeUpdate"
    />
    <player-controls
      :iconSize="110"
      :isSD="isSD"
      :isLoading="isLoading || isQualitySwitching"
      :isPlaying="isPlaying"
      :hasSD="hasSD"
      :videoCurrentTime="videoCurrentTime"
      :videoDuration="videoDuration"
      :playerSpeed="playerSpeed"
      @pauseVideo="handlePauseVideo"
      @playVideo="handlePlayVideo"
      @setIsSD="handleSetIsSD"
      @setPlayerSpeed="handleSetPlayerSpeed"
      @setVideoCurrentTime="handleSetVideoCurrentTime"
    />
  </div>
</template>

<script>
import PlayerControls from "@/components/DEditorBuilder/PlayerControls";
import { checkIsSignedUrl, getSignedUrlByRez, getSignedURL } from "@/server/sign-server.js";
export default {
  name: "BuilderViewer",
  props: {
    isStepReady: Boolean,
    poster: String,
    signedSrc: Object,
    videoWidthViewer: Number,
    videoHeightViewer: Number,
  },
  mounted() {},
  data() {
    return {
      signedPoster: "",
      hasSD: true,
      isSD: true,
      isQualitySwitching: false,
      isPlaying: false,
      isLoading: true,
      playerSpeed: 1,
      videoPreviousTime: 0,
      videoCurrentTime: 0,
      videoDuration: 0,
      videoSource: "",
      videoSDUrl: "",
      videoHDUrl: "",
    };
  },
  components: {
    PlayerControls,
  },
  computed: {
    displayPoster() {
      return this.videoCurrentTime === 0 ? this.signedPoster : "";
    },
  },
  methods: {
    showSpinner() {
      this.isLoading = true;
    },
    hideSpinner() {
      this.isLoading = false;
    },
    handlePauseVideo() {
      if (this.isPlaying) {
        this.isPlaying = false;
        this.$refs.videoPlayer.pause();
      }
    },
    handlePlayVideo() {
      if (!this.isPlaying) {
        this.isPlaying = true;
        this.$refs.videoPlayer.play();
      }
    },
    handlePlayerTimeUpdate() {
      if (!this.$refs.videoPlayer || this.isQualitySwitching) return;
      this.videoCurrentTime = this.$refs.videoPlayer.currentTime;
    },
    handlePlayerLoadedData() {
      this.videoDuration = this.$refs.videoPlayer.duration;
      this.reInitialPlayerStatusWhenVideoLoaded();
    },
    handleSetVideoCurrentTime(videoCurrentTime) {
      this.videoCurrentTime = videoCurrentTime;
      this.$refs.videoPlayer.currentTime = videoCurrentTime;
    },
    async getSDSignedUrl(signedSrc) {
      if (!this.isStepReady) {
        this.hasSD = false;
        return;
      }
      const { ok, url } = await getSignedUrlByRez("step", signedSrc.id, "360");
      this.hasSD = ok;
      this.videoSDUrl = ok ? url : "";
    },
    async getHDSignedUrl(signedSrc) {
      if (!this.isStepReady) {
        return;
      }
      const { ok, url } = await getSignedUrlByRez("step", signedSrc.id, "1080");
      this.videoHDUrl = ok ? url : "";
    },
    async setupVideoUrlWhenSourceChanged(signedSrc) {
      this.videoSDUrl = "";
      this.videoHDUrl = "";
      await this.getSDSignedUrl(signedSrc);
      await this.setVideoSource();
    },
    async setVideoSource() {
      if (this.isSD && this.hasSD) {
        this.videoSource = this.videoSDUrl;
        return;
      }
      if (!this.videoHDUrl) {
        await this.getHDSignedUrl(this.signedSrc);
      }
      if (this.videoHDUrl || this.videoSDUrl) {
        this.videoSource = this.videoHDUrl || this.videoSDUrl;
        return;
      }

      // prevent step.videos is not ready.
      const { notTranscodedVideo } = this.signedSrc;
      const isSigned = checkIsSignedUrl(notTranscodedVideo);
      if (isSigned) {
        this.videoSource = notTranscodedVideo;
        return;
      }
      this.videoSource = await getSignedURL({ src: notTranscodedVideo });
    },
    reInitialPlayerStatusWhenVideoLoaded() {
      this.$refs.videoPlayer.playbackRate = this.playerSpeed;
      if (!this.isQualitySwitching) return;
      this.isQualitySwitching = false;
      if (this.videoPreviousTime !== 0) {
        this.handleSetVideoCurrentTime(this.videoPreviousTime);
        this.videoPreviousTime = 0;
      }
      if (this.isPlayingBeforeSwitchQuality) {
        this.handlePlayVideo();
      }
    },
    async handleSetIsSD({ value }) {
      this.isQualitySwitching = true;
      this.isPlayingBeforeSwitchQuality = this.isPlaying;
      if (this.isPlayingBeforeSwitchQuality) {
        this.handlePauseVideo();
      }
      this.videoPreviousTime = this.videoCurrentTime;
      this.isSD = value;
      await this.setVideoSource();
    },
    handleSetPlayerSpeed({ value }) {
      this.playerSpeed = value;
      this.$refs.videoPlayer.playbackRate = value;
    },
  },
  watch: {
    isStepReady: {
      handler(isStepReady) {
        if (isStepReady) {
          this.setupVideoUrlWhenSourceChanged(this.signedSrc);
        }
      },
    },
    "signedSrc.id": {
      immediate: true,
      handler(newStepId, oldStepId) {
        if (newStepId && oldStepId !== newStepId) {
          this.setupVideoUrlWhenSourceChanged(this.signedSrc);
        }
      },
    },
    poster: {
      immediate: true,
      async handler(poster) {
        const isSigned = checkIsSignedUrl(poster);
        this.signedPoster = isSigned ? poster : await getSignedURL({ src: poster });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.builderViewer {
  --bar-height: 50px;
  position: relative;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  .video-player {
    object-fit: contain;
    height: 100%;
    max-width: 100%;
    background-color: #0c0c0e;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  }
}
</style>
