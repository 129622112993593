import dbapi from './src/db/dbApi.js';
import environment from "./env.js"

const env = environment.env;
const databaseType = environment.databaseType;

function isDatabaseApi() {
  return ['mongoDB'].includes(databaseType);
}

const database = {
    deleteDocument: function (collection, documentId) {
      if (isDatabaseApi()) {
        //console.log('dbapi::deleteDocument');
        return dbapi.deleteDocument(collection, documentId);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).doc(documentId).delete()
            .then(function () {
              console.log("Document successfully deleted!");
              resolve();
            }.bind(this))
            .catch(function (error) {
              console.error("Error removing document: ", error);
              reject(error);
            });
        }.bind(this));
      }
    },
    duplicateDocument: function (collection, documentId) {
      if (isDatabaseApi()) {
        //console.log('dbapi::duplicateDocument');
        return dbapi.duplicateDocument(collection, documentId);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).doc(documentId).get()
            .then(function (data) {
              const newDocumentId = this.$db.collection(collection).doc().id;
              let newData = data.data();
              newData["id"] = newDocumentId;
              this.addDocument(collection, newDocumentId, newData).then(function () {
                resolve(newDocumentId);
              })
            }.bind(this))
            .catch(function (error) {
              reject(error);
            });
        }.bind(this));
      }
    },
    duplicateDocumentToOrg: function (collection, documentId, organization) {
      if (isDatabaseApi()) {
        //console.log('dbapi::duplicateDocumentToOrg');
        return dbapi.duplicateDocumentToOrg(collection, documentId, organization);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).doc(documentId).get()
            .then(function (data) {
              const newDocumentId = this.$db.collection(collection).doc().id;
              let newData = data.data();
              newData["id"] = newDocumentId;
              newData["organization"] = organization
              this.addDocument(collection, newDocumentId, newData).then(function () {
                resolve();
              })
            }.bind(this))
            .catch(function (error) {
              reject(error);
            });
        }.bind(this));
      }
    },
    //
  //   getDocumentByWhere: function (collection, whereArray, limit) {
  //     const promiseTemplate1 = `return new Promise(function(resolve, reject){`
  //     const collectionTemplate = `this.$db.collection("${collection}")`;
  //     let whereTemplate = "";
  //     let hasOrganization = false;
  //     whereArray.forEach(where => {
  //       whereTemplate += `.where("${where.field}","${where.comparison}","${where.value}")`;
  //       if (where.field == "organization") {
  //         hasOrganization = true;
  //       }
  //     });
  //     if (!hasOrganization) {
  //       whereTemplate += `.where("organization", "==", "${this.$organization}")`;
  //     }
  //     if (limit) {
  //       whereTemplate += `.limit(${limit}`;
  //     }
  //     const thenTemplate = `.get().then(function(querySnapshot) {
  //                   let docuemnts = [];
  //                   querySnapshot.forEach(function(doc) {
  //                       docuemnts.push(doc.data());
  //                   });
  //                   resolve(docuemnts);
  //               }).catch(function(error) {
  //                   reject(error);
  //               });`
  //     const promiseTemplate2 = `}.bind(this));`;
  //     const query = promiseTemplate1 + collectionTemplate + whereTemplate + thenTemplate + promiseTemplate2;
  //     return Function(query).bind(this)();
  //   },
    getDocumentByQuery: function (collection, comparisons, methods) {
      if (isDatabaseApi()) {
        //console.log('dbapi::getDocumentByQuery');
        return dbapi.getDocumentByQuery(collection, comparisons, methods, this.$organization);
      } else {
        const promiseTemplate1 = `return new Promise(function(resolve, reject){`
        const collectionTemplate = `this.$db.collection("${collection}")`;
        let whereTemplate = "";
        let hasOrganization = false;
        let valueIn = [];
        comparisons.forEach(where => {
          if(typeof where.value === "boolean"){
            whereTemplate += `.where("${where.field}","${where.comparison}",${where.value})`;
          }else if(where.comparison === "in"){
            valueIn = where.value;
            whereTemplate += `.where("${where.field}","${where.comparison}", this.valueIn)`;
          }else{
            whereTemplate += `.where("${where.field}","${where.comparison}","${where.value}")`;
          }
          
          if (where.field == "organization") {
            hasOrganization = true;
          }
        });
        if (!hasOrganization) {
          whereTemplate += `.where("organization", "==", "${this.$organization}")`;
        }
        if (methods) {
          methods.forEach(method => {
            whereTemplate += `.${method.type}(${method.value})`
          })
        }
        const thenTemplate = `.get().then(function(querySnapshot) {
                      let docuemnts = [];
                      querySnapshot.forEach(function(doc) {
                          docuemnts.push(doc.data());
                      });
                      resolve(docuemnts);
                  }).catch(function(error) {
                      reject(error);
                  });`
        const promiseTemplate2 = `}.bind(this));`;
        const query = promiseTemplate1 + collectionTemplate + whereTemplate + thenTemplate + promiseTemplate2;
        // debugger
        this.valueIn = valueIn;
        return Function(query).bind(this)();
      }
    },
    //getDocumentByQuery2 allows startAfter and returns the original firestore doc reference; use this for pagination or infinte scrolling
    getDocumentByQuery2: function (collection, comparisons, methods, callback) {
      if (isDatabaseApi()) {
        //console.log('dbapi::getDocumentByQuery2');
        return dbapi.getDocumentByQuery2(collection, comparisons, methods, callback, this.$organization);
      } else {
        let whereTemplate = "";
        let startAt = "";
        let startAfter = "";
        let hasOrganization = false;
        //must pick up call back thru arguments forced by .call
        const argumentsTemplate =
          `
            const callback = arguments[0]; 
            let startAt; 
            let startAfter;
            if(arguments[1] && arguments[1].startAt){
                startAt = arguments[1].startAt;
            }
            if(arguments[1] && arguments[1].startAfter){
                startAfter = arguments[1].startAfter;
            }        
            `;
        const collectionTemplate =
          `const query = this.$db.collection("${collection}")`;
        comparisons.forEach(where => {
          if(typeof where.value === "boolean"){
            whereTemplate += `.where("${where.field}","${where.comparison}",${where.value})`;
          }else{
            whereTemplate += `.where("${where.field}","${where.comparison}","${where.value}")`;
          }
          if (where.field == "organization") {
            hasOrganization = true;
          }
        });
        if (!hasOrganization) {
          whereTemplate += `.where("organization", "==", "${this.$organization}")`;
        }
        if (methods) {
          methods.forEach(method => {
            if (method.type == "startAt") {
              whereTemplate += `.startAfter(startAt)`;
              startAt = method.value;
            } else if (method.type == "startAfter") {
              // debugger
              whereTemplate += `.startAfter(startAfter)`;
              startAfter = method.value;
            } else {
              whereTemplate += `.${method.type}(${method.value})`;
            }
    
          })
        }
        const thenTemplate = `.get().then(function (querySnapshot) {
                callback(querySnapshot);
            }.bind(this));
            return query;`
        const query = argumentsTemplate + collectionTemplate + whereTemplate + thenTemplate;
        // debugger
        const func = Function(query).bind(this);
        return func.call(this, callback, {
          startAt: startAt,
          startAfter: startAfter
        }); //callback must be forced into func thru .call
      }
    },    
    getDocumentListener: function (collection, documentId, callback) {
      if (isDatabaseApi()) {
        //console.log('dbapi::getDocumentListener');
        return dbapi.getDocumentListener(collection, documentId, callback);
      } else {
        const listener = this.$db.collection(collection).doc(documentId)
          .onSnapshot(function (doc) {
            callback(doc);
          });
        return listener;
      }
    },
    getDocumentByFieldValue: function (collection, field, value) {
      if (isDatabaseApi()) {
        //console.log('dbapi::getDocumentByFieldValue');
        return dbapi.getDocumentByFieldValue(collection, field, value, this.$organization);
      } else {
        return new Promise(function (resolve, reject) {
          if (field == "organization") {
            this.$db.collection(collection)
              .where(field, "==", value)
              // .where("organization", "==", this.$organization)
              .get()
              .then(function (querySnapshot) {
                // debugger
                if (querySnapshot.metadata.fromCache) {
                  throw "Connection to database failed."
                }
                let docuemnts = [];
                querySnapshot.forEach(function (doc) {
                  docuemnts.push(doc.data());
                });
                resolve(docuemnts);
              }).catch(function (error) {
                // debugger
                reject(error);
              });
          } else {
            this.$db.collection(collection)
              .where(field, "==", value)
              .where("organization", "==", this.$organization)
              .get()
              .then(function (querySnapshot) {
                // debugger
                if (querySnapshot.metadata.fromCache) {
                  throw "Connection to database failed."
                }
                let docuemnts = [];
                querySnapshot.forEach(function (doc) {
                  docuemnts.push(doc.data());
                });
                resolve(docuemnts);
              }).catch(function (error) {
                // debugger
                reject(error);
              });
          } 
        }.bind(this));
      }
    },
    getDocumentByArrayElement: function (collection, field, element) {
      if (isDatabaseApi()) {
        //console.log('dbapi::getDocumentByArrayElement');
        return dbapi.getDocumentByArrayElement(collection, field, element, this.$organization);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection)
            .where(field, "array-contains", element)
            .where("organization", "==", this.$organization).get()
            .then(function (querySnapshot) {
              let docuemnts = [];
              querySnapshot.forEach(function (doc) {
                docuemnts.push(doc.data());
              });
              resolve(docuemnts);
            }).catch(function (error) {
              reject(error);
            });
        }.bind(this));
      }
    },
    getAllDocument: function (collection) {
      if (isDatabaseApi()) {
        //console.log('dbapi::getAllDocument');
        return dbapi.getAllDocument(collection);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).get()
            .then(function (querySnapshot) {
              let docuemnts = [];
              querySnapshot.forEach(function (doc) {
                docuemnts.push(doc.data());
              });
              resolve(docuemnts);
            }).catch(function (error) {
              reject(error);
            });
        }.bind(this));
      }
    },
    addDocument: function (collection, documentId, data) {
      if (isDatabaseApi()) {
        //console.log('dbapi::upsertDocument');
        return dbapi.upsertDocument(collection, documentId, data);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).doc(documentId).set(data, {
              merge: true
            })
            .then(function () {
              resolve();
            })
            .catch(function (error) {
              reject(error);
            });
        }.bind(this));
      }
    },
    updateDocument: function (collection, documentId, data) {
      if (isDatabaseApi()) {
        //console.log('dbapi::updateDocument');
        return dbapi.updateDocument(collection, documentId, data);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).doc(documentId).update(data)
            .then(function () {
              resolve();
            })
            .catch(function (error) {
              reject(error);
            });
        }.bind(this));
      }
    },
    updateDocumentArrayField: function (collection, documentId, data) {
      // @todo To be added for China env
      if (isDatabaseApi()) {
        //console.log('dbapi::updateDocument');
        return;
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).doc(documentId).update({[data["documentField"]]: this.$firebase.firestore.FieldValue.arrayUnion(data["fieldValue"])})
          .then(function () {
            resolve();
          })
          .catch(function (error) {
            reject(error);
          });
        }.bind(this));
      }
    },
    generateDocId: function (collection) {
      if (isDatabaseApi()) {
        //console.log('dbapi::generateDocId');
        return dbapi.generateDocId(collection);
      } else {
        return this.$db.collection(collection).doc().id;
      }
    },
    setNestedObject: function(collection, documentId, data){
      if (isDatabaseApi()) {
        // console.log('dbapi::updateMap');
        // return dbapi.updateMap(collection, documentId, data);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).doc(documentId).set({
              data
            }, {merge: true})
            .then(function () {
              resolve();
            })
            .catch(function (error) {
              reject(error);
            });
        }.bind(this));
      }      
    },
    updateMap: function (collection, documentId, data) {
      if (isDatabaseApi()) {
        //console.log('dbapi::updateMap');
        return dbapi.updateMap(collection, documentId, data);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).doc(documentId).update({
              ["transcriptions" + "." + data.language]: data.id
            })
            .then(function () {
              resolve();
            })
            .catch(function (error) {
              reject(error);
            });
        }.bind(this));
      }
    },
    getDocument: function (collection, documentId) {
      if (isDatabaseApi()) {
        //console.log('dbapi::getDocument');
        return dbapi.getDocument(collection, documentId);
      } else {
        return new Promise(function (resolve, reject) {
          this.$db.collection(collection).doc(documentId).get()
            .then(function (data) {
              resolve(data.data());
            })
            .catch(function (error) {
              reject(error);
            });
        }.bind(this))
      }
    },
    getDocumentQueryListener: function (collection, comparisons, methods, callback) {
      if (isDatabaseApi()) {
        return dbapi.getDocumentQueryListener(collection, comparisons, methods, callback);
      } else {      
        let whereTemplate = "";
        let startAt = "";
        let startAfter = "";
        let date;
        let hasOrganization = false;
        let arrayContainsAny = [];
        //must pick up call back thru arguments forced by .call
        const argumentsTemplate =
          `
            const callback = arguments[0]; 
            let startAt; 
            let startAfter;
            let arrayContainsAny;
            if(arguments[1] && arguments[1].arrayContainsAny){
              arrayContainsAny = arguments[1].arrayContainsAny;
            }
            if(arguments[1] && arguments[1].startAt){
                startAt = arguments[1].startAt;
            }
            if(arguments[1] && arguments[1].startAfter){
                startAfter = arguments[1].startAfter;
            }
            
            if(arguments[1] && arguments[1].date){
              date = arguments[1].date;
            }          
            `;
        const collectionTemplate =
          `const listener = this.$db.collection("${collection}")`;
        comparisons.forEach(where => {
          //Date type must be handled differently
          if((where.value instanceof Date === true)){
            whereTemplate += `.where("${where.field}","${where.comparison}",date)`
            date = where.value;
          }else if(typeof where.value === "boolean"){
              whereTemplate += `.where("${where.field}","${where.comparison}",${where.value})`;
          }else if(where.comparison == "array-contains-any"){
            arrayContainsAny = where.value;
            whereTemplate += `.where("${where.field}","${where.comparison}",arrayContainsAny)`;
          }else{
              whereTemplate += `.where("${where.field}","${where.comparison}","${where.value}")`;
          }

          if (where.field == "organization") {
            hasOrganization = true;
          }
        });
        if (!hasOrganization) {
          whereTemplate += `.where("organization", "==", "${this.$organization}")`;
        }
        if (methods) {
          methods.forEach(method => {
            if (method.type == "startAt") {
              whereTemplate += `.startAfter(startAt)`;
              startAt = method.value;
            } else if (method.type == "startAfter") {
              // debugger
              whereTemplate += `.startAfter(startAfter)`;
              startAfter = method.value;
            } else {
              whereTemplate += `.${method.type}(${method.value})`;
            }
    
          })
        }
        const thenTemplate = `.onSnapshot(function (querySnapshot) {
                callback(querySnapshot);
            }.bind(this));
            return listener;`
        const query = argumentsTemplate + collectionTemplate + whereTemplate + thenTemplate;
        const func = Function(query).bind(this);
        return func.call(this, callback, {
          arrayContainsAny: arrayContainsAny,
          startAt: startAt,
          startAfter: startAfter,
          date: date
        }); //callback must be forced into func thru .call
      }
    },
    batchWrites: function(writes){
      if (isDatabaseApi()) {
        //console.log('dbapi::batchWrites');
        return dbapi.batchWrites(writes);
      } else {
        return new Promise(function (resolve, reject) {
          const batch = this.$db.batch();
          writes.forEach(write=>{
            const ref = this.$db.collection(write.collection).doc(write.id);
            if(write.type == "update"){
              batch.update(ref, write.data);
            }else if(write.type == "set"){
              batch.set(ref, write.data, { merge: true });
            }else if(write.type == "delete"){
              batch.delete(ref);
            }else if(write.type == "counter"){
              const field = write.field;
              const value = parseInt(write.value); 
              batch.update(ref, {
                [field]: this.$firebase.firestore.FieldValue.increment(value)
              })
            }else if(write.type == "arrayUnion"){
              batch.update(ref, {
                [write.field]: this.$firebase.firestore.FieldValue.arrayUnion(write.value)
              })              
            }else if(write.type == "arrayRemove"){
              batch.update(ref, {
                [write.field]: this.$firebase.firestore.FieldValue.arrayRemove(write.value)
              })  
            }
          })
          batch.commit().then(function () {
            resolve();
          }).catch(err=>{
            reject(err);
          })
        }.bind(this))
      }
    },
    getServerTimestamp: function () {
      if (isDatabaseApi()) {
        //console.log('dbapi::getServerTimestamp');
        return dbapi.getServerTimestamp();
      } else {
        return new Promise(function (resolve) {
          resolve(this.$firebase.firestore.FieldValue.serverTimestamp());
        }.bind(this));
      }
    },
    updateCounter: function(collection, documentId, data) {
      const field = data.field;
      const value = parsetInt(data.value); 
      if (isDatabaseApi()) {
        return dbapi.updateDocumentCounter(collection, documentId, field, value);
      } else {
        return new Promise(function(resolve, reject) {
          this.$db.collection(collection).doc(documentId).update({
            [field]: this.$firebase.firestore.FieldValue.increment(value)
          })
            .then(function() {
              resolve();
            })
            .catch(function(error) {
              reject(error);
            });
        }.bind(this));
      }     
    },
    updateDocumentArrayUnion: function(collection, documentId, data) {
      const field = data.field;
      const value = data.value; 
      if (isDatabaseApi()) {
        return dbapi.updateDocumentArrayUnion(collection, documentId, field, value);
      } else {
        return new Promise(function(resolve, reject) {
          this.$db.collection(collection).doc(documentId).update({
            [field]: this.$firebase.firestore.FieldValue.arrayUnion(value)
          })
            .then(function() {
              resolve();
            })
            .catch(function(error) {
              reject(error);
            });
        }.bind(this));
      }
    },
    updateDocumentArrayRemove: function(collection, documentId, data) {
      const field = data.field;
      const value = data.value; 
      if (isDatabaseApi()) {
        return dbapi.updateDocumentArrayRemove(collection, documentId, field, value);
      } else {
        return new Promise(function(resolve, reject) {
          this.$db.collection(collection).doc(documentId).update({
            [field]: this.$firebase.firestore.FieldValue.arrayRemove(value)
          })
            .then(function() {
              resolve();
            })
            .catch(function(error) {
              reject(error);
            });
        }.bind(this));
      }
    }
  }
  export default database;
  
