<template>
  <section>
    <admin-setting-permission-item
      :allow="getter_name_change_enabled"
      :title="$t('admin.changeProfileName')"
      :subtitle="$t('admin.allowsChangeProfileNameMsg')"
      autotest="change-profile-name-toggle"
      @click-switch="showConfirmation = true"
    />

    <d-change-permission-confirmation
      v-if="showConfirmation"
      :title="$t('admin.changeProfileName')"
      :content="
        getter_name_change_enabled ? $t('admin.disableChangeProfileNameMsg') : $t('admin.enableChangeProfileNameMsg')
      "
      @close-popup="showConfirmation = false"
      @change-permission="updateChangeProfileName"
    ></d-change-permission-confirmation>
  </section>
</template>

<script>
import AdminSettingPermissionItem from "@/components/DAdmin/Settings/AdminSettingPermissionItem.vue";
import DChangePermissionConfirmation from "@/components/DAdmin/confirmations/DChangePermissionConfirmation.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { loggingError } from "@/server/error-log-server";

export default {
  name: "AdminSettingChangeProfileName",
  props: {},
  components: {
    AdminSettingPermissionItem,
    DChangePermissionConfirmation,
  },
  watch: {},
  created() {},
  mounted() {},
  data() {
    return {
      showConfirmation: false,
    };
  },
  computed: {
    ...mapGetters("permission", ["getter_name_change_enabled"]),
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    ...mapActions("permission", ["updateNameChangeSettings"]),
    async updateChangeProfileName() {
      this.showConfirmation = false;
      const toEnable = !this.getter_name_change_enabled;
      const updateData = {
        userPermissions: { allowNameChange: toEnable },
      };

      try {
        await this.updateNameChangeSettings({ organization: this.$organization, updateData });

        const message = toEnable
          ? this.$t("admin.alertEnableChangeProfileNameSuccess")
          : this.$t("admin.alertDisableChangeProfileNameSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } catch (error) {
        const message = toEnable
          ? this.$t("admin.alertEnableChangeProfileNameFailed")
          : this.$t("admin.alertDisableChangeProfileNameFailed");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
        loggingError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
