<template>
  <div class="annotation">
    <div class="annotation__control">
      <div
        v-for="typeKey in Object.keys(iconTypeMap)"
        :key="typeKey"
        :class="[
          'annotation__control-btn',
          {
            'annotation__control-btn--active': type === typeKey && (type === 'select' ? !!watch.selectedId : true),
          },
        ]"
        @click="switchType(typeKey, { from: 'tab' })"
      >
        <component :is="iconTypeMap[typeKey]" />
      </div>
    </div>
    <section class="annotation__section" ref="annotation">
      <canvas
        :class="['annotation__canvas', { 'annotation__canvas--hidden': isHideCanvas }]"
        ref="annotation__canvas"
        id="annotation__canvas"
      ></canvas>
      <div v-if="isPublished" class="annotation__canvas--disabled" @click="checkIsWorkflowPublished" />
      <div class="annotation__video">
        <d-video-secure
          ref="annotationVideoPlayer"
          crossorigin="anonymous"
          :poster="videoPoster"
          :signedSrc="videoSource"
          @ended="handleVideoEnd"
          @timeupdate="handleVideoOnTimeUpdate()"
          @waiting="isShowSpinner = true"
          @canplay="isShowSpinner = false"
        />
        <div class="annotation__spinner">
          <v-progress-circular v-if="isShowSpinner || !isFontReady" :size="70" color="white" indeterminate />
        </div>
      </div>
      <div class="annotation__time-line">
        <builder-annotation-timeline
          :currentTime="currentTime"
          :selectedId="watch.selectedId"
          :videoEndTime="videoEndTime"
          :sortedDataArray="sortedDataArray"
          :isHideCanvas="isHideCanvas"
          :isPlaying="isVideoPlaying"
          :isPublished="isPublished"
          @handleNoteSwitch="handleNoteSwitch"
          @handleNoteSelect="handleNoteSelect"
          @handleNoteTimeUpdate="mutateFabricAndVuexData"
          @handleVideoCurrentTimeUpdate="handleVideoCurrentTimeUpdate"
          @handleVideoPlayOrPause="handleVideoPlayOrPause"
        />
      </div>
      <div v-if="selectedNote" class="annotation__target-tool" v-bind="v_bind_annotation_tool">
        <BuilderAnnotationTool
          :config="selectedNote"
          :currentStepId="currentStepId"
          @handleNoteStyleChange="mutateFabricAndVuexData"
        />
      </div>
    </section>
    <div class="annotation__notes">
      <builder-annotation-notes
        :isPublished="isPublished"
        :selectedId="watch.selectedId"
        :sortedDataArray="sortedDataArray"
        :videoEndTime="videoEndTime"
        @handleNoteDelete="handleNoteDelete"
        @handleNoteSelect="handleNoteSelect"
        @handleNoteTimeUpdate="mutateFabricAndVuexData"
      />
    </div>
  </div>
</template>

<script>
import BuilderAnnotationNotes from "./BuilderAnnotationNotes";
import BuilderAnnotationTimeline from "./BuilderAnnotationTimeline";
import BuilderAnnotationTool from "./BuilderAnnotationTool";
import DIconArrow from "@/components/icons/annotation/DIconArrow.vue";
import DIconCursor from "@/components/icons/annotation/DIconCursor.vue";
import DIconLine from "@/components/icons/annotation/DIconCursor.vue";
import DIconPen from "@/components/icons/annotation/DIconPen.vue";
import DIconRectangle from "@/components/icons/annotation/DIconRectangle.vue";
import DIconSticker from "@/components/icons/annotation/DIconSticker.vue";
import DIconText from "@/components/icons/annotation/DIconText.vue";
import DVideoSecure from "@/components/ui_components/DVideoSecure.vue";

import { AnnotationFabric } from "@/js/annotation/AnnotationFabric";
import { ANNOTATION_TYPE, ANNOTATION_DB_FABRIC_KEY_MAP } from "../../constants/annotationStatus";
import { mapState, mapMutations } from "vuex";
import { debounce } from "lodash-es";
import { checkNotoSerifFontFamilyLoaded } from "@/js/annotation/AnnotationUtils";

export default {
  name: "BuilderAnnotation",
  props: {
    videoEndTime: Number,
    videoPoster: String,
    videoSource: Object,
    currentStepId: {
      type: String,
      default: () => "",
    },
    madeChanges: {
      type: Boolean,
      required: true,
    },
    isPublished: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    const annotationConfig = {
      type: ANNOTATION_TYPE.SELECT,
    };
    const playerConfig = {
      currentTime: 0,
      isHideCanvas: false,
      isShowSpinner: true,
      isVideoPlaying: false,
    };
    const toolStyle = {
      top: 0,
      left: 0,
      display: "none",
    };

    return {
      watch: {
        selectedId: null,
      },
      ...annotationConfig,
      ...playerConfig,
      isFontReady: false,
      toolStyle,
      iconTypeMap: {
        [ANNOTATION_TYPE.SELECT]: "DIconCursor",
        [ANNOTATION_TYPE.TEXT]: "DIconText",
        [ANNOTATION_TYPE.PEN]: "DIconPen",
        [ANNOTATION_TYPE.ARROW]: "DIconArrow",
        // NOT_SUPPORT_YET
        // [ANNOTATION_TYPE.LINE]: 'DIconLine',
        // [ANNOTATION_TYPE.CIRCLE]: 'DIconRectangle',
        // [ANNOTATION_TYPE.STICKER]: 'DIconSticker',
      },
      dataArray: [],
      AnnotationFabric: null,
    };
  },
  components: {
    BuilderAnnotationNotes,
    BuilderAnnotationTimeline,
    BuilderAnnotationTool,
    DIconArrow,
    DIconCursor,
    DIconLine,
    DIconPen,
    DIconRectangle,
    DIconSticker,
    DIconText,
    DVideoSecure,
  },
  async mounted() {
    document.addEventListener("keydown", this.handleKeyboard);
    this.AnnotationFabric = new AnnotationFabric({
      canvasId: "annotation__canvas",
      sourceCanvas: this.$refs["annotation__canvas"],
      videoEndTime: this.videoEndTime,
      watch: this.watch,
      isHideCanvas: this.isHideCanvas,
    });
    this.AnnotationFabric.Fabric.on("object:added", this.handleAddDataIntoDataArray);
    this.AnnotationFabric.Fabric.on("object:modified", this.handleObjectChange);
    this.AnnotationFabric.Fabric.on("text:changed", debounce(this.handleObjectChange, 500));
    this.AnnotationFabric.Fabric.on("selection:cleared", this.handleEditorToolClear);
    this.AnnotationFabric.Fabric.on("selection:created", this.handleEditorToolPopup);
    this.AnnotationFabric.Fabric.on("selection:updated", this.handleEditorToolPopup);
    this.AnnotationFabric.Fabric.on("object:moving", this.handleEditorToolPopup);

    this.isFontReady = await checkNotoSerifFontFamilyLoaded();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyboard);
  },
  computed: {
    ...mapState("annotation", ["annotations"]),
    selectedNote() {
      const data = this.sortedDataArray.find(({ id }) => id === this.watch.selectedId);
      return data;
    },
    sortedDataArray() {
      return this.dataArray.sort((a, b) => a.startTime - b.startTime);
    },
    v_bind_annotation_tool() {
      return {
        style: {
          top: this.toolStyle.top + "px",
          left: this.toolStyle.left + "px",
          position: "absolute",
          display: this.toolStyle.display,
          "z-index": 30,
        },
      };
    },
  },
  methods: {
    ...mapMutations("annotation", ["ADD_ANNOTATIONS", "DELETE_ANNOTATION", "MUTATE_ANNOTATION_PROPERTY"]),
    checkIsWorkflowPublished() {
      if (this.isPublished) {
        this.$emit("showEditWorkflowPopup");
      }
      return this.isPublished;
    },
    switchType(type, { from, lineWidth, color } = {}) {
      if (this.checkIsWorkflowPublished()) return;
      if (this.isHideCanvas) this.handleNoteSwitch();
      this.type = type;
      this.AnnotationFabric.toggleDrawMode(type === ANNOTATION_TYPE.PEN);
      this.AnnotationFabric[type]({ lineWidth, color });
      if (from === "tab" && type === ANNOTATION_TYPE.SELECT) {
        this.AnnotationFabric.unselect();
      }
    },
    handleEditorToolClear() {
      this.$nextTick(() => {
        this.toolStyle.display = "none";
        this.watch.selectedId = null;
      });
    },
    handleEditorToolPopup(event) {
      const supportPopupType = [ANNOTATION_TYPE.ARROW, ANNOTATION_TYPE.PEN, ANNOTATION_TYPE.TEXT];
      let target = null;
      if (event.selected) {
        for (let i = 0; i < event.selected.length; i++) {
          const object = event.selected[i];
          if (supportPopupType.includes(object.type)) {
            target = object;
          }
        }
      } else if (event.target && supportPopupType.includes(event.target.type)) {
        target = event.target;
      }

      if (!target) {
        this.display = "none";
        return;
      }

      const { top, left } = target;
      // tool style
      this.toolStyle.top = top - 48 - 8; // space: 8, toolHeight: 48;
      this.toolStyle.left = left;
      this.toolStyle.display = "block";

      this.watch.selectedId = target.id;
    },
    handleAddDataIntoDataArray(data) {
      if (data.target.isRedraw || data.target.isDrawing) {
        return;
      }
      const startTime = this.currentTime;
      const endTime = startTime + 20 > this.videoEndTime ? this.videoEndTime : startTime + 20;
      if (!data.target.id && ANNOTATION_TYPE.PEN === data.target.type) {
        data.target.id = Math.random().toString(16).slice(2);
      }
      const annotation = {
        id: data.target.id,
        type: data.target.type,
        startTime,
        endTime,
        top: data.target.top,
        left: data.target.left,
        text: data.target.text,
        fill: data.target.fill,
        strokeWidth: data.target.strokeWidth,
        strokeColor: data.target.stroke,
        fontWeight: data.target.fontWeight,
        fontStyle: data.target.fontStyle,
        backgroundColor: data.target.backgroundColor,
        stroke: data.target.stroke,
        originalCanvasWidth: data.target.canvas.width,
        originalCanvasHeight: data.target.canvas.height,
        ...(ANNOTATION_TYPE.PEN === data.target.type && {
          path: data.target.path.map((p) => p.join(" ")).join(" "),
        }),
        ...(ANNOTATION_TYPE.ARROW === data.target.type && {
          startX: data.target.startX,
          startY: data.target.startY,
          endX: data.target.endX,
          endY: data.target.endY,
        }),
      };
      this.ADD_ANNOTATIONS({ annotation, stepId: this.currentStepId });
      this.$emit("update:madeChanges", true);
      this.watch.selectedId = data.target.id;
    },
    handleKeyboard(result) {
      const { key, srcElement } = result;
      const isNoteInputEditing = ["INPUT", "TEXTAREA"].includes(srcElement.tagName);
      if (isNoteInputEditing) return;
      this.handleKeyboardPressDelete(key);
      this.handleKeyboardPressArrow(key);
    },
    handleKeyboardPressArrow(key) {
      if (this.isVideoPlaying) return;
      if (!["ArrowLeft", "ArrowRight"].includes(key)) return;
      const targetValue = key === "ArrowLeft" ? -0.01 : 0.01;
      const nextTime = Number((this.currentTime + targetValue).toFixed(2));
      this.handleVideoCurrentTimeUpdate(nextTime);
    },
    handleKeyboardPressDelete(key) {
      if (!this.watch.selectedId) return;
      if (["Backspace", "Delete"].includes(key)) {
        this.handleNoteDelete(this.watch.selectedId);
      }
    },
    handleNoteDelete(targetId) {
      if (this.checkIsWorkflowPublished()) return;
      this.AnnotationFabric.deleteDataArray(targetId);
      this.DELETE_ANNOTATION({
        annotationId: targetId,
        stepId: this.currentStepId,
      });
      this.$emit("update:madeChanges", true);
      if (targetId === this.watch.selectedId) {
        this.watch.selectedId = null;
      }
    },
    handleNoteSwitch() {
      this.isHideCanvas = !this.isHideCanvas;
      this.AnnotationFabric.changeIsHideCanvas(this.isHideCanvas);
      this.AnnotationFabric.detectIsAnnotationShowUp(this.currentTime, this.currentStepId);
    },
    handleNoteSelect(targetData) {
      if (this.checkIsWorkflowPublished()) return;
      if (this.isHideCanvas) this.handleNoteSwitch();
      const { startTime, id } = targetData;
      this.handleVideoCurrentTimeUpdate(startTime);
      this.watch.selectedId = id;
    },
    mutateFabricAndVuexData({ id, key, value }) {
      const dbKey = ANNOTATION_DB_FABRIC_KEY_MAP[key];
      if (!dbKey) {
        console.error(`Key (${key}) is invalid`);
        return;
      }
      this.MUTATE_ANNOTATION_PROPERTY({
        stepId: this.currentStepId,
        key: dbKey,
        value,
        id,
      });
      this.AnnotationFabric.updateSelectedStyle({
        id,
        key: dbKey,
        value,
      });
      this.$emit("update:madeChanges", true);
      return;
    },
    handleVideoCurrentTimeUpdate(timeSecond) {
      this.$refs.annotationVideoPlayer.$refs.videoPlayer.currentTime = timeSecond;
    },
    handleVideoPlayOrPause() {
      if (this.isVideoPlaying) {
        this.isVideoPlaying = false;
        this.$refs.annotationVideoPlayer.pause();
      } else {
        this.isVideoPlaying = true;
        this.$refs.annotationVideoPlayer.play();
      }
    },
    handleVideoEnd(event) {
      if (event) {
        this.handleVideoPause();
        this.currentTime = this.videoEndTime;
      }
    },
    handleVideoPause() {
      if (this.isVideoPlaying) {
        this.isVideoPlaying = false;
        this.$refs.annotationVideoPlayer.pause();
      }
    },
    handleVideoOnTimeUpdate() {
      // prevent currentTime will over duration (unit: centi seconds)
      const currentVideoTime = this.$refs.annotationVideoPlayer.$refs.videoPlayer.currentTime;
      const currentTime = currentVideoTime > this.videoEndTime ? this.videoEndTime : currentVideoTime;
      this.currentTime = currentTime;
    },
    handleObjectChange(event) {
      const { action } = event;
      let keys = [];
      switch (action) {
        case "drag":
          keys = ["top", "left"];
          break;
        case "scale":
          keys = ["scaleX", "scaleY", "zoomX", "zoomY", "top", "left"];
          this.handleEditorToolPopup(event);
          break;
        default:
          keys = ["top", "left"];
      }
      if (event.target.type === ANNOTATION_TYPE.TEXT) {
        keys.push("text");
      }
      if (keys.length) {
        this.mutateAnnotationProperty(keys, event);
      }
    },
    mutateAnnotationProperty(keys = [], event) {
      keys.forEach((key) => {
        this.MUTATE_ANNOTATION_PROPERTY({
          stepId: this.currentStepId,
          key,
          value: event.target[key],
          id: event.target.id,
        });
      });
      this.$emit("update:madeChanges", true);
    },
    refreshDataArrayAndFabric(currentStepId, annotations) {
      if (!this.isFontReady) return;

      // reset hide status
      if (this.isHideCanvas) {
        this.isHideCanvas = false;
        this.AnnotationFabric.changeIsHideCanvas(this.isHideCanvas);
      }

      if (Array.isArray(annotations) && annotations.length) {
        const targetAnnotation = annotations.find(({ stepId }) => stepId === currentStepId);

        if (targetAnnotation) {
          const { elements } = targetAnnotation;
          this.$nextTick(() => {
            this.dataArray = elements;
            this.AnnotationFabric.loadData(elements);
            this.AnnotationFabric.detectIsAnnotationShowUp(this.currentTime, this.currentStepId);
          });
        }
      }
    },
  },
  watch: {
    "watch.selectedId": {
      handler(selectedId) {
        if (selectedId) {
          this.AnnotationFabric.selectDataArray(selectedId);
          this.switchType(ANNOTATION_TYPE.SELECT);
        }
      },
    },
    currentTime: {
      immediate: true,
      handler(currentTime) {
        if (this.AnnotationFabric) {
          this.AnnotationFabric.detectIsAnnotationShowUp(currentTime, this.currentStepId);
        }
      },
    },
    currentStepId: {
      immediate: true,
      handler(currentStepId) {
        this.handleVideoPause();
        this.refreshDataArrayAndFabric(currentStepId, this.annotations);
      },
    },
    isFontReady(isFontReady) {
      if (isFontReady) {
        this.refreshDataArrayAndFabric(this.currentStepId, this.annotations);
      }
    },
    annotations(annotations) {
      this.refreshDataArrayAndFabric(this.currentStepId, annotations);
    },
    type(type, previousType) {
      this.AnnotationFabric.toggleAnnotationEvent(type === ANNOTATION_TYPE.SELECT);
      this.AnnotationFabric.unbindMouseEvent(previousType);
    },
  },
};
</script>

<style scoped>
.annotation {
  --video-height: 380px;
  --video-width: 676px;
  --notes-width: 180px;
  --time-line-height: 60px;
  --gap: 30px;
  --icon-size: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-height: 100%;
}

.annotation__section {
  position: relative;
  width: var(--video-width);
  height: 100%;
  min-width: var(--video-width);
  min-height: calc(var(--video-height) + var(--time-line-height));
  margin: 0 var(--gap);
}

.annotation__section ::v-deep .canvas-container {
  position: absolute !important;
  z-index: 10;
}

.annotation__video {
  position: relative;
  background: black;
  width: var(--video-width);
  height: var(--video-height);
}

.annotation__video video {
  max-width: 100%;
  max-height: 100%;
}

.annotation__spinner {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
}

.annotation__canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--video-height);
}

.annotation__canvas--disabled {
  position: absolute;
  height: var(--video-height);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 25;
}

.annotation__canvas--hidden {
  z-index: -1;
}

.annotation__control {
  display: flex;
  flex-direction: column;
}

.annotation__control-btn {
  cursor: pointer;
  width: var(--icon-size);
  height: var(--icon-size);
  margin-bottom: 16px;
}

.annotation__control-btn--active {
  background: #4689f4;
  border-radius: 50%;
}

.annotation__notes {
  width: 100%;
  min-width: var(--notes-width);
  max-width: 300px;
  height: var(--video-height);
  max-height: var(--video-height);
  display: flex;
  flex-direction: column;
}

.annotation__time-line {
  width: 100%;
}

.annotation__target-tool {
  background-color: #2c2d32;
  border-radius: 4px;
}
</style>
