<template>
  <div class="serach-box center-horizontally">
    <div class="search-icon">
      <icon-base color="#52545D" width="24" height="24">
        <d-icon-search />
      </icon-base>
    </div>
    <input
      type="text"
      ref="searchField"
      v-model="inputText"
      :placeholder="placeholder"
      class="serach-input"
      :autotest="autotest || searchAutotest"
    />
    <slot></slot>
  </div>
</template>

<script>
import IconBase from "../IconBase.vue";
import DIconSearch from "../icons/DIconSearch.vue";

export default {
  name: "DTableSearch",
  props: {
    value: String, //for v-model value
    placeholder: String,
    autotest: String,
    searchAutotest: String,
  },
  components: {
    IconBase,
    DIconSearch,
  },
  watch: {
    value(newVal, oldVal) {
      // this check is mandatory to prevent endless cycle
      if (newVal !== oldVal) this.inputText = newVal;
    },
    inputText(newVal, oldVal) {
      // this check is mandatory to prevent endless cycle
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
        this.$emit("input-search", newVal);
      }
    },
  },
  created() {},
  updated() {},
  data: function () {
    return {
      inputText: "",
    };
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.serach-box {
  /* position: absolute;
  width: 100%;
  height: 72px;
  padding: 0 22px; */
  position: relative;
  width: 100%;
  height: 72px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  padding: 0 22px;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.search-icon {
  height: 32px;
  padding: 4px;
  margin-right: 4px;
}
.serach-input {
  margin: 0 8px;
  padding: 0;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  width: 100%;
}
.serach-input::placeholder {
  opacity: 0.5;
  color: #919191;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.serach-input:focus {
  outline-width: 0;
}
</style>
