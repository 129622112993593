<template>
  <div class="admin-group">
    <div class="tab-div">
      <div class="tab-title-row">
        <p class="tab-title text-no-wrap" autotest="workspace-count">
          <b>{{ workspaces.length }}</b>
          {{ $tc("all.countWorkspaces", workspaces.length) }}
        </p>
        <v-spacer></v-spacer>
        <d-button
          v-if="$role === 'admin-organization'"
          round
          depressed
          class="text-capitalize white--text body-2 create-btn"
          color="dBlue"
          @click="readyToAddWorkspace"
          light
          autotest="create-workspace-btn"
          >{{ $t("admin.createWorkspace") }}</d-button
        >
      </div>
      <div class="table-wrap">
        <d-data-table
          add-search
          local-search
          initial-sort
          :headers="workspaceTableHeaders"
          :loading="isLoadingTable"
          :items="isLoadingTable ? [] : workspaces"
          :placeholder="$t('admin.hintSearchWorkspaces')"
          searchAutotest="workspace-search-bar"
        >
          <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>

          <template slot="no-data">
            <span>
              <!-- This account does not have Workspaces yet. -->
              {{ $t("admin.msgNoRecordsWorkspacesGroupAdmin") }}
            </span>
          </template>

          <template slot="no-results">
            <span>
              <!-- No matching records found in Workspaces. -->
              {{ $t("admin.msgNoRecordsWorkspaces") }}
            </span>
          </template>

          <template slot="headerCell" slot-scope="props">
            <span>{{ props.header.text }}</span>
          </template>

          <template slot="items" slot-scope="props">
            <td class="text-xs-left">
              <span
                class="group-name-hover app-all-white-spaces"
                @click="enterWorkspace(props.item.id)"
                autotest="enter-workspace"
                >{{ props.item.name }}</span
              >
            </td>
            <td class="text-xs-left" v-if="isOnOrganizationLevel">
              <span class="app-all-white-spaces" autotest="workspaceGroup">{{ props.item.workspaceGroupName }}</span>
            </td>
            <td class="text-xs-left">
              <span class="app-all-white-spaces" autotest="business">{{ props.item.businessName }}</span>
            </td>
            <td class="text-xs-left" v-if="isOnOrganizationLevel">
              <span class="app-all-white-spaces" autotest="numberOfUsers">{{ props.item.numberOfUsers }}</span>
            </td>

            <!-- TODO: Confirm number of users is needed. -->
            <!-- <td class="text-xs-left" autotest="number-of-users-display">{{ props.item.numberOfUsers }}</td> -->
            <td v-if="showPrivateColumn" autotest="private-display">
              <div v-if="props.item.private" class="private-btn private-btn-dark">
                <span class="text-no-wrap text-capitalize">
                  <icon-base width="14" height="14" color="white" class="private-icon">
                    <d-icon-lock />
                  </icon-base>
                  {{ $t("all.private") }}
                </span>
              </div>

              <div v-else class="private-btn">
                <span class="text-no-wrap text-capitalize">
                  <icon-base width="14" height="14" color="#3F3F3F" class="private-icon">
                    <d-icon-lock-open />
                  </icon-base>
                  {{ $t("all.public") }}
                </span>
              </div>
            </td>

            <td class="text-xs-right text-no-wrap">
              <button
                class="table-editor-btn btn-hover"
                @click="openEditWorkspaceGroupPopup(props.item)"
                autotest="edit-workspace-btn"
              >
                <icon-base width="22" height="22">
                  <d-icon-pen />
                </icon-base>
              </button>

              <button
                v-if="props.item.id !== $defaultGroup"
                class="table-editor-btn btn-hover ml-3"
                autotest="delete-workspace-btn"
                @click="openDeleteWorkspacePopup(props.item)"
              >
                <icon-base width="22" height="22">
                  <d-icon-trash />
                </icon-base>
              </button>
            </td>
          </template>
        </d-data-table>
      </div>
    </div>

    <admin-workspace-popup
      v-if="showForm === 'create-workspace' || showForm === 'edit-workspace'"
      :showForm="showForm"
      :workspaces="workspaces"
      :businesses="businesses"
      :editedWorkspace="editedWorkspace"
      :isProcessingAction="isProcessingAction"
      :hasWorkspaceGroupOptions="isOnOrganizationLevel"
      :defaultWorkspaceGroupId="defaultWorkspaceGroupId"
      @addWorkspace="addWorkspace($event)"
      @updateWorkspace="updateWorkspace($event)"
      @closePopup="closePopup"
    ></admin-workspace-popup>

    <d-confirmation-popup
      v-model="showDeleteWorkspacePopup"
      light
      redActionBtn
      :title="$t('admin.deleteWorkspace')"
      :cancelBtnName="$t('all.cancel')"
      :actionBtnName="$t('skills.delete')"
      :content="deleteWorkspaceMessage"
      :autotest="{
        close: 'delete-workspace-close-btn',
        action: 'delete-workspace-popup-yes-btn',
        cancel: 'delete-workspace-popup-cancel-btn',
      }"
      @clickActionBtn="removeWorkspace"
    ></d-confirmation-popup>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconTrash from "@/components/icons/DIconTrash.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconLock from "@/components/icons/DIconLock.vue";
import DIconLockOpen from "@/components/icons/DIconLockOpen.vue";
import AdminWorkspacePopup from "@/components/DAdmin/workspaceGroup/AdminWorkspacePopup.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import DTabs from "@/components/ui_components/DTabs.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import { deleteWorkspace } from "@/server/delete-workspace-server";
import { getAllTypesWorkspaceGroups } from "@/js/workspace-group/workspace-group";
import { loggingError } from "@/server/error-log-server.js";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "AdminWorkspaceGroupWorkspaces",
  components: {
    IconBase,
    DIconSearch,
    DIconTrash,
    DIconPen,
    DIconLock,
    DIconLockOpen,
    DTabs,
    DDataTable,
    AdminWorkspacePopup,
    DConfirmationPopup,
  },
  mixins: [MixinDB, MixinUser, MixinAdmin],
  props: {
    isOnOrganizationLevel: Boolean,
    isLoadingWorkspaces: Boolean,
    workspaceList: Array,
  },
  data() {
    return {
      businesses: [],
      isProcessingAction: false,
      editedWorkspace: {},
      showForm: "",
      deleteWorkspaceMessage: "",
      showDeleteWorkspacePopup: false,
      isLoadingBusiness: true,
      defaultWorkspaceGroupId: "",
    };
  },
  async mounted() {
    await this.getBusinesses();
  },
  computed: {
    ...mapGetters("workspaceGroups", [
      "getter_admin_workspaceg_group_map",
      "getter_get_admin_workspace_group_by_workspace_id",
    ]),
    workspaces() {
      const list = this.workspaceList || [];
      return list.map((workspace) => {
        let dynamicFields = {};
        if (this.isOnOrganizationLevel) {
          dynamicFields.numberOfUsers = this.workspaceList?.find(({ id }) => id === workspace.id)?.numberOfUsers || 0;
          dynamicFields.workspaceGroupName =
            this.getter_admin_workspaceg_group_map[workspace.workspaceGroupId]?.name || "";
        }
        const business = this.businesses.find((business) => business.id === workspace.business);
        return {
          ...workspace,
          ...dynamicFields,
          businessName: business?.name || "",
        };
      });
    },
    isLoadingTable() {
      return this.isLoadingWorkspaces || this.isLoadingBusiness;
    },
    workspaceTableHeaders() {
      const workspaceGroupField = this.isOnOrganizationLevel
        ? [
            {
              text: this.$t("all.workspaceGroup"),
              align: "left",
              sortable: true,
              value: "workspaceGroupName",
              autotest: "workspaceGroup",
            },
          ]
        : [];
      const numberOfUsersField = this.isOnOrganizationLevel
        ? [
            {
              text: this.$t("analytics.numberOfUsers"),
              align: "left",
              sortable: true,
              value: "numberOfUsers",
              autotest: "numberOfUsers",
            },
          ]
        : [];
      const privateWorkspaceField = this.showPrivateColumn
        ? [
            {
              text: this.$t("all.private"),
              align: "left",
              sortable: true,
              value: "private",
              autotest: "private-sort",
            },
          ]
        : [];
      const tableHeader = [
        {
          text: this.$t("all.name"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "name-sort",
        },
        ...workspaceGroupField,
        {
          text: this.$t("all.business"),
          align: "left",
          sortable: true,
          value: "businessName",
          autotest: "business",
        },
        ...numberOfUsersField,
        ...privateWorkspaceField,
        {
          align: "right",
          sortable: false,
          value: "",
        },
      ];
      return tableHeader;
    },
    showPrivateColumn() {
      return true;
      // const hasPrivate = workspacesList.find((group) => group.private === true);
      // if (hasPrivate && !addedPrivate) {
      //   return true;
      // } else if (!hasPrivate && addedPrivate) {
      //   return false;
      // } else if (hasPrivate) {
      //   return true;
      // }
    },
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    ...mapActions("workspace", ["createWorkspace", "patchWorkspace"]),
    async getBusinesses() {
      try {
        this.isLoadingBusiness = true;
        this.businesses = await this.getDocumentByQuery("businesses", [
          {
            field: "organization",
            comparison: "==",
            value: this.$organization,
          },
        ]);
      } catch (err) {
        console.error("Failed to get bussinesses", err);
        loggingError(err);
      } finally {
        this.isLoadingBusiness = false;
      }
    },
    async addWorkspace({ data }) {
      this.isProcessingAction = true;
      try {
        await this.createWorkspace({ data });
        await getAllTypesWorkspaceGroups();
        this.closePopup();

        const message = this.$t("admin.alertSuccessfullyCreatedWorkspace");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } catch (error) {
        const message = this.$t("admin.aletFailedCreateWorkspace");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
        loggingError(error);
      } finally {
        this.isProcessingAction = false;
      }
    },
    async updateWorkspace({ data }) {
      this.isProcessingAction = true;

      try {
        await this.patchWorkspace({ data });
        await getAllTypesWorkspaceGroups();
        this.closePopup();

        const message = this.$t("admin.alertSuccessfullyEditedWorkspace");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } catch (error) {
        const message = error.response.data.includes("already exists")
          ? this.$t("admin.alertWorkspaceNameAlreadyExists")
          : this.$t("admin.alertFailedEditWorkspace");

        this.MUTATE_ALERT_INFO({ message, type: "error" });
        loggingError(error);
      } finally {
        this.isProcessingAction = false;
      }
    },
    openEditWorkspaceGroupPopup(workspace) {
      this.editedWorkspace = workspace;
      this.showForm = "edit-workspace";
    },
    openDeleteWorkspacePopup(workspace) {
      this.editedWorkspace = workspace;
      this.deleteWorkspaceMessage = this.$t("admin.popupMsgDeleteWorkspace", { name: workspace.name });
      this.showDeleteWorkspacePopup = true;
    },
    async removeWorkspace() {
      const { id } = this.editedWorkspace;
      const groupData = {
        organization: this.$organization,
        groupId: id,
      };

      // TODO: integrate `deleteWorkspace` to workspace-server.
      // TODO: confirm the result of `errorMessage`.
      const { ok, data, errorMessage } = await deleteWorkspace({ groupData });

      // TODO: confirm the result of data.
      if (ok && data === "successfully removed group.") {
        await getAllTypesWorkspaceGroups();

        const message = this.$t("admin.alertDeleteGroupSuccess");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } else {
        // TODO: confirm the types of `errorMessage`.
        const errorMessageMap = {
          "workflows assigned": this.$t("admin.alertDeleteGroupFailedWorkflows"),
          "playlists assigned": this.$t("admin.alertDeleteGroupFailedSkills"),
          "teams assigned": this.$t("admin.alertDeleteGroupFailedTeams"),
        };

        const messageKey = Object.keys(errorMessageMap).find((key) => errorMessage.includes(key));
        const message = !!messageKey ? errorMessageMap[messageKey] : "Something went wrong.";
        this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
    },
    closePopup() {
      this.showForm = "";
      this.editedWorkspace = {};
    },
    enterWorkspace(workspaceId) {
      this.$router.push({ name: "AdminWorkspaceUsers", params: { workspaceId, tab: "users" } });
    },
    readyToAddWorkspace() {
      this.defaultWorkspaceGroupId = this.isOnOrganizationLevel
        ? this.getter_get_admin_workspace_group_by_workspace_id(this.$defaultGroup)?.id
        : this.$route.params.workspaceGroupId;
      this.showForm = "create-workspace";
    },
  },
};
</script>

<style scoped>
.admin-group {
  padding: 0;
  height: 100%;
  background-color: #fbfbfb;
}
.top-tabs {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 28px;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.tab-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.tab-title-row {
  position: relative;
  height: 95px;
  width: 100%;
  display: flex;
  padding: 30px 28px 0 28px;
}
.tab-title {
  height: 44px;
  width: 145px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
}
.table-wrap {
  height: 100%;
  width: 100%;
  padding: 0 28px;
  overflow: scroll;
}
.table-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.group-name-hover:hover {
  color: #2d2e32;
  cursor: pointer;
  font-weight: 500;
}
.private-btn {
  display: inline-block;
  height: 32px;
  min-width: 100px;
  padding: 6px 8px 0 8px;
  border: 1px solid #e7e7e7;
  border-radius: 16px;
  background-color: #ffffff;
  color: #3f3f3f;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
}
.private-btn-dark {
  border: 1px solid #e7e7e7;
  border-radius: 19px;
  background-color: #52545d;
  color: #ffffff;
}
.private-icon {
  margin: 0 2px -2px 4px;
}
@media screen and (max-width: 959px) {
  .top-tabs {
    padding: 0 20px;
  }
  .tab-title-row {
    padding: 30px 20px 0 20px;
  }
  .table-wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1903px) {
  .top-tabs {
    padding: 0 28px;
  }
  .tab-title-row {
    padding: 30px 28px 0 28px;
  }
}
</style>
