import axios from "axios";
class Dispatcher {
  constructor() {
    this.handlers = [];
  }

  listen(handler) {
    this.handlers.push(handler);
  }

  emit(...args) {
    this.handlers.forEach((handler) => {
      handler(...args);
    });
  }
}

class AudioPlayer {
  constructor() {
    this.reset();
  }

  reset() {
    this.audioContext = new AudioContext();
    this.playListMap = new Map();
    this._playId = null;

    this.emptyNode = {
      file: null,
      offset: 0,
      start: null,
      source: null,
      buffer: null,
    };
    this.playListMap.set(this.emptyNode, this.emptyNode);

    this.onPlay = new Dispatcher();
    this.onPause = new Dispatcher();
    this.onChange = new Dispatcher();
    this.onReady = new Dispatcher();
    this.onSetPosition = new Dispatcher();
  }

  async readAudioBuffer(arrayBuffer) {
    return await this.audioContext.decodeAudioData(arrayBuffer);
  }

  async append({ arraybuffer, playId, speechURL }) {
    const isEmpty = this.isEmpty;
    if (this.playListMap.has(playId)) {
      return;
    }
    if (speechURL) {
      const response = await axios({
        url: speechURL,
        method: "get",
        responseType: "arraybuffer",
      });
      arraybuffer = response.data;
    }

    this.playListMap.set(playId, {
      file: arraybuffer,
      offset: 0,
      start: null,
      source: null,
      buffer: await this.readAudioBuffer(arraybuffer),
    });
    if (isEmpty) {
      this.onReady.emit(this);
    }
  }

  play() {
    if (!this.playListMap.size || this.isEmpty || this.current.source) {
      return;
    }
    const source = this.audioContext.createBufferSource();
    source.buffer = this.current.buffer;
    source.connect(this.audioContext.destination);
    source.start(0, this.current.offset);
    this.current.source = source;
    this.current.start = this.audioContext.currentTime;

    this.onPlay.emit(this);
  }

  pause() {
    if (!this.playListMap.size || !this.current.source) {
      return;
    }
    this.current.source.stop(0);
    this.current.source.disconnect(0);
    this.current.source.onended = null;
    this.current.source = null;
    this.current.offset = this.position;
    this.current.start = null;

    this.onPause.emit(this);
  }

  stop() {
    this.pause();
    this.current.offset = 0;
    this.current.start = null;
  }

  get isEmpty() {
    return this.current === this.playListMap.get(this.emptyNode);
  }

  get current() {
    return this.playListMap.get(this.playId || this.emptyNode);
  }

  get position() {
    if (!this.playListMap.size) {
      return 0;
    }
    return this.current.offset + (this.current.start !== null ? this.audioContext.currentTime - this.current.start : 0);
  }

  set position({ playId, offset }) {
    this.stop();
    this.playId = playId;
    this.current.offset = offset;
    this.current.start = null;
    this.onSetPosition.emit(this);
  }

  get playId() {
    return this._playId;
  }

  set playId(id) {
    if (this._playId === id) {
      return;
    }
    this._playId = id;
  }

  get duration() {
    return this.current.buffer ? this.current.buffer.duration : 0.001;
  }
}

export const player = new AudioPlayer();
