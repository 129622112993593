<template>
  <div class="d-resize-image">
    <canvas
      ref="imageCanvas"
      :width="canvasWidth"
      :height="canvasHeight"
      class="img-canvas"
      :style="{ 'background-color': backgroudColor }"
      @wheel.prevent="wheel"
      @mousedown="onmousedown"
      @mouseup="onmouseup"
      @mouseleave="onmouseup"
      @mousemove="onmousemove"
    ></canvas>
    <div class="slider-div">
      <v-slider
        :value="sliderWidth"
        always-dirty
        track-color="#8D909F"
        :min="imgSize.minW"
        :max="imgSize.maxW"
        class="ma-0"
        @change="onSliderChange"
      >
        <template slot="prepend">
          <v-icon :color="'grey'" @click="decrementSlider">remove</v-icon>
        </template>
        <template slot="append">
          <v-icon :color="'grey'" @click="incrementSlider">add</v-icon>
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
export default {
  name: "DResizeImage",
  props: {
    imgUrl: {
      type: String,
      default: () => "",
    },
    contain: {
      type: Boolean,
      default: () => false,
    },
    canvasWidth: {
      type: Number,
      default: () => 495,
    },
    canvasHeight: {
      type: Number,
      default: () => 270,
    },
  },
  watch: {
    imgUrl: function () {
      this.getImage();
    },
  },
  mounted() {
    this.canvasRatio = this.canvasWidth / this.canvasHeight;
    this.imgCanvas = this.$refs.imageCanvas;
    this.imgCxt = this.imgCanvas.getContext("2d");
  },
  data() {
    return {
      canvasRatio: 0,
      imgCanvas: null,
      imgNew: null,
      imgCxt: null,
      imgSize: {
        imgW: 0,
        imgH: 0,
        minW: 0,
        minH: 0,
        imgRatio: 0,
        maxW: 0,
        step: 0,
      },
      sliderWidth: 495,
      moving: false,
      lastmouseX: null,
      lastmouseY: null,
      lastImgX: 0,
      lastImgY: 0,
      backgroudColor: "rgba(0, 0, 0, 1)",
    };
  },
  methods: {
    getImage() {
      if (!this.imgUrl) return;
      this.imgNew = new Image();
      this.imgNew.src = this.imgUrl;
      const self = this;
      this.imgNew.onload = () => {
        self.calculateFactor();
        const originPosition = self.canvasOriginPosition();
        self.redrawImg(originPosition.positionX, originPosition.positionY);
        self.lastImgX = originPosition.positionX;
        self.lastImgY = originPosition.positionY;
      };
    },
    redrawImg(pX, pY) {
      if (!this.imgNew) return;
      this.imgCanvas.setAttribute("height", this.canvasHeight);
      this.imgCanvas.setAttribute("width", this.canvasWidth);
      this.imgCxt.fillStyle = this.backgroudColor;
      this.imgCxt.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
      const imgW = this.sliderWidth;
      const imgH = imgW / this.imgSize.imgRatio;
      this.imgCxt.drawImage(this.imgNew, pX, pY, imgW, imgH);
    },
    canvasOriginPosition() {
      let positionX, positionY;
      const imgW = this.sliderWidth;
      const imgH = imgW / this.imgSize.imgRatio;
      positionX = (this.canvasWidth - imgW) / 2;
      positionY = (this.canvasHeight - imgH) / 2;
      return { positionX: positionX, positionY: positionY };
    },
    calculateFactor() {
      this.imgSize.imgH = this.imgNew.height;
      this.imgSize.imgW = this.imgNew.width;
      this.imgSize.imgRatio = this.imgNew.width / this.imgNew.height;

      if (this.contain) {
        //contain (can show the whole original image)
        if (this.imgSize.imgRatio < this.canvasRatio) {
          // gap on left and right
          this.imgSize.minW = this.imgSize.imgRatio * this.canvasHeight;
          this.imgSize.minH = this.canvasHeight;
        } else {
          // gap on top and bottom
          this.imgSize.minW = this.canvasWidth;
          this.imgSize.minH = this.imgSize.minW / this.imgSize.imgRatio;
        }
        this.imgSize.maxW = this.canvasWidth * 3;
      } else {
        // defult (always cover the whole canvas, may cut part of the original image)
        if (this.imgSize.imgRatio < this.canvasRatio) {
          // cut top and bottom
          this.imgSize.minW = this.canvasWidth;
          this.imgSize.minH = this.canvasWidth / this.imgSize.imgRatio;
        } else {
          // cut left and right
          this.imgSize.minH = this.canvasHeight;
          this.imgSize.minW = this.canvasHeight * this.imgSize.imgRatio;
        }
        this.imgSize.maxW = this.imgSize.minW * 3;
      }
      // for Slider
      this.imgSize.step = Math.ceil((this.imgSize.maxW - this.imgSize.minW) / 20);
      this.sliderWidth = this.imgSize.minW;
    },
    // resize
    wheel(event) {
      if (event.deltaY < 0) {
        this.incrementSlider();
      } else if (event.deltaY > 0) {
        this.decrementSlider();
      }
    },
    changeSlider() {
      // left upper conner will keep in the same place
      // const newPosition = this.checkPosition(this.lastImgX, this.lastImgY);
      // this.redrawImg(newPosition.x, newPosition.y);
      // this.lastImgX = newPosition.x;
      // this.lastImgY = newPosition.y;
      const originPosition = this.canvasOriginPosition();
      this.redrawImg(originPosition.positionX, originPosition.positionY);
      this.lastImgX = originPosition.positionX;
      this.lastImgY = originPosition.positionY;
    },
    decrementSlider() {
      if (this.sliderWidth - this.imgSize.step > this.imgSize.minW) {
        this.sliderWidth = this.sliderWidth - this.imgSize.step;
      } else {
        this.sliderWidth = this.imgSize.minW;
      }
      this.changeSlider();
    },
    incrementSlider() {
      if (this.sliderWidth + this.imgSize.step < this.imgSize.maxW) {
        this.sliderWidth = this.sliderWidth + this.imgSize.step;
      } else {
        this.sliderWidth = this.imgSize.maxW;
      }
      this.changeSlider();
    },
    onSliderChange(value) {
      this.sliderWidth = value;
      this.changeSlider();
    },
    onmousedown(e) {
      this.moving = true;
      this.lastmouseX = e.clientX;
      this.lastmouseY = e.clientY;
    },
    onmouseup(e) {
      if (this.moving === true) {
        this.moving = false;
        const pX = e.clientX - this.lastmouseX + this.lastImgX;
        const pY = e.clientY - this.lastmouseY + this.lastImgY;
        const newPosition = this.checkPosition(pX, pY);
        this.lastImgX = newPosition.x;
        this.lastImgY = newPosition.y;
      }
    },
    onmousemove(e) {
      if (this.moving) {
        const pX = e.clientX - this.lastmouseX + this.lastImgX;
        const pY = e.clientY - this.lastmouseY + this.lastImgY;
        const newPosition = this.checkPosition(pX, pY);
        this.redrawImg(newPosition.x, newPosition.y);
      }
    },
    checkPosition(pX, pY) {
      const imgW = this.sliderWidth;
      const imgH = imgW / this.imgSize.imgRatio;
      let x = pX;
      let y = pY;
      if (this.contain) {
        // contain (can show the whole original image)
        if (this.canvasWidth > imgW) {
          x = (this.canvasWidth - imgW) / 2;
        } else {
          if (pX >= 0) {
            x = 0;
          }
          if (pX + imgW <= this.canvasWidth) {
            x = this.canvasWidth - imgW;
          }
        }
        if (this.canvasHeight > imgH) {
          y = (this.canvasHeight - imgH) / 2;
        } else {
          if (pY >= 0) {
            y = 0;
          }
          if (pY + imgH <= this.canvasHeight) {
            y = this.canvasHeight - imgH;
          }
        }
      } else {
        // defult (always cover the whole canvas)
        if (pX >= 0) {
          x = 0;
        }
        if (pX + imgW <= this.canvasWidth) {
          x = this.canvasWidth - imgW;
        }
        if (pY >= 0) {
          y = 0;
        }
        if (pY + imgH <= this.canvasHeight) {
          y = this.canvasHeight - imgH;
        }
      }
      return { x: x, y: y };
    },
  },
  computed: {},
};
</script>

<style scoped>
.d-resize-image {
  position: relative;
}
.img-canvas {
  position: relative;
  width: 100%;
  border-radius: 4px;
}
.slider-div {
  position: relative;
}
</style>
