<template>
  <div class="d-progress-bar">
    <div
      ref="timeBar"
      class="time-detect-div"
      @mousedown="mousedownTimeDetect"
      @touchstart="mousedownTimeDetect"
      @touchmove="mousemoveTimeDetect"
      @touchend="mouseupTimeDetect"
    ></div>
    <div
      class="progress-bar"
      :style="{ width: getBarPercentage(value) }"
      :class="{
        'no-transition-delay': noTransitionDelay,
      }"
    ></div>
    <div
      class="progress-thumb"
      :style="{ left: getBarPercentage(value) }"
      :class="{
        'progress-thumb-dragging': isDraggingThumb,
        'no-transition-delay': noTransitionDelay,
        'progress-thumb-hide': hideControls,
      }"
    ></div>
    <div class="progress-track"></div>
  </div>
</template>
<script>
export default {
  name: "DProgressBar",
  props: {
    value: Number, //for v-model currentVideoTime
    max: Number,
    min: Number,
    isPhone: Boolean,
    hideControls: Boolean,
  },
  watch: {
    max: function () {
      //cancel Dragging when step changed
      this.cancelDragging();
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("mousemove", this.mousemoveTimeDetect);
      window.addEventListener("mouseup", this.mouseupTimeDetect);
    });
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mousemoveTimeDetect);
    window.removeEventListener("mouseup", this.mouseupTimeDetect);
  },
  data() {
    return {
      isDraggingThumb: false,
      noTransitionDelay: false,
      noDelayTimeout: null,
    };
  },
  methods: {
    getBarPercentage(time) {
      if (this.max - time < 1) {
        //corrected progress-thumb end positon for short video
        return "100%";
      } else {
        return (time / this.max) * 100 + "%";
      }
    },
    getPercentageOffset(event) {
      if (this.isTouchScreen()) {
        const touch = event.touches[0];
        const pageX = touch.pageX - this.$refs.timeBar.getBoundingClientRect().left;
        const barWidth = this.$refs.timeBar.offsetWidth;
        return pageX / barWidth;
      }
      const pageX = event.offsetX;
      const barWidth = this.$refs.timeBar.offsetWidth;
      return pageX / barWidth;
    },
    isTouchScreen() {
      return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    },
    getCurrentTime(percentage) {
      return percentage * this.max;
    },
    cancelDragging() {
      if (this.isDraggingThumb) {
        this.isDraggingThumb = false;
        this.$emit("keepPlayingVideo");
      }
    },
    mousedownTimeDetect(event) {
      event.preventDefault();
      this.$emit("temporaryPauseVideo");
      this.isDraggingThumb = true;
      this.updateTime(event);
    },
    mouseupTimeDetect(event) {
      if (this.isDraggingThumb) {
        event.preventDefault();
        this.isDraggingThumb = false;
        this.$emit("keepPlayingVideo");
      }
    },
    mousemoveTimeDetect(event) {
      if (this.isDraggingThumb) {
        event.preventDefault();
        this.updateTime(event);
      }
    },
    updateTime(event) {
      const percentage = this.getPercentageOffset(event);
      const newTime = this.getCurrentTime(percentage);
      this.$emit("input", newTime);
      this.$emit("setTimePosition");
      this.thumbMoveNoDelay();
    },
    thumbMoveNoDelay() {
      clearTimeout(this.noDelayTimeout);
      this.noTransitionDelay = true;
      this.noDelayTimeout = setTimeout(() => {
        this.noTransitionDelay = false;
      }, 100);
    },
  },
};
</script>

<style scoped>
.d-progress-bar {
  height: 22px;
}
.time-detect-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 5;
  cursor: pointer;
}
.progress-bar {
  position: absolute;
  top: 8px;
  left: 0;
  height: 4px;
  background-color: #4689f3;
  transition: 0.3s;
  z-index: 1;
}
.progress-thumb {
  position: absolute;
  top: 2px;
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  margin-left: -9px;
  border: 1px solid #979797;
  background-color: #ffffff;
  border-radius: 10px;
  transition: all 0.3s ease;
  z-index: 2;
}
.progress-thumb-hide {
  top: 8px;
  height: 4px;
  width: 4px;
  margin-left: -2px;
  background-color: transparent;
  border: none;
}
.progress-thumb-dragging {
  top: 0;
  height: 20px;
  width: 20px;
  margin-left: -11px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
.progress-track {
  position: absolute;
  top: 8px;
  left: 0;
  height: 4px;
  width: 100%;
  opacity: 0.2;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 0;
}
.no-transition-delay {
  transition: all 0s ease;
}
</style>
