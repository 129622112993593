<template>
  <section
    class="PlayerProcessedVideo"
    ref="desktopPlayer"
    @contextmenu.prevent
    @mousemove="actionToShowIcons"
    @mouseleave="actionToHideIcons"
    @click="showOrHideIcons"
  >
    <player-loading v-if="isLoadingVideo || (firstLoadingVideo && !isUsingIframe)" z-index="2" />
    <!-- video -->
    <div
      v-if="hasHls"
      class="PlayerProcessedVideo__video"
      :class="{
        'PlayerProcessedVideo__subtitles--ipad': IS_TABLET,
        'PlayerProcessedVideo__subtitles--iframe': isUsingIframe && !iframeFullscreen,
        'PlayerProcessedVideo__subtitles--lower': hideControls,
      }"
      v-d-resize-observer="handleVideoResizeEvent"
    >
      <video
        v-for="(step, idx) in steps"
        :key="idx"
        v-show="idx === currentStep"
        ref="videoPlayer"
        :poster="step.poster"
        :preload="useHlsByDefault ? 'none' : 'auto'"
        crossorigin="anonymous"
        @timeupdate="timeUpdateEvent(idx)"
        @ended="handleVideoEnded"
        @loadeddata="handleVideoLoadedData(idx, $event)"
        @waiting="handleVideoWaiting()"
        @canplay="handleVideoCanplay(idx)"
      >
        <track
          v-for="(subtitle, idx) in step.subtitles"
          kind="subtitles"
          :key="idx"
          :srclang="subtitle.language"
          :src="subtitle.source"
          :disabled="subtitle.disabled"
        />
      </video>
      <slot name="annotation"></slot>
      <slot name="append-video"></slot>
    </div>
    <div
      v-else
      class="PlayerProcessedVideo__video"
      :class="{
        'PlayerProcessedVideo__subtitles--ipad': IS_TABLET,
        'PlayerProcessedVideo__subtitles--iframe': isUsingIframe && !iframeFullscreen,
        'PlayerProcessedVideo__subtitles--lower': hideControls,
      }"
      v-d-resize-observer="handleVideoResizeEvent"
    >
      <video
        v-for="(step, idx) in steps"
        :key="idx"
        v-show="idx === currentStep"
        ref="videoPlayer"
        :poster="step.poster"
        preload="auto"
        crossorigin="anonymous"
        @timeupdate="timeUpdateEvent(idx)"
        @ended="handleVideoEnded"
        @loadeddata="handleVideoLoadedData(idx, $event)"
        @waiting="handleVideoWaiting()"
        @canplay="handleVideoCanplay(idx)"
      >
        <source :src="videoSources[idx]" />
        <track
          v-for="(subtitle, idx) in step.subtitles"
          kind="subtitles"
          :key="idx"
          :srclang="subtitle.language"
          :src="subtitle.source"
          :disabled="subtitle.disabled"
        />
      </video>
      <slot name="annotation"></slot>
      <slot name="append-video"></slot>
    </div>

    <!-- controls（iFrame version, for inseted iFrame player on other websites, like ABI is using on Acadia)  -->
    <controls-iframe
      v-if="isUsingIframe && !iframeFullscreen"
      :playing="playing"
      :isMuted="isMuted"
      :currentStep="currentStep"
      :currentVideoTime="currentVideoTime"
      :watermarkId="safetyInfoWatermark"
      :showControl="!hideControls"
      @play-video="(playing = true), playOrPauseVideo()"
      @pause-video="pauseVideo"
      @play-back="playBack"
      @play-forward="playForward"
      @play-previous-step="playPreviousStep"
      @play-next-step="playNextStep"
      @click-mute="muteVideoButton"
      @change-fullscreen="changeFullscreen"
    >
      <d-progress-bar
        v-model="currentVideoTime"
        :max="steps[currentStep].duration"
        :hideControls="hideControls"
        @setTimePosition="setTimePositionNew"
        @temporaryPauseVideo="temporaryPauseVideo"
        @keepPlayingVideo="keepPlayingVideo"
      ></d-progress-bar>
    </controls-iframe>

    <!-- controls (desktop version) -->
    <controls-desktop
      v-if="!isUsingIframe || iframeFullscreen"
      :hideControls="hideControls"
      :playing="playing"
      :isMuted="isMuted"
      :currentStep="currentStep"
      :currentVideoTime="currentVideoTime"
      :showCloseBtn="showCloseBtn"
      :subtitlesChoice="subtitlesChoice"
      :iframeFullscreen="iframeFullscreen"
      :isUsingIframe="isUsingIframe"
      :isEmbedDeephow="isEmbedDeephow"
      :enableTheaterButton="enableTheaterButton"
      :volume="volume"
      :showTopBarForcible="showTopBarForcible"
      :watermarkId="safetyInfoWatermark"
      @update-volume="updateVolume"
      @close-player="closeVideoPlayer"
      @click-menu-btn="clickToShowStepsMenu($event)"
      @play-video="(playing = true), playOrPauseVideo()"
      @pause-video="pauseVideo"
      @play-back="playBack"
      @play-forward="playForward"
      @play-previous-step="playPreviousStep"
      @play-next-step="playNextStep"
      @click-mute="muteVideoButton"
      @click-setting="showSettingPopup = !showSettingPopup"
      @click-search="clickToShowSearch"
      @change-fullscreen="changeFullscreen"
    >
      <d-progress-bar
        v-model="currentVideoTime"
        :max="steps[currentStep].duration"
        :hideControls="hideControls"
        @setTimePosition="setTimePositionNew"
        @temporaryPauseVideo="temporaryPauseVideo"
        @keepPlayingVideo="keepPlayingVideo"
      ></d-progress-bar>
    </controls-desktop>

    <div v-if="!isUsingIframe || iframeFullscreen" class="PlayerProcessedVideo__addonsWrapper">
      <!-- setting popup  -->
      <d-player-setting
        v-show="showSettingPopup"
        ref="DPlayerSetting"
        class="PlayerProcessedVideo__DPlayerSetting"
        :class="{
          'PlayerProcessedVideo__DPlayerSetting--ipad': IS_TABLET,
          'PlayerProcessedVideo__DPlayerSetting--smallScreen': isSmallScreenSettings,
        }"
        :isMobile="false"
        :showSettingPopup="showSettingPopup"
        :enableText2Speech="enableText2Speech"
        :currentAudioChoice="audioChoice"
        :autoNextStep="autoNextStep"
        :isUserSetSubtitleToNull="isUserSetSubtitleToNull"
        :steps="steps"
        :currentStep="currentStep"
        :subtitlesChoice="subtitlesChoice"
        :hasHls="hasHls"
        :videoResolution="videoResolution"
        :hlsRezOptions="hlsRezOptions"
        :hlsRefs="hlsRefs"
        :hlsCurrentLevel="hlsCurrentLevel"
        :speedOption="speedOption"
        :currentPlaySpeed="playSpeed"
        @close="showSettingPopup = false"
        @change-auto-play="changeAutoPlay($event)"
        @change-text2-speech="audioChoice = $event.audioChoice"
        @change-subtitles="
          isUserSetSubtitleToNull = $event.isUserSetSubtitleToNull;
          subtitlesChoice = $event.subtitlesChoice;
        "
        @set-quality-video-resolution="videoResolution = $event.videoResolution"
        @set-quality-hls-rez="setHlsRez($event.level)"
        @change-speed="
          playSpeed = $event.playSpeed;
          setPlaybackRate();
        "
      />

      <!-- Step menu -->
      <player-processed-menu
        :workflow="workflow"
        :steps="steps"
        :currentStep="currentStep"
        :menuHidden="menuHidden"
        :subtitlesChoice="subtitlesChoice"
        :attachmentsData="attachmentsData"
        @pause-video="pauseVideo"
        @setCurrentStepEvent="setCurrentStep($event)"
        @close-menu="clickToCloseStepMenu()"
      ></player-processed-menu>

      <!-- Search result -->
      <player-search
        ref="playerSearch"
        :workflow="workflow"
        :steps="steps"
        :showPlayerSearch="showPlayerSearch"
        :subtitlesChoice="subtitlesChoice"
        :isPublic="isPublic"
        @closePlayerSearch="clickToCloseSearch()"
        @jumpToSearchResult="jumpToSearchResult($event)"
      ></player-search>
      <player-maven-qa
        v-if="!getter_is_window_mode"
        @closePlayerMavenQa="showMavenQA = false"
        :show="showMavenQA"
        :workflow="workflow"
      />
      <!-- Attachments -->
      <attachments-bar
        v-show="showAttachmentsBtn"
        :hideControls="hideControls"
        :currentStep="currentStep"
        :iframeFullscreen="iframeFullscreen"
        :isEmbedDeephow="isEmbedDeephow"
      />
      <player-attachments
        ref="playerDiagram"
        :currentStepName="currentStepName"
        :currentStep="currentStep"
        :isDisabledPictureInPicture="isDisabledPictureInPicture"
      ></player-attachments>

      <!-- Step name tag -->
      <d-step-name-tag
        :isShowingTaskName="isShowingTaskName"
        :stepNum="getStepNum(currentStep)"
        :currentStepName="currentStepName"
        :isMobileDevice="false"
        class="PlayerProcessedVideo__stepNameTag"
      ></d-step-name-tag>

      <!-- Reactions (only show on fullscreen mode or Theater mode) -->
      <div
        v-if="!isPublic && workflow.published"
        v-show="showReactionsBtn"
        class="PlayerProcessedVideo__reactionsButton"
      >
        <d-button v-if="showQABtn" style="background: #33353a !important" outline round @click="showMavenQA = true"
          ><div class="askMaven__btn">
            <img width="24" height="24" src="@/img/GPT-workflow.png" alt="" />{{ $t("askMaven.qa") }}
          </div></d-button
        >

        <reactions-in-player :workflowId="workflow.id" />
      </div>

      <!-- Loading language popup  -->
      <player-loading v-if="loadingLanguage" z-index="8">
        {{ $t("player.loadingLanguage") }}
      </player-loading>
    </div>

    <player-voice-assistant
      ref="voiceAssistant"
      @playVideoEvent="playing = true"
      @pauseVideoEvent="playing = false"
      @playNextStepEvent="playNextStep"
      @repeatEvent="videoRepeat++"
      @openMenuEvent="menuHidden = false"
      @muteEvent="isMuted = true"
      @unmuteEvent="isMuted = false"
      @showMeHowToEvent="$emit('showMeHowToEvent', $event)"
      @showMeEvent="$emit('showMeEvent', $event)"
      @goBackEvent="playPreviousStep"
      @speakInEvent="speakIn($event)"
    ></player-voice-assistant>

    <player-processed-language
      ref="PlayerProcessedLanguage"
      :videoCurrentTime="currentVideoTime"
      :currentStep="currentStep"
      :steps="steps"
      :isPlaying="playing"
      :language="language"
      @muteVideoEvent="$refs.videoPlayer[currentStep].muted = true"
      @unmuteVideoEvent="$refs.videoPlayer[currentStep].muted = false"
      @finishLoading="finishLoading()"
    ></player-processed-language>
  </section>
</template>

<script>
import PlayerLoading from "@/components/DWorkflowPlayer/player/message/PlayerLoading.vue";
import PlayerProcessedMenu from "@/components/PlayerProcessedMenu.vue";
import PlayerVoiceAssistant from "@/components/PlayerVoiceAssistant.vue";
import PlayerSearch from "@/components/DWorkflowPlayer/PlayerSearch.vue";
import PlayerMavenQa from "@/components/DWorkflowPlayer/PlayerMavenQa.vue";
import PlayerAttachments from "@/components/DWorkflowPlayer/attachments/PlayerAttachments.vue";
import PlayerProcessedLanguage from "@/components/PlayerProcessedLanguage.vue";
import DStepNameTag from "@/components/ui_components/DStepNameTag.vue";
import DProgressBar from "@/components/ui_components/DProgressBar.vue";
import DPlayerSetting from "@/components/DWorkflowPlayer/setting/DPlayerSetting.vue";
import ReactionsInPlayer from "@/components/DWorkflowPlayer/controls/ReactionsInPlayer.vue";
import ControlsDesktop from "@/components/DWorkflowPlayer/controls/ControlsDesktop.vue";
import ControlsIframe from "@/components/DWorkflowPlayer/controls/ControlsIframe.vue";
import AttachmentsBar from "@/components/DWorkflowPlayer/attachments/AttachmentsBar.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinTracker from "@/components/MixinTracker.vue";
import MixinVideo from "@/components/MixinVideo.vue";
import Vue from "vue";
import Hls from "hls.js";
import { setTimeout } from "timers";
import eventbus from "@/main";
import MixinIdle from "@/components/MixinIdle.vue";
import Analytics from "@/js/analytics/analytics";
import {
  watchOnStepChangedWithVideoTracker,
  saveInitialStepWithVideoTrackerOnce,
} from "@/js/video-tracker/videoTrackerUtility.js";
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { debounce } from "lodash-es";
import { togglePlayerFullscreen, closePlayerFullscreen, isShowingFullscreen } from "@/js/workflow/fullscreen.js";
import { checkIsSignedUrl } from "@/server/sign-server";
import { mapState, mapActions, mapGetters } from "vuex";
import { IS_TABLET, IS_SAFARI } from "@/constants/device-version";
import { REPEAT_TYPES } from "@/constants/workflow-repeat-status.js";
import DResizeObserver from "@/directives/d-resize-observer";
import { loggingError } from "@/server/error-log-server.js";

export default {
  name: "PlayerProcessedVideo",
  directives: { DResizeObserver },
  watch: {
    showMavenQA(val) {
      this.setIsQaTabOpen({ isOpen: val });
    },
    audioChoice: function () {
      this.speakIn(this.audioChoice);
    },
    subtitlesChoice: function () {
      this.setSubtitles();
    },
    playing: function () {
      const event = this.playing ? "on_play" : "on_pause";
      this.trackPlayer({
        ...this.getTrackPlayerArgsWhenOnPalyOrPause({
          event,
        }),
      });

      this.playOrPauseVideo();
      if (this.playing) {
        if (this.showIcons) {
          this.hideIconsOnIdle();
        }
      }
    },
    steps: function () {
      this.language = this.steps[this.currentStep].languageCode;
    },
    isMuted: function () {
      const foreign = this.$refs.PlayerProcessedLanguage.audioPlayer;
      if (this.language == this.steps[this.currentStep].languageCode || this.language == "default") {
        this.setMuteStatus();
      } else {
        if (this.isMuted) {
          foreign.muted = true;
        } else {
          foreign.muted = false;
        }
      }
    },
    currentVideoTime: function (currentVideoTime) {
      this.$emit("fetchVideoDataAndCurrentStepIndex", { currentVideoTime });
      if (this.playing && Math.floor(this.currentVideoTime) == 2 && !this.isShowingTaskName) {
        this.showTaskName();
      }
    },
    currentStep: function (currentStepIndex, prevStepIndex) {
      this.$emit("fetchVideoDataAndCurrentStepIndex", { currentStepIndex });
      watchOnStepChangedWithVideoTracker({
        mixinTrackerRef: this,
        prevStepIndex,
        currentStepIndex,
        steps: this.steps,
      });

      //move back to the beginning of the step when changing steps
      if (!this.useHlsAutoStartLoad) {
        if (this.hlsRefs[this.previousStep] && this.previousStep < this.steps.length) {
          this.hlsRefs[this.previousStep].stopLoad();
        }

        this.hlsRefs.forEach((hls, index) => {
          if (index == this.currentStep) {
            hls.startLoad();
          } else {
            let performanceStepOffset = index - this.currentStep;
            if (performanceStepOffset > 0 && performanceStepOffset <= this.performanceStepCount) {
              hls.startLoad();
            }
          }
        });
      }
      this.setSubtitles();
      this.playOrPauseVideo();
      this.setCurrentStepIdx({ index: currentStepIndex }); // update current step index in steps sidebar
      this.setVideoElement(this.currentVideoPlayer);
      if (this.getter_is_repeat_step) {
        this.changeRepeatType({ type: REPEAT_TYPES.WORKFLOW });
      }
    },
    videoResolution: function () {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_change_rez",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
        settings: { rez: this.videoResolution },
      });

      this.updateVideoSources();
    },
    menuHidden(menuHidden) {
      this.togglePlayerAnnotation(!menuHidden);
    },
    showPlayerSearch(showPlayerSearch) {
      this.togglePlayerAnnotation(showPlayerSearch);
    },
    isWorkflowURLsUpdated: {
      handler(isWorkflowURLsUpdated) {
        if (isWorkflowURLsUpdated) {
          this.updateVideoSources();
        }
      },
    },
    getter_current_step_idx: {
      handler(getter_current_step_idx) {
        if (getter_current_step_idx >= 0 && getter_current_step_idx !== this.currentStep) {
          this.setCurrentStep(getter_current_step_idx);
        }
      },
    },
    getter_is_side_menu_open_desktop_ui: {
      handler() {
        this.handleResize();
      },
      immediate: true,
    },
    getter_is_window_mode: {
      handler() {
        this.handleResize();
      },
    },
    getter_is_repeat_workflow: {
      handler(getter_is_repeat_workflow) {
        const ele = this.$refs.DPlayerSetting;
        if (ele && getter_is_repeat_workflow) ele.changeAutoPlay(getter_is_repeat_workflow);
      },
    },
    getter_is_repeat_step: {
      handler(getter_is_repeat_step) {
        const ele = this.$refs.DPlayerSetting;
        if (ele && getter_is_repeat_step) ele.changeAutoPlay(getter_is_repeat_step);
      },
    },
    getter_is_quiz_screen_open(isOpen) {
      if (isOpen) {
        this.pauseVideo();
      }
    },
  },
  props: {
    workflow: {
      type: Object,
    },
    steps: {
      type: Array,
    },
    attachmentsData: {
      type: Array,
      default: () => [],
    },
    stepId: {
      type: String,
    },
    startTimestamp: {
      type: Number,
    },
    isPublic: {
      type: Boolean,
    },
    isUsingIframe: {
      type: Boolean,
    },
    isEmbedDeephow: {
      type: Boolean,
    },
    iframeFullscreen: {
      type: Boolean,
    },
    isSkillPlayer: {
      type: Boolean,
    },
    isSearchPlayer: {
      type: Boolean,
    },
    isNewPlayer: {
      type: Boolean,
    },
    isFromRustici: {
      type: Boolean,
    },
    hideCloseBtn: {
      type: Boolean,
    },
    showTopBarForcible: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.mounted = true;
    // for iOS devices force to start loading video when open player in iPad
    if (!this.useHlsByDefault && (IS_TABLET || IS_SAFARI)) {
      this.videoResolution = "360";
      const self = this;
      clearInterval(this.loadingInterval);
      this.loadingInterval = setInterval(function () {
        if (self.$refs.videoPlayer[self.currentStep] && self.videoSources[self.currentStep]) {
          self.$refs.videoPlayer[self.currentStep].load();
          clearInterval(self.loadingInterval);
        }
      }, 500);
    }
    //setVideoResolution not working for Safari
    if (!IS_TABLET && !IS_SAFARI) {
      this.setVideoResolution();
    }
    this.updateVideoSources();
    this.language = this.steps[this.currentStep].languageCode;
    if (!IS_TABLET && !IS_SAFARI) {
      this.$nextTick((e) => {
        window.addEventListener("keyup", this.handleKeyupEventListener);
      });
    }
    eventbus.$on("stopPlaybackClosePlayer", () => {
      if (this.currentVideoPlayer) {
        this.closeVideoPlayer();
      }
    });
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    this.hlsRefs.forEach((hls, index) => {
      hls.destroy();
    });
    window.removeEventListener("keyup", this.handleKeyupEventListener);
    clearInterval(this.loadingInterval);
    window.addEventListener("resize", this.handleResize);
  },
  updated() {
    if (!this.audioChoice) {
      this.audioChoice = this.steps[this.currentStep].languageCode;
    }
    if (!this.videoSources[this.currentStep] && !this.useHlsByDefault) {
      //for iOS devices
      this.updateVideoSources();
    }
    if (!this.hlsIsSet) {
      // this.setHls();
      this.hlsIsSet = true;
    }
  },
  data() {
    return {
      IS_TABLET,
      IS_SAFARI,
      autoNextStep: true,
      playing: false,
      isMuted: false,
      videoRepeat: 0,
      menuHidden: true,
      currentStep: 0,
      previousStep: 0,
      currentTime: [], //keeps track of the video's currentTime
      showIcons: true,
      language: "",
      subtitlesChoice: null,
      audioChoice: null,
      videoCurrentTime: "",
      isShowingTaskName: false,
      fadeOutTimeout: null,
      taskNameTimeout: null,
      hideIconsTimeout: null,
      videoRetryTimeout: null,
      videoResolution: "720", //default video resolution to 720
      progress: [],
      currentVideoTime: 0, //keeps track of the currentTime of the current video
      onTimeUpdateWasSet: false,
      videoSources: [],
      videoSwapped: false,
      loadingLanguage: false,
      showSettingPopup: false,
      wasPlaying: false,
      showPlayerSearch: false,
      firstLoadingVideo: true,
      isLoadingVideo: false,
      hasHls: false,
      hlsIsSet: false,
      hlsStarted: [], //track hls loading
      hlsRefs: [],
      hlsRezOptions: ["Auto", "360", "480", "720", "1080"],
      hlsCurrentLevel: 0,
      enableText2Speech: false,
      playSpeed: 1,
      speedOption: [0.5, 0.75, 1, 1.25, 1.5, 2],
      useHlsByDefault: true,
      useHlsAutoStartLoad: false,
      performanceStepCount: 1,
      loadingInterval: null,
      isUserSetSubtitleToNull: false,
      volume: 1,
      isSmallScreenSettings: false,
      showMavenQA: false,
    };
  },
  mixins: [MixinDB, MixinTracker, MixinUser, MixinVideo, MixinIdle],
  components: {
    PlayerLoading,
    PlayerProcessedMenu,
    PlayerVoiceAssistant,
    PlayerSearch,
    PlayerMavenQa,
    PlayerAttachments,
    PlayerProcessedLanguage,
    DStepNameTag,
    DProgressBar,
    DPlayerSetting,
    ReactionsInPlayer,
    ControlsDesktop,
    ControlsIframe,
    AttachmentsBar,
  },
  created() {
    this.detectFullscreen();
    this.setCurrentStepById(this.stepId);
    document.addEventListener("fullscreenchange", this.detectFullscreen);
    if (this.$enableText2Speech) {
      this.enableText2Speech = true;
    }
    //only check auth if not public
    if (!this.isPublic) {
      this.auth().onAuthStateChanged((user) => {
        if (!user) {
          this.$router.push("/");
          location.reload();
        } else {
          if (!this.$organization) {
            //if group is not yet available (when the user refresh the page)
            this.getUserData();
          }
        }
      });
    }
    if (this.isPublic) {
      this.firstLoadingVideo = false;
    }

    window.addEventListener("beforeunload", this.beforeWindowUnload);

    this.$syncPlayerTracking.bindingGetVideoPlayerPauseArgs(() => {
      return this.getTrackPlayerArgsWhenOnPalyOrPause({
        event: "on_pause",
      });
    });
    this.$syncPlayerTracking.bindingGetVideoPlayerPlayArgs(() => {
      return this.getTrackPlayerArgsWhenOnPalyOrPause({
        event: "on_play",
      });
    });
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
    document.removeEventListener("fullscreenchange", this.detectFullscreen);
  },
  methods: {
    ...mapActions("workflowPlayer", [
      "setSelectedAttachmentStep",
      "setCurrentStepIdx",
      "setVideoElement",
      "enterDesktopFullscreen",
      "exitDesktopFullscreen",
      "changeTheaterMode",
      "changeRepeatType",
      "setIsQaTabOpen",
    ]),
    handleVideoCanplay(idx) {
      this.isLoadingVideo = false;
      if (this.firstLoadingVideo && idx === this.currentStep) {
        const validTimestamp = this.startTimestamp && this.startTimestamp < this.steps[this.currentStep].duration;
        if (validTimestamp) {
          this.$refs.videoPlayer[this.currentStep].currentTime = this.startTimestamp;
        }
        this.firstLoadingVideo = false;
        const canPlay = !IS_SAFARI && !IS_TABLET && !this.isUsingIframe && !this.showPlayerSearch;
        if (canPlay) {
          this.playing = true;
          this.playOrPauseVideo();
        }
      }
      if (this.$backendType === "ali") {
        clearTimeout(this.videoRetryTimeout);
      }
    },
    handleVideoWaiting() {
      this.isLoadingVideo = true;
      if (this.$backendType === "ali") {
        clearTimeout(this.videoRetryTimeout);
        this.videoRetryTimeout = setTimeout(() => {
          this.resetVideoSource();
        }, 1500);
      }
    },
    resetVideoSource() {
      const reset = this.currentVideoTime > 0 && this.isLoadingVideo && this.playing;
      if (!reset) return;
      this.playing = false;
      this.playOrPauseVideo();
      const currentSources = this.videoSources[this.currentStep];
      this.videoSources[this.currentStep] = "";
      setTimeout(() => {
        this.videoSources[this.currentStep] = currentSources;
        this.playing = true;
        this.playOrPauseVideo();
      }, 100);
    },
    // Using debounce to track sound status by Heap.
    trackMuteStatusByHeap: debounce(function () {
      Analytics.setTrack({
        category: "PlayerMain",
        action: "Adjust Video Setting",
        name: "Sound status in Player",
        params: {
          dimension3: this.isMuted,
        },
      });
    }, 3000),
    // Using debounce to track auto-play status by Heap.
    trackAutoPlayStatusByHeap: debounce((autoPlay) => {
      Analytics.setTrack({
        category: "PlayerMain",
        action: "Adjust Video Setting",
        name: "Auto Play status in Player",
        params: {
          dimension3: autoPlay,
        },
      });
    }, 3000),
    handleResize() {
      this.setPlayerSettingsUI();
    },
    setPlayerSettingsUI() {
      if (!this.$refs.desktopPlayer) return;
      const MAX_WIDTH = 600;
      const playerWidth = this.$refs.desktopPlayer.clientWidth;
      this.isSmallScreenSettings = playerWidth < MAX_WIDTH;
    },
    handleVideoResizeEvent: debounce(function ({ clientWidth, clientHeight }) {
      this.$emit("fetchVideoDataAndCurrentStepIndex", { clientWidth, clientHeight });
    }, 500),
    togglePlayerAnnotation(isShow) {
      if (this.wasPlaying && isShow) {
        setTimeout(() => {
          this.$emit("update:isPlayerAttachmentShowUp", isShow);
        }, 300);
      } else {
        this.$emit("update:isPlayerAttachmentShowUp", isShow);
      }
    },
    getTrackPlayerArgsWhenOnPalyOrPause({ event }) {
      return {
        workflow: this.workflow,
        event,
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
      };
    },
    beforeWindowUnload(e) {
      if (this.isFromRustici) return;
      e.preventDefault();
      e.returnValue = "";
    },
    closeVideoPlayer() {
      this.playing = false;
      this.playOrPauseVideo();
      if (this.$refs.videoPlayer[this.currentStep]) {
        this.$refs.videoPlayer[this.currentStep].pause();
        this.$refs.videoPlayer[this.currentStep].removeAttribute("src");
        this.$refs.videoPlayer[this.currentStep].load();
      }
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_close_video_player",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
      });
      closePlayerFullscreen();

      this.$nextTick(() => {
        if (!this.isSkillPlayer && !this.isSearchPlayer) {
          this.$emit("closePlayerEvent");
        }
      });
    },
    detectFullscreen() {
      const isFullscreen = isShowingFullscreen();
      if (isFullscreen) {
        this.enterDesktopFullscreen();
      } else {
        this.exitDesktopFullscreen();
      }
    },
    changeFullscreen() {
      const isFullscreen = isShowingFullscreen();

      Analytics.setTrack({
        category: "PlayerMain",
        action: "Adjust Video Setting",
        name: "Screen status in Player",
        params: {
          dimension3: !isFullscreen,
        },
      });

      if (isFullscreen) {
        this.closeAllComponents();
        closePlayerFullscreen();
        this.showMavenQA = false;
      } else {
        togglePlayerFullscreen();
      }
    },
    closeAllComponents() {
      this.showSettingPopup = false;
      this.clickToCloseStepMenu();
      this.clickToCloseSearch();
    },
    setCurrentStepById(stepId) {
      if (!stepId) {
        this.setCurrentStepIdx({ index: 0 });
        return;
      }
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i].id == stepId) {
          this.currentStep = i;
          this.setCurrentStepIdx({ index: i });
          break;
        }
      }
    },
    jumpToSearchResult(result) {
      if (this.playing) {
        this.wasPlaying = true;
        this.playing = false;
        this.playOrPauseVideo();
      }
      this.currentStep = result.stepNumber;
      this.$refs.videoPlayer[this.currentStep].currentTime = result.startTime;
      setTimeout(() => {
        this.clickToCloseSearch();
      }, 100);
    },
    /////// Hls methods //////////
    setHlsRez(level) {
      if (!this.useHlsAutoStartLoad) {
        this.hlsRefs.forEach((hls, index) => {
          if (index == this.currentStep) {
            console.log("[setHlsRez] Step", this.currentStep, "level", level - 1);
            hls.currentLevel = level - 1;
          } else {
            let performanceStepOffset = index - this.currentStep;
            if (performanceStepOffset > 0 && performanceStepOffset <= this.performanceStepCount) {
              console.log("[setHlsRez] performanceStep", this.currentStep + performanceStepOffset, "level", level - 1);
              hls.currentLevel = level - 1;
            } else {
              hls.loadLevel = level - 1;
            }
          }
        });
      } else {
        this.hlsRefs.forEach((hls, index) => {
          hls.currentLevel = level - 1; // -1 is auto, 0 is the lowest (in the list 0 is auto)
        });
      }
      this.hlsCurrentLevel = level; //keeps track which level was selected
    },
    setHls(videoURL, index) {
      const self = this;
      this.hasHls = true;
      this.hlsStarted[index] = true;
      const video = this.$refs.videoPlayer[index];
      const autoStartLoad = this.useHlsAutoStartLoad;
      const config = {
        //debug: true,
        // maxBufferSize: 30* 1000 * 1000, //30MB
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90,
        //abrBandWidthFactor: 1.1,
        abrBandWidthUpFactor: 0.8,
        autoStartLoad: autoStartLoad,
        // initialLiveManifestSize: 10,
        // maxBufferSize: 600 * 1000 * 1000,
        startLevel: 2,
        xhrSetup: function (xhr, url) {
          xhr.setRequestHeader("Access-Control-Allow-Headers", "Content-Type, Accept, X-Requested-With");
          xhr.setRequestHeader("Access-Control-Allow-Origin", "deephow.ai");
          xhr.setRequestHeader("Access-Control-Allow-Credentials", "true");
        },
      };
      if (Hls.isSupported()) {
        var hls = new Hls(config);
        hls.loadSource(videoURL);
        hls.attachMedia(video);
        //error handler
        hls.on(Hls.Events.ERROR, function (event, data) {
          // alert("Step" + (index+1) + " " + data.type + ": " + data.details);
        });
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          if (!self.useHlsAutoStartLoad) {
            if (index == self.currentStep) {
              this.startLoad();
            } else {
              // preload next steps by performanceStepCount
              let performanceStepOffset = index - self.currentStep;
              if (performanceStepOffset > 0 && performanceStepOffset <= self.performanceStepCount) {
                this.startLoad();
              }
            }
          }
          self.hlsRefs[index] = this;
          if (this.playing) {
            video.play().catch((err) => {
              this.playing = false;
              loggingError(err);
            });
          } else {
            video.pause();
          }
        });
      }
      // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
      // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
      // This is using the built-in support of the plain video element, without using hls.js.
      // Note: it would be more normal to wait on the 'canplay' event below however on Safari (where you are most likely to find built-in HLS support) the video.src URL must be on the user-driven
      // white-list before a 'canplay' event will be emitted; the last video event that can be reliably listened-for when the URL is not on the white-list is 'loadedmetadata'.
      else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // video.src = "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8";
        // video.addEventListener("loadedmetadata", function() {
        //   video.play();
        // });
      }
    },
    /////// new methods //////////
    playOrPauseVideoByClickVideo() {
      this.playing = !this.playing;
    },
    handleKeyupEventListener(event) {
      const disableListener = this.isTypingWithPlayer || this.showPlayerSearch || !this.menuHidden || this.isQaTabOpen;
      if (disableListener) return;
      event.preventDefault();
      const keyCode = event.keyCode;
      switch (keyCode) {
        case 32: // SpaceBar - play Or Pause Video
          this.playing = !this.playing;
          break;
        case 70: // “F” key - change Fullscreen mode
          this.changeFullscreen();
          break;
        case 84: // “T” key - change Theater mode
          if (this.enableTheaterButton) {
            this.changeTheaterMode();
          }
          break;
      }
    },
    clickToShowSearch() {
      this.showPlayerSearch = true;
      this.wasPlaying = this.playing;
      this.playing = false;
    },
    clickToCloseSearch() {
      this.showPlayerSearch = false;
      if (this.wasPlaying) {
        this.playing = true;
        this.playOrPauseVideo();
      }
    },
    finishLoading() {
      this.loadingLanguage = false;
      if (this.wasPlaying) {
        this.playing = true;
        this.playOrPauseVideo();
        this.$refs.PlayerProcessedLanguage.audioPlayer.play();
      }
    },
    handleVideoLoadedData(idx) {
      //turn on subtitle by default
      const isNotYetSetupSubtitleLanguage = this.subtitlesChoice === null;
      if (isNotYetSetupSubtitleLanguage && !this.isUserSetSubtitleToNull) {
        this.subtitlesChoice = this.checkSubtitleMatchUserLocal(idx);
      }

      saveInitialStepWithVideoTrackerOnce({
        mixinTrackerRef: this,
        currentStepIndex: this.currentStep,
        steps: this.steps,
      });
    },
    checkSubtitleMatchUserLocal(idx) {
      if (!this.steps[idx].subtitles || !this.$locale) return this.workflow.languageCode;

      const userLanguage = this.$locale;
      const subtitleOptions = this.steps[idx].subtitles.map((subtitle) => subtitle.language);
      return (
        subtitleOptions.find((subtitle) => subtitle === userLanguage) ??
        subtitleOptions.find((subtitle) => subtitle.substr(0, 2) === userLanguage.substr(0, 2)) ??
        this.workflow.languageCode
      );
    },
    showOrHideIcons() {
      if (this.showIcons) {
        this.actionToHideIcons();
      } else {
        this.actionToShowIcons();
      }
    },
    actionToShowIcons() {
      this.showIcons = true;
      this.hideIconsOnIdle();
    },
    actionToHideIcons() {
      clearInterval(this.hideIconsTimeout);
      this.showIcons = false;
    },
    hideIconsOnIdle() {
      clearInterval(this.hideIconsTimeout);
      this.hideIconsTimeout = setInterval(() => {
        this.showIcons = false;
      }, 4000);
    },
    getStepTitle(idx) {
      const stepIdx = idx;
      if (stepIdx < 0 || stepIdx == this.steps.length) {
        return "";
      } else {
        return this.steps[stepIdx].title;
      }
    },
    clickToShowStepsMenu() {
      this.menuHidden = false;
      this.wasPlaying = this.playing;
      this.playing = false;
    },
    clickToCloseStepMenu() {
      this.menuHidden = true;
      if (this.wasPlaying) {
        this.playing = true;
      }
    },
    playBack() {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_backward",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
        settings: {},
        currentTime: this.currentVideoTime,
      });

      this.wasPlaying = this.playing;
      this.playing = false;
      if (this.currentVideoTime + 5 >= 0) {
        this.$refs.videoPlayer[this.currentStep].currentTime = this.currentVideoTime - 5;
      } else {
        this.$refs.videoPlayer[this.currentStep].currentTime = 0;
      }
      this.playing = this.wasPlaying;

      Analytics.setTrack({
        category: "PlayerMain",
        action: "Video Palyer",
        name: "Previous Five Seconds in Player",
      });
    },
    playForward() {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_forward",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
        settings: {},
        currentTime: this.currentVideoTime,
      });
      this.wasPlaying = this.playing;
      this.playing = false;
      if (this.currentVideoTime + 5 < this.steps[this.currentStep].duration) {
        this.$refs.videoPlayer[this.currentStep].currentTime = this.currentVideoTime + 5;
        this.playing = this.wasPlaying;
      } else {
        this.$refs.videoPlayer[this.currentStep].currentTime = this.steps[this.currentStep].duration;
        this.playing = false;
      }

      Analytics.setTrack({
        category: "PlayerMain",
        action: "Video Palyer",
        name: "Next Five Seconds in Player",
      });
    },
    temporaryPauseVideo() {
      if (this.playing) {
        this.wasPlaying = true;
        this.playing = false;
      }
    },
    keepPlayingVideo() {
      if (this.wasPlaying) {
        this.playing = true;
        this.wasPlaying = false;
      }
    },
    mousedownTimePosition() {
      this.wasPlaying = this.playing;
      this.playing = false;
    },
    setTimePositionNew() {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_progress_bar",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
        currentTime: this.$refs.videoPlayer[this.currentStep].currentTime,
      });
      this.$refs.videoPlayer[this.currentStep].currentTime = this.currentVideoTime;
    },
    setTimePosition() {
      this.$refs.videoPlayer[this.currentStep].currentTime = this.currentVideoTime;
      if (this.wasPlaying) {
        this.$nextTick(() => {
          this.playing = true;
          this.playOrPauseVideo();
        });
      }
    },
    timeUpdateEvent(idx) {
      const videoPlayerInstance = this.$refs.videoPlayer[idx];
      if (!videoPlayerInstance) {
        return;
      }
      this.currentTime[idx] = videoPlayerInstance.currentTime;
      this.currentVideoTime = this.currentTime[idx];
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_playing",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
        playSpeed: this.playSpeed,
      });
      this.updateIdleTimeout();
    },
    autoPlayNextVideo() {
      const isLatestStep = this.currentStep == this.steps.length - 1;

      //go to next step when video ends if user choise auto play
      if (!isLatestStep && this.autoNextStep) {
        //emit event for the previous video
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_ending",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
          nextStep: this.steps[this.currentStep + 1],
        });

        this.previousStep = this.currentStep;
        this.currentStep++;
        //emit event for the new video
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_playNext",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
          nextStep: this.steps[this.currentStep + 1],
        });
        this.playNewStep();
        return;
      }

      this.playing = false;
      this.playOrPauseVideo();
      if (isLatestStep) {
        this.$emit("workflow-ended");
      }
      this.videoEnded();
    },
    videoEnded() {
      this.trackPlayer({
        workflow: this.workflow,
        event: "on_ending",
        step: this.steps[this.currentStep],
        organization: this.getter_organization,
        user: this.$user,
      });
    },
    getStepNum(index) {
      index++;
      if (index < 10) {
        return "0" + index.toString();
      } else {
        return index.toString();
      }
    },
    getUserData: function () {
      Vue.prototype.$user = this.auth().currentUser;
      this.getOrganization();
    },
    getOrganization() {
      const self = this;
      this.getDocument("users", this.$user.uid)
        .then((user) => {
          Vue.prototype.$organization = user.organization;
        })
        .catch((err) => {
          loggingError(err);
          alert("failed to get organization.");
        });
    },
    setVideoResolution() {
      const speed = navigator.connection.downlink;
      if (speed >= 10) {
        this.videoResolution = "1080";
      } else if (speed >= 1) {
        this.videoResolution = "480";
      }
    },
    updateVideoSources() {
      const sourceSteps = this.steps;
      // TODO Dev cn nginx POC
      this.$store.dispatch("docGoogleUrlBridgeReplacement", sourceSteps);

      // get video source based on resolution and availability
      for (let i = 0; i < sourceSteps.length; i++) {
        // Hls is not smooth on iPad
        const useHlsUrl = !IS_TABLET && sourceSteps[i].hlsUrl;
        if (this.getter_is_gen2) {
          this.$refs.videoPlayer[i].src = sourceSteps[i].video;
        } else if (useHlsUrl) {
          if (!this.videoSources[i] && !this.hlsStarted[i]) {
            const isSigned = checkIsSignedUrl(sourceSteps[i].hlsUrl);
            if (isSigned) {
              this.setHls(sourceSteps[i].hlsUrl, i);
            }
          }
        } else {
          if (sourceSteps[i].videos) {
            if (sourceSteps[i].videos[this.videoResolution]) {
              this.videoSources[i] = sourceSteps[i].videos[this.videoResolution];
            } else {
              //resolution not available
              const lowestAvailable = Object.keys(sourceSteps[i].videos).sort(function (a, b) {
                return a - b;
              })[0];
              // console.log("video for " + i + ": " + lowestAvailable);
              if (parseInt(this.videoResolution) > parseInt(lowestAvailable)) {
                this.videoResolution = lowestAvailable;
              }
              this.videoSources[i] = sourceSteps[i].videos[lowestAvailable];
            }
            this.$refs.videoPlayer[i].src = this.videoSources[i];
            //restart the video at where it was before rez change
            if (this.currentTime[i] > 0) {
              this.$refs.videoPlayer[i].currentTime = this.currentTime[i];
            }
          }
        }
      }
      this.playOrPauseVideo();
      this.setVideoElement(this.currentVideoPlayer);
    },
    setMuteStatus() {
      for (let i = 0; i < this.steps.length; i++) {
        this.$refs.videoPlayer[i].muted = this.isMuted;
      }
    },
    showTaskName() {
      //must clear previous timeout
      clearTimeout(this.taskNameTimeout);
      this.isShowingTaskName = true;
      this.taskNameTimeout = setTimeout(() => {
        this.isShowingTaskName = false;
      }, 6000);
    },
    cancelShowTaskName() {
      this.isShowingTaskName = false;
      //must clear previous timeout
      clearTimeout(this.taskNameTimeout);
    },
    setSubtitles() {
      const { isPreviewVideoMode } = this.$route.params;
      if (isPreviewVideoMode && !this.steps[this.currentStep].subtitles) return;
      //set subtitle to off if the language it's not available
      let languageIdx = -1;
      for (let i = 0; i < this.steps[this.currentStep].subtitles.length; i++) {
        if (this.steps[this.currentStep].subtitles[i].language == this.subtitlesChoice) {
          languageIdx = i;
        }
      }
      if (languageIdx == -1) {
        this.subtitlesChoice = null;
      }
      if (this.subtitlesChoice != null) {
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_subtitles",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
          settings: { languageCode: this.subtitlesChoice },
        });

        for (let i = 0; i < this.steps[this.currentStep].subtitles.length; i++) {
          if (i != languageIdx) {
            this.$refs.videoPlayer[this.currentStep].textTracks[i].mode = "disabled";
          } else {
            this.$refs.videoPlayer[this.currentStep].textTracks[languageIdx].mode = "showing";
          }
        }
      } else {
        for (let i = 0; i < this.steps[this.currentStep].subtitles.length; i++) {
          this.$refs.videoPlayer[this.currentStep].textTracks[i].mode = "disabled";
        }
      }
    },
    speakIn(language) {
      //this will trigger PlayerProcessedLanguage to work
      if (language == this.steps[this.currentStep].languageCode) {
        this.language = this.steps[this.currentStep].languageCode;
        this.$refs.videoPlayer[this.currentStep].muted = false;
        this.$refs.PlayerProcessedLanguage.audioPlayer.muted = true;
      } else {
        this.language = language;
        this.wasPlaying = this.playing;
        this.playing = false;
        this.playOrPauseVideo();
        this.loadingLanguage = true;
        this.$refs.videoPlayer[this.currentStep].muted = true;
        this.$refs.videoPlayer[this.currentStep].pause();
        this.$refs.PlayerProcessedLanguage.setAudioPlayer(language);
      }
    },
    setCurrentStep(index) {
      ///called when user clicks steps-menu
      if (index == this.currentStep) {
        this.clickToCloseStepMenu();
      } else {
        this.menuHidden = true; //hide menu after clicking step
        this.previousStep = this.currentStep;
        this.currentStep = index;
        if (this.wasPlaying) {
          this.playing = true;
        }
        this.playNewStep();
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_change_step_from_menu",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
        });
      }
    },
    playNewStep() {
      //this is called when currentStep changes
      this.updateAudioAndSubtitles();
      this.$refs.videoPlayer[this.previousStep].pause();
      this.$refs.videoPlayer[this.currentStep].currentTime = 0;
      this.playOrPauseVideo();
    },
    updateAudioAndSubtitles() {
      //set audio to defult if the audio choice is not available in the new step
      if (
        this.audioChoice != this.steps[this.currentStep].languageCode &&
        this.audioOptions.indexOf(this.audioChoice) === -1
      ) {
        this.audioChoice = this.steps[this.currentStep].languageCode;
      }
    },
    pauseVideo() {
      this.playing = false;
      this.playOrPauseVideo();
    },
    playOrPauseVideo(isTrackingByHeap) {
      //this is called when "playing" changes
      if (this.playing && this.getter_is_quiz_screen_open) {
        this.playing = false;
      }

      const videoPlayer = this.currentVideoPlayer;
      videoPlayer.playbackRate = this.playSpeed;
      if (this.playing) {
        videoPlayer.play().catch((err) => {
          this.playing = false;
          loggingError(err);
        });
      } else {
        videoPlayer.pause();
      }
      videoPlayer.muted = this.isMuted;
      videoPlayer.volume = this.volume;

      // If function got param value if true, then track event by Heap.
      if (isTrackingByHeap) {
        Analytics.setTrack({
          category: "PlayerMain",
          action: "Video Palyer",
          name: "Play/Pause in Player",
        });
      }
    },
    playPreviousStep() {
      this.cancelShowTaskName();
      if (!this.isPublic) {
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_previous",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
        });
      } else {
        this.trackPlayer({
          workflow: { ...this.workflow, id: "" },
          event: "on_previous_public",
          step: this.steps[this.currentStep],
          previousStep: this.steps[this.currentStep - 1],
        });
      }
      this.previousStep = this.currentStep;
      if (this.currentStep > 0) {
        this.currentStep = this.currentStep - 1;
      }
      this.playNewStep();

      Analytics.setTrack({
        category: "PlayerMain",
        action: "Video Palyer",
        name: "Previous Step in Player",
      });
    },
    playNextStep() {
      this.cancelShowTaskName();
      if (!this.isPublic) {
        this.trackPlayer({
          workflow: this.workflow,
          event: "on_next",
          step: this.steps[this.currentStep],
          organization: this.getter_organization,
          user: this.$user,
          nextStep: this.steps[this.currentStep + 1],
        });
      } else {
        this.trackPlayer({
          workflow: { ...this.workflow, id: "" },
          event: "on_next_public",
          step: this.steps[this.currentStep],
          nextStep: this.steps[this.currentStep + 1],
        });
      }
      this.previousStep = this.currentStep;
      const totalSteps = this.steps.length - 1;
      if (this.currentStep < totalSteps) {
        this.currentStep = this.currentStep + 1;
        this.playNewStep();
      }

      Analytics.setTrack({
        category: "PlayerMain",
        action: "Video Palyer",
        name: "Next Step in Player",
      });
    },
    muteVideoButton() {
      this.isMuted = !this.isMuted;
      if (!this.isMuted && this.volume === 0) {
        this.updateVolume(0.5);
      }
      this.trackMuteStatusByHeap();
    },
    updateVolume(newVolume) {
      this.volume = newVolume;
      this.currentVideoPlayer.volume = this.volume;
      this.isMuted = this.volume === 0;
    },
    setPlaybackRate() {
      const videoPlayer = this.currentVideoPlayer;
      videoPlayer.playbackRate = this.playSpeed;
    },
    changeAutoPlay({ autoPlay }) {
      this.autoNextStep = autoPlay;
      const isRepeatOn = this.getter_is_repeat_workflow || this.getter_is_repeat_step;
      if (!autoPlay && isRepeatOn) this.changeRepeatType({ type: REPEAT_TYPES.DISABLE });
      this.trackAutoPlayStatusByHeap(autoPlay);
    },
    repeatCurrentStep() {
      this.$refs.videoPlayer[this.currentStep].currentTime = 0;
      this.playOrPauseVideo();
    },
    repeatWorkflow() {
      if (this.currentStep === this.steps.length - 1) {
        this.previousStep = this.currentStep;
        this.currentStep = 0;
        this.playNewStep();
      } else this.autoPlayNextVideo();
    },
    handleVideoEnded() {
      if (this.getter_is_disabled_repeat) this.autoPlayNextVideo();
      else if (this.getter_is_repeat_workflow) this.repeatWorkflow();
      else if (this.getter_is_repeat_step) this.repeatCurrentStep();
    },
  },
  computed: {
    ...mapState("workflowPlayer", [
      "isTypingWithPlayer",
      "isWorkflowURLsUpdated",
      "isDesktopFullscreen",
      "isQaTabOpen",
    ]),
    ...mapGetters("workflowPlayer", [
      "getter_current_step_idx",
      "getter_show_attachments_popup_fullscreen",
      "getter_is_window_mode",
      "getter_is_disabled_repeat",
      "getter_is_repeat_workflow",
      "getter_is_repeat_step",
    ]),
    ...mapGetters("workflowQuiz", ["getter_is_quiz_screen_open"]),
    ...mapGetters(["getter_is_gen2", "getter_organization"]),
    ...mapGetters("global", ["getter_is_side_menu_open_desktop_ui"]),
    ...mapGetters("auth", ["getter_user_id"]),
    ...mapGetters("permission", ["getter_ai_qa_feature_enabled", "getter_ai_qa_enabled"]),
    safetyInfoWatermark() {
      return this.isPublic ? this.workflow.author.uid : this.getter_user_id;
    },
    isDisabledPictureInPicture() {
      return this.isUsingIframe && !this.isEmbedDeephow;
    },
    showReactionsBtn() {
      const showingComponents = this.showPlayerSearch || !this.menuHidden;
      return (!this.getter_is_window_mode || this.isEmbedDeephow) && !showingComponents && !this.hideControls;
    },
    showQABtn() {
      return this.workflow.languageCode === "en-us" && this.getter_ai_qa_enabled && this.getter_ai_qa_feature_enabled;
    },
    hideControls() {
      return !this.showIcons && this.playing && !this.showSettingPopup;
    },
    showAttachmentsBtn() {
      const showingComponents =
        this.showPlayerSearch || !this.menuHidden || this.getter_show_attachments_popup_fullscreen;
      return !showingComponents;
    },
    showCloseBtn() {
      return (
        !this.isUsingIframe &&
        !this.isPublic &&
        !this.isEmbedDeephow &&
        !this.isSkillPlayer &&
        !this.isSearchPlayer &&
        !this.hideCloseBtn
      );
    },
    audioOptions() {
      if (this.steps[this.currentStep].foreignLanguageAudio && this.enableText2Speech) {
        return Object.keys(this.steps[this.currentStep].foreignLanguageAudio);
      } else {
        return [];
      }
    },
    currentVideoPlayer() {
      return this.$refs.videoPlayer[this.currentStep];
    },
    currentStepName() {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      const step = this.steps[this.currentStep] || {};
      return getDisplayTitle(step, displayLang);
    },
    enableTheaterButton() {
      return !this.isEmbedDeephow && !this.isUsingIframe && !this.getter_is_gen2 && !this.isDesktopFullscreen;
    },
  },
};
</script>

<style lang="scss" scoped>
$smallPlayerMaxWidth: 1263px;
$z-index: (
  settings: 10,
  subtitles: 10,
  step-name-tag: 2,
  reactions-button: 11,
  addons-wrapper: 3,
);
.PlayerProcessedVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #0c0c0e;
  &__video {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    video {
      object-fit: contain;
      height: 100%;
      max-width: 100%;
    }
    video::cue {
      color: white;
      background-color: rgba(12, 12, 14, 0.6) !important;
      opacity: 1;
      font-size: 70%;
    }
    video::-webkit-media-text-track-container {
      z-index: map-get($z-index, subtitles);
      position: relative;
      overflow: visible !important;
      transform: translateY(-80px) !important;
      @media (max-width: $smallPlayerMaxWidth) {
        transform: translateY(-56px) !important;
      }
    }
  }
  &__subtitles {
    &--ipad {
      video::-webkit-media-text-track-container {
        transform: translateY(-40px) !important;
      }
    }
    &--iframe {
      video::-webkit-media-text-track-container {
        transform: translateY(-24px) !important;
      }
    }
    &--lower {
      video::-webkit-media-text-track-container {
        transform: translateY(-4px) !important;
      }
    }
  }
  &__addonsWrapper {
    z-index: map-get($z-index, addons-wrapper);
  }
  &__DPlayerSetting {
    z-index: map-get($z-index, settings);
    position: absolute;
    right: 92px;
    bottom: 72px;
    user-select: none;
    touch-action: auto;
    &--ipad {
      position: absolute;
      right: 66px;
      bottom: 90px;
      -webkit-touch-callout: none;
    }
    &--smallScreen {
      right: 0;
      bottom: 0;
      height: 100%;
      min-height: 100%;
      width: 100%;
      min-width: unset;
    }
  }
  &__reactionsButton {
    z-index: map-get($z-index, reactions-button);
    position: absolute;
    bottom: 15px;
    left: 82px;
    display: flex;
    .askMaven__btn {
      color: #fff;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    @media (max-width: $smallPlayerMaxWidth) {
      bottom: -2px;
      left: 56px;
    }
  }
  &__stepNameTag {
    z-index: map-get($z-index, step-name-tag);
    position: absolute;
    bottom: 128px;
    left: 32px;
    pointer-events: none;
  }
}
.maven_wrapper {
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 99;
}
</style>
