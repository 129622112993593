<template lang="html">
  <section class="mixin-user"></section>
</template>

<script>
import Vue from "vue";
import firebase from "firebase";
import axios from "axios";
import eventbus from "@/main";
import auth from "../../auth.js";
import AliOss from "../js/AliOss";
import Analytics from "../js/analytics/analytics";
import environment from "../../env";
import { recordLoginActivityOfEmailLogin } from "@/server/login-activity-server.js";
import { getBrowserVersion } from "@/js/getBrowserVersion.js";
import { setLogoutLocalStorage, setLogoutSharedAccountLocalStorage } from "@/js/login/login.js";
import { isEnableMasterPassword } from "@/js/employeeid-login/employeeid-login-service.js";
import {
  BROWSER_TAB_ID_KEY,
  BROWSER_TAB_KEY,
  LOGOUT_NOTIFY_KEY,
  DONT_SHOW_CATEGORY_POPUP,
  removeIdleLocalStorage,
  setLogoutImmediatelyLocalStorage,
} from "@/js/idle/idle.js";
import {
  extractEmployeeIdFromAccount,
  getEmployeeIdEmail,
  removeEmployeeIdAccountFromLocalStorage,
} from "@/js/user/employee-id.js";
import {
  getSharedAccountEmail,
  extractEmployeeIdFromSharedAccount,
  removeSharedAccountFromLocalStorage,
  isSharedAccount,
} from "@/js/user/share-account.js";
import { getEmployeeIdLoginToken, getDisposablePwd } from "@/server/admin-fixed-token-server.js";
import { employeeIdLogin, sharedAccountLogin } from "@/server/basic-authentication-server.js";
import { loggingError } from "@/server/error-log-server.js";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "mixin-user",
  data() {
    return {
      auth: auth,
      defaultCustomizedPermissions: {
        disablePublicLinks: false,
        disableDownloads: false,
        allowAutoLogout: false,
        autoLogoutTimeout: "15",
      },
      aliOss: AliOss,
      clientKey: "",
    };
  },
  computed: {
    ...mapGetters("permission", ["getter_dh_login_enabled"]),
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    ...mapActions("global", ["setUserAccessReady"]),
    ...mapActions("global", ["openAlert"]),
    ...mapActions("permission", ["setHasEmployeeIdLogin"]),
    signInWithSAML() {
      console.log("Logging with SAML");
      const provider = new firebase.auth.SAMLAuthProvider("saml.jumpcloud.dh.test");

      this.auth()
        .signInWithPopup(provider)
        .then((res) => {
          self.processLogin(res.credential);
        })
        .catch((e) => {
          if (e.code == "auth/account-exists-with-different-credential") {
            console.log("Account found with different credential");
            self.processLogin(e.credential);
          } else {
            console.log("Error in login", e);
            loggingError(e);
          }
        });
    },
    signInWithGoogleSSO() {
      this.launchSSOLogin(this.auth().signInWithPopup({ gcpIdp: "google" }));
    },
    signInWithMicrosoftSSO() {
      this.launchSSOLogin(this.auth().signInWithPopup({ gcpIdp: "microsoft" }));
    },
    signInWithCustomSSO(ssoConfig) {
      this.launchSSOLogin(this.auth().signInWithPopup(ssoConfig));
    },
    signOutWithError() {
      this.isLoggingInSso = false;
      this.isLoadingSso = false;
      const self = this;
      this.auth()
        .signOut()
        .then(() => {
          console.log("User signed out");
        })
        .catch((e) => {
          console.log("Could not sign out user");
          this.$store.commit("auth/SET_IS_LOGIN", false);
          loggingError(e);
        })
        .finally(async () => {
          self.$store.commit("global/MUTATE_ALERT_INFO", { message: "login failed", type: "error" });
          localStorage.removeItem("ssoCredential");
        });
    },
    launchSSOLogin(signInPromise) {
      const self = this;

      signInPromise
        .then((res) => {
          localStorage.setItem(
            "ssoCredential",
            JSON.stringify({
              ...res.credential,
              idToken: res.credential.idToken,
              accessToken: res.credential.accessToken,
            })
          );
          self.validateOAuth(res.credential).then((response) => {
            if (response === "error") {
              self.signOutWithError();
              return;
            }

            if (response.data.useCustomToken) {
              self
                .auth()
                .signInWithCustomToken(response.data.userToken)
                .catch(function (error) {
                  self.isLoggingInSso = false;
                  loggingError(error);
                  alert("Login failed");

                  return;
                });
            }

            //should only do this for the first time user
            //must refresh idToken to ensure the client side have updated token if the user is newly created
            this.auth()
              .currentUser.getIdToken(true)
              .then((idToken) => {
                self.$root.$children[0]
                  .initializeUser(self.auth().currentUser)
                  .then(() => {
                    // china sso need blow code to redirect.
                    // call App.vue redirectRouter function which contain gen2 logic and if userProfile not ready,
                    // call api immediately
                    self.$root.$children[0].redirectRouter();
                  })
                  .catch((e) => {
                    this.isLoggingInSso = false;
                    console.log("Cannot initialize user");
                    loggingError(e);
                  });
              })
              .catch((err) => {
                this.isLoggingInSso = false;
                console.log("Cannot get the idToken");
                loggingError(err);
              });
            localStorage.removeItem("ssoCredential");
          });
        })
        .catch((e) => {
          if (e.code == "auth/account-exists-with-different-credential") {
            console.log("Account found with different credential");

            self.validateOAuth(e.credential).then((response) => {
              if (response === "error") {
                self.signOutWithError();
                return;
              }

              self
                .auth()
                .signInWithCustomToken(response.data.userToken)
                .then(() => {
                  location.reload();
                })
                .catch(function (error) {
                  self.isLoggingInSso = false;
                  loggingError(error);
                  location.reload();
                });
            });
          } else {
            console.error(e);
            self.isLoggingInSso = false;
            self.isLoadingSso = false;
            loggingError(e);
          }
        });
    },
    validateOAuth(credential) {
      this.isLoadingSso = false;
      this.isLoggingInSso = true;
      const self = this;
      const data = {
        token: "rG5kXk0CDbhgF4RBlNoV",
        idToken: credential.idToken,
        accessToken: credential.accessToken,
        device: getBrowserVersion(),
        product: "web",
      };

      return new Promise((resolve, reject) => {
        axios
          .post(self.$service.admin + "sso/validate-oauth", data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            //console.log("validate oauth error", err);
            //reject(err);
            resolve("error"); // reject doesn't seem to work
          });
      });
    },
    getUidByEmail(email) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocumentByFieldValue("users", "email", email)
          .then((data) => {
            if (data.length > 0) {
              resolve(data[0].id);
            } else {
              resolve("no such user");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    generateClientKeyForEmployeeId({ employeeId }) {
      const hostname = window.location.hostname;
      const subdomain = hostname.slice(0, hostname.indexOf("."));
      let clientKey;
      if (employeeId.includes("@")) {
        clientKey = employeeId.slice(employeeId.indexOf("@") + 1);
      } else {
        clientKey = subdomain;
      }

      return clientKey;
    },
    /**
     * @params
     *  account: {employeeID}@{clientKey}
     *  employeeIdMP: master password
     * @return
     *  {
     *    ok: Boolean,
     *    data: { isSharedAccount: Boolean, clientKey: String, employeeId: String },
     *    errorMessage: String(if ok is true, errorMessage is null)
     *  }
     */
    async signInWithEmployeeId(account, employeeIdMP) {
      this.clientKey = this.generateClientKeyForEmployeeId({ employeeId: account });
      if (!this.clientKey) {
        return { ok: false, errorMessage: "client key is required for employee id login." };
      }
      return isSharedAccount({ account })
        ? this.loginWithSharedAccount(account, employeeIdMP)
        : this.loginWithEmployeeId(account, employeeIdMP);
    },
    async loginWithSharedAccount(account, employeeIdMP) {
      try {
        const employeeId = extractEmployeeIdFromSharedAccount({ account });
        const loginInfo = { clientKey: this.clientKey, employeeId, password: employeeIdMP };
        const { ok, data, errorMessage } = await sharedAccountLogin(loginInfo);
        if (!ok) {
          return { ok: false, errorMessage };
        }
        const { disposablePwd, refreshToken } = data.item;
        const email = getSharedAccountEmail({ clientKey: this.clientKey, employeeId });
        const { user } = await auth().signInWithEmailAndPassword(email, disposablePwd);
        // Record and Analytics
        Analytics.setEventProperties({
          Version: this.$settings.web.versions[environment.env]["minimum"],
        });
        return {
          ok: true,
          data: { isSharedAccount: true, account, user, refreshToken },
        };
      } catch (error) {
        return { ok: false, errorMessage: error.message };
      }
    },
    async loginWithEmployeeId(account, employeeIdMP) {
      try {
        const employeeId = extractEmployeeIdFromAccount({ account });
        const loginData = { clientKey: this.clientKey, employeeId, employeeIdMP };
        const { ok, data, errorMessage } = await employeeIdLogin(loginData);
        if (!ok) {
          return { ok: false, errorMessage };
        }
        const { disposablePwd, refreshToken } = data.item;
        const email = getEmployeeIdEmail({ clientKey: this.clientKey, employeeId });
        const { user } = await auth().signInWithEmailAndPassword(email, disposablePwd);
        // Record and Analytics
        Analytics.setEventProperties({
          Version: this.$settings.web.versions[environment.env]["minimum"],
        });
        return { ok: true, data: { isSharedAccount: false, account, user, refreshToken } };
      } catch (error) {
        return { ok: false, errorMessage: error.message };
      }
    },
    recoverPassword(email) {
      const self = this;
      return new Promise(function (resolve, reject) {
        const data = {
          token: "rG5kXk0CDbhgF4RBlNoV",
          email: email,
          baseUrl: self.$baseUrl,
          languageCode: self.$i18n.locale,
        };
        axios
          .post(self.$service.admin + "reset/forgot", data)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getIdToken() {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .auth()
          .currentUser.getIdToken()
          .then(function (idToken) {
            resolve(idToken);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    signInWithEmail(email, password) {
      if (email.indexOf("@") === -1) {
        email += "@deephow.ai";
      }

      return this.auth()
        .signInWithEmailAndPassword(email, password)
        .then((signInInfo) => {
          Analytics.setEventProperties({
            Version: this.$settings.web.versions[environment.env]["minimum"],
          });
          return signInInfo;
        })
        .then(({ user }) => {
          recordLoginActivityOfEmailLogin({
            user,
            deviceInfo: getBrowserVersion(),
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const aliErrorCodeMap = {
            2004: this.$t("auth.alertUserNotFound"),
            2006: this.$t("auth.wrongPassword"),
          };
          const globalErrorCodes = [
            { code: "user-not-found", message: this.$t("auth.alertUserNotFound") },
            { code: "wrong-password", message: this.$t("auth.wrongPassword") },
          ];
          if (environment.backendType === "ali") {
            const message = aliErrorCodeMap[errorCode] || this.$t("alert.unexpectedError");
            this.openAlert({ message, type: "alert" });
            loggingError(error);
            return;
          }
          const foundError = globalErrorCodes.find((error) => errorCode.includes(error.code));
          const message = foundError ? foundError.message : this.$t("alert.unexpectedError");
          this.openAlert({ message, type: "alert" });
          loggingError(error);
        });
    },
    /**
     * Sign in with shared account, and get master pwd through API
     * @return Boolean
     */
    async signInWithSharedAccount({ account }) {
      if (environment.backendType === "ali") {
        let isSuccess = true;
        const masterPwdRep = await isEnableMasterPassword({
          clientKey: this.$clientKey,
        });
        // Get disposable pwd by emplyee ID
        const employeeId = `${extractEmployeeIdFromSharedAccount({ account })}.shared`;
        const data = {
          clientKey: this.$clientKey,
          employeeId,
          employeeIdMP: masterPwdRep.password,
        };
        const {
          ok,
          data: { password },
        } = await getDisposablePwd(data);
        if (!ok) {
          isSuccess = false;
          return isSuccess;
        }

        // Ready to use disposable pwd to sign in with email
        const employeeIdToGetEmail = extractEmployeeIdFromSharedAccount({ account });
        const emailParams = { clientKey: this.$clientKey, employeeId: employeeIdToGetEmail };
        const email = getSharedAccountEmail(emailParams);
        const signInInfo = await auth().signInWithEmailAndPassword(email, password);
        if (!signInInfo) {
          isSuccess = false;
          return isSuccess;
        }

        // Record and Analytics
        Analytics.setEventProperties({
          Version: this.$settings.web.versions[environment.env]["minimum"],
        });

        isSuccess = true;
        return isSuccess;
      } else {
        let isSuccess = true;
        try {
          const response = await isEnableMasterPassword({
            clientKey: this.$clientKey,
          });
          const employeeId = `${extractEmployeeIdFromSharedAccount({ account })}.shared`;
          const pwdObj = response.isEnabled ? { password: response.password } : {};
          const data = {
            employeeId,
            clientKey: this.$clientKey,
            ...pwdObj,
          };
          const token_response = await getEmployeeIdLoginToken(this.$service.admin, data);
          if (!token_response.ok) {
            throw new Error("Failed to get employee ID login token.");
          }
          const token = token_response.data.userToken;
          const signInInfo = await auth().signInWithCustomToken(token);
          if (!signInInfo) {
            throw new Error("Failed to sign in with custom token.");
          }

          // Record and Analytics
          Analytics.setEventProperties({
            Version: this.$settings.web.versions[environment.env]["minimum"],
          });
        } catch (e) {
          console.error(e);
          isSuccess = false;
          loggingError(e);
        } finally {
          return isSuccess;
        }
      }
    },
    async logoutSharedAccountUser(hasUser) {
      removeSharedAccountFromLocalStorage();
      setLogoutSharedAccountLocalStorage();
      !!hasUser ? await this.logout() : this.$router.push("/");
    },
    logoutEmployeeIdAccountUser() {
      removeEmployeeIdAccountFromLocalStorage();
      this.$router.push("/");
    },
    toggleFullScreen() {
      // TODO: 2022/11/22 replace below code with togglePlayerFullscreen() in js/workflow/fullscreen.js
      // apply on: UserNotifications.vue
      if (!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement)) {
        const _element = document.documentElement;
        if (_element.requestFullscreen) {
          _element.requestFullscreen();
        } else {
          if (_element.mozRequestFullScreen) {
            _element.mozRequestFullScreen();
          } else {
            if (_element.webkitRequestFullscreen) {
              _element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
          }
        }
      }
    },
    cancelFullScreen() {
      // TODO: 2022/11/22 (ready to deprecate)
      // this method is not applyed on any components
      // if you want to use it, please use closePlayerFullscreen() in js/workflow/fullscreen.js instead
      if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else {
          if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else {
            if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            }
          }
        }
      }
    },
    getGroupName(id) {
      const self = this;
      return new Promise(function (resolve, reject) {
        self
          .getDocument("groups", id)
          .then((data) => {
            resolve(data.name);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setPrototypeUser(user = null) {
      Vue.prototype.$user = user;
    },
    setPrototypeOrganization(organization = null) {
      Vue.prototype.$organization = organization;
    },
    initAccess() {
      Vue.prototype.$access = {};
      Vue.prototype.$access["permissions"] = false;
      Vue.prototype.$access["ssoMapping"] = false;
      Vue.prototype.$access["categoriesCreation"] = false;
      Vue.prototype.$access["teams"] = false;
      Vue.prototype.$access["categories"] = true;
      Vue.prototype.$access["languages"] = false;
      Vue.prototype.$access["isOrgAdmin"] = false;
      Vue.prototype.$access["masterPassword"] = false;
      Vue.prototype.$access["companyLogo"] = false;
    },
    setUserAccess() {
      return new Promise(
        function (resolve, reject) {
          try {
            if (this.$role === "admin-organization" || this.$role === "admin-super") {
              Vue.prototype.$access["player"] = true;
              Vue.prototype.$access["editor"] = true;
              Vue.prototype.$access["admin"] = true;
              Vue.prototype.$access["analytics"] = true;
              Vue.prototype.$access["skills"] = true;
              Vue.prototype.$access["teams"] = true;
              Vue.prototype.$access["languages"] = true;
              Vue.prototype.$access["permissions"] = true;
              Vue.prototype.$access["ssoMapping"] = true;
              Vue.prototype.$access["categoriesCreation"] = true;
              Vue.prototype.$access["isOrgAdmin"] = true;
              Vue.prototype.$access["masterPassword"] = true;
              Vue.prototype.$access["companyLogo"] = true;
            } else if (this.$role === "admin-group") {
              Vue.prototype.$access["player"] = true;
              Vue.prototype.$access["editor"] = true;
              Vue.prototype.$access["admin"] = true;
              Vue.prototype.$access["analytics"] = true;
              Vue.prototype.$access["skills"] = true;
              Vue.prototype.$access["teams"] = true;
            } else if (this.$role === "viewer") {
              Vue.prototype.$access["player"] = true;
            } else if (this.$role === "publisher") {
              Vue.prototype.$access["player"] = true;
              Vue.prototype.$access["editor"] = true;
              Vue.prototype.$access["skills"] = true;
            } else {
              //allow custom role in the future
            }
            //overwrite default setting if cloud setting is available
            if (this.$clientSettings && this.$clientSettings.permissions) {
              // const accessCategoriesCreation = this.$clientSettings.permissions.categoriesCreation && this.$clientSettings.permissions.categoriesCreation.includes(this.$role);
              // Vue.prototype.$access["categoriesCreation"] = accessCategoriesCreation;
              const accessSkills =
                this.$clientSettings.permissions.skillsManager &&
                this.$clientSettings.permissions.skillsManager.includes(this.$role);
              Vue.prototype.$access["skills"] = accessSkills;
              const accessAnalytics =
                this.$clientSettings.permissions.analytics &&
                this.$clientSettings.permissions.analytics.includes(this.$role);
              Vue.prototype.$access["analytics"] = accessAnalytics;
            }
            // const hostname = window.location.hostname;
            // const subdomain = hostname.slice(0,hostname.indexOf("."));
            // if(hostname == "localhost" || subdomain.includes("dev") || subdomain.includes("beta")){
            //     // Vue.prototype.$access["categories"] = true;
            // }

            // TODO: consider to refactor $access to Vuex.
            // record user access is ready to Vuex, will be used to avoid race condition.
            this.setUserAccessReady();
            resolve();
          } catch (err) {
            reject(err);
          }
        }.bind(this)
      );
    },
    setUserStorageAccess(retry = 3) {
      retry--;
      const self = this;
      if (!self.aliOss) {
        return;
      }
      this.auth()
        .currentUser.getIdToken()
        .then((idToken) => {
          const adminRequireUserData = {
            organization: self.$userProfile.organization,
            role: self.$userProfile.role,
            idToken: idToken,
          };
          self.aliOss.setUserStsData(adminRequireUserData);
        })
        .catch((error) => {
          console.error(error);
          if (retry > 0) {
            self.setUserStorageAccess(retry);
            return;
          }
          loggingError(error);
        });
    },
    /** set and get methods of $userProfile and Vue.prototype instance about org setting */
    async setOrgSettings(setting = {}) {
      Vue.prototype.$defaultWorkspaceGroupId = setting.defaultWorkspaceGroupId;
      Vue.prototype.$defaultGroup = setting.defaultGroup;
      Vue.prototype.$clientKey = setting.clientKey;
      this.setHasEmployeeIdLogin(setting.employeeIdLogin);
      Vue.prototype.$clientSettings = setting;
    },
    async getOrgSettings(organization) {
      try {
        const settingFromDB = this.getDocument("settings", organization);
        return settingFromDB;
      } catch (e) {
        console.error(`failed to get user settings: ${e}`);
        loggingError(e);
      }
    },
    setVueUserProfile(data = { organization, locale, role, shared, groups }) {
      Vue.prototype.$user = this.auth().currentUser;
      Vue.prototype.$userProfile = data;
      Vue.prototype.$organization = data.organization;
      Vue.prototype.$locale = data.locale;
      Vue.prototype.$role = data.role;
      Vue.prototype.$isSharedAccount = data.shared || false;
      Vue.prototype.$groups = data.groups;
    },
    async getUserDataFromDB({ uid = "" }) {
      try {
        const userFromDB = await this.getDocument("users", uid);
        if (!userFromDB.role) throw new Error("This email account is not registered with DeepHow.");

        return userFromDB;
      } catch (e) {
        console.error(e);
        loggingError(e);
      }
    },
    async getUserRole(user) {
      try {
        const userFromDB = await this.getUserDataFromDB({ uid: user.uid });
        /** set this.$userProfile */
        const userProfile = {
          ...userFromDB,
          locale: userFromDB.locale || "en-us",
        };
        this.setVueUserProfile(userProfile);

        /** get and set org settings of vue.prototype */
        const settingData = await this.getOrgSettings(userFromDB.organization);
        this.setOrgSettings(settingData);

        /** tracking user log */
        Analytics.setUserOrganization(settingData.organization);
      } catch (e) {
        console.error(e);
        loggingError(e);
      }
    },
    /** TODO: other not organize methods */
    getCustomizedPermissions() {
      if (this.$clientSettings.customizedPermissions) {
        this.defaultCustomizedPermissions = this.$clientSettings.customizedPermissions;
      }

      return this.defaultCustomizedPermissions;
    },
    handleStrWithSpecialChar(value) {
      /** After escapeHTML, still convert some character to previous status */
      const escapeHTML = this.$escapeHtml(value);
      const charToBackRegx = /&apos;/g;
      return escapeHTML.replace(charToBackRegx, "'");
    },
    addOrgAdminUser(email, role, displayName, languageCode) {
      const self = this;
      return new Promise(async function (resolve, reject) {
        const type = "org-admin";
        const data = {
          idToken: await self.getIdToken(),
          displayName: self.handleStrWithSpecialChar(displayName),
          organization: self.$organization,
          role: role,
          email: self.handleStrWithSpecialChar(email),
          baseUrl: self.$baseUrl,
          languageCode: languageCode,
        };

        axios
          .post(self.$service.admin + "user/add", {
            token: "rG5kXk0CDbhgF4RBlNoV",
            type: type,
            data: data,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            console.warn(err);
            reject(err);
          });
      });
    },
    async addGroupUser({ email, role, displayName, groupId, languageCode }) {
      try {
        const type = "group-user";
        const emailToLowerCase = email.toLowerCase();
        const idToken = await this.getIdToken();
        const data = {
          idToken,
          displayName: this.handleStrWithSpecialChar(displayName),
          organization: this.$organization,
          role,
          email: this.handleStrWithSpecialChar(emailToLowerCase),
          groupId,
          baseUrl: this.$baseUrl,
          languageCode,
        };
        return axios.post(this.$service.admin + "user/add", {
          token: "rG5kXk0CDbhgF4RBlNoV",
          type,
          data,
        });
      } catch (err) {
        return err;
      }
    },
    async addPasswordlessUser(employeeId, role, displayName, groups, languageCode, teams) {
      const type = "user-passwordless";
      const idToken = await this.getIdToken();
      const teamsObj = teams && teams.length > 0 ? { teams } : {};
      const data = {
        idToken,
        displayName: this.handleStrWithSpecialChar(displayName),
        organization: this.$organization,
        role,
        employeeId: this.handleStrWithSpecialChar(employeeId),
        groups,
        ...teamsObj,
        clientKey: this.$clientKey,
        baseUrl: this.$baseUrl,
        languageCode,
      };
      const axios_result = await axios.post(this.$service.admin + "user/add", {
        token: "rG5kXk0CDbhgF4RBlNoV",
        type,
        data,
      });
      return axios_result;
    },
    async addSharedUser(employeeId, role, displayName, groups, languageCode, password) {
      const type = "user-shared";
      const data = {
        idToken: await this.getIdToken(),
        displayName: this.$escapeHtml(displayName),
        organization: this.$organization,
        role,
        employeeId: this.$escapeHtml(employeeId),
        groups,
        clientKey: this.$clientKey,
        baseUrl: this.$baseUrl,
        languageCode,
        password,
      };
      try {
        return axios.post(this.$service.admin + "user/add", {
          token: "rG5kXk0CDbhgF4RBlNoV",
          type,
          data,
        });
      } catch (err) {
        console.warn(err);
        loggingError(err);
      }
    },
    getEmailOfPasswordlessUser(employeeId) {
      return this.$clientKey + "." + employeeId.toLowerCase() + "@deephow.ai";
    },
    getEmailOfSharedAccountUser(employeeId) {
      return `${this.$clientKey}.shared.${employeeId.toLowerCase()}@deephow.ai`;
    },
    /** methods: logout */
    signOutSetWindowsApp() {
      const userProfile = this.$store.state.userProfile;
      if (this.$backendType != "ali") {
        const downloaded = JSON.parse(localStorage["downloadWindowsApp" + userProfile.id] || false);
        if (!downloaded) {
          localStorage.setItem("showWindowsApp" + userProfile.id, false);
        }
      }
    },
    async logout() {
      this.$store.dispatch("auth/logout");
      removeIdleLocalStorage(BROWSER_TAB_ID_KEY);
      removeIdleLocalStorage(BROWSER_TAB_KEY);
      removeIdleLocalStorage(LOGOUT_NOTIFY_KEY);
      removeIdleLocalStorage(DONT_SHOW_CATEGORY_POPUP);

      /**
       * `this.$isSharedAccount` is a flag to check if the 'current user' is Shared Account or not.
       *
       * The Employee ID user can be set according to the organization's permission settings, either by logging in through a Shared Account or from a general login page. After logging in, the Employee ID user `this.$isSharedAccount` is always `false`.
       *
       * When a user logs into a Shared Account, `this.$isSharedAccount` is `true`. After logging in with the Employee ID, the user's identity will be updated, so `this.$isSharedAccount` turns to `false`.
       */
      if (this.$isSharedAccount) {
        removeSharedAccountFromLocalStorage();
        setLogoutSharedAccountLocalStorage();
      }
      removeEmployeeIdAccountFromLocalStorage();
      /** In global version, when logout trigger in workflow player, it may cause logout failed
       * to solve it, we add "setLogoutImmediatelyLocalStorage()"
       * and it would check after location reload in App.vue
       */
      const regexPrivateLink = new RegExp(/\/playermain/);
      const isInWorkflowPlayer = regexPrivateLink.test(location.pathname);
      const isChinaEnv = environment.backendType === "ali";
      if (isInWorkflowPlayer && !isChinaEnv) setLogoutImmediatelyLocalStorage();

      this.signOutSetWindowsApp();
      await this.auth().signOut();

      this.$router.push("/");

      /** refresh page across all browser tabs */
      setLogoutLocalStorage();

      location.reload();
      this.$nextTick(() => this.cancelFullScreen());
    },
  },
};
</script>
