import deephowApiServerWrapper from "@/server/api-server";

export async function getExportProcessesById({ exportProcessId }) {
  return deephowApiServerWrapper("get", `/v1/rustici-export-processes/${exportProcessId}`);
}

export async function getExportProcessesByPagination({ size, page, order, orderBy, exportType = "" }) {
  return deephowApiServerWrapper(
    "get",
    `/v1/rustici-export-processes?size=${size}&page=${page}&export_type=${exportType}&desc=${order}&order_by=${orderBy}`
  );
}

export function getExportProcessesByWorkflowId({ workflowId, exportType }) {
  return deephowApiServerWrapper(
    "get",
    `/v1/rustici-export-processes/available/workflows/${workflowId}?export_type=${exportType}`
  );
}

export function postCourseExportProcess({ workflowIds, exportType, exportSource }) {
  return deephowApiServerWrapper("post", "/v1/rustici-export-processes", { workflowIds, exportType, exportSource });
}

export function retryGetExportProcess({ exportProcessId }) {
  return deephowApiServerWrapper("put", `/v1/rustici-export-processes/${exportProcessId}/retry`);
}
