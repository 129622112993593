<template>
  <div class="annotationTool">
    <template v-if="supportLine">
      <div
        v-for="[widthKey, item] in Object.entries(lineWidthMap)"
        :key="widthKey"
        :class="[
          'annotationTool__style',
          {
            'annotationTool__style--active': item.value === config.strokeWidth,
          },
        ]"
        @click="handleLineWidthChange(item.value)"
      >
        <component :is="item.icon" />
      </div>
      <div class="annotationTool__hr" />
      <div class="annotationTool__color-block" @click="handleSwitchColorPicker($event, 'strokeColor')">
        <div class="annotationTool__color" :style="{ background: config[ANNOTATION_DB_FABRIC_KEY_MAP.strokeColor] }" />
        <v-icon class="annotationTool__color-arrow" color="#9B9B9B" :size="14">keyboard_arrow_down</v-icon>
      </div>
    </template>
    <template v-else-if="supportText">
      <div
        v-for="item in Object.values(textStyleMap)"
        :key="item.key"
        :class="[
          'annotationTool__style',
          {
            'annotationTool__style--active': item.value === config[item.key],
          },
        ]"
        @click="handleTextStyle(item.key, item.value)"
      >
        <component :is="item.icon" />
      </div>
      <div class="annotationTool__hr" />
      <div class="annotationTool__style" @click="handleSwitchColorPicker($event, 'fill')">
        <d-icon-text-color />
        <div class="annotationTool__text-color-style" :style="{ background: config.fill }" />
      </div>
      <div class="annotationTool__style" @click="handleSwitchColorPicker($event, 'backgroundColor')">
        <d-icon-text-bg-color />
        <div class="annotationTool__text-color-style" :style="{ background: config.backgroundColor }" />
      </div>
    </template>
    <div class="annotationTool__colors-picker" v-show="isOpenColorPicker" :style="colorPickerStyle">
      <div
        v-if="colorKey === 'backgroundColor'"
        class="annotationTool__color annotationTool__color--transparent"
        @click="handleColorChange('')"
      />
      <div
        v-for="color in colors"
        :key="color"
        class="annotationTool__color"
        :style="{ background: color }"
        @click="handleColorChange(color)"
      />
    </div>
  </div>
</template>

<script>
import DIconArrowDown from "@/components/icons/DIconArrowDown";
import DIconLineWidthBold from "@/components/icons/annotation/DIconLineWidthBold.vue";
import DIconLineWidthNormal from "@/components/icons/annotation/DIconLineWidthNormal.vue";
import DIconLineWidthThin from "@/components/icons/annotation/DIconLineWidthThin.vue";
import DIconTextBgColor from "@/components/icons/annotation/DIconTextBgColor.vue";
// TODO_WAIT_ICON_WITHOUT_SELECT
import DIconTextBold from "@/components/icons/annotation/DIconTextBold.vue";
import DIconTextColor from "@/components/icons/annotation/DIconTextColor.vue";
import DIconTextItalic from "@/components/icons/annotation/DIconTextItalic.vue";
import {
  ANNOTATION_COLOR,
  ANNOTATION_DB_FABRIC_KEY_MAP,
  ANNOTATION_TYPE,
  ANNOTATION_LINE_WIDTH,
  ANNOTATION_LINE_WIDTH_VALUE,
  ANNOTATION_TEXT_STYLE,
} from "@/constants/annotationStatus";

export default {
  name: "BuilderAnnotationLineTool",
  props: {
    config: Object,
  },
  data() {
    return {
      ANNOTATION_DB_FABRIC_KEY_MAP,
      isOpenColorPicker: false,
      colors: Object.values(ANNOTATION_COLOR),
      colorKey: "",
      colorPickerStyle: {
        left: 0,
        width: 0,
      },
      textStyleMap: {
        [ANNOTATION_TEXT_STYLE.BOLD]: {
          icon: "DIconTextBold",
          key: "fontWeight",
          value: ANNOTATION_TEXT_STYLE.BOLD,
        },
        [ANNOTATION_TEXT_STYLE.ITALIC]: {
          icon: "DIconTextItalic",
          key: "fontStyle",
          value: ANNOTATION_TEXT_STYLE.ITALIC,
        },
      },
      lineWidthMap: {
        [ANNOTATION_LINE_WIDTH.THIN]: {
          icon: "DIconLineWidthThin",
          value: ANNOTATION_LINE_WIDTH_VALUE.THIN,
        },
        [ANNOTATION_LINE_WIDTH.NORMAL]: {
          icon: "DIconLineWidthNormal",
          value: ANNOTATION_LINE_WIDTH_VALUE.NORMAL,
        },
        [ANNOTATION_LINE_WIDTH.BOLD]: {
          icon: "DIconLineWidthBold",
          value: ANNOTATION_LINE_WIDTH_VALUE.BOLD,
        },
      },
    };
  },
  components: {
    DIconArrowDown,
    DIconLineWidthBold,
    DIconLineWidthNormal,
    DIconLineWidthThin,
    DIconTextBgColor,
    DIconTextBold,
    DIconTextColor,
    DIconTextItalic,
  },
  mounted() {},
  computed: {
    supportLine() {
      return [ANNOTATION_TYPE.ARROW, ANNOTATION_TYPE.PEN].includes(this.config.type);
    },
    supportText() {
      return [ANNOTATION_TYPE.TEXT].includes(this.config.type);
    },
  },
  methods: {
    handleColorChange(color) {
      this.isOpenColorPicker = false;
      const id = this.config.id;
      this.$emit("handleNoteStyleChange", {
        key: this.colorKey,
        value: color,
        id,
      });
    },
    handleLineWidthChange(value) {
      const id = this.config.id;
      this.$emit("handleNoteStyleChange", {
        key: "strokeWidth",
        value,
        id,
      });
    },
    handleSwitchColorPicker(e, colorKey) {
      if (this.isOpenColorPicker && this.colorKey === colorKey) {
        this.isOpenColorPicker = false;
        return;
      }
      const space = colorKey === "strokeColor" ? 9 : 0;
      this.colorKey = colorKey;
      this.colorPickerStyle.left = e.currentTarget.offsetLeft - space + "px";
      this.colorPickerStyle.width = e.currentTarget.clientWidth + "px";
      this.isOpenColorPicker = true;
    },
    handleTextStyle(key, value) {
      const id = this.config.id;
      this.$emit("handleNoteStyleChange", {
        key,
        value: this.config[key] === value ? "normal" : value,
        id,
      });
    },
  },
  watch: {
    "config.id": {
      handler(selectedId) {
        if (selectedId) {
          this.isOpenColorPicker = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.annotationTool {
  --line-tool-height: 48px;
  --line-tool-bg: #2c2d32;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  background: var(--line-tool-bg);
  height: var(--line-tool-height);
  padding-left: 8px;
  padding-right: 8px;
  width: auto;
  border-radius: 4px;
}

.annotationTool__style {
  position: relative;
  height: 32px;
  width: 32px;
  margin-right: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.annotationTool__style:hover,
.annotationTool__style--active {
  background-color: rgba(255, 255, 255, 0.1);
}

.annotationTool__hr {
  width: 1px;
  height: 33px;
  background: #4a4a4a;
  margin: 0 4px;
}

.annotationTool__text-color-style {
  position: absolute;
  width: 25px;
  height: 4.5px;
  bottom: 3.5px;
  left: 4px;
}

.annotationTool__color-block {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  margin-left: 6px;
  cursor: pointer;
}

.annotationTool__color {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.annotationTool__color--transparent {
  border: 1px solid white;
  opacity: 0.5;
}

.annotationTool__color-arrow {
  margin-left: 6px;
}

.annotationTool__colors-picker {
  position: absolute;
  background: var(--line-tool-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 0 8px 8px;
  z-index: 30;
  top: 47px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
