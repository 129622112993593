export const ATTACHMENT_STATUS = {
  DIAGRAMS: "diagrams",
  LINKS: "links",
  PDF: "pdf",
};

export const ADDON_TYPE = {
  DIAGRAM: "diagram",
  LINKS: "links",
  PDF: "pdf",
};

export const ADDON_GET_DATA_TYPE = {
  [ADDON_TYPE.DIAGRAM]: "diagrams",
  [ADDON_TYPE.LINKS]: "links",
  [ADDON_TYPE.PDF]: "pdfs",
};

export const ATTACHMENT_FILE_PATH = {
  // Link don't have any file to upload
  [ADDON_TYPE.DIAGRAM]: "/images/diagrams/",
  [ADDON_TYPE.PDF]: "/files/pdf",
};
