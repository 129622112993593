<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 22 22">
    <defs>
      <clipPath id="a">
        <path d="M0 0h22v22H0z"></path>
      </clipPath>
    </defs>
    <g :fill="color" clip-path="url(#a)">
      <rect width=".6" height="5" rx=".3" transform="rotate(-30 5.5 -9.526)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="1.2"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="rotate(-60 5.5 1.473)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="1.1"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="rotate(-90 5.8 5.8)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="1"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="rotate(-120 5.8 7.998)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="0.9"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="rotate(-150 5.8 9.606)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="0.8"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="translate(11 17)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="0.7"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="rotate(-30 37.222 -18.026)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="0.6"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="rotate(-60 20.222 -7.026)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="0.5"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="rotate(-90 14.3 -2.7)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="0.4"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="rotate(-120 10.708 -.502)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="0.3"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="rotate(-150 8.078 1.107)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="0.2"></animate>
      </rect>
      <rect width=".6" height="5" rx=".3" transform="translate(11)">
        <animate attributeName="opacity" dur="1.2s" values="0;1;1;0" repeatCount="indefinite" begin="0.1"></animate>
      </rect>
    </g>
  </svg>
</template>

<script>
export default {
  name: "DIconLoading",
  props: {
    color: {
      type: String,
      default: () => "#4689f4",
    },
  },
};
</script>
