import { render, staticRenderFns } from "./DImage.vue?vue&type=template&id=afd131d6&scoped=true&"
import script from "./DImage.vue?vue&type=script&lang=js&"
export * from "./DImage.vue?vue&type=script&lang=js&"
import style0 from "./DImage.vue?vue&type=style&index=0&id=afd131d6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afd131d6",
  null
  
)

export default component.exports