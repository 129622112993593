<template>
  <div class="VolumeSlider">
    <div @mouseenter="showVolumeSlider">
      <slot></slot>
    </div>
    <div v-if="!IS_TABLET" v-show="showSlider" class="VolumeSlider__wrapper" @mouseleave="showSlider = false">
      <input
        ref="volumeSlider"
        type="range"
        min="0"
        max="1"
        step="0.1"
        :value="isMuted ? 0 : volume"
        @input.stop="updateVolume"
        @blur="showSlider = false"
        class="VolumeSlider__slider"
        :style="{ '--active-track-width': activeTrackWidthPercentage }"
      />
    </div>
  </div>
</template>

<script>
import { IS_TABLET } from "@/constants/device-version";

export default {
  name: "VolumeSlider",
  props: {
    volume: Number,
    isMuted: Boolean,
  },
  data() {
    return {
      showSlider: false,
      IS_TABLET,
    };
  },
  methods: {
    updateVolume(event) {
      const volume = parseFloat(event.target.value);
      this.$emit("update-volume", volume);
    },
    showVolumeSlider() {
      this.showSlider = true;
      this.$nextTick(() => this.$refs.volumeSlider.focus());
    },
  },
  computed: {
    activeTrackWidthPercentage() {
      const percentage = this.isMuted ? 0 : this.volume;
      return `${percentage * 100}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
$track-height: 5px;
$track-radius: 2.5px;
$track-color: #9397a6;
$active-track-color: #4689f3;
$thumb-size: 16px;
$thumb-color: #ffffff;
$thumb-border: 1px solid #979797;
.VolumeSlider {
  z-index: 99;
  position: relative;
  display: inline-block;
  &__wrapper {
    position: absolute;
    top: -178px;
    left: calc(50% - 24px);
    width: 48px;
    height: 164px;
    border-radius: 24px;
    background: #28292ed0;
  }
  &__slider {
    position: absolute;
    left: 22px;
    bottom: 14px;
    width: 126px;
    height: $track-height;
    transform-origin: left top;
    transform: rotate(270deg);
    border-radius: $track-radius;
    background: $track-color;
    -webkit-appearance: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: $track-height;
      border-radius: $track-radius;
      width: var(--active-track-width);
      background-color: $active-track-color;
      z-index: -1;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: $thumb-color;
      border: $thumb-border;
      width: $thumb-size;
      height: $thumb-size;
      border-radius: 50%;
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;
      background: $thumb-color;
      border: $thumb-border;
      width: $thumb-size;
      height: $thumb-size;
      border-radius: 50%;
    }
    &::-ms-thumb {
      -webkit-appearance: none;
      background: $thumb-color;
      border: $thumb-border;
      width: $thumb-size;
      height: $thumb-size;
      border-radius: 50%;
    }
  }
}
</style>
