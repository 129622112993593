<template>
  <div class="SopCollapseFormItem" :class="{ open: isOpen }">
    <div class="SopCollapseFormItem__header">
      <div class="SopCollapseFormItem__header--leftSide">
        <div v-if="!isIntroOrConclusion" class="SopCollapseFormItem__header--index">
          {{ $t("all.step") }} {{ String(index).padStart(2, "0") }}
        </div>
        <div class="SopCollapseFormItem__header--titleBlock">
          <font-awesome-icon class="text-primary" size="lg" :icon="['far', 'arrow-progress']" />
          <input
            class="SopCollapseFormItem__header--titleInput SopCollapseFormItemInput"
            v-model.trim="localItem.step"
            :placeholder="$t('builder.insertStepName')"
            :readonly="isIntroOrConclusion || readonly"
            @click="handleInputClick"
            @input="handleInput"
          />
        </div>
      </div>
      <button class="SopCollapseFormItem__header--toggle" @click="toggleCollapse">
        <font-awesome-icon :class="{ 'fa-rotate-90': isOpen }" :icon="['far', 'chevron-right']" size="lg" />
      </button>
    </div>
    <div class="SopCollapseFormItem__content">
      <textarea
        ref="textareaDescription"
        :placeholder="$t('builder.insertStepDescription')"
        rows="1"
        class="SopCollapseFormItemInput indent"
        name="description"
        v-model.trim="localItem.description"
        :readonly="readonly"
        @click="handleInputClick"
        @input="handleInput"
      />
      <template v-if="!isIntroOrConclusion">
        <div class="SopCollapseFormItem__subItem">
          <div class="title-row">
            <font-awesome-icon class="text-primary" size="lg" :icon="['fas', 'screwdriver-wrench']" />
            <label class="title">{{ $t("mavenSop.toolsNeeded") }}</label>
          </div>
          <textarea
            ref="textareaTool"
            :placeholder="$t('builder.insertToolsNeeded')"
            rows="1"
            class="SopCollapseFormItemInput indent"
            name="tool"
            v-model.trim="localItem.tool"
            :readonly="readonly"
            @click="handleInputClick"
            @input="handleInput"
          />
        </div>
        <div class="SopCollapseFormItem__subItem">
          <div class="title-row">
            <font-awesome-icon class="text-warning" size="lg" :icon="['far', 'triangle-exclamation']" />
            <label class="title">{{ $t("mavenSop.safetyWarnings") }}</label>
          </div>
          <textarea
            ref="textareaSafetyOrWarning"
            :placeholder="$t('builder.insertSafetyWarnings')"
            rows="1"
            class="SopCollapseFormItemInput indent"
            name="safetyOrWarning"
            v-model.trim="localItem.safetyOrWarning"
            :readonly="readonly"
            @click="handleInputClick"
            @input="handleInput"
          />
        </div>
        <div v-if="localItem.step === ''" class="SopCollapseFormItem__attention">
          <font-awesome-icon size="lg" :icon="['far', 'circle-info']" />
          <div>
            <span class="text-white mr-1">{{ `${$t("builder.attention")}:` }}</span>
            {{ $t("builder.sopAttentionDescription") }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "SopCollapseFormItem",
  props: {
    item: Object,
    index: Number,
    total: Number,
    readonly: Boolean,
  },
  data() {
    const DEFAULT_ITEM = { step: "", description: "", tool: "", safetyOrWarning: "" };
    return {
      isOpen: true,
      localItem: { ...DEFAULT_ITEM, ...this.item },
    };
  },
  computed: {
    isIntroOrConclusion() {
      return this.index === 0 || this.total - 1 === this.index;
    },
  },
  mounted() {
    this.adjustTextareaHeight(this.$refs.textareaDescription);
    this.adjustTextareaHeight(this.$refs.textareaTool);
    this.adjustTextareaHeight(this.$refs.textareaSafetyOrWarning);
  },
  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen;
    },
    handleInput(event) {
      if (event.target.tagName === "TEXTAREA") {
        this.adjustTextareaHeight(event);
      }
      this.$emit("update-item", this.localItem, this.index);
    },
    handleInputClick() {
      if (this.readonly) {
        this.$emit("open-edit-confirm");
      }
    },
    adjustTextareaHeight(event) {
      const textarea = event?.target || event;
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + 2 + "px";
    },
  },
};
</script>

<style scoped lang="scss">
.SopCollapseFormItem {
  border-radius: 8px;
  width: 100%;
  background: var(--dGrey2-color);
  text-align: left;
  font-size: 16px;
  &.open > &__header {
    padding: 24px 24px 0px;
  }
  &.open > &__content {
    display: flex;
  }
  &__header {
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--dGrey6-color);
    font-weight: 500;
    gap: 8px;

    &--leftSide {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
    }
    &--index {
      text-transform: capitalize;
      padding-left: 38px;
    }
    &--titleBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      width: 100%;
    }
    &--titleInput {
      font-size: 20px;
      color: white !important;
      user-select: none;
      flex: 1;
    }
    &--toggle {
      font-size: 20px;
      width: 40px;
      height: 40px;
      text-align: right;
    }
  }

  &__content {
    display: none;
    flex-direction: column;
    padding: 0 24px 24px;
    gap: 16px;
  }

  &__subItem {
    width: 100%;
    .title-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
    }
    .title {
      color: white;
      margin-left: 16px;
      font-size: 20px;
    }
  }

  &__attention {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 30px;
    color: var(--dGrey6-color);
  }

  &Input {
    --border-color: transparent;
    width: 100%;
    outline: none;
    resize: none;
    padding: 8px;
    border: 1px dashed var(--border-color);
    transition: all 0.2s ease-in;
    overflow: hidden;
    color: #bcc0cb;
    &:focus:not(:read-only) {
      --border-color: var(--dGrey6-color);
      background: var(--dGrey3-color);
      border-radius: 4px;
      overflow: auto;
    }
    &.indent {
      margin-left: 30px;
      width: calc(100% - 24px);
    }
  }
}
</style>
