<template>
  <div class="d-select">
    <div class="role-label" :class="{ 'role-label--isLabelOnBorder': isLabelOnBorder }">{{ label }}</div>
    <v-menu :disabled="disabled" class="dropdown-menu" transition="slide-y-transition">
      <div class="input-box" slot="activator">
        <div v-if="!!$slots.selection" class="text-xs-left text-no-wrap text-truncate selection-div">
          <slot name="selection" />
        </div>
        <v-text-field
          v-model="inputText"
          solo
          flat
          single-line
          readonly
          background-color="transparent"
          class="input-box-field"
          :label="''"
          :rules="rules"
          :disabled="disabled"
          :autocomplete="autocomplete"
          :type="type"
          :placeholder="placeholder"
          @change="$emit('change')"
        >
          <template slot="append">
            <icon-base width="14" height="14" color="#52545D" class="arrow-icon">
              <d-icon-arrow-back />
            </icon-base>
          </template>
        </v-text-field>
      </div>
      <div v-if="!valueProperty" class="menu-list" :style="{ 'max-width': listWidth + 'px' }">
        <div
          v-for="(item, index) in items"
          :key="index"
          @click="$emit('input', item), $emit('change')"
          class="menu-list-item text-xs-left text-no-wrap text-truncate"
          :class="{ 'current-list-item': item === value }"
        >
          <slot name="item" :item="item" :index="index">
            <!-- fallback content -->
            {{ item }}
          </slot>
        </div>
      </div>
      <div v-else class="menu-list" :style="{ 'max-width': listWidth + 'px' }">
        <div
          v-for="(item, i) in items"
          :key="i"
          @click="$emit('input', item), $emit('change')"
          class="menu-list-item text-xs-left text-no-wrap text-truncate"
          :class="{ 'current-list-item': item[valueProperty] === value[valueProperty] }"
        >
          {{ item[displayProperty] }}
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
export default {
  name: "DSelect",
  props: {
    value: [Object, String], //for v-model value
    label: String,
    rules: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hint: String,
    autocomplete: String,
    appendIcon: String,
    type: String,
    placeholder: String,
    displayProperty: String,
    valueProperty: String,
    listWidth: String,
    isLabelOnBorder: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: function () {
      this.getInputText();
    },
  },
  components: {
    IconBase,
    DIconArrowBack,
  },
  created() {
    this.getInputText();
  },
  mounted() {},
  data() {
    return {
      inputText: "",
    };
  },
  methods: {
    getInputText() {
      if (this.displayProperty) {
        this.inputText = this.value[this.displayProperty];
      } else {
        this.inputText = this.value;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.d-select {
  position: relative;
  width: 100%;
  text-align: left;
  padding-bottom: 24px;
}
.role-label {
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
}
.role-label--isLabelOnBorder {
  position: absolute;
  z-index: 1;
  top: -0.6rem;
  left: 1rem;
  padding: 0 0.5rem;
  color: #9f9f9f;
  background-color: #ffffff;
  font-weight: 400;
}
.input-box {
  position: relative;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 2px 12px 0 12px;
  height: 40px;
}
.dropdown-menu {
  position: relative;
  width: 100%;
}
.input-box-field {
  position: absolute;
  left: 0;
  top: -4px;
  width: 100%;
  margin: 0;
}
.input-box-field ::v-deep .v-text-field__details {
  margin-top: -10px;
}
.v-menu__content {
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
}
.menu-list {
  position: relative;
  width: 100%;
  max-height: 300px;
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 6px 0;
}
.menu-list:hover::-webkit-scrollbar-thumb {
  background-color: #cfcfcf !important;
}
.menu-list-item {
  position: relative;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  padding: 10px 12px;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
}
.menu-list-item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.current-list-item {
  color: #4689f4;
}
.selection-div {
  position: absolute;
  left: 2px;
  top: 2px;
  width: calc(100% - 44px);
  height: calc(100% - 4px);
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  z-index: 1;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 10px;
}
.arrow-icon {
  transform: rotate(-90deg);
}
</style>
