<template>
  <div v-if="!noAccessDropDown" class="headerNavDropdown">
    <div
      v-if="currentSelected"
      class="headerNavDropdown__btn"
      :class="{ 'headerNavDropdown__btn--notClickable': isOnlyOneAccessNavOption }"
      @mousedown="open = !open"
    >
      <font-awesome-icon class="menu-icon text-primary" :icon="currentSelected.icon" />
      <span class="group__title--selected">{{ currentSelected.title }}</span>
      <div v-if="!isTabletDevice && (!isOnlyOneAccessNavOption || !isCurrentSelectedWithAccess)" class="arrows">
        <font-awesome-icon :icon="['fas', 'chevron-down']" color="#9397A6" size="xs" />
      </div>
    </div>
    <div v-if="isOpenedMenu" class="headerNavDropdown__menu" v-click-outside="handleClickOutside">
      <div
        @click="optionClickhandler(option.url)"
        class="menu__item"
        :id="option.id"
        v-for="option in navOptions"
        :key="option.title"
        v-show="option.access"
      >
        <div class="icon__wrap" :class="{ beta: option.isBeta }">
          <font-awesome-icon class="menu-icon text-primary" :icon="option.icon" />
          <span v-if="option.isBeta" class="beta__label__small">Beta</span>
        </div>
        <span class="group__title text-no-wrap text-truncate">{{ option.title }}</span>
      </div>
    </div>
    <span class="beta__label" v-if="currentSelected && currentSelected.isBeta">Beta</span>
  </div>
</template>
<script>
import clickOutsideDirective from "@/directives/click-outside-directive.js";
import { analyticsRoutes, playerRoutes } from "@/constants/routers";
import { IS_TABLET } from "@/constants/device-version";
import { mapGetters, mapState } from "vuex";
import { MAVEN_ROUTE_NAME } from "@/router/routes/MavenRoute.js";

export default {
  name: "DNavDropdown",
  props: {
    access: {
      type: Object,
      required: true,
    },
  },
  directives: {
    "click-outside": clickOutsideDirective,
  },
  data() {
    return {
      isTabletDevice: IS_TABLET,
      open: false,
      mavenRouteName: MAVEN_ROUTE_NAME,
    };
  },
  computed: {
    ...mapState("permission", ["mavenEnable"]),
    ...mapGetters(["getter_is_gen2", "getter_is_allowed_using_maven_features"]),
    ...mapGetters("auth", ["getter_user_ip_passed"]),
    navOptions() {
      return [
        {
          title: this.$t("player.navigator"),
          icon: ["fas", "play"],
          url: "/navigator/home",
          activeRouter: [...playerRoutes, "NavigatorHome"],
          access: this.access.player && !this.getter_is_gen2 && this.getter_user_ip_passed,
          isBeta: false,
        },
        {
          title: this.getter_is_gen2 ? this.$t("all.contentManager") : this.$t("all.sopStudio"),
          icon: ["fas", "bolt"],
          url: "/editorexplorer",
          activeRouter: ["EditorExplorer", "StudioWorkspaceGroup", "StudioWorkspace"],
          access: this.access.editor && this.getter_user_ip_passed,
          isBeta: false,
        },
        {
          title: this.$t("all.admin"),
          icon: ["fas", "gear"],
          url: "/admin/main",
          activeRouter: [
            "AdminMain",
            "AdminWorkspaceGroupWorkspaces",
            // TODO: enable this when we enter phase 2.
            // "AdminWorkspaceGroupAdmins",
            "AdminWorkspaceUsers",
            "AdminWorkspaceTeams",
          ],
          access: this.access.admin,
          isBeta: false,
        },
        {
          title: this.$t("all.analytics"),
          icon: ["fas", "chart-simple"],
          url: "/analytics/main/",
          activeRouter: [...analyticsRoutes],
          access: this.access.analytics && !this.getter_is_gen2 && this.getter_user_ip_passed,
          isBeta: false,
        },
        {
          title: this.$t("all.skills"),
          icon: ["fas", "bullseye-arrow"],
          url: "/skills/explorer/",
          activeRouter: ["SkillsExplorer", "SkillsMatrix"],
          access: this.access.skills && !this.getter_is_gen2 && this.getter_user_ip_passed,
          isBeta: false,
        },
        {
          title: this.$t("maven.mavenChat"),
          icon: ["far", "comments"],
          url: `/${this.mavenRouteName.MAVEN_CHAT}`,
          activeRouter: [this.mavenRouteName.MAVEN_CHAT],
          access: this.getter_is_gen2 || (this.getter_is_allowed_using_maven_features && this.getter_user_ip_passed),
          isBeta: false,
        },
        {
          title: this.$t("maven.map"),
          icon: ["fas", "chart-pie"],
          url: `/${this.mavenRouteName.MAVEN_MAP}`,
          activeRouter: [this.mavenRouteName.MAVEN_MAP],
          access: this.getter_is_gen2 || (this.getter_is_allowed_using_maven_features && this.getter_user_ip_passed),
          isBeta: false,
        },
        {
          title: this.$t("askMaven.askMaven"),
          icon: ["fas", "robot"],
          url: `/${this.mavenRouteName.MAVEN}`,
          activeRouter: [this.mavenRouteName.MAVEN],
          access: this.mavenEnable,
          isBeta: true,
          id: "ug-ask_maven",
        },
      ];
    },
    currentSelected() {
      return this.navOptions.find((op) => op.activeRouter.includes(this.$route.name));
    },
    noAccessDropDown() {
      return this.navOptions.every((op) => !op.access);
    },
    isCurrentSelectedWithAccess() {
      return !!this.currentSelected.access;
    },
    isOnlyOneAccessNavOption() {
      return this.navOptions.filter((option) => option.access).length === 1;
    },
    isOpenedMenu() {
      return this.open && !this.isTabletDevice && (!this.isOnlyOneAccessNavOption || !this.isCurrentSelectedWithAccess);
    },
  },
  methods: {
    optionClickhandler(url) {
      this.$router.push(url);
      this.open = false;
    },
    handleClickOutside() {
      this.open = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.headerNavDropdown {
  position: relative;
  margin-left: 24px;
  display: flex;
  align-items: center;
  &__btn {
    height: 40px;
    background: #1d1e21;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 21px;
    &--notClickable {
      cursor: default;
    }
    .group__title--selected {
      color: #ffffff;
      font-weight: 500;
      margin-inline-start: 15px;
    }
    .arrows {
      line-height: 0px;
      margin-inline-start: 16px;
    }
  }
  &__darkBackground {
    z-index: 6;
    position: fixed;
    top: var(--total-header-height);
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000050;
  }
  &__menu {
    z-index: 6;
    position: absolute;
    top: 56px;
    left: 0;
    min-width: 100px;
    min-height: 100px;
    background: #28292e;
    border: 1px solid #3d3f45;
    border-radius: 8px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-row-gap: 15px;
    padding: 12px;
    gap: 12px;
  }
  .menu-icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
  .menu__item {
    position: relative;
    max-width: 100px;
    min-width: 62px;
    text-decoration: none;
    cursor: pointer;
    .icon__wrap {
      width: 32px;
      height: 32px;
      background: #33353a;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin: 0 auto;
      position: relative;

      &.beta {
        padding-bottom: 8px;
      }
      .beta__label__small {
        background: #4689f3;
        border-radius: 10px;
        padding: 0 4px;
        font-size: 10px;
        color: white;
        position: absolute;
        bottom: -5px;
      }
    }
    .group__title {
      position: relative;
      width: 100%;
      color: #9397a6;
      display: inline-block;
      margin-top: 8px;
      line-height: 16px;
    }
  }
  .menu__item:hover {
    .icon__wrap {
      background: #475267;
    }
    .group__title {
      color: white;
      overflow: visible !important;
    }
  }
  .beta__label {
    background: #4689f3;
    padding: 8px 12px;
    color: white;
    border-radius: 18.5px;
    margin-left: 8px;
  }
}
</style>
