const key = "currentSharedAccount";
export const setSharedAccountInLocalStorage = (accountData) => {
  localStorage.setItem(key, accountData);
};
export const getSharedAccountFromLocalStorage = () => {
  return localStorage.getItem(key);
};
export const removeSharedAccountFromLocalStorage = () => {
  return localStorage.removeItem(key);
};

/**
 * To judge account is shared account or not
 * @param { account: String }
 * @returns Boolean
 */
export const isSharedAccount = ({ account = "" }) => {
  const fixedSharedString = ".shared@";
  return account.toLowerCase().includes(fixedSharedString);
};
/**
 * To get employee ID from shared account
 * account format: {employeeID}.shared@{clientKey}
 * @param { account: String }
 * @returns String - employeeID
 */
export const extractEmployeeIdFromSharedAccount = ({ account = "" }) => {
  const fixedSharedString = ".shared@";
  const index = account.indexOf(fixedSharedString);
  return account.substring(0, index);
};

/**
 * To Get shared account email by clientKey and employee ID
 * @param { clientKey: String, employee: String }
 * @returns String - email
 * email format: {clientKey}.{employeeID with lower case}@deephow.ai
 */
export const getSharedAccountEmail = ({ clientKey = "", employeeId = "" }) => {
  const lowerCaseEmployeeId = employeeId.toLowerCase();
  return `${clientKey}.shared.${lowerCaseEmployeeId}@deephow.ai`;
};

/**
 * To Get shared account by clientKey and employee ID
 * @param { clientKey: String, employee: String }
 * @returns String - shared account
 * account format: {employeeID}.shared@{clientKey}
 */
export const getSharedAccount = ({ clientKey = "", employeeId = "" }) => {
  return `${employeeId}.shared@${clientKey}`;
};

export const isSharedAccountInLocalStorage = () => {
  const sharedAccountData = JSON.parse(getSharedAccountFromLocalStorage());
  return !!sharedAccountData?.refreshToken;
};
