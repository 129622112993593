var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ZoomTimeline"},[_c('div',{staticClass:"ZoomTimeline__display-outer-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.canScrollable),expression:"canScrollable"}],ref:"scrollShadowLeft",staticClass:"ZoomTimeline__display-outer-container--shadow-left"}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.canScrollable),expression:"canScrollable"}],ref:"scrollShadowRight",staticClass:"ZoomTimeline__display-outer-container--shadow-right"}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHintMessage && !_vm.isVoiceOver),expression:"showHintMessage && !isVoiceOver"}],staticClass:"ZoomTimeline__hint-tooltip"},[_c('div',{staticClass:"ZoomTimeline__hint-tooltip--text"},[_vm._v("\n          "+_vm._s(_vm.hintMessage)+"\n          "),_c('div',{staticClass:"ZoomTimeline__hint-tooltip--arrow"})])]),_vm._v(" "),(_vm.targetElm)?_c('div',{staticClass:"ZoomTimeline__time-tooltip",style:({ left: _vm.tooltipData.position })},[_c('div',{staticClass:"ZoomTimeline__time-tooltip--text"},[_vm._v("\n          "+_vm._s(_vm.tooltipData.timeText)+"\n          "),_c('div',{staticClass:"ZoomTimeline__time-tooltip--arrow"})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ZoomTimeline__display-outer-container--slot",style:({
          left: (_vm.startPosition + "%"),
          width: ((_vm.endPosition - _vm.startPosition) + "%"),
        })},[_vm._t("outer-container")],2)]),_vm._v(" "),_c('div',{ref:"scrollContainer",staticClass:"ZoomTimeline__scroll-container"},[_c('div',{ref:"innerContainer",staticClass:"ZoomTimeline__container",style:({
          width: ("" + _vm.zoomValueWidth),
          'max-width': ((_vm.zoomBreakPoints.MAX) + "px"),
        })},[_c('input',{staticClass:"ZoomTimeline__input-range",attrs:{"name":"startTime","type":"range","step":"0.01","min":"0","max":_vm.videoEndTime,"disabled":_vm.disabled,"origin-value":_vm.startTime},domProps:{"value":_vm.startTime},on:{"input":function($event){$event.stopPropagation();_vm.handleInputRangeChange($event)},"change":_vm.trackInputRangeChangeByHeap,"focus":function($event){_vm.targetElm = 'start'},"blur":function($event){_vm.handleResetSelfTargetElmOnly('start')}}}),_vm._v(" "),_c('input',{staticClass:"ZoomTimeline__input-range",attrs:{"name":"endTime","type":"range","step":"0.01","min":"0","max":_vm.videoEndTime,"disabled":_vm.disabled,"origin-value":_vm.endTime},domProps:{"value":_vm.endTime},on:{"input":function($event){$event.stopPropagation();_vm.handleInputRangeChange($event)},"change":_vm.trackInputRangeChangeByHeap,"focus":function($event){_vm.targetElm = 'end'},"blur":function($event){_vm.handleResetSelfTargetElmOnly('end')}}}),_vm._v(" "),_c('div',{ref:"timeline",staticClass:"ZoomTimeline__display-inner-container"},[_c('div',{staticClass:"ZoomTimeline__preview-images"},_vm._l((_vm.previewImages),function(img,index){return _c('d-lazy-image',{key:img + index,attrs:{"aspect-ratio":4 / 3,"height":"100%","src":img}})}),1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasChanged || (_vm.videoEndTime && _vm.isVoiceOver)),expression:"hasChanged || (videoEndTime && isVoiceOver)"}],staticClass:"ZoomTimeline__cover-layer"},[_c('div',{staticClass:"ZoomTimeline__cover-range",class:{ 'ZoomTimeline__cover--delete': _vm.type === 'trim' },style:({
                left: 0,
                width: (_vm.startPosition + "%"),
              })}),_vm._v(" "),_c('div',{staticClass:"ZoomTimeline__cover-range",class:{
                'ZoomTimeline__cover--delete': _vm.type === 'erase',
                'ZoomTimeline__cover--record': _vm.type === 'voiceOver',
              },style:({
                left: (_vm.startPosition + "%"),
                width: ((_vm.endPosition - _vm.startPosition) + "%"),
              })},[_vm._t("range-container")],2),_vm._v(" "),_c('div',{staticClass:"ZoomTimeline__cover-range",class:{ 'ZoomTimeline__cover--delete': _vm.type === 'trim' },style:({
                left: (_vm.endPosition + "%"),
                width: ((100 - _vm.endPosition) + "%"),
              })})]),_vm._v(" "),_c('div',{ref:"startDrag",staticClass:"ZoomTimeline__drag-start-btn",style:({ left: (_vm.startPosition + "%") })},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("navigate_before")])],1),_vm._v(" "),_c('div',{staticClass:"ZoomTimeline__drag-end-btn",style:({ left: (_vm.endPosition + "%") })},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("navigate_next")])],1),_vm._v(" "),_c('div',{ref:"ZoomTimeline__current-time",staticClass:"ZoomTimeline__current-time",class:{ isTouchBlueHandler: _vm.isWhiteThumbTimeEqualBlueHandler },style:({ left: (_vm.currentTimePosition + "%") })}),_vm._v(" "),_c('div',{staticClass:"ZoomTimeline__listen-event-layer",class:{
              'ZoomTimeline__listen-event-layer--tracking': _vm.targetElm === 'currentTime',
              'ZoomTimeline__listen-event-layer--disabled': _vm.disabled,
            },on:{"mousedown":_vm.handleSetCurrentTimeAndTargetElm,"mousemove":_vm.handleSetCurrentTime,"mouseup":function($event){_vm.handleResetSelfTargetElmOnly('currentTime')},"mouseleave":function($event){_vm.handleResetSelfTargetElmOnly('currentTime')}}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }