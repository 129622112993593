<template>
  <div class="aiAssistance">
    <div class="aiAssistance--title">{{ $t("admin.aiAssistance") }}</div>

    <d-admin-setting-switcher
      v-if="getter_quiz_enabled"
      @changePermission="changeQuizPermission"
      :isEnabled="getter_ai_quiz_enabled"
      :title="$t('admin.aiPoweredQuizzing')"
      :description="$t('admin.aiPoweredQuizzingDescription')"
      :enableMessage="$t('admin.enableAiPoweredQuizzingMessage')"
      :disableMessage="$t('admin.disableAiPoweredQuizzingMessage')"
    ></d-admin-setting-switcher>

    <d-admin-setting-switcher
      v-if="getter_multi_modal_sop_feature_enabled"
      beta
      @changePermission="changeMultiModalSopPermission"
      :isEnabled="getter_multi_modal_sop_enabled"
      :title="$t('admin.aiGeneratedMultiModalSop')"
      :description="$t('admin.aiGeneratedMultiModalSopDescription')"
      :enableMessage="$t('admin.enableAiGenerateMultiModalSopMessage')"
      :disableMessage="$t('admin.disableAiGenerateMultiModalSopMessage')"
    ></d-admin-setting-switcher>

    <d-admin-setting-switcher
      v-if="getter_ai_qa_feature_enabled"
      beta
      @changePermission="changeAiQaPermission"
      :isEnabled="getter_ai_qa_enabled"
      :title="$t('admin.workflowQaWithAi')"
      :description="$t('admin.workflowQaWithAiDescription')"
      :enableMessage="$t('admin.enableWorkflowQaWithAiMessage')"
      :disableMessage="$t('admin.disableWorkflowQaWithAiMessage')"
    ></d-admin-setting-switcher>

    <d-admin-setting-switcher
      v-if="getter_ai_summary_feature_enabled"
      beta
      @changePermission="changeAISummaryPermission"
      :isEnabled="getter_ai_summary_enabled"
      :title="$t('admin.aiSummary')"
      :description="$t('admin.aiSummaryDescription')"
      :enableMessage="$t('admin.enableAISummaryMessage')"
      :disableMessage="$t('admin.disableAISummaryMessage')"
    ></d-admin-setting-switcher>
  </div>
</template>

<script>
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import DAdminSettingSwitcher from "@/components/DAdmin/Settings/DAdminSettingSwitcher.vue";
import { settingsMapping } from "@/constants/aiAssistance.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DAdminAiAssistance",
  components: {
    DAdminSettingSwitcher,
  },
  mixins: [MixinUser, MixinDB],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("permission", [
      "getter_quiz_enabled",
      "getter_ai_quiz_enabled",
      "getter_multi_modal_sop_feature_enabled",
      "getter_multi_modal_sop_enabled",
      "getter_ai_qa_feature_enabled",
      "getter_ai_qa_enabled",
      "getter_ai_summary_feature_enabled",
      "getter_ai_summary_enabled",
    ]),
  },
  methods: {
    ...mapActions("global", ["openAlert"]),
    ...mapActions("permission", [
      "getOrgPermissionsSettings",
      "updateQuizSettings",
      "updateMultiModalSopSettings",
      "updateAiQaSettings",
      "updateAISummarySettings",
    ]),
    async updateSetting(settingKey, enabled) {
      const {
        action,
        apiParam,
        enableSuccessfullyMessage,
        disableSuccessfullyMessage,
        enableFailedMessage,
        disableFailedMessage,
      } = settingsMapping[settingKey];
      const updateData = { aiAssistance: { [apiParam]: enabled } };
      const successMessage = enabled ? this.$t(enableSuccessfullyMessage) : this.$t(disableSuccessfullyMessage);
      const errorMessage = enabled ? this.$t(enableFailedMessage) : this.$t(disableFailedMessage);

      try {
        await this[action]({ organization: this.$organization, updateData });
        this.openAlert({ type: "success", message: successMessage });
      } catch (error) {
        this.openAlert({ type: "error", message: errorMessage });
      }
    },
    changeQuizPermission(enabled) {
      this.updateSetting("QUIZ", enabled);
    },
    changeMultiModalSopPermission(enabled) {
      this.updateSetting("MULTI_MODAL_SOP", enabled);
    },
    changeAiQaPermission(enabled) {
      this.updateSetting("AIQA", enabled);
    },
    changeAISummaryPermission(enabled) {
      this.updateSetting("SUMMARY", enabled);
    },
  },
};
</script>

<style scoped lang="scss">
.aiAssistance {
  overflow: scroll;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  padding-bottom: 80px;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;

  &:hover::-webkit-scrollbar-thumb {
    background: #b6b6b6;
  }

  &--title {
    font-family: Roboto;
    letter-spacing: 0;
    color: #3f3f3f;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 20px;
  }
}
</style>
