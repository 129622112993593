<template>
  <div class="DCompositeWorkspaceDropdown">
    <d-dropdown
      :dark="!isSkill && !isAdmin"
      :class="dropdownClass"
      :defaultOptionVal="workspaceGroupId"
      :label="workspaceGroupLabel || $t('all.workspaceGroup')"
      :options="options"
      optionKey="id"
      optionNameKey="name"
      searchable
      searchAutoTest="search-search-for-workspace-group"
      :searchPlaceholder="$t('admin.searchWorkspaceGroups')"
      @choose-option="(workspaceGroupOption) => $emit('change:workspaceGroupId', workspaceGroupOption.id)"
    />
    <d-dropdown
      :key="workspaceId"
      :dark="!isSkill && !isAdmin"
      :class="dropdownClass"
      :defaultOptionVal="workspaceId"
      :label="workspaceLabel || $t('all.workspace')"
      :options="workspaceOptions"
      optionKey="id"
      optionNameKey="name"
      @choose-option="handleWorkspaceChanged"
      searchable
      searchAutoTest="search-search-for-workspace"
      :searchPlaceholder="$t('analytics.searchWorkspace')"
    >
      <template slot="option" slot-scope="{ option }">
        <icon-base
          v-if="hasPrivateWorkspace"
          class="DCompositeWorkspaceDropdown__privateIcon"
          color="#8d909f"
          width="16"
          height="16"
        >
          <d-icon-lock v-if="option.private" />
        </icon-base>
        {{ option.name }}
      </template>
    </d-dropdown>
  </div>
</template>
<script>
import DDropdown from "@/components/ui_components/DDropdown.vue";
import { mapState, mapGetters } from "vuex";
import IconBase from "@/components/IconBase.vue";
import DIconLock from "@/components/icons/DIconLock.vue";

export default {
  name: "DCompositeWorkspaceDropdown",
  props: {
    dropdownClass: {
      type: String,
      required: false,
      default: () => "",
    },
    workspaceId: {
      type: String,
      required: true,
    },
    workspaceLabel: {
      type: String,
      required: false,
    },
    workspaceGroupId: {
      type: String,
      required: true,
    },
    workspaceGroupLabel: {
      type: String,
      required: false,
    },
    initDefaultWorkspaces: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    IconBase,
    DDropdown,
    DIconLock,
  },
  data() {
    return {
      workspacesFromWorkspaceGroup: [],
    };
  },
  created() {
    this.setDefaultWorkspaces();
  },
  computed: {
    ...mapState("workspaceGroups", ["canEditWorkspaceGroups"]),
    ...mapGetters("workspaceGroups", [
      "getter_get_edit_workspace_group_by_workspace_id",
      "getter_admin_workspace_groups",
      "getter_get_admin_workspace_group_by_workspace_id",
    ]),
    isSkill() {
      return this.$route.name === "SkillsExplorer";
    },
    isAdmin() {
      return this.$route.path.split("/")[1] === "admin";
    },
    options() {
      if (this.isAdmin) {
        return this.getter_admin_workspace_groups;
      }
      return this.canEditWorkspaceGroups;
    },
    hasPrivateWorkspace() {
      return this.workspaceOptions.some((workspace) => workspace.private);
    },
    workspaceOptions() {
      if (!this.workspaceGroupId) return [];
      return this.workspacesFromWorkspaceGroup;
    },
  },
  watch: {
    workspaceGroupId(val, old) {
      if (val === old) return;
      const option = this.options.find((op) => op.id === val);
      if (option) {
        this.handleWorkspaceGroupChanged(option);
      }
    },
  },
  methods: {
    getWorkspaceGroupByProduct(workspaceId) {
      if (this.isAdmin) {
        return this.getter_get_admin_workspace_group_by_workspace_id(workspaceId);
      } else {
        return this.getter_get_edit_workspace_group_by_workspace_id(workspaceId);
      }
    },
    setDefaultWorkspaces() {
      const isAllowedSettingWorkspace = this.isSkill || this.isAdmin || this.initDefaultWorkspaces;
      if (!isAllowedSettingWorkspace) return;
      const workspaceGroup = this.getWorkspaceGroupByProduct(this.workspaceId);
      this.workspacesFromWorkspaceGroup = (workspaceGroup && workspaceGroup.workspaces) || [];
    },
    handleWorkspaceGroupChanged(workspaceGroupOption) {
      this.$emit("change:workspaceId", "");
      this.workspacesFromWorkspaceGroup = workspaceGroupOption.workspaces;
    },
    handleWorkspaceChanged(workspaceOption) {
      const workspaceId = workspaceOption.id;
      if (this.workspaceId !== workspaceId) {
        this.$emit("change:workspaceId", workspaceId);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.DCompositeWorkspaceDropdown {
  display: flex;
  flex-direction: column;

  &__privateIcon {
    min-width: 16px;
    margin-right: 8px;
    position: relative;
    top: 3.5px;
  }
}
</style>
