import deephowApiServerWrapper from "@/server/api-server";
import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

const workflowServer = axios.create({
  baseURL:
    environment.backendType === "firebase"
      ? environment.service[environment.env].workflow
      : environment.service[environment.env]["china"].workflow,
});

workflowServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

const workflowServerApiWrapper = apiWrapperGenerator(workflowServer);

export const downloadScormFormat = async (workflowId, resolution) =>
  deephowApiServerWrapper("post", `/v1/workflows/${workflowId}/download-scorm`, { resolution });

export const getWorkflowQuiz = async ({ workflowId, skillId }) =>
  deephowApiServerWrapper("get", `/v1/workflows/${workflowId}/user-quiz`, { params: { skillId } });

export const getWorkflowSop = async (workflowId) =>
  deephowApiServerWrapper("get", `/v1/workflows/${workflowId}/multimodal-sop`);

export const getWorkflowSummary = async (workflowId) =>
  deephowApiServerWrapper("get", `/v1/workflows/${workflowId}/ai-summary`);

// TODO: COMBINE TO DeephowApiServer
export const resetWorkflow = (workflowId) => workflowServerApiWrapper("post", `/v1/workflows/${workflowId}/reset`);

export default workflowServer;
