export const getSortedWorkflows = (rule, targetArray) => {
  if (rule === null) return [];
  let result = [];
  switch (rule) {
    case "alphabetically":
      const navigatorLang = navigator.languages[0] || navigator.language;
      const sortSetting = { numeric: true, ignorePunctuation: true };
      result = targetArray.sort((first, second) => {
        const firstTitle = first.title.trim().toLowerCase();
        const secondTitle = second.title.trim().toLowerCase();
        return firstTitle.localeCompare(secondTitle, navigatorLang, sortSetting);
      });
      return result;
    case "last-updated":
      result = targetArray.sort((first, second) => {
        if (
          !first.publishedDate ||
          !second.publishedDate ||
          first.publishedDate.seconds === second.publishedDate.seconds
        ) {
          return 0;
        } else {
          return first.publishedDate.seconds > second.publishedDate.seconds ? -1 : 1;
        }
      });
      return result;
  }
};
