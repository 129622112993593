import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";
import { CLIENT_ENV } from "../constants/envVersion";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].deephowApiServer
    : environment.service[environment.env]["china"].deephowApiServer;
}

const apiServer = axios.create({
  baseURL: getBaseUrl(),
});

apiServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    config.headers["client-environment"] = CLIENT_ENV;
    return config;
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(apiServer);

export const fetchVersionHistoryByWorkflowId = (workflowId) =>
  apiWrapper("get", `/v1/workflows/${workflowId}/version-histories`);

export default apiServer;
